import { Message } from '../types/chat';

// Additional message threads for conversations 2-20
export const additionalMessages: Record<string, Message[]> = {
  '2': [
    {
      id: '2-1',
      content: "Michael, are you up for a round of golf this weekend?",
      time: '9:30',
      isSender: true,
      status: 'read'
    },
    {
      id: '2-2',
      content: "Definitely! The Singapore Golf Club just renovated their course.",
      time: '9:35',
      isSender: false
    },
    {
      id: '2-3',
      content: "Perfect timing! What's the weather forecast?",
      time: '9:40',
      isSender: true,
      status: 'read'
    },
    {
      id: '2-4',
      content: "The course looks amazing today!",
      time: '9:45',
      isSender: false
    }
  ],
  '3': [
    {
      id: '3-1',
      content: "Hi Sofia! How was your padel session yesterday?",
      time: '8:15',
      isSender: true,
      status: 'read'
    },
    {
      id: '3-2',
      content: "It was fantastic! The new courts at Dubai Sports City are incredible.",
      time: '8:20',
      isSender: false
    },
    {
      id: '3-3',
      content: "Would you like to play again next week?",
      time: '8:25',
      isSender: true,
      status: 'read'
    },
    {
      id: '3-4',
      content: "Thanks for the great padel session!",
      time: '8:30',
      isSender: false
    }
  ],
  '4': [
    {
      id: '4-1',
      content: "David, are we still on for basketball tonight?",
      time: '7:00',
      isSender: true,
      status: 'read'
    },
    {
      id: '4-2',
      content: "Yes, definitely! I've booked the court for 8 PM.",
      time: '7:05',
      isSender: false
    },
    {
      id: '4-3',
      content: "Perfect! I'll bring some extra players.",
      time: '7:10',
      isSender: true,
      status: 'read'
    },
    {
      id: '4-4',
      content: "See you at the basketball court!",
      time: '7:15',
      isSender: false
    }
  ],
  '5': [
    {
      id: '5-1',
      content: "Emma, about tomorrow's tournament...",
      time: '6:05',
      isSender: true,
      status: 'read'
    },
    {
      id: '5-2',
      content: "Yes, I've got all the details. Have you seen the schedule?",
      time: '6:10',
      isSender: false
    },
    {
      id: '5-3',
      content: "Not yet, could you share it with me?",
      time: '6:15',
      isSender: true,
      status: 'read'
    },
    {
      id: '5-4',
      content: "The tournament starts at 9 AM sharp.",
      time: '6:20',
      isSender: false
    }
  ],
  '6': [
    {
      id: '6-1',
      content: "Hey Alexander, how's the tennis club in Zurich?",
      time: '5:30',
      isSender: true,
      status: 'read'
    },
    {
      id: '6-2',
      content: "It's fantastic! They just renovated the indoor courts.",
      time: '5:35',
      isSender: false
    },
    {
      id: '6-3',
      content: "Perfect! Should we book a session for Saturday?",
      time: '5:40',
      isSender: true,
      status: 'read'
    },
    {
      id: '6-4',
      content: "I'll reserve the tennis court for Saturday morning.",
      time: '5:45',
      isSender: false
    }
  ],
  '7': [
    {
      id: '7-1',
      content: "Isabella, are you joining us for skiing this weekend?",
      time: '4:15',
      isSender: true,
      status: 'read'
    },
    {
      id: '7-2',
      content: "Absolutely! Have you seen the weather forecast?",
      time: '4:20',
      isSender: false
    },
    {
      id: '7-3',
      content: "Not yet, how's it looking?",
      time: '4:25',
      isSender: true,
      status: 'read'
    },
    {
      id: '7-4',
      content: "The ski conditions are perfect this weekend!",
      time: '4:30',
      isSender: false
    }
  ],
  '8': [
    {
      id: '8-1',
      content: "James, up for a squash match tomorrow?",
      time: '3:00',
      isSender: true,
      status: 'read'
    },
    {
      id: '8-2',
      content: "Yes! The club's courts are free after 6 PM.",
      time: '3:05',
      isSender: false
    },
    {
      id: '8-3',
      content: "Great, shall we say 6:30 PM?",
      time: '3:10',
      isSender: true,
      status: 'read'
    },
    {
      id: '8-4',
      content: "Looking forward to our squash match tomorrow.",
      time: '3:15',
      isSender: false
    }
  ],
  '9': [
    {
      id: '9-1',
      content: "Sophia, want to play tennis at Roland Garros?",
      time: '2:35',
      isSender: true,
      status: 'read'
    },
    {
      id: '9-2',
      content: "That would be amazing! When are you thinking?",
      time: '2:40',
      isSender: false
    },
    {
      id: '9-3',
      content: "How about tomorrow afternoon?",
      time: '2:45',
      isSender: true,
      status: 'read'
    },
    {
      id: '9-4',
      content: "The Roland Garros courts are booked for us.",
      time: '2:50',
      isSender: false
    }
  ],
  '10': [
    {
      id: '10-1',
      content: "Marcus, fancy a cricket match at Lords?",
      time: '1:15',
      isSender: true,
      status: 'read'
    },
    {
      id: '10-2',
      content: "Just finished a fantastic match there!",
      time: '1:20',
      isSender: false
    },
    {
      id: '10-3',
      content: "How was it? Good turnout?",
      time: '1:25',
      isSender: true,
      status: 'read'
    },
    {
      id: '10-4',
      content: "Great game at the Lords Cricket Ground!",
      time: '1:30',
      isSender: false
    }
  ]
};