import { Post } from '../types/post';

export const posts: Post[] = [
  {
    id: '1',
    author: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80',
      location: 'Monte Carlo Tennis Club'
    },
    content: "Just clinched the Dubai Corporate Tennis Championship! Huge thanks to my doubles partner @UriSchneider. The competition was fierce but we pulled through! 🏆 #TennisLife",
    media: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80',
    likes: 245,
    comments: 42,
    shares: 15,
    timeAgo: '2h ago',
    achievement: 'Tournament Winner'
  },
  {
    id: 'us1',
    author: {
      id: '55',
      name: 'Uri Schneider',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
      location: 'Valencia'
    },
    content: "Excited to share that I've joined the advisory board of the Valencia Tennis Association. Looking forward to contributing to the growth of tennis in our region! 🎾 #TennisLeadership",
    likes: 312,
    comments: 87,
    shares: 45,
    timeAgo: '1h ago'
  },
  {
    id: '2',
    author: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80',
      location: 'Paris'
    },
    content: "Perfect conditions at Golf National today. Shot my personal best! The course improvements are remarkable. Open invitation for a round this weekend. ⛳",
    media: 'https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?auto=format&fit=crop&w=800&q=80',
    likes: 156,
    comments: 31,
    shares: 8,
    timeAgo: '5h ago'
  },
  
  
  {
    id: '8',
    author: {
      id: '61', // Marcus Chen
      name: 'Marcus Chen',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80',
      location: 'Singapore Sports Hub'
    },
    content: "Just wrapped up our quarterly partners' tennis tournament. Great way to strengthen team bonds and stay active! 🎾 #MorganStanleySports",
    media: 'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80',
    likes: 312,
    comments: 54,
    shares: 27,
    timeAgo: '12h ago'
  },
 {
    id: '9',
    author: {
      id: '63', // Alexander Weber
      name: 'Alexander Weber',
      image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=300&q=80',
      location: 'Luxembourg'
    },
    content: "Honored to host the annual Banking & Finance Tennis Cup. Congratulations to all participants! Special thanks to @SarahConnor for an amazing final match. 🏆",
    likes: 289,
    comments: 47,
    shares: 31,
    timeAgo: '1d ago'
  },
  {
    id: '10',
    author: {
      id: '66', // Maria Silva
      name: 'Maria Silva',
      image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?auto=format&fit=crop&w=300&q=80',
      location: 'Madrid'
    },
    content: "Leading the new corporate wellness initiative through sports. Today's tennis session with our executive team was a huge success! 🎾💪",
    media: 'https://images.unsplash.com/photo-1622163642998-1ea32b0bbc67?auto=format&fit=crop&w=800&q=80',
    likes: 345,
    comments: 62,
    shares: 41,
    timeAgo: '1d ago'
  },
  {
    id: '11',
    author: {
      id: '60',
      name: 'Elena Petrova',
      image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
      location: 'Monte Carlo'
    },
    content: "Amazing morning run along the Monaco coastline! Perfect way to start the day and clear the mind. Who's joining me tomorrow? 🏃‍♀️",
    media: 'https://images.unsplash.com/photo-1502904550040-7534597429ae?auto=format&fit=crop&w=800&q=80',
    likes: 156,
    comments: 28,
    shares: 12,
    timeAgo: '3h ago'
  },
  {
    id: '12',
    author: {
      id: '61',
      name: 'Marcus Chen',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80',
      location: 'Singapore'
    },
    content: "Great 'Walk & Talk' session with fellow finance professionals at Gardens by the Bay. Networking while staying active is the future! 🚶‍♂️💼",
    media: 'https://images.unsplash.com/photo-1542361345-89e58247f2d5?auto=format&fit=crop&w=800&q=80',
    likes: 234,
    comments: 45,
    shares: 18,
    timeAgo: '5h ago'
  },
  {
    id: '13',
    author: {
      id: '62',
      name: 'Isabella Martinez',
      image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80',
      location: 'Madrid'
    },
    content: "Just completed my first wellness retreat with the Playclub community. The combination of yoga, meditation, and networking is exactly what busy professionals need! 🧘‍♀️✨",
    media: 'https://images.unsplash.com/photo-1545205597-3d9d02c29597?auto=format&fit=crop&w=800&q=80',
    likes: 312,
    comments: 67,
    shares: 41,
    timeAgo: '6h ago'
  },
  {
    id: '14',
    author: {
      id: '63',
      name: 'Alexander Weber',
      image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=300&q=80',
      location: 'Luxembourg'
    },
    content: "Early morning run through the Kirchberg district with fellow CEOs. Nothing beats combining exercise with valuable business connections! 🏃‍♂️💪",
    media: 'https://images.unsplash.com/photo-1552674605-db6ffd4facb5?auto=format&fit=crop&w=800&q=80',
    likes: 178,
    comments: 34,
    shares: 15,
    timeAgo: '8h ago'
  },
  {
    id: '15',
    author: {
      id: '64',
      name: 'Sophie Laurent',
      image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80',
      location: 'Paris'
    },
    content: "Hosted a mindful walking session around Le Marais. Beautiful discussions about work-life balance and wellness in the corporate world. Join us next week! 🚶‍♀️🌟",
    media: 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?auto=format&fit=crop&w=800&q=80',
    likes: 245,
    comments: 52,
    shares: 23,
    timeAgo: '10h ago'
  },
  {
    id: '16',
    author: {
      id: '65',
      name: 'Richard Thompson',
      image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80',
      location: 'London'
    },
    content: "Weekly wellness challenge completed! 5km run followed by a group meditation session. The perfect mix of physical and mental wellbeing. Who's joining next week? 🏃‍♂️🧘‍♂️",
    media: 'https://images.unsplash.com/photo-1551927336-09d50efd69cd?auto=format&fit=crop&w=800&q=80',
    likes: 289,
    comments: 47,
    shares: 19,
    timeAgo: '12h ago'
  },
  {
    id: '17',
    author: {
      id: '66',
      name: 'Maria Silva',
      image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?auto=format&fit=crop&w=300&q=80',
      location: 'Madrid'
    },
    content: "Incredible sunrise yoga session at Retiro Park! Thanks to everyone who joined. Remember: wellness is not a destination, it's a journey. 🧘‍♀️🌅",
    media: 'https://images.unsplash.com/photo-1506126613408-eca07ce68773?auto=format&fit=crop&w=800&q=80',
    likes: 198,
    comments: 42,
    shares: 16,
    timeAgo: '14h ago'
  },
  {
    id: '18',
    author: {
      id: '67',
      name: 'Daniel Kim',
      image: 'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?auto=format&fit=crop&w=300&q=80',
      location: 'Singapore'
    },
    content: "Just finished leading a mindfulness walk through the Southern Ridges. Amazing conversations about sustainable business practices while staying active! 🚶‍♂️🌿",
    media: 'https://images.unsplash.com/photo-1507041957456-9c397ce39c97?auto=format&fit=crop&w=800&q=80',
    likes: 167,
    comments: 31,
    shares: 14,
    timeAgo: '16h ago'
  },
  {
    id: '19',
    author: {
      id: '68',
      name: 'Emma Watson',
      image: 'https://images.unsplash.com/photo-1534751516642-a1af1ef26a56?auto=format&fit=crop&w=300&q=80',
      location: 'London'
    },
    content: "Fantastic group run through the City! Love how Playclub combines fitness with networking. Met some brilliant minds from the fintech world today. 🏃‍♀️💫",
    media: 'https://images.unsplash.com/photo-1552674605-db6ffd4facb5?auto=format&fit=crop&w=800&q=80',
    likes: 223,
    comments: 38,
    shares: 21,
    timeAgo: '18h ago'
  },
  {
    id: '20',
    author: {
      id: '60',
      name: 'Elena Petrova',
      image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
      location: 'Monte Carlo'
    },
    content: "Today's wellness workshop was a huge success! Thank you to all the executives who joined our discussion on maintaining peak performance through mindful movement. 🧘‍♀️💭",
    media: 'https://images.unsplash.com/photo-1599447421416-3414500d18a5?auto=format&fit=crop&w=800&q=80',
    likes: 276,
    comments: 49,
    shares: 25,
    timeAgo: '20h ago'
  }
];