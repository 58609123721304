import { useState, useCallback, useRef, useEffect } from 'react';

interface UseInfiniteScrollProps {
  totalItems: number;
  itemsPerPage: number;
  preloadThreshold?: number;
  rootMargin?: string;
}

export function useInfiniteScroll({
  totalItems,
  itemsPerPage,
  preloadThreshold = 0.5,
  rootMargin = '500px'
}: UseInfiniteScrollProps) {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const loadingRef = useRef(loading);

  // Keep loading ref in sync
  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  const hasMore = page * itemsPerPage < totalItems;

  const lastElementRef = useCallback((node: HTMLElement | null) => {
    if (loadingRef.current || !hasMore) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loadingRef.current) {
          setLoading(true);
          // Use requestAnimationFrame for smoother loading
          requestAnimationFrame(() => {
            setPage(prev => prev + 1);
            // Short delay to prevent rapid loading
            setTimeout(() => setLoading(false), 100);
          });
        }
      },
      {
        threshold: preloadThreshold,
        rootMargin
      }
    );

    if (node) {
      observer.current.observe(node);
    }
  }, [hasMore, preloadThreshold, rootMargin]);

  const reset = useCallback(() => {
    setPage(1);
    setLoading(false);
    if (observer.current) {
      observer.current.disconnect();
    }
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return {
    page,
    loading,
    hasMore,
    lastElementRef,
    reset
  };
}