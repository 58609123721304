import React from 'react';
import { Globe } from 'lucide-react';

interface UserStatusProps {
  location: string;
  isOnline: boolean;
}

export const UserStatus: React.FC<UserStatusProps> = ({ location, isOnline }) => (
  <div className="flex items-center text-dark-800 text-sm">
    <Globe className="h-3.5 w-3.5 mr-1" />
    {location}
    {isOnline && (
      <span className="ml-2 text-green-400">● Online</span>
    )}
  </div>
);