import React, { useState, useMemo } from 'react';
import { Search, MapPin, Calendar, X } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/dialog';
import { members } from '../../../data/members';
import { Trip } from '../../../types/trip';
import PlayerCard from '../../matchmaking/PlayerCard';
import { citiesMatch } from '../../../lib/city';
import { useTheme } from '../../../contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface TripQuickPlayModalProps {
  isOpen: boolean;
  onClose: () => void;
  trip: Trip;
}

const TripQuickPlayModal: React.FC<TripQuickPlayModalProps> = ({
  isOpen,
  onClose,
  trip
}) => {
  const { isDarkMode } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');

  // Get all members in the city
  const cityMembers = useMemo(() => {
    return members
      .filter(member => citiesMatch(member.city, trip.city))
      .map(member => ({
        id: member.id,
        name: member.name,
        age: member.age,
        location: member.city,
        distance: 0, // They're in the same city
        sports: member.sports,
        rating: member.rating,
        matchPercentage: Math.floor(Math.random() * 15 + 85),
        availability: member.availability,
        image: member.image,
        recentActivity: 'Available during your trip',
        company: {
          name: member.company,
          position: member.jobTitle,
          industry: member.industry
        }
      }));
  }, [trip.city]);

  // Filter members based on search
  const filteredMembers = useMemo(() => {
    return cityMembers.filter(member => {
      const searchTermLower = searchTerm.toLowerCase();
      return searchTerm === '' || 
        member.name.toLowerCase().includes(searchTermLower) ||
        member.sports.some(sport => sport.toLowerCase().includes(searchTermLower));
    });
  }, [cityMembers, searchTerm]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={cn(
        "sm:max-w-2xl p-0",
        isDarkMode ? "bg-dark-200 border-dark-300" : "bg-white border-gray-200"
      )}>
        <DialogHeader className="p-6">
          <DialogTitle className={cn(
            "text-xl font-bold flex items-center space-x-2",
            isDarkMode ? "text-white" : "text-gray-900"
          )}>
            <MapPin className="h-5 w-5 text-gold-400" />
            <span>Players in {trip.city}</span>
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 p-6 pt-0">
          {/* Trip Dates Info */}
          <div className={cn(
            "flex items-center space-x-2 p-4 rounded-xl",
            isDarkMode ? "bg-dark-300 text-dark-800" : "bg-gray-50 text-gray-600"
          )}>
            <Calendar className="h-5 w-5 text-gold-400" />
            <span>
              Your trip: {new Date(trip.startDate).toLocaleDateString()} - {new Date(trip.endDate).toLocaleDateString()}
            </span>
          </div>

          {/* Search */}
          <div className="relative">
            <Search className={cn(
              "absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5",
              isDarkMode ? "text-dark-800" : "text-gray-400"
            )} />
            <input
              type="text"
              placeholder="Search players by name or sport..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={cn(
                "w-full pl-10 pr-4 py-3 rounded-xl border",
                isDarkMode 
                  ? "bg-dark-300 border-dark-400 text-white placeholder-dark-800" 
                  : "bg-white border-gray-200 text-gray-900 placeholder-gray-500"
              )}
            />
          </div>

          {/* Players List */}
          <div className="space-y-4 max-h-[60vh] overflow-y-auto pr-2">
            {filteredMembers.length > 0 ? (
              filteredMembers.map((player) => (
                <PlayerCard
                  key={player.id}
                  player={player}
                  sports={player.sports}
                  dates={[trip.startDate, trip.endDate]}
                  timeSlots={['morning', 'afternoon', 'evening']}
                  onMatchSelect={() => {}}
                />
              ))
            ) : (
              <div className={cn(
                "text-center py-12 rounded-xl",
                isDarkMode ? "bg-dark-300" : "bg-gray-50"
              )}>
                <p className={isDarkMode ? "text-dark-800" : "text-gray-600"}>
                  No players found in {trip.city}
                </p>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TripQuickPlayModal;