import { supabase } from '../supabase';
import { OnboardingData } from '../../types/onboarding';

export async function signUpWithEmail(
  email: string,
  password: string,
  userData: OnboardingData
) {
  console.log('Starting signup process:', { email, userData });
  try {
    if (!email || !password || !userData.fullName || !userData.city) {
      throw new Error('Missing required user data');
    }

    // Check if user already exists (invited user)
    const { data: { user: existingUser }, error: userError } = await supabase.auth.getUser();
    
    if (userError && userError.message !== 'Failed to fetch') {
      console.error('Error checking user:', userError);
      throw userError;
    }

    let authData;
    if (existingUser) {
      // Update password for existing user
      const { data, error: updateError } = await supabase.auth.updateUser({
        password: password
      });

      if (updateError) {
        console.error('Error updating user:', updateError);
        throw updateError;
      }

      authData = { user: existingUser };
    } else {
      // Create new user if not invited
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: userData.fullName
          }
        }
      });

      if (signUpError) {
        console.error('Signup error:', signUpError);
        throw signUpError;
      }

      authData = data;
    }

    if (!authData?.user) {
      console.error('No user data returned from signup');
      throw new Error('Failed to create user account');
    }

    // Create user profile
    const { data: profileData, error: profileError } = await supabase
      .from('users')
      .upsert([{
        auth_id: authData.user.id,
        full_name: userData.fullName,
        city: userData.city,
        avatar_url: userData.profilePic,
        skill_level: 'beginner'
      }], { onConflict: 'auth_id' })
      .select()
      .single();

    if (profileError) {
      console.error('Profile creation error:', profileError);
      throw new Error('Failed to create user profile');
    }

    // Create user profile
    const { error: extendedProfileError } = await supabase
      .from('user_profiles')
      .upsert([{
        user_id: profileData.id,
        bio: '',
        preferences: {
          dateOfBirth: userData.dateOfBirth,
          gender: userData.gender,
          nationality: userData.nationality,
          phone: userData.phone,
          company: userData.company,
          jobTitle: userData.jobTitle,
          linkedinUrl: userData.linkedinUrl
        }
      }], { onConflict: 'user_id' });

    if (extendedProfileError) {
      console.error('Extended profile creation error:', extendedProfileError);
      throw new Error('Failed to create extended user profile');
    }

    // Add user sports
    if (userData.sports && userData.sports.length > 0) {
      console.log('Adding user sports:', userData.sports);
      
      // Delete existing sports first
      await supabase
        .from('user_sports')
        .delete()
        .eq('user_id', profileData.id);

      const { data: existingSports, error: sportsQueryError } = await supabase
        .from('sports')
        .select('id, name')
        .in('name', userData.sports);

      if (sportsQueryError) {
        console.error('Sports query error:', sportsQueryError);
        throw new Error('Failed to fetch sports information');
      }

      const sportsMappings = existingSports.map(sport => ({
        user_id: profileData.id,
        sport_id: sport.id
      }));

      const { error: userSportsError } = await supabase
        .from('user_sports')
        .insert(sportsMappings);

      if (userSportsError) {
        console.error('User sports creation error:', userSportsError);
        throw new Error('Failed to add user sports preferences');
      }
    }

    return authData;
  } catch (error) {
    console.error('Error in signUpWithEmail:', error);
    throw error instanceof Error 
      ? error 
      : new Error('An unexpected error occurred during signup');
  }
}

async function signInWithEmail(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });

  if (error) throw error;
  return data;
}

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  attempt = 1,
  timeout = 5000
): Promise<T> {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    const result = await Promise.race([
      operation(),
      new Promise<never>((_, reject) => {
        controller.signal.addEventListener('abort', () => {
          reject(new Error('Request timeout'));
        });
      })
    ]);

    clearTimeout(timeoutId);
    return result;
  } catch (error) {
    const isNetworkError = error instanceof Error && 
      (error.message.toLowerCase().includes('failed to fetch') ||
       error.message.toLowerCase().includes('network') ||
       error.message.toLowerCase().includes('timeout'));
    
    if (retries > 0 && isNetworkError) {
      const delay = RETRY_DELAY * Math.pow(2, attempt - 1);
      console.log(`Network error, retrying in ${delay}ms... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return fetchWithRetry(operation, retries - 1, attempt + 1);
    }

    // For auth operations, always throw network errors
    if (isNetworkError) {
      throw new Error('Unable to connect to authentication service. Please check your connection and try again.');
    }

    throw error;
  }
}