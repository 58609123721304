import React, { useState } from 'react';
import { Clock, ChevronDown, ChevronUp } from 'lucide-react';
import { cn } from '@/lib/utils';

interface VenueOpeningHoursProps {
  openingHours?: {
    open_now?: boolean;
    weekday_text?: string[];
  };
}

export const VenueOpeningHours: React.FC<VenueOpeningHoursProps> = ({ openingHours }) => {
  const [showFullSchedule, setShowFullSchedule] = useState(false);

  if (!openingHours?.weekday_text?.length) return null;

  const isOpen = openingHours.open_now;
  const currentDay = new Date().getDay();
  // Convert Sunday from 0 to 6 to match the weekday_text array
  const adjustedCurrentDay = currentDay === 0 ? 6 : currentDay - 1;

  return (
    <div className="mb-6">
      <button
        onClick={() => setShowFullSchedule(!showFullSchedule)}
        className={cn(
          "flex items-center justify-between w-full",
          "rounded-xl p-3 transition-colors",
          "dark:bg-dark-300/50 bg-light-200/50",
          "dark:hover:bg-dark-300 hover:bg-light-200"
        )}
      >
        <div className="flex items-center">
          <Clock className="h-5 w-5 dark:text-dark-800 text-light-600 mr-2" />
          <div className="flex items-center">
            <span className={cn(
              "text-sm font-medium mr-2",
              isOpen ? "text-green-400" : "text-red-400"
            )}>
              {isOpen ? 'Open Now' : 'Closed'}
            </span>
            <span className="dark:text-dark-800 text-light-600">
              • {openingHours.weekday_text[adjustedCurrentDay].split(': ')[1]}
            </span>
          </div>
        </div>
        {showFullSchedule ? (
          <ChevronUp className="h-5 w-5 dark:text-dark-800 text-light-600" />
        ) : (
          <ChevronDown className="h-5 w-5 dark:text-dark-800 text-light-600" />
        )}
      </button>

      {showFullSchedule && (
        <div className={cn(
          "mt-3 space-y-2 rounded-xl p-4",
          "dark:bg-dark-300/50 bg-light-200/50"
        )}>
          {openingHours.weekday_text.map((schedule, index) => {
            const [day, hours] = schedule.split(': ');
            const isToday = index === adjustedCurrentDay;
            
            return (
              <div 
                key={day}
                className={cn(
                  "flex justify-between items-center py-1",
                  isToday ? "text-gold-400 font-medium" : "dark:text-white text-light-900"
                )}
              >
                <span>{day}</span>
                <span>{hours}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};