import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { formatDateForInput } from '../../lib/utils/date';

interface DateRangePickerProps {
  dateRange: {
    start: string;
    end: string;
  };
  onDateRangeChange: (range: { start: string; end: string }) => void;
  minDate?: string;
  className?: string;
}

const weekDays = [
  { key: 'sun', label: 'S' },
  { key: 'mon', label: 'M' },
  { key: 'tue', label: 'T' },
  { key: 'wed', label: 'W' },
  { key: 'thu', label: 'T' },
  { key: 'fri', label: 'F' },
  { key: 'sat', label: 'S' }
];

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateRange,
  onDateRangeChange,
  minDate = new Date().toISOString().split('T')[0],
  className
}) => {
  const [currentMonth, setCurrentMonth] = useState(() => {
    return dateRange.start ? new Date(dateRange.start) : new Date();
  });
  const [hoverDate, setHoverDate] = useState<string | null>(null);

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
    
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }
    
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }
    
    return days;
  };

  const handleDateClick = (date: Date) => {
    if (isDateDisabled(date)) return;
    
    const clickedDate = formatDateForInput(date);
    
    if (!dateRange.start || (dateRange.start && dateRange.end)) {
      onDateRangeChange({ start: clickedDate, end: '' });
    } else {
      if (clickedDate < dateRange.start) {
        onDateRangeChange({ start: clickedDate, end: dateRange.start });
      } else {
        onDateRangeChange({ ...dateRange, end: clickedDate });
      }
    }
  };

  const isDateInRange = (date: Date) => {
    const dateStr = formatDateForInput(date);
    if (!dateRange.start) return false;
    if (!dateRange.end) return dateStr === dateRange.start;
    return dateStr >= dateRange.start && dateStr <= dateRange.end;
  };

  const isDateDisabled = (date: Date) => {
    const dateStr = formatDateForInput(date);
    return dateStr < minDate;
  };

  const isDateInHoverRange = (date: Date) => {
    if (!dateRange.start || dateRange.end || !hoverDate) return false;
    const dateStr = formatDateForInput(date);
    return dateStr >= dateRange.start && dateStr <= hoverDate;
  };

  return (
    <div className="bg-dark-300 rounded-lg p-3 max-w-[320px]">
      <div className="flex items-center justify-between mb-2">
        <button
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1))}
          className="p-1 hover:bg-dark-400 rounded-lg transition-colors"
        >
          <ChevronLeft className="h-4 w-4 text-dark-800" />
        </button>
        <span className="text-white text-sm">
          {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </span>
        <button
          onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
          className="p-1 hover:bg-dark-400 rounded-lg transition-colors"
        >
          <ChevronRight className="h-4 w-4 text-dark-800" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1">
        {weekDays.map(day => (
          <div key={day.key} className="text-center text-dark-800 text-xs py-1">
            {day.label}
          </div>
        ))}
        {getDaysInMonth(currentMonth).map((date, index) => (
          <div key={`day-${index}`} className="aspect-square">
            {date ? (
              <button
                onClick={() => handleDateClick(date)}
                onMouseEnter={() => setHoverDate(formatDateForInput(date))}
                onMouseLeave={() => setHoverDate(null)}
                disabled={isDateDisabled(date)}
                className={`w-full h-full flex items-center justify-center text-sm rounded transition-colors ${
                  isDateDisabled(date)
                    ? 'text-dark-800 cursor-not-allowed'
                    : isDateInRange(date)
                    ? 'bg-gold-400 text-dark-50'
                    : isDateInHoverRange(date)
                    ? 'bg-gold-400/50 text-dark-50'
                    : 'text-white hover:bg-dark-400'
                }`}
              >
                {date.getDate()}
              </button>
            ) : (
              <div className="w-full h-full" />
            )}
          </div>
        ))}
      </div>

      {(dateRange.start || dateRange.end) && (
        <div className="mt-2 space-y-1">
          <div className="flex items-center justify-between text-xs">
            <span className="text-dark-800">Start:</span>
            <span className="text-white">
              {dateRange.start ? new Date(dateRange.start).toLocaleDateString() : '-'}
            </span>
          </div>
          <div className="flex items-center justify-between text-xs">
            <span className="text-dark-800">End:</span>
            <span className="text-white">
              {dateRange.end ? new Date(dateRange.end).toLocaleDateString() : '-'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;