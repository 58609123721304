import React, { useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useScrollIndicators } from './hooks/useScrollIndicators';

interface TabScrollerProps {
  children: React.ReactNode;
  className?: string;
}

export const TabScroller: React.FC<TabScrollerProps> = ({ children, className }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { indicators, checkScroll } = useScrollIndicators(scrollRef);

  const scroll = (direction: 'left' | 'right') => {
    const el = scrollRef.current;
    if (!el) return;
    
    const scrollAmount = direction === 'left' ? -200 : 200;
    el.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  return (
    <div className="relative group">
      {/* Scroll Buttons */}
      {indicators.showLeftArrow && (
        <button
          onClick={() => scroll('left')}
          className={cn(
            "absolute left-0 top-1/2 -translate-y-1/2 z-10 h-8 w-8",
            "flex items-center justify-center rounded-full shadow-lg",
            "opacity-0 group-hover:opacity-100 transition-opacity",
            "dark:bg-dark-200/80 dark:hover:bg-dark-300",
            "light:bg-white/80 light:hover:bg-gray-100"
          )}
          aria-label="Scroll left"
        >
          <ChevronLeft className={cn(
            "h-5 w-5",
            "dark:text-white",
            "light:text-gray-700"
          )} />
        </button>
      )}
      
      {/* Scrollable Content */}
      <div
        ref={scrollRef}
        className={cn(
          "overflow-x-auto scrollbar-none scroll-smooth",
          "touch-pan-x", // Enable touch gestures
          className
        )}
        onScroll={checkScroll}
        role="tablist"
      >
        {children}
      </div>

      {/* Right Scroll Button */}
      {indicators.showRightArrow && (
        <button
          onClick={() => scroll('right')}
          className={cn(
            "absolute right-0 top-1/2 -translate-y-1/2 z-10 h-8 w-8",
            "flex items-center justify-center rounded-full shadow-lg",
            "opacity-0 group-hover:opacity-100 transition-opacity",
            "dark:bg-dark-200/80 dark:hover:bg-dark-300",
            "light:bg-white/80 light:hover:bg-gray-100"
          )}
          aria-label="Scroll right"
        >
          <ChevronRight className={cn(
            "h-5 w-5",
            "dark:text-white",
            "light:text-gray-700"
          )} />
        </button>
      )}

      {/* Gradient Indicators */}
      {indicators.showRightArrow && (
        <div className={cn(
          "absolute right-0 top-0 bottom-0 w-12 pointer-events-none",
          "dark:bg-gradient-to-l dark:from-dark-200 dark:to-transparent",
          "light:bg-gradient-to-l light:from-white light:to-transparent"
        )} />
      )}
      {indicators.showLeftArrow && (
        <div className={cn(
          "absolute left-0 top-0 bottom-0 w-12 pointer-events-none",
          "dark:bg-gradient-to-r dark:from-dark-200 dark:to-transparent",
          "light:bg-gradient-to-r light:from-white light:to-transparent"
        )} />
      )}
    </div>
  );
};