import { VenueApiResult } from '../../types/venue';

export type VenueSortOption = 'rating' | 'reviews' | 'distance';

export function sortVenues(
  venues: VenueApiResult[],
  sortBy: VenueSortOption,
  distances: Record<string, number>
): VenueApiResult[] {
  return [...venues].sort((a, b) => {
    switch (sortBy) {
      case 'distance': {
        // Sort by distance (closest first), fallback to rating
        const distanceA = distances[a.id] ?? Infinity;
        const distanceB = distances[b.id] ?? Infinity;
        if (distanceA !== distanceB) {
          return distanceA - distanceB;
        }
        return b.rating - a.rating;
      }

      case 'rating': {
        // Sort by rating (highest first), then by number of reviews
        if (a.rating !== b.rating) {
          return b.rating - a.rating;
        }
        return (b.user_ratings_total || 0) - (a.user_ratings_total || 0);
      }

      case 'reviews': {
        // Sort by number of reviews (highest first), then by rating
        if ((b.user_ratings_total || 0) !== (a.user_ratings_total || 0)) {
          return (b.user_ratings_total || 0) - (a.user_ratings_total || 0);
        }
        return b.rating - a.rating;
      }

      default:
        return 0;
    }
  });
}