import React from 'react';
import { Users, Dumbbell, MapPin, FileText } from 'lucide-react';
import { CommandItem } from '../ui/command';
import { Member } from '../../types/member';
import { Trainer } from '../../types/trainer';
import { Facility } from '../../types/facility';
import { Post } from '../../types/post';
import { SearchItemDetails } from '../../types/search';
import { useTheme } from '../../contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface SearchResultItemProps {
  item: Member | Trainer | Facility | Post;
  type: 'player' | 'trainer' | 'facility' | 'post';
  onSelect: (type: string, id: string) => void;
}

export const SearchResultItem: React.FC<SearchResultItemProps> = ({ 
  item, 
  type, 
  onSelect 
}) => {
  const { isDarkMode } = useTheme();

  const getItemDetails = (): SearchItemDetails => {
    switch (type) {
      case 'player':
      case 'trainer': {
        const member = item as Member;
        return {
          icon: type === 'player' ? <Users className="h-4 w-4 text-gold-400" /> : <Dumbbell className="h-4 w-4 text-gold-400" />,
          title: member.name,
          subtitle: `${member.jobTitle} at ${member.company}`,
          image: member.image
        };
      }
      case 'facility':
        const facility = item as Facility;
        return {
          icon: <MapPin className="h-4 w-4 text-gold-400" />,
          title: facility.name,
          subtitle: facility.location
        };
      case 'post':
        const post = item as Post;
        return {
          icon: <FileText className="h-4 w-4 text-gold-400" />,
          title: post.author?.name || '',
          subtitle: post.content?.substring(0, 60) + '...' || ''
        };
      default:
        return {
          icon: <Users className="h-4 w-4 text-gold-400" />,
          title: '',
          subtitle: ''
        };
    }
  };

  const { icon, title, subtitle, image } = getItemDetails();

  return (
    <CommandItem
      onSelect={() => onSelect(type, item.id)}
      className={cn(
        "flex items-center py-3 px-2 cursor-pointer",
        isDarkMode 
          ? "hover:bg-dark-300" 
          : "hover:bg-gray-100"
      )}
    >
      <div className="flex items-center flex-1 space-x-3">
        {/* Show circular profile image for members/trainers */}
        {(type === 'player' || type === 'trainer') && image ? (
          <img
            src={image}
            alt={title}
            className="w-8 h-8 rounded-full object-cover border border-dark-400"
          />
        ) : (
          <div className={cn(
            "p-2 rounded-full",
            isDarkMode ? "bg-dark-300" : "bg-gray-100"
          )}>
            {icon}
          </div>
        )}
        <div className="min-w-0 flex-1">
          <p className={cn(
            "truncate",
            isDarkMode ? "text-white" : "text-gray-900"
          )}>{title}</p>
          <p className={cn(
            "text-sm truncate",
            isDarkMode ? "text-dark-800" : "text-gray-500"
          )}>{subtitle}</p>
        </div>
      </div>
    </CommandItem>
  );
};
