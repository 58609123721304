import { Activity } from '../types/activity';

export const activities: Activity[] = [
  {
    id: '1',
    sport: '🏃‍♂️ Running Together',
    opponent: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-15',
    time: '14:00',
    venue: {
      name: 'Monte Carlo Tennis Club',
      address: '12 Avenue des Spélugues',
      city: 'Monte Carlo'
    },
    status: 'played'
  },
  {
    id: '2',
    sport: '🚶‍♂️ Walk & Talk',
    opponent: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-20',
    time: '10:00',
    venue: {
      name: 'Royal Golf Club',
      address: '156 Avenue du Golf',
      city: 'Nice'
    },
    status: 'confirmed'
  },
  {
    id: '3',
    sport: '🧘‍♀️ Wellness',
    opponent: {
      id: 'sg1',
      name: 'Michael Zhang',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-22',
    time: '16:00',
    venue: {
      name: 'Singapore Tennis Club',
      address: '45 Marina Boulevard',
      city: 'Singapore'
    },
    status: 'pending',
    direction: 'received',
    isNew: true
  },
  {
    id: '4',
    sport: '🏃‍♂️ Running Together',
    opponent: {
      id: 'lon2',
      name: 'Victoria Palmer',
      image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-10',
    time: '09:00',
    venue: {
      name: 'London Aquatics Centre',
      address: 'Queen Elizabeth Olympic Park, London E20 2ZQ',
      city: 'London'
    },
    status: 'played'
  },
  {
    id: '5',
    sport: '🧘‍♀️ Wellness',
    opponent: {
      id: 'par1',
      name: 'Antoine Dubois',
      image: 'https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-25',
    time: '15:00',
    venue: {
      name: 'Roland Garros',
      address: '2 Avenue Gordon Bennett, 75016 Paris',
      city: 'Paris'
    },
    status: 'confirmed'
  },
  {
    id: '6',
    sport: '🚶‍♂️ Walk & Talk',
    opponent: {
      id: 'dub1',
      name: 'Hassan Al-Rashid',
      image: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-18',
    time: '08:00',
    venue: {
      name: 'Emirates Golf Club',
      address: 'Emirates Hills 2, Dubai',
      city: 'Dubai'
    },
    status: 'pending',
    direction: 'sent'
  },
  {
    id: '7',
    sport: '🧘‍♀️ Wellness',
    opponent: {
      id: 'bcn1',
      name: 'Isabella Romano',
      image: 'https://images.unsplash.com/photo-1534751516642-a1af1ef26a56?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-28',
    time: '17:00',
    venue: {
      name: 'Real Club de Tenis Barcelona',
      address: 'Bosch i Gimpera 5-13, 08034 Barcelona',
      city: 'Barcelona'
    },
    status: 'confirmed'
  },
  {
    id: '8',
    sport: '🏃‍♂️ Running Together',
    opponent: {
      id: 'rom2',
      name: 'Giulia Conti',
      image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80'
    },
    date: '2024-03-30',
    time: '07:00',
    venue: {
      name: 'Roma Aquatics Center',
      address: 'Via delle Terme di Caracalla, 00153 Roma',
      city: 'Rome'
    },
    status: 'confirmed'
  }
];