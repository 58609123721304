import React, { useState } from 'react';
import { Edit2, X, Plus } from 'lucide-react';
import { Trip } from '../../../types/trip';
import NewTripForm from './NewTripForm';
import TripQuickPlayModal from './TripQuickPlayModal';
import TripHeader from './TripHeader';
import TripActions from './TripActions';
import TripCancelConfirmation from './TripCancelConfirmation';

interface TripCardProps {
  trip: Trip;
  type: 'future' | 'past';
  onEdit?: (tripId: string, updatedTrip: Trip) => void;
  onCancel?: (tripId: string) => void;
}

const TripCard: React.FC<TripCardProps> = ({ trip, type, onEdit, onCancel }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showQuickPlayModal, setShowQuickPlayModal] = useState(false);

  const handleEdit = (updatedTrip: Trip) => {
    onEdit?.(trip.id, updatedTrip);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="bg-dark-200 rounded-xl p-4 sm:p-6 border border-dark-300">
        <NewTripForm
          initialData={trip}
          onSubmit={handleEdit}
          onCancel={() => setIsEditing(false)}
        />
      </div>
    );
  }

  return (
    <>
      <div className="bg-dark-200 rounded-xl p-4 sm:p-6 border border-dark-300 hover:border-gold-400 transition-all duration-300">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <TripHeader trip={trip} />

          {type === 'future' && (
            <TripActions
              onQuickPlay={() => setShowQuickPlayModal(true)}
              onEdit={() => setIsEditing(true)}
              onCancel={() => setShowCancelConfirm(true)}
            />
          )}
        </div>

        {showCancelConfirm && (
          <TripCancelConfirmation
            onConfirm={() => onCancel?.(trip.id)}
            onCancel={() => setShowCancelConfirm(false)}
          />
        )}
      </div>

      <TripQuickPlayModal
        isOpen={showQuickPlayModal}
        onClose={() => setShowQuickPlayModal(false)}
        trip={trip}
      />
    </>
  );
};

export default TripCard;