import { useState, useCallback } from 'react';
import { ProfileFormData } from '../../types';
import { validateProfileForm, ValidationErrors } from '../validation';

interface UseProfileFormProps {
  initialData?: any;
  onUpdate?: (data: Partial<ProfileFormData>) => void;
  onNext: () => void;
}

export function useProfileForm({ initialData, onUpdate, onNext }: UseProfileFormProps) {
  const [formData, setFormData] = useState<ProfileFormData>(initialData || {
    fullName: '',
    email: '',
    company: '',
    jobTitle: '',
    linkedinUrl: '',
    dateOfBirth: '',
    gender: '',
    nationality: '',
    city: '',
    phone: ''
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback((field: string, value: string) => {
    const newData = { ...formData, [field]: value };
    setFormData(newData);
    onUpdate?.(newData);

    // Clear error when field is modified
    if (errors[field]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  }, [formData, errors, onUpdate]);

  const handleBlur = useCallback((field: string) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    
    const fieldErrors = validateProfileForm({
      ...formData,
      [field]: formData[field as keyof ProfileFormData]
    });

    if (fieldErrors[field as keyof ValidationErrors]) {
      setErrors(prev => ({
        ...prev,
        [field]: fieldErrors[field as keyof ValidationErrors]
      }));
    }
  }, [formData]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      
      // Mark all fields as touched
      const allFields = Object.keys(formData).reduce((acc, key) => ({
        ...acc, [key]: true
      }), {});
      setTouched(allFields);
      
      // Proceed to next step regardless of validation
      onNext();
      return true;
    } catch (error) {
      console.error('Form submission error:', error);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  }, [formData, onNext]);

  return {
    formData,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setTouched
  };
}