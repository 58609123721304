import React from 'react';
import { MoreVertical, Pencil, Trash2 } from 'lucide-react';
import { 
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../ui/popover';
import { cn } from '@/lib/utils';

interface PostMenuProps {
  onEdit: () => void;
  onDelete: () => void;
  isOwner: boolean;
}

const PostMenu: React.FC<PostMenuProps> = ({ onEdit, onDelete, isOwner }) => {
  if (!isOwner) return null;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="p-2 text-dark-800 hover:text-white transition-colors rounded-lg">
          <MoreVertical className="h-5 w-5" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-48 p-2 bg-dark-200 border-dark-300">
        <div className="space-y-1">
          <button
            onClick={onEdit}
            className={cn(
              "w-full flex items-center px-3 py-2 text-sm rounded-lg",
              "text-white hover:bg-dark-300 transition-colors"
            )}
          >
            <Pencil className="h-4 w-4 mr-2" />
            Edit Post
          </button>
          <button
            onClick={onDelete}
            className={cn(
              "w-full flex items-center px-3 py-2 text-sm rounded-lg",
              "text-red-400 hover:bg-dark-300 transition-colors"
            )}
          >
            <Trash2 className="h-4 w-4 mr-2" />
            Delete Post
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default PostMenu;