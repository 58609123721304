import React from 'react';
import { Star, X, ThumbsUp, MessageCircle } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { VenueApiResult } from '../../../../../types/venue';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface ReviewsModalProps {
  venue: VenueApiResult;
  isOpen: boolean;
  onClose: () => void;
}

export const ReviewsModal: React.FC<ReviewsModalProps> = ({
  venue,
  isOpen,
  onClose
}) => {
  const { isDarkMode } = useTheme();
  
  if (!venue.reviews?.length) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={cn(
        "max-w-2xl p-0",
        isDarkMode ? "bg-dark-200 border-dark-300" : "bg-white border-gray-200"
      )}>
        {/* Header */}
        <DialogHeader className={cn(
          "p-6 border-b",
          isDarkMode ? "border-dark-300" : "border-gray-200"
        )}>
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <DialogTitle className={cn(
                "text-xl font-semibold",
                isDarkMode ? "text-white" : "text-gray-900"
              )}>
                {venue.name}
              </DialogTitle>
              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <Star className="h-5 w-5 text-gold-400 mr-1.5" />
                  <span className={cn(
                    "text-lg font-medium",
                    isDarkMode ? "text-white" : "text-gray-900"
                  )}>
                    {venue.rating.toFixed(1)}
                  </span>
                  <span className={cn(
                    "ml-1.5",
                    isDarkMode ? "text-dark-800" : "text-gray-500"
                  )}>
                    ({venue.user_ratings_total} reviews)
                  </span>
                </div>
                <div className={cn(
                  "flex items-center",
                  isDarkMode ? "text-dark-800" : "text-gray-500"
                )}>
                  <MessageCircle className="h-4 w-4 mr-1.5" />
                  <span>{venue.reviews.length} written reviews</span>
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>

        {/* Reviews List */}
        <div className="max-h-[70vh] overflow-y-auto p-6 space-y-6">
          {venue.reviews?.map((review, index) => (
            <div 
              key={index}
              className={cn(
                "rounded-xl p-6 transition-colors",
                isDarkMode 
                  ? "bg-dark-300/50 hover:bg-dark-300" 
                  : "bg-gray-50 hover:bg-gray-100"
              )}
            >
              {/* Review Header */}
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center space-x-3">
                  {review.profile_photo_url ? (
                    <img
                      src={review.profile_photo_url}
                      alt={review.author_name}
                      className={cn(
                        "w-10 h-10 rounded-full border",
                        isDarkMode ? "border-dark-400" : "border-gray-200"
                      )}
                    />
                  ) : (
                    <div className={cn(
                      "w-10 h-10 rounded-full flex items-center justify-center",
                      isDarkMode ? "bg-dark-400" : "bg-gray-200"
                    )}>
                      <span className={cn(
                        "text-lg",
                        isDarkMode ? "text-white" : "text-gray-700"
                      )}>
                        {review.author_name[0].toUpperCase()}
                      </span>
                    </div>
                  )}
                  <div>
                    <div className={cn(
                      "font-medium",
                      isDarkMode ? "text-white" : "text-gray-900"
                    )}>
                      {review.author_name}
                    </div>
                    <div className={cn(
                      "text-sm",
                      isDarkMode ? "text-dark-800" : "text-gray-500"
                    )}>
                      {new Date(review.time * 1000).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className={cn(
                    "px-3 py-1 rounded-full text-sm font-medium",
                    review.rating >= 4 
                      ? "bg-green-100 text-green-700 dark:bg-green-400/10 dark:text-green-400" 
                      : review.rating >= 3 
                        ? "bg-yellow-100 text-yellow-700 dark:bg-yellow-400/10 dark:text-yellow-400"
                        : "bg-red-100 text-red-700 dark:bg-red-400/10 dark:text-red-400"
                  )}>
                    <div className="flex items-center">
                      <Star className="h-4 w-4 mr-1" />
                      {review.rating}
                    </div>
                  </div>
                </div>
              </div>

              {/* Review Content */}
              <div className="space-y-3">
                <p className={cn(
                  "leading-relaxed",
                  isDarkMode ? "text-dark-800" : "text-gray-600"
                )}>
                  {review.text}
                </p>
                <div className={cn(
                  "flex items-center space-x-4 text-sm",
                  isDarkMode ? "text-dark-800" : "text-gray-500"
                )}>
                  <button className={cn(
                    "flex items-center transition-colors",
                    isDarkMode 
                      ? "hover:text-white" 
                      : "hover:text-gray-900"
                  )}>
                    <ThumbsUp className="h-4 w-4 mr-1.5" />
                    Helpful
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};