import React from 'react';
import MatchStatistics from '../../performance/MatchStatistics';
import BlurredStatisticsOverlay from '../overlays/BlurredStatisticsOverlay';

const StatisticsTab: React.FC = () => {
  return (
    <div className="relative min-h-[600px]">
      {/* Scrollable content with blur */}
      <div className="absolute inset-0 overflow-y-auto">
        <div className="blur-sm pointer-events-none">
          <MatchStatistics />
        </div>
      </div>

      {/* Centered overlay */}
      <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
        <div className="pointer-events-auto">
          <BlurredStatisticsOverlay />
        </div>
      </div>
    </div>
  );
};

export default StatisticsTab;