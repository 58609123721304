import React from 'react';
import { Search } from 'lucide-react';
import { Input } from '../../../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../../ui/select';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface ConnectionFiltersProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  sortBy: 'name' | 'distance' | 'mutual';
  onSortChange: (value: 'name' | 'distance' | 'mutual') => void;
  establishedConnections: number;
  pendingCount: number;
}

const ConnectionFilters: React.FC<ConnectionFiltersProps> = ({
  searchTerm,
  onSearchChange,
  sortBy,
  onSortChange,
  establishedConnections,
  pendingCount
}) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={cn(
      "rounded-xl p-4 border",
      isDarkMode ? "bg-dark-200 border-dark-300" : "bg-white border-gray-200"
    )}>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <span className={cn(
          isDarkMode ? "text-dark-800" : "text-gray-500"
        )}>
          {establishedConnections} established {establishedConnections === 1 ? 'connection' : 'connections'}
        </span>
        
        <div className="flex items-center gap-4 w-full sm:w-auto">
          <div className="relative flex-1 sm:flex-initial">
            <Search className={cn(
              "absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5",
              isDarkMode ? "text-dark-800" : "text-gray-400"
            )} />
            <Input
              type="text"
              placeholder="Search connections..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className={cn(
                "pl-10",
                isDarkMode 
                  ? "bg-dark-300 border-dark-400 text-white placeholder-dark-800" 
                  : "bg-white border-gray-200 text-gray-900 placeholder-gray-500"
              )}
            />
          </div>

          <Select value={sortBy} onValueChange={(value: any) => onSortChange(value)}>
            <SelectTrigger className={cn(
              isDarkMode 
                ? "bg-dark-300 border-dark-400 text-white" 
                : "bg-white border-gray-200 text-gray-900"
            )}>
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="name">Sort by Name</SelectItem>
              <SelectItem value="distance">Sort by Distance</SelectItem>
              <SelectItem value="mutual">Sort by Mutual Friends</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default ConnectionFilters;