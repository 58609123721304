import { supabase } from '../supabase';
import { UserProfile } from '../../types/profile';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export async function getUserProfile() {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) {
      console.error('Auth error:', authError);
      // For network errors, return null to allow fallback to cache
      if (authError.message?.includes('Failed to fetch')) {
        return null;
      }
      throw authError;
    }
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await fetchWithRetry(() => supabase
      .from('users')
      .select(`
        id,
        auth_id,
        full_name,
        avatar_url,
        city,
        company,
        job_type,
        user_profiles (
          bio,
          preferences
        ),
        user_sports (
          sports (
            name,
            emoji
          )
        )
      `)
      .eq('auth_id', user.id)
      .single(), 3); // Add retry count

    if (error) {
      console.error('Profile fetch error:', error);
      // For network errors, return null to allow fallback to cache
      if (error.message?.includes('Failed to fetch')) {
        return null;
      }
      throw error;
    }

    const transformedData = {
      ...data,
      preferences: data.user_profiles?.[0]?.preferences || {}
    };

    return transformedData;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    // For network errors, return null to allow fallback to cache
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      return null;
    }
    throw error;
  }
}

export async function updateUserProfile(updates: Partial<UserProfile>) {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;
    if (!user) throw new Error('Not authenticated');

    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userError) throw userError;
    if (!userData) throw new Error('User not found');

    const userUpdates = {
      full_name: updates.full_name,
      city: updates.city,
      avatar_url: updates.avatar_url,
      company: updates.company,
      job_type: updates.job_type
    };

    const { error: userUpdateError } = await supabase
      .from('users')
      .update(userUpdates)
      .eq('id', userData.id);

    if (userUpdateError) throw userUpdateError;

    const userProfileData = updates.user_profiles?.[0];
    if (userProfileData) {
      const { data: existingProfile } = await supabase
        .from('user_profiles')
        .select('preferences')
        .eq('user_id', userData.id)
        .single();

      const mergedPreferences = {
        ...(existingProfile?.preferences || {}),
        ...userProfileData.preferences
      };

      const { error: profileError } = await supabase
        .from('user_profiles')
        .upsert({
          user_id: userData.id,
          bio: userProfileData.bio || '',
          preferences: mergedPreferences
        }, {
          onConflict: 'user_id'
        });

      if (profileError) throw profileError;
    }

    return true;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
}