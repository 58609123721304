import React from 'react';
import { MapPin } from 'lucide-react';
import SocialLinks from '../../social/SocialLinks';

interface PlayerInfoProps {
  name: string;
  location: string;
  distance?: number;
  company: {
    name: string;
    position: string;
  };
  matchPercentage: number;
  mutualConnections?: number;
  onClick: () => void;
  onShowMutuals?: () => void;
  linkedinUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
}

const PlayerInfo: React.FC<PlayerInfoProps> = ({
  name,
  location,
  distance,
  company,
  matchPercentage,
  mutualConnections,
  onClick,
  onShowMutuals,
  linkedinUrl,
  facebookUrl,
  instagramUrl
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-start justify-between text-center sm:text-left">
      <div className="space-y-1.5">
        <button
          onClick={onClick}
          className="text-lg font-medium text-white hover:text-gold-400 transition-colors"
        >
          {name}
        </button>
        <div className="flex items-center justify-center sm:justify-start text-dark-800 gap-1">
          <MapPin className="h-4 w-4 flex-shrink-0" />
          <span className="truncate">{location}</span>
          {typeof distance === 'number' && (
            <>
              <span className="mx-1">•</span>
              <span>{distance === 0 ? 'Same city' : `${distance}km away`}</span>
            </>
          )}
        </div>
        <div className="text-dark-800 w-full sm:max-w-[300px] lg:max-w-[400px]">
          <span className="inline-block truncate align-bottom">
            {company.position}
          </span>
          <span className="mx-1">at</span>
          <span className="inline-block truncate align-bottom">
            {company.name}
          </span>
        </div>
        {mutualConnections && mutualConnections > 0 && (
          <button
            onClick={onShowMutuals}
            className="text-dark-800 hover:text-gold-400 transition-colors text-sm"
          >
            {mutualConnections} mutual connection{mutualConnections !== 1 ? 's' : ''}
          </button>
        )}
        <SocialLinks
          linkedinUrl={linkedinUrl}
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          className="justify-center sm:justify-start mt-2"
        />
      </div>
      <span className="inline-flex items-center px-3 py-1 bg-gold-400/10 text-gold-400 rounded-full text-sm mt-2 sm:mt-0 mx-auto sm:mx-0">
        {matchPercentage}% Match
      </span>
    </div>
  );
};

export default PlayerInfo;