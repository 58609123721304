import React from 'react';
import { ShieldCheck } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../ui/dialog';
import { Button } from '../../ui/button';

interface BlockSuccessProps {
  isOpen: boolean;
  onClose: () => void;
  username: string;
}

export const BlockSuccess: React.FC<BlockSuccessProps> = ({
  isOpen,
  onClose,
  username
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <div className="flex justify-center mb-4">
            <div className="bg-green-400/20 p-3 rounded-full">
              <ShieldCheck className="h-6 w-6 text-green-400" />
            </div>
          </div>
          <DialogTitle className="text-center">User Blocked</DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <p className="text-center text-dark-800">
            You have successfully blocked {username}. They can no longer interact with you on the platform.
          </p>
        </div>

        <DialogFooter>
          <Button
            onClick={onClose}
            className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};