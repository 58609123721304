import React from 'react';
import { Building2 } from 'lucide-react';
import { facilities } from '../../data/facilities';
import VenueCard from './VenueCard';

interface CityVenuesListProps {
  city: string;
}

const CityVenuesList: React.FC<CityVenuesListProps> = ({ city }) => {
  const cityFacilities = facilities.filter(facility => facility.location === city);

  if (cityFacilities.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl">
        <Building2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Venues Found</h3>
        <p className="text-dark-800">
          No sports facilities are currently available in {city}
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {cityFacilities.map((facility) => (
        <VenueCard key={facility.id} facility={facility} />
      ))}
    </div>
  );
};

export default CityVenuesList;