import React from 'react';
import { Clock } from 'lucide-react';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../../ui/select';
import { cn } from '@/lib/utils';

interface TimeSelectorProps {
  selectedTime: string;
  onTimeChange: (time: string) => void;
  className?: string;
}

const TimeSelector: React.FC<TimeSelectorProps> = ({
  selectedTime,
  onTimeChange,
  className
}) => {
  const timeSlots = [
    '09:00', '10:00', '11:00', '12:00', '13:00', 
    '14:00', '15:00', '16:00', '17:00', '18:00'
  ];

  // Ensure time is in HH:mm format
  const formatTime = (time: string) => {
    if (!time) return '';
    console.log('TimeSelector formatting time:', { inputTime: time });
    // If time is already in correct format, return it
    if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) return time;
    // Otherwise try to parse and format it
    try {
      const [hours, minutes] = time.split(':');
      const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
      console.log('TimeSelector formatted time:', { formattedTime });
      return formattedTime;
    } catch (e) {
      console.error('Error formatting time:', e);
      return '';
    }
  };

  const formattedTime = formatTime(selectedTime);
  console.log('TimeSelector component state:', {
    selectedTime,
    formattedTime,
    timeSlots
  });

  return (
    <div className={cn("space-y-2", className)}>
      <label className="block text-dark-800">Time</label>
      <div className="relative">
        <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800 pointer-events-none" />
        <Select 
          value={formattedTime}
          onValueChange={(value) => {
            console.log('TimeSelector value changed:', { newValue: value });
            onTimeChange(value);
          }}
        >
          <SelectTrigger className="w-full pl-10 bg-dark-300 border-dark-400 text-white">
            <SelectValue placeholder={formattedTime || "Select time"} />
          </SelectTrigger>
          <SelectContent>
            {timeSlots.map((time) => (
              <SelectItem key={time} value={time}>
                {time}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default TimeSelector;