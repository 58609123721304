import React from 'react';
import { MapPin, Briefcase, Facebook, Linkedin, Instagram } from 'lucide-react';
import { getCurrentTitle } from '../../../data/points';
import { sportEmojis } from '../../../lib/constants';
import { Member } from '../../../types/member';
import ProfileImage from './ProfileImage';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import SocialLinks from '../../social/SocialLinks';

interface ProfileHeaderProps {
  member: Member;
  cityImage: string;
  onImageChange?: (file: File) => void;
  profile?: any;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  member,
  cityImage,
  onImageChange,
  profile,
}) => {
  const { isDarkMode } = useTheme();

  // Memoize current title to prevent unnecessary recalculations
  const currentTitle = React.useMemo(() => {
    return getCurrentTitle(member.points.total);
  }, [member.points.total]);

  // Memoize job info to prevent unnecessary recalculations
  const jobInfo = React.useMemo(() => {
    const preferences = profile?.user_profiles?.[0]?.preferences;
    return {
      jobTitle: preferences?.jobTitle || member?.jobTitle || 'Role',
      company: preferences?.company || member?.company || 'Company',
    };
  }, [profile?.user_profiles, member?.jobTitle, member?.company]);

  // Memoize sports transformation
  const userSports = React.useMemo(() => {
    if (profile?.user_sports?.length) {
      return profile.user_sports.map(
        (us: any) => ({
          name: us.sports.name,
          emoji: us.sports.emoji
        })
      );
    }
    // Map member sports to include emojis from sportEmojis mapping
    return member.sports.map(sport => ({
      name: sport,
      emoji: sportEmojis[sport] || '🎯'
    }));
  }, [profile?.user_sports, member.sports]);

  // Get social links from profile
  const socialLinks = React.useMemo(() => {
    const preferences = profile?.user_profiles?.[0]?.preferences;
    return {
      linkedinUrl: preferences?.linkedinUrl,
      facebookUrl: preferences?.facebookUrl,
      instagramUrl: preferences?.instagramUrl
    };
  }, [profile?.user_profiles?.[0]?.preferences]);

  return (
    <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
      <div className="relative h-64">
        <img
          src={cityImage}
          alt={`${member.city} cityscape`}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent" />
      </div>

      <div className="relative px-6 pb-8">
        <div className="flex flex-col items-center -mt-16">
          <div className="relative">
            <ProfileImage
              src={profile?.avatar_url || member.image}
              alt={member.name}
              size={128}
              onImageChange={onImageChange}
              className="shadow-xl ring-4 ring-dark-200"
            />
          </div>
          <div className="mt-4 text-center">
            <h2 className="text-2xl font-bold text-white">
              {profile?.full_name || member?.name}
            </h2>
            {userSports?.length > 0 && (
              <div className="flex flex-wrap justify-center gap-1.5 mt-2 mb-3">
                {userSports.map((sport, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-1 bg-dark-300 rounded-full text-sm text-white hover:bg-dark-400 transition-colors"
                  >
                    {sport.emoji} {sport.name}
                  </span>
                ))}
              </div>
            )}
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <MapPin className="h-4 w-4 mr-1" />
              {profile?.city || member.city}
            </div>
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <Briefcase className="h-4 w-4 mr-1" />
              {jobInfo.jobTitle} at {jobInfo.company}
            </div>
            <SocialLinks
              linkedinUrl={socialLinks.linkedinUrl}
              facebookUrl={socialLinks.facebookUrl}
              instagramUrl={socialLinks.instagramUrl}
              className="mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;