import React, { useState } from 'react';
import FutureTrips from './FutureTrips';
import PastTrips from './PastTrips';
import { Trip } from '../../../types/trip';

const MyTripsTab: React.FC = () => {
  const [activeSection, setActiveSection] = useState<'future' | 'past'>('future');

  return (
    <div className="space-y-6">
      {/* Section Toggle */}
      <div className="flex space-x-4 border-b border-dark-300">
        <button
          onClick={() => setActiveSection('future')}
          className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
            activeSection === 'future'
              ? 'border-gold-400 text-gold-400'
              : 'border-transparent text-dark-800 hover:text-white'
          }`}
        >
          Future Trips
        </button>
        <button
          onClick={() => setActiveSection('past')}
          className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
            activeSection === 'past'
              ? 'border-gold-400 text-gold-400'
              : 'border-transparent text-dark-800 hover:text-white'
          }`}
        >
          Past Trips
        </button>
      </div>

      {/* Content */}
      {activeSection === 'future' ? <FutureTrips /> : <PastTrips />}
    </div>
  );
};

export default MyTripsTab;