interface CompressOptions {
  maxWidth: number;
  maxHeight: number;
  quality: number;
}

export async function compressImage(
  file: File,
  options: CompressOptions = {
    maxWidth: 2048,
    maxHeight: 2048,
    quality: 0.8
  }
): Promise<File> {
  return new Promise((resolve, reject) => {
    try {
      // Create image element
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        // Calculate new dimensions
        let { width, height } = img;
        if (width > options.maxWidth) {
          height = Math.round((height * options.maxWidth) / width);
          width = options.maxWidth;
        }
        if (height > options.maxHeight) {
          width = Math.round((width * options.maxHeight) / height);
          height = options.maxHeight;
        }

        // Create canvas
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        // Draw and compress image
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        ctx.drawImage(img, 0, 0, width, height);

        // Convert to blob
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error('Failed to compress image'));
              return;
            }

            // Create new file
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now()
            });

            // Clean up
            URL.revokeObjectURL(img.src);

            resolve(compressedFile);
          },
          file.type,
          options.quality
        );
      };

      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        reject(new Error('Failed to load image'));
      };
    } catch (error) {
      reject(error instanceof Error ? error : new Error('Failed to compress image'));
    }
  });
}