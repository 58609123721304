import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '../ui/dialog';
import { useTheme } from '../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import { useQuickMatch } from '../../hooks/matchmaking/useQuickMatch';
import QuickPlayFilters from './filters/QuickPlayFilters';
import SearchingState from './modals/SearchingState';
import PlayerResults from './modals/PlayerResults';
import MatchSuccessMessage from './modals/MatchSuccessMessage';

interface QuickMatchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const QuickMatchModal: React.FC<QuickMatchModalProps> = ({
  isOpen,
  onClose
}) => {
  const { isDarkMode } = useTheme();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<any | null>(null);

  const {
    potentialPlayers,
    searchState,
    isInitialSearch,
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    sports,
    setSearchTerm,
    setSelectedSport,
    setSelectedGender,
    setSelectedRating,
    setShowConnectionsOnly,
    filterPlayers,
    resetSearch
  } = useQuickMatch();

  // Reset search when modal is opened
  useEffect(() => {
    if (isOpen) {
      handleSearchAgain();
    }
  }, [isOpen]);

  // Reset showFilters when screen size changes
  useEffect(() => {
    const handleResize = () => {
      setShowFilters(window.innerWidth >= 640);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearchAgain = () => {
    resetSearch();
    setSelectedMatch(null);
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={cn(
        "sm:max-w-2xl p-0",
        isDarkMode 
          ? "bg-dark-200 border-dark-300" 
          : "bg-white border-gray-200"
      )}>
        {searchState === 'searching' && (
          <SearchingState onSearchComplete={() => {}} />
        )}

        {(searchState === 'found' || searchState === 'not-found') && (
          <div className="p-6">
            <QuickPlayFilters
              searchTerm={searchTerm}
              selectedSport={selectedSport}
              selectedGender={selectedGender}
              selectedRating={selectedRating}
              showConnectionsOnly={showConnectionsOnly}
              onSearchChange={handleSearchChange}
              onSportChange={setSelectedSport}
              onGenderChange={setSelectedGender}
              onRatingChange={setSelectedRating}
              onConnectionsOnlyChange={setShowConnectionsOnly}
              sports={sports}
              showFilters={showFilters}
              onToggleFilters={() => setShowFilters(!showFilters)}
              onSearchAgain={handleSearchAgain}
              className="mb-6"
            />

            <PlayerResults
              players={potentialPlayers}
              onMatchSelect={setSelectedMatch}
              onSearchAgain={handleSearchAgain}
              isSearching={isInitialSearch}
            />
          </div>
        )}

        {searchState === 'request-sent' && selectedMatch && (
          <MatchSuccessMessage
            playerName={selectedMatch.player.name}
            onClose={onClose}
            onViewActivities={() => {}}
            onStartChat={() => {}}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default QuickMatchModal;