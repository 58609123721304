import React, { useState } from 'react';
import { Search, UserX } from 'lucide-react';
import { useBlockStore } from '../../../../store/blockStore';
import BlockedMembersList from './BlockedMembersList';
import UnblockConfirmModal from './UnblockConfirmModal';

const BlockedMembersTab: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const { blockedMembers, unblockMember } = useBlockStore();

  const filteredMembers = blockedMembers.filter(member =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUnblock = async (memberId: string) => {
    await unblockMember(memberId);
    setSelectedMember(null);
  };

  return (
    <div className="space-y-6">
      {/* Search */}
      {blockedMembers.length > 0 && (
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
          <input
            type="text"
            placeholder="Search blocked members..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
          />
        </div>
      )}

      {/* Blocked Members List */}
      <BlockedMembersList
        members={filteredMembers}
        onUnblock={setSelectedMember}
      />

      {/* Unblock Confirmation Modal */}
      {selectedMember && (
        <UnblockConfirmModal
          isOpen={true}
          onClose={() => setSelectedMember(null)}
          onConfirm={() => handleUnblock(selectedMember)}
          memberName={blockedMembers.find(m => m.id === selectedMember)?.name || ''}
        />
      )}
    </div>
  );
};

export default BlockedMembersTab;