import React from 'react';
import { cn } from '@/lib/utils';

interface ActivitySkeletonProps {
  count?: number;
  className?: string;
}

const ActivitySkeleton: React.FC<ActivitySkeletonProps> = ({ 
  count = 3,
  className 
}) => {
  return (
    <div className={cn("space-y-4", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div 
          key={index}
          className="bg-dark-200 rounded-xl p-4 border border-dark-300 animate-pulse"
        >
          <div className="flex items-start space-x-4">
            {/* Avatar Skeleton */}
            <div className="relative flex-shrink-0">
              <div className="w-20 h-20 rounded-full bg-dark-300" />
              <div className="absolute -bottom-1 -right-1 w-8 h-8 rounded-full bg-dark-300" />
            </div>

            {/* Content Skeleton */}
            <div className="flex-1">
              {/* Header */}
              <div className="flex items-start justify-between">
                <div className="space-y-2">
                  <div className="h-4 w-32 bg-dark-300 rounded" />
                  <div className="h-3 w-24 bg-dark-300 rounded" />
                </div>
                <div className="h-6 w-20 bg-dark-300 rounded" />
              </div>

              {/* Details */}
              <div className="mt-4 grid grid-cols-2 gap-4">
                <div className="h-3 w-24 bg-dark-300 rounded" />
                <div className="h-3 w-28 bg-dark-300 rounded" />
              </div>

              {/* Actions */}
              <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-300">
                <div className="flex items-center space-x-2">
                  <div className="h-8 w-24 bg-dark-300 rounded-lg" />
                  <div className="h-8 w-20 bg-dark-300 rounded-lg" />
                </div>
                <div className="h-6 w-24 bg-dark-300 rounded" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActivitySkeleton;