/**
 * Calculates the distance between two points on Earth using the Haversine formula
 * Returns distance in kilometers
 */
export function calculateDistance(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number {
  // Validate coordinates
  if (!isValidCoordinate(lat1, lng1) || !isValidCoordinate(lat2, lng2)) {
    return -1;
  }

  const R = 6371; // Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);
  
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLng/2) * Math.sin(dLng/2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return Math.round(R * c * 10) / 10; // Round to 1 decimal place
}

function isValidCoordinate(lat: number, lng: number): boolean {
  return (
    typeof lat === 'number' &&
    typeof lng === 'number' &&
    !isNaN(lat) &&
    !isNaN(lng) &&
    Math.abs(lat) <= 90 &&
    Math.abs(lng) <= 180
  );
}

function toRad(degrees: number): number {
  return degrees * (Math.PI / 180);
}

/**
 * Formats a distance in kilometers for display
 */
function formatDistance(distance: number): string {
  if (distance === -1) return 'Distance unavailable';
  if (distance === 0) return 'Less than 0.1km';
  if (distance < 1) return `${Math.round(distance * 1000)}m`;
  if (distance >= 1000) {
    const thousands = Math.round(distance / 100) / 10;
    return `${thousands}k km`;
  }
  return `${distance}km`;
}