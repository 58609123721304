import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeAwareLogo } from './brand/ThemeAwareLogo';

interface HeaderProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

export const Header: React.FC<HeaderProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 ${isLandingPage ? 'bg-dark-200/50 backdrop-blur-sm' : 'bg-opacity-80 backdrop-blur-md bg-app border-b border-dark-300'}`}>
      <div className="max-w-[1400px] mx-auto px-2 sm:px-3">
        <div className="h-16 flex items-center justify-between">
          {/* Logo section */}
          <Link to="/" className="flex-shrink-0 flex items-center space-x-2">
            <ThemeAwareLogo 
              height={36} 
              colorScheme="white"
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

