import React from 'react';
import { CommandGroup, CommandItem } from '../ui/command';
import { MapPin, Users, Building2, Hash, MessageSquare } from 'lucide-react';
import { members } from '../../data/members';
import { facilities } from '../../data/facilities';
import { posts } from '../../data/posts';
import { sportEmojis } from '../../lib/constants';

interface SearchSuggestionsProps {
  onSelect: (type: string, value: string) => void;
}

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({ onSelect }) => {
  // Get unique values for suggestions
  const cities = Array.from(new Set(members.map(m => m.city)));
  const industries = Array.from(new Set(members.map(m => m.industry)));
  const sports = Array.from(new Set(members.flatMap(m => m.sports)));
  
  // Get top members by connections for suggestions
  const suggestedMembers = members
    .sort((a, b) => b.connections - a.connections)
    .slice(0, 3);
  
  // Get top facilities by rating
  const popularFacilities = facilities
    .sort((a, b) => b.rating - a.rating)
    .slice(0, 3);
  
  // Get trending posts
  const trendingPosts = posts
    .sort((a, b) => b.likes - a.likes)
    .slice(0, 3);

  return (
    <div className="py-4 space-y-4">
      {/* Popular Searches */}
      <CommandGroup heading="Popular Searches">
        <CommandItem onSelect={() => onSelect('search', 'tennis trainers in monte carlo')}>
          <Hash className="h-4 w-4 text-gold-400 mr-2" />
          Tennis trainers in Monte Carlo
        </CommandItem>
        <CommandItem onSelect={() => onSelect('search', 'golf clubs in dubai')}>
          <Hash className="h-4 w-4 text-gold-400 mr-2" />
          Golf clubs in Dubai
        </CommandItem>
        <CommandItem onSelect={() => onSelect('search', 'investment bankers tennis')}>
          <Hash className="h-4 w-4 text-gold-400 mr-2" />
          Investment bankers tennis
        </CommandItem>
      </CommandGroup>

      {/* Suggested Members */}
      <CommandGroup heading="Suggested Members">
        {suggestedMembers.map(member => (
          <CommandItem
            key={member.id}
            onSelect={() => onSelect('search', member.name)}
          >
            <div className="flex items-center space-x-3">
              <img 
                src={member.image}
                alt={member.name}
                className="w-8 h-8 rounded-full object-cover border border-dark-400"
              />
              <div>
                <div className="text-white">{member.name}</div>
                <div className="text-dark-800 text-sm">{member.jobTitle}</div>
              </div>
            </div>
          </CommandItem>
        ))}
      </CommandGroup>

      {/* Cities */}
      <CommandGroup heading="Cities">
        {cities.slice(0, 3).map(city => (
          <CommandItem
            key={city}
            onSelect={() => onSelect('search', city)}
          >
            <MapPin className="h-4 w-4 text-gold-400 mr-2" />
            {city}
          </CommandItem>
        ))}
      </CommandGroup>

      {/* Industries */}
      <CommandGroup heading="Industries">
        {industries.slice(0, 3).map(industry => (
          <CommandItem
            key={industry}
            onSelect={() => onSelect('search', industry)}
          >
            <Building2 className="h-4 w-4 text-gold-400 mr-2" />
            {industry}
          </CommandItem>
        ))}
      </CommandGroup>

      {/* Sports */}
      <CommandGroup heading="Sports">
        {sports.slice(0, 3).map(sport => (
          <CommandItem
            key={sport}
            onSelect={() => onSelect('search', sport)}
          >
            <span className="w-4 mr-2 text-center">
              {sportEmojis[sport] || '🎯'}
            </span>
            {sport}
          </CommandItem>
        ))}
      </CommandGroup>

      {/* Popular Facilities */}
      <CommandGroup heading="Popular Facilities">
        {popularFacilities.map(facility => (
          <CommandItem
            key={facility.id}
            onSelect={() => onSelect('search', facility.name)}
          >
            <MapPin className="h-4 w-4 text-gold-400 mr-2" />
            {facility.name} • {facility.location}
          </CommandItem>
        ))}
      </CommandGroup>

      {/* Trending Posts */}
      <CommandGroup heading="Trending Posts">
        {trendingPosts.map(post => (
          <CommandItem
            key={post.id}
            onSelect={() => onSelect('search', post.content.slice(0, 50))}
          >
            <MessageSquare className="h-4 w-4 text-gold-400 mr-2" />
            {post.content.slice(0, 50)}...
          </CommandItem>
        ))}
      </CommandGroup>
    </div>
  );
};

export default SearchSuggestions;
