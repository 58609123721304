// Singleton pattern for Google Maps API loading
let loadPromise: Promise<void> | null = null;

export function loadGoogleMaps(): Promise<void> {
  if (loadPromise) {
    return loadPromise;
  }

  loadPromise = new Promise((resolve, reject) => {
    try {
      // If already loaded, resolve immediately
      if (window.google?.maps) {
        resolve();
        return;
      }

      // Create callback function
      const callbackName = `initMap${Date.now()}`;
      window[callbackName] = () => {
        delete window[callbackName];
        resolve();
      };

      // Create script element
      const script = document.createElement('script');
      const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
      
      if (!apiKey) {
        throw new Error('Google Maps API key is missing');
      }

      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${callbackName}&v=weekly`;
      script.async = true;
      script.defer = true;
      
      script.onerror = () => {
        delete window[callbackName];
        reject(new Error('Failed to load Google Maps API'));
        script.remove();
        loadPromise = null;
      };

      document.head.appendChild(script);
    } catch (error) {
      reject(error);
      loadPromise = null;
    }
  });

  return loadPromise;
}