import React from 'react';
import { cn } from '@/lib/utils';

interface VenueActivitiesProps {
  activities: string[];
}

export const VenueActivities: React.FC<VenueActivitiesProps> = ({ activities }) => {
  if (!activities.length) return null;

  return (
    <div className="mb-3">
      <div className="flex flex-wrap gap-2">
        {activities.map((activity, index) => (
          <span
            key={`activity-${index}`}
            className={cn(
              "px-3 py-1.5 rounded-lg text-sm flex items-center space-x-2",
              "dark:bg-gold-400/10 dark:text-gold-400",
              "light:bg-gold-100 light:text-gold-700"
            )}
          >
            <span className="text-base">{activity}</span>
          </span>
        ))}
      </div>
    </div>
  );
};