import { normalizeText } from './utils/text';
import { CITY_ALIASES, IGNORED_REGIONS } from './city/constants';
import { normalizeCity } from './city/normalizer';

export function citiesMatch(city1: string, city2: string): boolean {
  // Normalize both cities
  const normalizedCity1 = normalizeCity(city1);
  const normalizedCity2 = normalizeCity(city2);

  // Compare normalized cities
  if (normalizedCity1 === normalizedCity2) {
    return true;
  }

  // Compare normalized text versions as fallback
  return normalizeText(normalizedCity1) === normalizeText(normalizedCity2);
}