import React from 'react';
import { CheckCircle2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../ui/dialog';
import { Button } from '../../ui/button';

interface ReportSuccessProps {
  isOpen: boolean;
  onClose: () => void;
  username: string;
}

export const ReportSuccess: React.FC<ReportSuccessProps> = ({
  isOpen,
  onClose,
  username
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <div className="flex justify-center mb-4">
            <div className="bg-green-400/20 p-3 rounded-full">
              <CheckCircle2 className="h-6 w-6 text-green-400" />
            </div>
          </div>
          <DialogTitle className="text-center">Report Submitted</DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <p className="text-center text-dark-800">
            Thank you for reporting {username}. We take all reports seriously and will review this case promptly.
          </p>
        </div>

        <DialogFooter>
          <Button
            onClick={onClose}
            className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};