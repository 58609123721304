import React from 'react';
import { TabScroller } from '../ui/tabs/TabScroller';
import { cn } from '@/lib/utils';

interface Tab {
  id: string;
  label: string;
}

interface ProfileTabsProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ activeTab, onTabChange }) => {
  const tabs: Tab[] = [
    { id: 'posts', label: 'Moments' },
    { id: 'network', label: 'Connections' },
    { id: 'ratings', label: 'Ratings' }
  ];

  return (
    <TabScroller className="border-b border-dark-300">
      <div className="flex items-center space-x-4 px-4">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={cn(
              "px-4 py-2 -mb-px border-b-2 transition-colors whitespace-nowrap flex-shrink-0",
              activeTab === tab.id
                ? "border-gold-400 text-gold-400"
                : "border-transparent text-dark-800 hover:text-white"
            )}
            role="tab"
            aria-selected={activeTab === tab.id}
            aria-controls={`${tab.id}-panel`}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </TabScroller>
  );
};

export default ProfileTabs;