import React, { useState } from 'react';
import { Navigation2 } from 'lucide-react';
import { navigationApps } from '../../lib/navigation';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { cn } from '@/lib/utils';

interface LocationActionsProps {
  venueName: string;
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  hideViewMap?: boolean;
  isPastActivity?: boolean;
  className?: string;
}

const LocationActions: React.FC<LocationActionsProps> = ({
  venueName,
  address,
  coordinates,
  hideViewMap = false,
  isPastActivity = false,
  className
}) => {
  const [showModal, setShowModal] = useState(false);

  // Don't render anything if it's a past activity and view map is hidden
  if (isPastActivity && hideViewMap) {
    return null;
  }

  return (
    <>
      {/* Only show navigation button for non-past activities */}
      {!isPastActivity && (
        <button
          onClick={() => setShowModal(true)}
          className={cn(
            "flex items-center justify-center space-x-2",
            "w-full min-w-0 px-3 sm:px-4 py-2",
            "bg-dark-300 hover:bg-dark-400 text-white",
            "rounded-xl transition-colors truncate",
            className
          )}
        >
          <Navigation2 className="h-4 w-4 flex-shrink-0 text-gold-400" />
          <span className="truncate">Navigate</span>
        </button>
      )}

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 border-dark-300">
          <DialogHeader>
            <DialogTitle className="text-white">Navigate to {venueName}</DialogTitle>
          </DialogHeader>

          <div className="space-y-3 py-4">
            {navigationApps.map((app) => (
              <a
                key={app.name}
                href={app.getUrl(coordinates, venueName)}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  "flex items-center justify-center space-x-3 w-full",
                  "px-4 py-3 bg-dark-300 hover:bg-dark-400",
                  "text-white rounded-xl transition-colors"
                )}
                onClick={() => setShowModal(false)}
              >
                <span className="text-xl">{app.icon}</span>
                <span className="truncate">Open in {app.name}</span>
              </a>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LocationActions;