import React, { useState, useEffect } from 'react';
import { MapPin, Loader2, X } from 'lucide-react';
import { City } from '@/types/city';
import { searchCities } from '@/lib/services/geocoding';
import { cn } from '@/lib/utils';

interface AddressSearchProps {
  value: string;
  onChange: (address: string) => void;
  onLocationSelect: (location: { lat: number; lng: number }) => void;
  isLoading?: boolean;
  error?: string | null;
  className?: string;
}

const AddressSearch: React.FC<AddressSearchProps> = ({
  value,
  onChange,
  onLocationSelect,
  isLoading,
  error,
  className
}) => {
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const fetchSuggestions = async () => {
      if (!value.trim() || value.length < 3) {
        setSuggestions([]);
        return;
      }

      try {
        const results = await searchCities(value);
        setSuggestions(results);
      } catch (err) {
        console.error('Error fetching suggestions:', err);
        setSuggestions([]);
      }
    };

    timeoutId = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timeoutId);
  }, [value]);

  const handleSuggestionClick = (suggestion: City) => {
    if (suggestion.coordinates) {
      onChange(suggestion.name);
      onLocationSelect(suggestion.coordinates);
      setShowSuggestions(false);
    }
  };

  const handleClear = () => {
    onChange('');
    setSuggestions([]);
  };

  return (
    <div className={cn("relative", className)}>
      <div className="relative">
        <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setShowSuggestions(true)}
          placeholder="Enter location for distance calculation..."
          className={cn(
            "w-full pl-10 pr-10 py-3 bg-dark-300",
            "border border-dark-400 rounded-xl",
            "text-white placeholder-dark-800",
            "focus:outline-none focus:ring-2 focus:ring-gold-400",
            error && "border-red-500 focus:ring-red-500"
          )}
        />
        {value && !isLoading && (
          <button
            onClick={handleClear}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-dark-800 hover:text-white"
          >
            <X className="h-4 w-4" />
          </button>
        )}
        {isLoading && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <Loader2 className="h-4 w-4 text-dark-800 animate-spin" />
          </div>
        )}
      </div>

      {/* Location Suggestions */}
      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute z-50 w-full mt-1 bg-dark-200 border border-dark-400 rounded-xl shadow-lg max-h-[300px] overflow-y-auto">
          {suggestions.map((suggestion, index) => (
            <button
              key={`${suggestion.name}-${index}`}
              onClick={() => handleSuggestionClick(suggestion)}
              className="w-full flex items-center px-4 py-3 hover:bg-dark-300 text-left"
            >
              <MapPin className="h-4 w-4 text-dark-800 mr-2 flex-shrink-0" />
              <div>
                <div className="text-white">{suggestion.name}</div>
                {(suggestion.state || suggestion.country) && (
                  <div className="text-dark-800 text-sm">
                    {[suggestion.state, suggestion.country].filter(Boolean).join(', ')}
                  </div>
                )}
              </div>
            </button>
          ))}
        </div>
      )}

      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

export default AddressSearch;