import React from 'react';
import { Building2, Users, Dumbbell } from 'lucide-react';

interface CityTabsProps {
  activeTab: 'clubs' | 'players' | 'trainers';
  onTabChange: (tab: 'clubs' | 'players' | 'trainers') => void;
}

const CityTabs: React.FC<CityTabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="flex space-x-4 border-b border-dark-300">
      <button
        onClick={() => onTabChange('clubs')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors flex items-center space-x-2 ${
          activeTab === 'clubs'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        <Building2 className="h-4 w-4" />
        <span>Clubs</span>
      </button>
      <button
        onClick={() => onTabChange('players')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors flex items-center space-x-2 ${
          activeTab === 'players'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        <Users className="h-4 w-4" />
        <span>Players</span>
      </button>
      <button
        onClick={() => onTabChange('trainers')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors flex items-center space-x-2 ${
          activeTab === 'trainers'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        }`}
      >
        <Dumbbell className="h-4 w-4" />
        <span>Trainers</span>
      </button>
    </div>
  );
};

export default CityTabs;