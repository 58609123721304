import React from 'react';
import { cn } from '@/lib/utils';
import { SearchBar } from './components/search/SearchBar';
import { ActivityTags } from './components/search/ActivityTags';

interface VenueSearchSectionProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  selectedTags: string[];
  onTagSelect: (tag: string) => void;
  className?: string;
}

export const VenueSearchSection: React.FC<VenueSearchSectionProps> = ({
  searchTerm,
  onSearchChange,
  selectedTags,
  onTagSelect,
  className
}) => {
  return (
    <div className={cn("space-y-6", className)}>
      <h2 className="text-xl font-semibold text-white">Find Member Clubs</h2>
      
      <div className="space-y-4">
        <SearchBar
          value={searchTerm}
          onChange={onSearchChange}
        />
        <ActivityTags
          selectedTags={selectedTags}
          onTagSelect={onTagSelect}
        />
      </div>
    </div>
  );
};

