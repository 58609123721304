import { Message, Conversation } from '../types/chat';
import { additionalConversations, additionalMessages } from './mockConversations';

// Base messages for conversations 1-5
export const messages: Record<string, Message[]> = {
  '1': [
    {
      id: '1-1',
      content: "Hey Sarah! Are we still on for tomorrow's tennis match?",
      time: '10:00',
      isSender: true,
      status: 'read'
    },
    {
      id: '1-2',
      content: "Yes, absolutely! I've booked Court 3 at Monte Carlo Tennis Club for 2 PM.",
      time: '10:03',
      isSender: false
    },
    {
      id: '1-3',
      content: "Great! Should I bring anything specific?",
      time: '10:05',
      isSender: true,
      status: 'read'
    },
    {
      id: '1-4',
      content: "Perfect! I'll bring some extra tennis balls just in case.",
      time: '10:10',
      isSender: false
    }
  ],
  ...additionalMessages
};

// Combine existing conversations with additional ones
export const conversations: Conversation[] = [
  {
    id: '1',
    name: 'Sarah Connor',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80',
    city: 'Monte Carlo',
    lastMessage: "I'll bring some extra tennis balls just in case.",
    time: '10:10',
    unread: 0,
    online: true
  },
  {
    id: '2',
    name: 'Michael Chen',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80',
    city: 'Singapore',
    lastMessage: 'The course looks amazing today!',
    time: '9:45',
    unread: 2,
    online: false
  },
  {
    id: '3',
    name: 'Sofia Martinez',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=300&q=80',
    city: 'Dubai',
    lastMessage: 'Thanks for the great padel session!',
    time: '8:30',
    unread: 0,
    online: true
  },
  {
    id: '4',
    name: 'David Kim',
    image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80',
    city: 'London',
    lastMessage: 'See you at the basketball court!',
    time: '7:15',
    unread: 1,
    online: false
  },
  {
    id: '5',
    name: 'Emma Wilson',
    image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80',
    city: 'New York',
    lastMessage: 'The tournament starts at 9 AM sharp.',
    time: '6:20',
    unread: 0,
    online: true
  },
  ...additionalConversations
];