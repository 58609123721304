import React from 'react';
import { VenueApiResult } from '../../../types/venue';
import { VENUE_TYPE_IMAGES } from '../../../lib/services/venues/venue-type-images';

interface VenueMarkerProps {
  venue: VenueApiResult;
  map: google.maps.Map;
}

const VenueMarker: React.FC<VenueMarkerProps> = ({ venue, map }) => {
  React.useEffect(() => {
    const marker = new google.maps.Marker({
      position: venue.coordinates,
      map,
      title: venue.name,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: '#FFC933',
        fillOpacity: 1,
        strokeColor: '#202124',
        strokeWeight: 2
      }
    });

    // Get venue photo or fallback image
    const venueImage = venue.photos?.[0]?.url || 
      VENUE_TYPE_IMAGES[venue.type as keyof typeof VENUE_TYPE_IMAGES] || 
      VENUE_TYPE_IMAGES.default;

    // Create info window with compact styling
    const infoWindow = new google.maps.InfoWindow({
      content: `
        <div style="
          width: 180px;
          font-family: system-ui, -apple-system, sans-serif;
          line-height: 1.2;
          background: #202124;
          margin: -8px;
          border-radius: 8px;
          overflow: hidden;
        ">
          <img 
            src="${venueImage}" 
            alt="${venue.name}"
            style="
              width: 100%;
              height: 90px;
              object-fit: cover;
              display: block;
            "
          />
          
          <div style="padding: 12px;">
            <h3 style="
              margin: 0 0 4px;
              font-size: 13px;
              font-weight: 600;
              color: #FFFFFF;
            ">${venue.name}</h3>
            
            <div style="margin-bottom: 4px;">
              <span style="
                display: inline-block;
                padding: 2px 6px;
                background: rgba(255, 201, 51, 0.1);
                border-radius: 10px;
                font-size: 10px;
                color: #FFC933;
              ">${venue.type}</span>
            </div>

            ${venue.rating ? `
              <div style="
                display: flex;
                align-items: center;
                margin-bottom: 4px;
              ">
                <span style="color: #FFC933; margin-right: 4px; font-size: 11px;">★</span>
                <span style="color: #FFFFFF; font-size: 11px;">${venue.rating}</span>
                ${venue.user_ratings_total ? `
                  <span style="color: #7A7B7F; font-size: 11px; margin-left: 4px;">
                    (${venue.user_ratings_total})
                  </span>
                ` : ''}
              </div>
            ` : ''}

            <div style="
              color: #7A7B7F;
              font-size: 11px;
              margin-bottom: 4px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            ">
              ${venue.address}
            </div>

            ${venue.opening_hours?.open_now !== undefined ? `
              <div style="
                font-size: 11px;
                color: ${venue.opening_hours.open_now ? '#10B981' : '#EF4444'};
              ">
                ${venue.opening_hours.open_now ? 'Open Now' : 'Closed'}
              </div>
            ` : ''}
          </div>
        </div>
      `,
      maxWidth: 180,
      disableAutoPan: false,
      pixelOffset: new google.maps.Size(0, -15)
    });

    // Add click listener
    marker.addListener('click', () => {
      // Close any other open info windows first
      google.maps.event.trigger(map, 'closeAllInfoWindows');
      infoWindow.open({
        map,
        anchor: marker,
        shouldFocus: false
      });
    });

    // Add custom event to close info window
    map.addListener('closeAllInfoWindows', () => {
      infoWindow.close();
    });

    return () => {
      marker.setMap(null);
      infoWindow.close();
      google.maps.event.clearInstanceListeners(marker);
    };
  }, [venue, map]);

  return null;
};

export default VenueMarker;