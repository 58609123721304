import React from 'react';
import { UserMinus, Flag } from 'lucide-react';
import { cn } from '@/lib/utils';

interface MenuListProps {
  show: boolean;
  onBlock: () => void;
  onReport: () => void;
  className?: string;
}

export const MenuList: React.FC<MenuListProps> = ({
  show,
  onBlock,
  onReport,
  className
}) => {
  if (!show) return null;

  return (
    <div className={cn(
      "absolute right-0 mt-2 w-48 bg-dark-300 rounded-xl border border-dark-400 shadow-lg z-50",
      className
    )}>
      <div className="py-1">
        <button
          onClick={onBlock}
          className="flex items-center w-full px-4 py-2 text-white hover:bg-dark-400 transition-colors"
        >
          <UserMinus className="h-4 w-4 mr-2" />
          Block User
        </button>
        <button
          onClick={onReport}
          className="flex items-center w-full px-4 py-2 text-white hover:bg-dark-400 transition-colors"
        >
          <Flag className="h-4 w-4 mr-2" />
          Report User
        </button>
      </div>
    </div>
  );
};