import React from 'react';

interface DeclineButtonProps {
  onClick: () => void;
}

const DeclineButton: React.FC<DeclineButtonProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition-colors"
  >
    Decline
  </button>
);

export default DeclineButton;