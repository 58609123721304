import { Cache } from './index';
import type { Post } from '../../types/post';
import type { Player } from '../../types/matchmaking';
import type { Activity } from '../../types/activity';
import type { UserProfile } from '../../types/profile';

// Create cache instances
export const postsCache = new Cache<Post[]>('posts');
const playersCache = new Cache<Player[]>('players');
export const activitiesCache = new Cache<Activity[]>('activities');
const profileCache = new Cache<UserProfile>('profile');