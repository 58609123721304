import React from 'react';
import { motion } from 'framer-motion';
import { features } from './constants';
import MembershipFeature from './MembershipFeature';
import MembershipCard from './MembershipCard';

export const ExclusiveMembership: React.FC = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black via-dark-100 to-dark-200">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Left Column - Features */}
          <div>
            <motion.h2 
              className="text-2xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-white to-gold-400"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              Exclusive Membership
            </motion.h2>
            
            <div className="space-y-8">
              {features.map((feature, index) => (
                <MembershipFeature
                  key={feature.title}
                  icon={feature.icon}
                  title={feature.title}
                  text={feature.text}
                  delay={index * 0.1}
                />
              ))}
            </div>
          </div>

          {/* Right Column - Card */}
          <div className="flex justify-center items-center perspective">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="relative w-80 h-48 [transform-style:preserve-3d] group"
            >
              <div className="absolute inset-0 animate-rotate-y">
                <MembershipCard />
              </div>
              
              {/* Card glow effect */}
              <div className="absolute inset-0 -z-10">
                <div className="absolute inset-0 bg-gold-400/10 blur-2xl rounded-full scale-90 animate-pulse" />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};