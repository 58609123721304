import React, { useState, useEffect, useRef, useMemo } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import ActivityList from '../components/activities/ActivityList';
import ActivityFilters from '../components/activities/ActivityFilters';
import { Activity } from '../types/activity';
import { getActivities, updateActivityStatus, acceptActivity, declineActivity, rateActivity } from '../lib/supabase/activities';
import { useActivities } from '../hooks/useActivities';
import ActivitySkeleton from '../components/activities/ActivitySkeleton';
import { CACHE_DURATION, activitiesCache } from '../lib/cache/activities';
import ManageActivityModal from '../components/members/modals/ManageActivityModal';
import { RefreshCw } from 'lucide-react';
import { cn } from '@/lib/utils';

const Activities: React.FC = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [activeTab, setActiveTab] = useState<'current' | 'past'>('current');
  const [sortOrder, setSortOrder] = useState<'recent' | 'oldest'>('recent');
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isMounted = useRef(true);
  const abortControllerRef = useRef<AbortController>();
  const [isCached, setIsCached] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    // Subscribe to storage events for cache invalidation
    const handleStorageEvent = (e: StorageEvent) => {
      if (e.key === 'activities-cache-invalidated') {
        loadActivities();
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    const loadActivities = async () => {
      // Check cache first
      const cached = activitiesCache.get('activities');
      if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
        console.log('Using cached activities');
        setActivities(cached.data);
        setIsCached(true);
        return;
      }

      if (!isMounted.current || isLoading || isCached) return;

      setIsLoading(true);
      setError(null);

      // Create new abort controller
      abortControllerRef.current = new AbortController();

      try {
        const result = await getActivities();
        if ('error' in result) {
          setError(result.error);
          setActivities([]);
        } else {
          if (!isMounted.current) return;
          setActivities(result);
          
          // Cache the results
          activitiesCache.set('activities', {
            data: result,
            timestamp: Date.now()
          });
        }
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          console.log('Request aborted');
          return;
        }
        const message = error instanceof Error ? error.message : 'Failed to load activities';
        console.error(message, error);
        setError(message);
        setActivities([]);
      } finally {
        setIsLoading(false);
      }
    };

    // Initial load
    if (!isLoading && !isCached && activities.length === 0) {
      loadActivities();
    }

    // Handle visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Only reload if cache is expired
        const cached = activitiesCache.get('activities');
        if (!cached || Date.now() - cached.timestamp >= CACHE_DURATION) {
          loadActivities();
        }
      } else {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      isMounted.current = false;
      window.removeEventListener('storage', handleStorageEvent);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [isLoading, isCached]);

  const filteredActivities = useMemo(() => {
    return activities.filter(activity => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const activityDate = new Date(activity.date);
      const isPastActivity = activityDate < today;

      // Update status for past activities
      if (isPastActivity && activity.status !== 'played' && activity.status !== 'missed') {
        activity.status = activity.status === 'confirmed' ? 'played' : 'missed';
      }

      // Filter by tab first
      if (activeTab === 'current' && isPastActivity) return false;
      if (activeTab === 'past' && !isPastActivity) return false;

      const searchTermLower = searchTerm.toLowerCase();
      const matchesSearch = !searchTerm || 
        (activity.opponent.name && activity.opponent.name.toLowerCase().includes(searchTermLower)) ||
        activity.sport.toLowerCase().includes(searchTermLower) ||
        activity.city.toLowerCase().includes(searchTermLower);

      const matchesSport = selectedSport === 'all' || activity.sport === selectedSport;
      const matchesCity = selectedCity === 'all' || activity.city === selectedCity;
      const matchesStatus = selectedStatus === 'all' || activity.status === selectedStatus;

      return matchesSearch && matchesSport && matchesCity && matchesStatus;
    }).sort((a, b) => {
      const dateA = new Date(`${a.date} ${a.time}`).getTime();
      const dateB = new Date(`${b.date} ${b.time}`).getTime();
      // For current activities, sort by closest date first
      // For past activities, sort by most recent first
      return activeTab === 'current' 
        ? (sortOrder === 'recent' ? dateA - dateB : dateB - dateA)
        : (sortOrder === 'recent' ? dateB - dateA : dateA - dateB);
    });
  }, [activities, searchTerm, selectedSport, selectedCity, selectedStatus, sortOrder, activeTab]);

  const {
    selectedMatch,
    showManageModal,
    showDeclineModal,
    setSelectedMatch,
    setShowManageModal,
    handleManageSession,
    handleRematch,
    handleDeclineRequest
  } = useActivities(filteredActivities, {
    searchTerm,
    selectedSport,
    selectedCity,
    selectedStatus,
    sortOrder
  });

  const handleUpdateActivity = async (updatedActivity: Activity) => {
    try {
      await updateActivityStatus(updatedActivity.id, updatedActivity.status);
      
      // Refresh activities
      const updatedActivities = await getActivities();
      setActivities(updatedActivities);
      
      setShowManageModal(false);
      setSelectedMatch(null);
    } catch (error) {
      console.error('Failed to update activity:', error);
      setError(error instanceof Error ? error.message : 'Failed to update activity');
    }
  };

  const handleAcceptActivity = async (activity: Activity) => {
    try {
      await acceptActivity(activity.id);
      console.log('Activity accepted:', activity.id);
      // Refresh activities
      const updatedActivities = await getActivities();
      setActivities(updatedActivities);
    } catch (error) {
      console.error('Failed to accept activity:', error);
      setError(error instanceof Error ? error.message : 'Failed to accept activity');
    }
  };

  const handleDeclineActivity = async (activity: Activity, reason?: string) => {
    try {
      await declineActivity(activity.id, reason);
      // Refresh activities after declining
      const updatedActivities = await getActivities();
      setActivities(updatedActivities);
    } catch (error) {
      console.error('Failed to decline activity:', error);
      setError(error instanceof Error ? error.message : 'Failed to decline activity');
    }
  };

  const handleRateActivity = async (activity: Activity, rating: number, feedback: string, categories: any) => {
    try {
      await rateActivity(activity.id, rating, feedback, categories);
      // Refresh activities
      const result = await getActivities();
      setActivities(result);
    } catch (error) {
      console.error('Failed to rate activity:', error);
      setError(error instanceof Error ? error.message : 'Failed to rate activity');
    }
  };

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">My Activities</h1>
              <p className="text-dark-800 mt-1">Track your sports activities and matches</p>
            </div>
            <button
              onClick={() => window.location.reload()}
              className={cn(
                "p-2 rounded-lg transition-colors",
                "hover:bg-dark-300 text-dark-800 hover:text-white",
                "active:scale-95 transform"
              )}
              title="Refresh activities"
            >
              <RefreshCw className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
            <p>{error}</p>
            <button 
              onClick={() => window.location.reload()}
              className="text-sm underline mt-2 hover:text-red-400"
            >
              Try Again
            </button>
          </div>
        )}

        {/* Loading State */}
        {isLoading && <ActivitySkeleton count={5} />}

        {/* Filters */}
        {!isLoading && <ActivityFilters
          searchTerm={searchTerm}
          selectedSport={selectedSport}
          selectedCity={selectedCity}
          selectedStatus={selectedStatus}
          activeTab={activeTab}
          sortOrder={sortOrder}
          showFilters={showFilters}
          onSearchChange={setSearchTerm}
          onSportChange={setSelectedSport}
          onCityChange={setSelectedCity}
          onStatusChange={setSelectedStatus}
          onTabChange={setActiveTab}
          onSortOrderChange={setSortOrder}
          onToggleFilters={() => setShowFilters(!showFilters)}
        />}

        {/* Activities List */}
        {!isLoading && <ActivityList
          activities={filteredActivities}
          initialLoadCount={20}
          batchSize={10}
          isOwnProfile={true}
          activeTab={activeTab}
          onManageSession={handleManageSession}
          onAccept={handleAcceptActivity}
          onRematch={handleRematch}
          onDeclineRequest={handleDeclineActivity}
          onRate={handleRateActivity}
        />}

        {/* Manage Activity Modal */}
        {selectedMatch && (
          <ManageActivityModal
            activity={selectedMatch}
            isOpen={showManageModal}
            onClose={() => {
              setShowManageModal(false);
              setSelectedMatch(null);
            }}
            onUpdate={handleUpdateActivity}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default Activities;