import React from 'react';
import { Building } from 'lucide-react';
import type { Conversation } from '../../../types/chat';
import { cn } from '@/lib/utils';

interface ConversationItemProps {
  conversation: Conversation;
  isActive: boolean;
  onClick: () => void;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  isActive,
  onClick
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-full px-4 py-3 transition-colors text-left relative",
        "hover:bg-dark-300/50 dark:hover:bg-dark-300/50 light:hover:bg-light-100/50",
        isActive && "dark:bg-dark-300/80 light:bg-light-100/80"
      )}
      data-conversation-id={conversation.id}
    >
      <div className="flex items-start space-x-3">
        {/* Profile Image */}
        <div className="relative flex-shrink-0">
          <img
            src={conversation.image}
            alt={conversation.name}
            className={cn(
              "w-12 h-12 rounded-full object-cover",
              "border-2 transition-colors",
              "dark:border-dark-400 light:border-light-300",
              "dark:hover:border-gold-400 light:hover:border-gold-600"
            )}
          />
          {conversation.online && (
            <div className="absolute bottom-0.5 right-0.5 w-3 h-3 bg-green-400 rounded-full border-2 dark:border-dark-200 light:border-white" />
          )}
        </div>
        
        {/* Content */}
        <div className="flex-1 min-w-0">
          {/* Header with name and city */}
          <div className="flex items-center space-x-2">
            <h4 className="text-sm font-medium dark:text-white light:text-gray-900 truncate">
              {conversation.name}
            </h4>
            <span className="text-xs dark:text-dark-800 light:text-gray-500">
              {conversation.city}
            </span>
          </div>

          {/* Last Message and Time */}
          <div className="flex items-center justify-between mt-1.5">
            <p className="text-xs truncate dark:text-dark-800 light:text-gray-500 max-w-[80%]">
              {conversation.lastMessage}
            </p>
            <span className="text-[10px] dark:text-dark-800 light:text-gray-400 flex-shrink-0">
              {conversation.time}
            </span>
          </div>
        </div>

        {/* Notification Badge - Positioned absolutely */}
        {conversation.unread > 0 && (
          <div className="absolute right-3 top-2">
            <span className="px-1.5 py-0.5 bg-gold-400 text-dark-50 text-[10px] font-medium rounded-full">
              {conversation.unread}
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

export default ConversationItem;