import { Globe, LineChart, Users, Handshake } from 'lucide-react';

export const features = [
  {
    icon: Handshake,
    title: 'Global Network',
    text: 'Connect, train, and engage with like-minded members globally for sports and wellness'
  },
  {
    icon: Globe,
    title: 'Premium Access',
    text: 'Global access to premium sports and wellness member clubs'
  },
  {
    icon: LineChart,
    title: 'Performance Tracking',
    text: 'AI-driven performance insights'
  },
  {
    icon: Users,
    title: 'Personalized Experience',
    text: 'Personalized trainers and networking opportunities'
  }
];