import React, { useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PostCard from './PostCard';
import { Post } from '../../types/post';
import { useRef } from 'react';

interface PostListProps {
  posts: Post[];
  activeFilter: string;
}

const PostList: React.FC<PostListProps> = ({ posts, activeFilter }) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const sortedPosts = useMemo(() => {
    const postsCopy = [...posts];
    
    // Always ensure posts are sorted by date first
    postsCopy.sort((a, b) => 
      new Date(b.timeAgo).getTime() - new Date(a.timeAgo).getTime()
    );
    
    switch (activeFilter) {
      case 'trending':
        return postsCopy.sort((a, b) => 
          (b.likes + b.comments + b.shares) - (a.likes + a.comments + a.shares)
        );
      case 'recent':
        return postsCopy; // Already sorted by date
      case 'top':
        return postsCopy.sort((a, b) => b.likes - a.likes);
      default:
        return postsCopy; // Default to recent sorting
    }
  }, [posts, activeFilter]);

  return (
    <div 
      ref={parentRef}
      className="space-y-6"
    >
      <AnimatePresence mode="popLayout">
        {sortedPosts.map((post, index) => (
          <motion.div
            key={`post-${post.id}-${index}`}
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ 
              duration: 0.3,
              delay: index * 0.05,
              layout: { duration: 0.3 }
            }}
            className="w-full"
          >
            <PostCard {...post} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default PostList;