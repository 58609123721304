import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface PostContentProps {
  content: string;
  formattedContent?: React.ReactNode;
  media?: string;
  endorsements?: string[];
}

const PostContent: React.FC<PostContentProps> = ({
  content,
  formattedContent,
  media,
  endorsements
}) => {
  return (
    <div className="space-y-4">
      <p className="text-white text-lg leading-relaxed">
        {formattedContent || content}
      </p>

      {media && (
        <motion.div 
          className="relative rounded-xl overflow-hidden bg-dark-300"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.2 }}
        >
          <img
            src={media}
            alt="Post media"
            className="w-full max-h-[400px] object-cover hover:opacity-95 transition-opacity"
            loading="lazy"
          />
        </motion.div>
      )}

      {endorsements && endorsements.length > 0 && (
        <div className={cn(
          "flex flex-wrap gap-2 mt-3",
          "bg-dark-300 rounded-xl p-4"
        )}>
          {endorsements.map((endorsement, index) => (
            <span
              key={index}
              className="px-3 py-1.5 bg-dark-400 rounded-full text-sm text-white"
            >
              {endorsement}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostContent;