import { useState, useCallback, useEffect, useRef } from 'react';
import { Player } from '../../types/matchmaking';
import { DEFAULT_FILTERS } from '../../lib/utils/filters';
import { sortQuickPlayResults } from '../../lib/utils/sorting';
import { supabase } from '../../lib/supabase';
import { sportEmojis } from '../../lib/constants';

// Cache configuration
const CACHE_DURATION = 60 * 1000; // 1 minute cache
const BATCH_SIZE = 20;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const STALE_WHILE_REVALIDATE = true;

// Maximum retries for fetch operations
const NETWORK_ERRORS = new Set([
  'failed to fetch',
  'network request failed',
  'networkerror',
  'network timeout',
  'failed to fetch resource'
]);

// Memory cache
const playersCache = new Map<string, {
  data: Player[];
  timestamp: number;
  isStale: boolean;
}>();

// Cache management
function getCachedPlayers(cacheKey: string): Player[] | null {
  const cached = playersCache.get(cacheKey);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < CACHE_DURATION) {
    return cached.data;
  }

  if (STALE_WHILE_REVALIDATE && age < CACHE_DURATION * 2) {
    cached.isStale = true;
    return cached.data;
  }

  playersCache.delete(cacheKey);
  return null;
}

function setCachedPlayers(cacheKey: string, data: Player[]) {
  playersCache.set(cacheKey, {
    data,
    timestamp: Date.now(),
    isStale: false
  });
}

async function fetchWithRetry<T>( 
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  attempt = 1
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    const isNetworkError = error instanceof Error && 
      NETWORK_ERRORS.has(error.message.toLowerCase());
    
    if (retries > 0 && isNetworkError) {
      const delay = RETRY_DELAY * Math.pow(2, attempt - 1);
      console.log(`Network error, retrying in ${delay}ms... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return fetchWithRetry(operation, retries - 1, attempt + 1);
    }
    throw error;
  }
}

export function useQuickMatch() {
  const [potentialPlayers, setPotentialPlayers] = useState<Player[]>([]);
  const [searchState, setSearchState] = useState<'searching' | 'found' | 'request-sent' | 'not-found'>('searching');
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  
  const [searchTerm, setSearchTerm] = useState(DEFAULT_FILTERS.searchTerm);
  const [selectedSport, setSelectedSport] = useState(DEFAULT_FILTERS.sport);
  const [selectedGender, setSelectedGender] = useState(DEFAULT_FILTERS.gender);
  const [selectedRating, setSelectedRating] = useState(DEFAULT_FILTERS.rating);
  const [showConnectionsOnly, setShowConnectionsOnly] = useState(DEFAULT_FILTERS.connectionsOnly);
  const [sports, setSports] = useState<string[]>([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const cacheKeyRef = useRef<string>(`${Date.now()}`);

  // Cache for profile images
  const imageCache = useRef<Map<string, string>>(new Map());

  const fetchUsers = useCallback(async () => {
    if (!shouldFetch) return [];
    setIsLoading(true);
    setError(null);

    // Generate cache key based on filters
    const cacheKey = `${selectedSport}_${selectedGender}_${selectedRating}_${cacheKeyRef.current}`;

    // Check cache first
    const cached = getCachedPlayers(cacheKey);
    if (cached) {
      setPotentialPlayers(cached);
      setSearchState('found');
      setIsLoading(false);
      return cached;
    }

    try {
      // Get current session with retry
      const { data: { session }, error: sessionError } = await fetchWithRetry(() => 
        supabase.auth.getSession(), 3
      );

      if (sessionError) throw sessionError;
      if (!session?.user) {
        throw new Error('Please sign in to continue');
      }

      // Get current user with retry
      const { data: currentUser, error: userError } = await fetchWithRetry(() =>
        supabase
          .from('users')
          .select('id, auth_id')
          .eq('auth_id', session.user.id)
          .single()
      , 3);

      if (userError || !currentUser) {
        throw new Error('Unable to load user profile');
      }

      // Fetch users in batches with optimized query
      const users = [];
      let hasMore = true;
      let offset = 0;
      let retryCount = 0;
      const MAX_BATCH_RETRIES = 3;

      while (hasMore) {
        try {
          const { data, error } = await fetchWithRetry(() =>
            supabase
              .from('users')
              .select(`
                id,
                auth_id,
                full_name,
                avatar_url,
                city,
                skill_level,
                user_profiles (
                  preferences
                ),
                user_sports (
                  sports (
                    name,
                    emoji
                  )
                )
              `)
              .neq('id', currentUser.id)
              .range(offset, offset + BATCH_SIZE - 1)
          );

          if (error) {
            if (retryCount < MAX_BATCH_RETRIES) {
              retryCount++;
              await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
              continue;
            }
            throw new Error('Unable to load players');
          }

          if (!data || data.length === 0) {
            hasMore = false;
            break;
          }

          users.push(...data);
          offset += BATCH_SIZE;
          retryCount = 0;

          // Stop if we have enough users
          if (data.length < BATCH_SIZE) {
            hasMore = false;
          }
        } catch (error) {
          if (retryCount < MAX_BATCH_RETRIES) {
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            continue;
          }
          throw error;
        }
      }
      
      // Process users and collect sports
      const allSports = new Set<string>();
      const processedUsers = users.map(user => {
        const userSports = user.user_sports
          ?.map(sportEntry => 
            sportEntry.sports?.name
          )
          .filter((name): name is string => Boolean(name)) || [];

        userSports.forEach(sport => allSports.add(sport));

        // Map sports to emoji format using our sportEmojis mapping
        const formattedSports = userSports.map(sport => {
          return sportEmojis[sport] ? `${sportEmojis[sport]} ${sport}` : sport;
        });

        const preferences = user.user_profiles?.preferences || {};

        return {
          id: user.auth_id,
          name: user.full_name,
          age: preferences.age || 0,
          location: user.city,
          sports: formattedSports,
          rating: preferences.rating || 4.5,
          matchPercentage: Math.floor(Math.random() * 15 + 85),
          availability: ['morning', 'afternoon', 'evening'],
          image: imageCache.current.get(user.auth_id) || user.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
          company: {
            name: preferences.company || 'Company',
            position: preferences.jobTitle || 'Professional',
            industry: preferences.industry || 'Finance'
          },
          gender: preferences.gender || 'rather_not_say',
          distance: 0,
          recentActivity: 'Recently active'
        };
      });

      // Cache profile images for future use
      processedUsers.forEach(user => {
        if (user.image && !imageCache.current.has(user.id)) {
          imageCache.current.set(user.id, user.image);
        }
      });

      setSports(['all', ...Array.from(allSports)].sort());

      // Cache the results
      setCachedPlayers(cacheKey, processedUsers);

      return processedUsers;

    } catch (error) {
      throw error;
    }
  }, [shouldFetch, selectedSport, selectedGender, selectedRating]);

  const filterPlayers = useCallback(async () => {
    if (!shouldFetch) return;
    try {
      setIsLoading(true);
      setError(null);
      
      const users = await fetchUsers();
      if (!users || users.length === 0) {
        console.log('No users found');
        setPotentialPlayers([]);
        setSearchState('not-found');
        return;
      }
      
      const filteredPlayers = users.filter(user => {
        const searchTermLower = searchTerm.toLowerCase();
        const matchesSearch = searchTerm === '' || 
          user.name.toLowerCase().includes(searchTermLower) ||
          user.location.toLowerCase().includes(searchTermLower) ||
          user.sports.some(sport => sport.toLowerCase().includes(searchTermLower)) ||
          user.company.name.toLowerCase().includes(searchTermLower) ||
          user.company.position.toLowerCase().includes(searchTermLower);

        const matchesSport = selectedSport === 'all' || user.sports.includes(selectedSport);
        const matchesGender = selectedGender === 'all' || user.gender === selectedGender;
        const matchesRating = selectedRating === 'all' || user.rating >= parseFloat(selectedRating);

        return matchesSearch && matchesSport && matchesGender && matchesRating;
      });

      const sortedPlayers = sortQuickPlayResults(filteredPlayers);
      setPotentialPlayers(sortedPlayers);
      setSearchState(sortedPlayers.length > 0 ? 'found' : 'not-found');
      setError(null);
    } catch (error) {
      console.error('Error in filterPlayers:', error);
      if (error instanceof Error) {
        setError({ message: error.message === 'Failed to fetch' 
          ? 'Unable to connect to the server. Please check your internet connection.'
          : error.message 
        });
      } else {
        setError({ message: 'Unable to find players. Please try again.' });
      }
      setPotentialPlayers([]);
      setSearchState('not-found');
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, selectedSport, selectedGender, selectedRating, fetchUsers, shouldFetch]);

  const resetSearch = useCallback(() => {
    // Update cache key to force new fetch
    cacheKeyRef.current = `${Date.now()}`;
    
    // Clear cache
    playersCache.clear();
    
    // Clear all filters but keep image cache
    setSearchTerm(DEFAULT_FILTERS.searchTerm);
    setSelectedSport(DEFAULT_FILTERS.sport);
    setSelectedGender(DEFAULT_FILTERS.gender);
    setSelectedRating(DEFAULT_FILTERS.rating);
    setShowConnectionsOnly(DEFAULT_FILTERS.connectionsOnly);
    setIsInitialSearch(true);
    setPotentialPlayers([]);
    setSearchState('searching');
    setError(null);
    setShouldFetch(true);
    // Clear players cache
    playersCache.clear();
    
    // Trigger immediate search
    setTimeout(() => {
      filterPlayers();
      setIsInitialSearch(false);
    }, 100);
  }, [filterPlayers]);

  useEffect(() => {
    if (shouldFetch && !isInitialSearch) {
      filterPlayers();
    }
  }, [searchTerm, selectedSport, selectedGender, selectedRating, showConnectionsOnly, isInitialSearch, filterPlayers]);

  useEffect(() => {
    return () => {
      setShouldFetch(false);
      setPotentialPlayers([]);
    };
  }, []);

  return {
    potentialPlayers,
    searchState,
    isInitialSearch,
    isLoading,
    error,
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    sports,
    setSearchTerm,
    setSelectedSport,
    setSelectedGender,
    setSelectedRating,
    setShowConnectionsOnly,
    filterPlayers,
    resetSearch
  };
}