import React from 'react';
import { Newspaper } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface EmptyPostsStateProps {
  memberName: string;
}

const EmptyPostsState: React.FC<EmptyPostsStateProps> = ({ memberName }) => {
  const { isDarkMode } = useTheme();
  const firstName = memberName.split(' ')[0];
  
  return (
    <div className={cn(
      "text-center py-16 rounded-xl border",
      isDarkMode ? "bg-dark-300 border-dark-400" : "bg-gray-50 border-gray-200"
    )}>
      <Newspaper className={cn(
        "h-12 w-12 mx-auto mb-4",
        isDarkMode ? "text-dark-800" : "text-gray-400"
      )} />
      <h3 className={cn(
        "text-lg font-medium mb-2",
        isDarkMode ? "text-white" : "text-gray-900"
      )}>
        No Moments Yet
      </h3>
      <p className={cn(
        "max-w-sm mx-auto",
        isDarkMode ? "text-dark-800" : "text-gray-600"
      )}>
        {firstName} hasn't shared any moments yet. Check back later to see their achievements, matches, and training highlights.
      </p>
    </div>
  );
};

export default EmptyPostsState;