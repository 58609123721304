import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-dark-50">
      <div className="container mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-dark-800 hover:text-white mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-8">Privacy Policy</h1>
          
          <div className="space-y-8 text-dark-800">
            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">Introduction</h2>
              <p>
                At Playclub, we take your privacy seriously. This Privacy Policy explains how we collect, 
                use, disclose, and safeguard your information when you use our platform.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">Information We Collect</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>Profile information (name, email, profession)</li>
                <li>Sports preferences and skill levels</li>
                <li>Location data for matchmaking</li>
                <li>Activity and usage information</li>
                <li>Communication data between members</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">How We Use Your Information</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>Personalize your experience</li>
                <li>Match you with compatible sports partners</li>
                <li>Provide access to facilities and trainers</li>
                <li>Improve our services</li>
                <li>Send relevant notifications and updates</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">Data Protection</h2>
              <p>
                We implement robust security measures to protect your personal information. 
                Your data is encrypted and stored securely, with access limited to authorized personnel only.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">Your Rights</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li>Access your personal data</li>
                <li>Request data correction or deletion</li>
                <li>Opt out of marketing communications</li>
                <li>Control your privacy settings</li>
                <li>Export your data</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-white mb-4">Contact Us</h2>
              <p>
                For any privacy-related questions or concerns, please contact us at{' '}
                <a href="mailto:info@playclub.ai" className="text-gold-400 hover:text-gold-500">
                  info@playclub.ai
                </a>
              </p>
            </section>

            <section className="text-sm">
              <p>Last updated: March 15, 2025</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;