import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import { MessageSquare, HelpCircle } from 'lucide-react';
import FAQSection from './FAQSection';

interface SupportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SupportModal: React.FC<SupportModalProps> = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState<'faq' | 'contact'>('faq');

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[700px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <DialogTitle>Help & Support</DialogTitle>
        </DialogHeader>

        {/* Tab Navigation */}
        <div className="flex space-x-4 border-b border-dark-300 mb-6">
          <button
            onClick={() => setActiveTab('faq')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
              activeTab === 'faq'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            <div className="flex items-center space-x-2">
              <HelpCircle className="h-4 w-4" />
              <span>FAQ</span>
            </div>
          </button>
          <button
            onClick={() => setActiveTab('contact')}
            className={`px-4 py-2 -mb-px border-b-2 transition-colors ${
              activeTab === 'contact'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            <div className="flex items-center space-x-2">
              <MessageSquare className="h-4 w-4" />
              <span>Contact Support</span>
            </div>
          </button>
        </div>

        {/* Content */}
        <div className="max-h-[60vh] overflow-y-auto pr-2">
          {activeTab === 'faq' ? (
            <FAQSection />
          ) : (
            <div className="space-y-6">
              {/* Contact Form */}
              <div className="space-y-4">
                <div>
                  <label className="block text-dark-800 mb-2">Subject</label>
                  <input
                    type="text"
                    className="w-full bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                    placeholder="Brief description of your issue"
                  />
                </div>
                <div>
                  <label className="block text-dark-800 mb-2">Message</label>
                  <textarea
                    className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                    placeholder="Describe your issue in detail..."
                  />
                </div>
                <Button className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50">
                  Send Message
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SupportModal;