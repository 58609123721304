import { supabase } from '../supabase';
import { Notification } from '../../types/notification';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Retrying operation, ${retries} attempts remaining...`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export async function getNotifications(): Promise<Notification[]> {
  const fetchWithRetry = async (retries = MAX_RETRIES): Promise<Notification[]> => {
    try {
      // Get current session
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      
      if (authError || !session) {
        console.log('No active session:', authError?.message || 'User not logged in');
        return [];
      }

      // Get user's database ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', session.user.id)
        .single();

      if (userError || !userData) {
        console.log('User data error:', userError?.message || 'User not found');
        return [];
      }

      // Get notifications for this user with retry
      let retries = MAX_RETRIES;
      while (retries > 0) {
        try {
          const { data: notifications, error } = await supabase
            .from('notifications')
            .select('*')
            .eq('user_id', userData.id)
            .order('created_at', { ascending: false });

          if (error) throw error;
          return notifications || [];
        } catch (err) {
          retries--;
          if (retries > 0 && err instanceof Error && err.message.includes('Failed to fetch')) {
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            continue;
          }
          throw err;
        }
      }
      return [];
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      // Return empty array for all errors to prevent UI disruption
      return [];
    }
  };

  try {
    return await fetchWithRetry();
  } catch (error) {
    console.error('Failed to fetch notifications:', error);
    // Return empty array for all errors to prevent UI disruption
    return [];
  }
}

export async function markAsRead(notificationId: string): Promise<void> {
  try {
    const { data: { session }, error: authError } = await supabase.auth.getSession();
    if (authError) throw authError;
    if (!session) throw new Error('Not authenticated');

    const { error } = await fetchWithRetry(() =>
      supabase
        .from('notifications')
        .update({ read_status: true })
        .eq('id', notificationId)
    );

    if (error) throw error;
  } catch (error) {
    console.error('Failed to mark notification as read:', error);
    throw error;
  }
}

export async function markAllAsRead(): Promise<void> {
  try {
    const { data: { session }, error: authError } = await supabase.auth.getSession();
    if (authError) throw authError;
    if (!session) throw new Error('Not authenticated');

    const { error } = await fetchWithRetry(() =>
      supabase
        .from('notifications')
        .update({ read_status: true })
        .eq('read_status', false)
    );

    if (error) throw error;
  } catch (error) {
    console.error('Failed to mark all notifications as read:', error);
    throw error;
  }
}

export function subscribeToNotifications(callback: (notification: Notification) => void) {
  const subscription = supabase
    .channel('public:notifications')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'notifications'
      },
      async (payload) => {
        try {
          const { data, error } = await fetchWithRetry(() =>
            supabase
              .from('notification_details')
              .select('*')
              .eq('id', payload.new.id)
              .single()
          );

          if (error) throw error;
          if (!data) throw new Error('No notification data found');

          callback({
            id: data.id,
            type: data.type,
            title: getNotificationTitle(data.type),
            message: data.content,
            timestamp: data.timestamp,
            read: data.read_status,
            actionUrl: getActionUrl(data),
            sender: data.sender_id ? {
              id: data.sender_auth_id,
              name: data.sender_name,
              image: data.sender_avatar
            } : undefined,
            metadata: data.metadata
          });
        } catch (error) {
          console.error('Error processing new notification:', error);
        }
      }
    )
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
}

function getNotificationTitle(type: string): string {
  switch (type) {
    case 'match_request':
      return 'New Match Request';
    case 'match_accepted':
      return 'Match Accepted';
    case 'match_declined':
      return 'Match Declined';
    case 'new_message':
      return 'New Message';
    case 'mention':
      return 'New Mention';
    case 'connection_request':
      return 'New Connection Request';
    case 'connection_accepted':
      return 'Connection Accepted';
    default:
      return 'New Notification';
  }
}

function getActionUrl(notification: any): string {
  switch (notification.type) {
    case 'match_request':
    case 'match_accepted':
    case 'match_declined':
      return '/dashboard/activities';
    case 'new_message':
      return '/dashboard/messages';
    case 'mention':
      return notification.metadata?.postId 
        ? `/dashboard?post=${notification.metadata.postId}`
        : '/dashboard';
    case 'connection_request':
    case 'connection_accepted':
      return '/dashboard/network';
    default:
      return '/dashboard';
  }
}