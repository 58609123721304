import React from 'react';

interface TripActivitiesCountProps {
  count: number;
}

const TripActivitiesCount: React.FC<TripActivitiesCountProps> = ({ count }) => {
  return (
    <div className="text-dark-800">
      {count} {count === 1 ? 'activity' : 'activities'}
    </div>
  );
};

export default TripActivitiesCount;