import { formatDistanceToNow, format, isValid, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

/**
 * Formats a post timestamp with smart relative/absolute time display
 * @param {string | Date} timestamp - The timestamp to format
 * @returns {string} Formatted timestamp string
 */
/**
 * Formats a post timestamp with smart relative/absolute time display
 * @param {string | Date} timestamp - The timestamp to format
 * @returns {string} Formatted timestamp string
 */
export function formatPostTime(timestamp: string | Date): string {
  try {
    // Parse the timestamp
    let date: Date;
    if (typeof timestamp === 'string') {
      try {
        date = parseISO(timestamp);
      } catch {
        date = new Date(timestamp);
      }
    } else {
      date = timestamp;
    }

    // Validate the parsed date
    if (!isValid(date)) {
      throw new Error('Invalid date');
    }

    const now = new Date();
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));

    // Posted now (within last 5 minutes)
    if (diffInMinutes < 5) {
      return 'Posted now';
    }

    // Posted X minutes ago (less than 1 hour)
    if (diffInMinutes < 60) {
      return `Posted ${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    }

    // Posted X hours ago (less than 24 hours)
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `Posted ${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    }

    // Posted X days ago (less than 365 days)
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 365) {
      return `Posted ${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }

    // Posted X years ago
    const diffInYears = Math.floor(diffInDays / 365);
    return `Posted ${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
  } catch (error) {
    console.warn('Error formatting post time:', error);
    return 'Posted recently';
  }
}

export function formatTime(date: Date): string {
  try {
    if (!isValid(date)) {
      throw new Error('Invalid date');
    }
    return format(date, 'HH:mm', { locale: enUS });
  } catch (error) {
    console.warn('Error formatting time:', error);
    return '--:--';
  }
}

export function formatRelativeTime(timestamp: string | Date): string {
  try {
    if (!timestamp) {
      console.warn('Empty timestamp provided');
      return 'A while ago';
    }

    let date: Date;
    if (typeof timestamp === 'string') {
      // If timestamp already contains "Posted" or "ago", return it as is
      if (timestamp.includes('Posted') || timestamp.includes('ago')) {
        return timestamp;
      }
      
      // Try parsing as ISO date first
      try {
        date = parseISO(timestamp);
      } catch {
        // Fallback to standard date parsing
        date = new Date(timestamp);
      }
    } else {
      date = timestamp;
    }

    if (!date || !isValid(date) || isNaN(date.getTime())) {
      console.warn('Invalid date after parsing:', date);
      return 'A while ago';
    }

    const now = Date.now();
    const timestampMs = date.getTime();

    if (timestampMs > now) {
      console.warn('Future date detected:', date);
      return format(date, 'MMM dd, yyyy \'at\' HH:mm', { locale: enUS });
    }

    return formatDistanceToNow(date, {
      addSuffix: true,
      locale: enUS,
      includeSeconds: true
    });
  } catch (error) {
    console.warn('Error formatting relative time:', {
      error,
      input: timestamp,
      type: typeof timestamp
    });
    return 'A while ago';
  }
}

function formatDateForDisplay(date: string): string {
  try {
    const parsedDate = parseISO(date);
    if (!isValid(parsedDate)) {
      throw new Error('Invalid date');
    }
    return format(parsedDate, 'MMM dd, yyyy', { locale: enUS });
  } catch (error) {
    console.warn('Error formatting date for display:', error);
    return 'Invalid date';
  }
}