import React from 'react';
import { cn } from '@/lib/utils';

interface ConnectionSkeletonProps {
  count?: number;
  className?: string;
}

const ConnectionSkeleton: React.FC<ConnectionSkeletonProps> = ({
  count = 3,
  className
}) => {
  return (
    <div className={cn("space-y-4", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="bg-dark-200 rounded-xl p-4 border border-dark-300 animate-pulse"
        >
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
            {/* Profile Image */}
            <div className="flex-shrink-0">
              <div className="w-16 h-16 rounded-full bg-dark-300" />
            </div>

            {/* Info and Actions */}
            <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
              <div className="text-center sm:text-left w-full">
                {/* Name and Title */}
                <div className="h-5 w-32 bg-dark-300 rounded mb-2" />
                <div className="h-4 w-48 bg-dark-300 rounded mb-2" />
                <div className="h-4 w-40 bg-dark-300 rounded" />

                {/* Sports Tags */}
                <div className="flex flex-wrap justify-center sm:justify-start gap-2 mt-3">
                  {[1, 2].map((i) => (
                    <div
                      key={i}
                      className="h-6 w-20 bg-dark-300 rounded-full"
                    />
                  ))}
                </div>

                {/* Actions */}
                <div className="flex flex-col sm:flex-row items-center justify-between mt-4 pt-4 border-t border-dark-300">
                  <div className="h-4 w-36 bg-dark-300 rounded" />
                  <div className="flex items-center gap-2 mt-3 sm:mt-0">
                    <div className="h-10 w-24 bg-dark-300 rounded-lg" />
                    <div className="h-10 w-10 bg-dark-300 rounded-lg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConnectionSkeleton;