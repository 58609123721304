export const PHOTON_API_URL = 'https://photon.komoot.io';

// Default search radius in kilometers if no specific city bounds are defined
export const DEFAULT_SEARCH_RADIUS = 30;

// Search bias parameters
export const SEARCH_PARAMS = {
  limit: 20,
  locationBiasScale: 0.8, // 0-1, how much to bias results towards the given location
  minScore: 0.6 // Minimum relevance score (0-1) for results
};

// Map of cities to their country codes
export const CITY_COUNTRY_MAP: Record<string, string> = {
  'Monte Carlo': 'MC',
  'Nice': 'FR',
  'Paris': 'FR',
  'London': 'GB',
  'Singapore': 'SG',
  'Dubai': 'AE',
  'Rome': 'IT',
  'Barcelona': 'ES',
  'Madrid': 'ES',
  'Luxembourg': 'LU',
  'Washington DC': 'US',
  'Valencia': 'ES'
};

// Approximate city centers and search radius
export const CITY_CENTERS: Record<string, { lat: number; lng: number; radius: number }> = {
  'Monte Carlo': { lat: 43.7384, lng: 7.4246, radius: 30 },
  'Nice': { lat: 43.7102, lng: 7.2620, radius: 30 },
  'Paris': { lat: 48.8566, lng: 2.3522, radius: 30 },
  'London': { lat: 51.5074, lng: -0.1278, radius: 30 },
  'Singapore': { lat: 1.3521, lng: 103.8198, radius: 30 },
  'Dubai': { lat: 25.2048, lng: 55.2708, radius: 30 },
  'Rome': { lat: 41.9028, lng: 12.4964, radius: 30 },
  'Barcelona': { lat: 41.3851, lng: 2.1734, radius: 30 },
  'Madrid': { lat: 40.4168, lng: -3.7038, radius: 30 },
  'Luxembourg': { lat: 49.6116, lng: 6.1319, radius: 30 },
  'Washington DC': { lat: 38.8977, lng: -77.0365, radius: 30 },
  'Valencia': { lat: 39.4699, lng: -0.3763, radius: 30 }
};