import React from 'react';
import { Edit2, X, Plus } from 'lucide-react';

interface TripActionsProps {
  onQuickPlay: () => void;
  onEdit: () => void;
  onCancel: () => void;
}

const TripActions: React.FC<TripActionsProps> = ({
  onQuickPlay,
  onEdit,
  onCancel
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      <button
        onClick={onQuickPlay}
        className="flex items-center space-x-2 px-3 py-1.5 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors text-sm"
        title="Find players in this city"
      >
        <Plus className="h-4 w-4" />
        <span>Find Players</span>
      </button>
      <button
        onClick={onEdit}
        className="p-2 text-dark-800 hover:text-white transition-colors"
        title="Edit trip"
      >
        <Edit2 className="h-5 w-5" />
      </button>
      <button
        onClick={onCancel}
        className="p-2 text-dark-800 hover:text-red-400 transition-colors"
        title="Cancel trip"
      >
        <X className="h-5 w-5" />
      </button>
    </div>
  );
};

export default TripActions;