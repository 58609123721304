import React, { useState } from 'react';
import { Globe, Phone } from 'lucide-react';
import { VenueApiResult } from '../../../../../types/venue';
import { VenueOpeningHours } from '../VenueOpeningHours';
import LocationActions from '../../../LocationActions';
import { cn } from '@/lib/utils';

interface VenueDetailsProps {
  venue: VenueApiResult;
}

export const VenueDetails: React.FC<VenueDetailsProps> = ({ venue }) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const hasContactInfo = venue.details.website || venue.details.phone;

  const handlePhoneClick = (e: React.MouseEvent) => {
    // Only show number if not on mobile
    if (!('ontouchstart' in window)) {
      e.preventDefault();
      setShowPhoneNumber(true);
    }
  };

  return (
    <div className="space-y-2 flex-1">
      {/* Opening Hours */}
      <VenueOpeningHours openingHours={venue.opening_hours} />

      {/* Contact Information and Navigation */}
      {(hasContactInfo || venue.coordinates) && (
        <div className="flex items-center space-x-4">
          {venue.details.phone && (
            <a 
              href={`tel:${venue.details.phone}`}
              onClick={handlePhoneClick}
              className={cn(
                "flex items-center rounded-xl p-3 group transition-colors",
                "dark:bg-dark-300/50 bg-light-200/50",
                "dark:hover:bg-dark-300 hover:bg-light-200"
              )}
            >
              <Phone className={cn(
                "h-5 w-5",
                !showPhoneNumber && "mr-2",
                "dark:text-dark-800 text-light-600",
                "group-hover:text-gold-400"
              )} />
              <span className={cn(
                "dark:text-white text-light-900",
                "group-hover:text-gold-400"
              )}>
                {showPhoneNumber ? venue.details.phone : 'Call'}
              </span>
            </a>
          )}
          
          {venue.details.website && (
            <a 
              href={venue.details.website}
              target="_blank"
              rel="noopener noreferrer"
              className={cn(
                "flex items-center rounded-xl p-3 group transition-colors",
                "dark:bg-dark-300/50 bg-light-200/50",
                "dark:hover:bg-dark-300 hover:bg-light-200"
              )}
            >
              <Globe className={cn(
                "h-5 w-5 mr-2",
                "dark:text-dark-800 text-light-600",
                "group-hover:text-gold-400"
              )} />
              <span className={cn(
                "dark:text-white text-light-900",
                "group-hover:text-gold-400"
              )}>
                Website
              </span>
            </a>
          )}

          {venue.coordinates && (
            <LocationActions
              venueName={venue.name}
              address={venue.address}
              coordinates={venue.coordinates}
              className="flex-1"
            />
          )}
        </div>
      )}
    </div>
  );
};