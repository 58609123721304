import { City } from '../../../types/city';
import { GEOCODING_CONFIG } from './config';

interface CacheEntry {
  timestamp: number;
  results: City[];
}

export class GeocodingCache {
  private static cache = new Map<string, CacheEntry>();

  static get(query: string): City[] | null {
    const entry = this.cache.get(this.normalizeKey(query));
    if (!entry) return null;

    // Check if cache has expired
    if (Date.now() - entry.timestamp > GEOCODING_CONFIG.common.cacheExpiry) {
      this.cache.delete(this.normalizeKey(query));
      return null;
    }

    return entry.results;
  }

  static set(query: string, results: City[]): void {
    this.cache.set(this.normalizeKey(query), {
      timestamp: Date.now(),
      results
    });
  }

  static clear(): void {
    this.cache.clear();
  }

  private static normalizeKey(query: string): string {
    return query.toLowerCase().trim();
  }
}