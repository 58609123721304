import React, { useState, useEffect, useRef } from 'react';
import { Send, Smile, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Comment } from '../../../types/social';
import CommentsSkeleton from './CommentsSkeleton';
import { Button } from '../../ui/button';
import { getComments, createComment, subscribeToComments } from '../../../lib/supabase/social';
import { formatRelativeTime } from '../../../lib/utils/time';
import { API_CONFIG } from '@/lib/constants';

const { MAX_RETRIES, RETRY_DELAY } = API_CONFIG;

interface PostCommentsProps {
  postId: string;
  onCommentAdded?: () => void;
}

const PostComments: React.FC<PostCommentsProps> = ({ postId, onCommentAdded }) => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<Comment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalComments, setTotalComments] = useState(0);
  const commentCache = useRef(new Set<string>());
  const subscriptionRef = useRef<() => void>();
  const isSubscribed = useRef(false);

  // Fetch initial comments
  useEffect(() => {
    const fetchComments = async () => {
      try {
        setIsLoading(true);
        setError(null);

        setIsLoading(true);
        
        const { comments: initialComments, hasMore: moreComments, total } = 
          await getComments(postId);
        
        setComments(initialComments);
        setHasMore(moreComments);
        setTotalComments(total);

        // Add initial comments to cache
        initialComments.forEach(comment => {
          commentCache.current.add(comment.id);
        });
      } catch (error) {
        console.error('Error fetching comments:', error);
        if (error instanceof Error && error.message.includes('Failed to fetch')) {
          setError('Unable to load comments. Please check your connection.');
        } else {
          setError('Failed to load comments');
        }
        // Set empty state on error
        setComments([]);
        setHasMore(false);
        setTotalComments(0);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();
  }, [postId]); // Only re-run if postId changes

  // Subscribe to new comments
  useEffect(() => {
    // Clean up previous subscription
    if (subscriptionRef.current && isSubscribed.current) {
      subscriptionRef.current();
      subscriptionRef.current = undefined;
      isSubscribed.current = false;
    }

    // Initialize notifications and subscription
    subscriptionRef.current = subscribeToComments(postId, (comment) => {
      if (!isSubscribed.current) return;
      
      // Check if we've already seen this comment
      if (commentCache.current.has(comment.id)) {
        return;
      }
      
      // Add to cache and state
      commentCache.current.add(comment.id);
      setComments(prev => {
        // Avoid duplicate comments
        if (prev.some(c => c.id === comment.id)) {
          return prev;
        }
        return [...prev, comment];
      });
    });
    isSubscribed.current = true;

    return () => {
      if (subscriptionRef.current && isSubscribed.current) {
        subscriptionRef.current();
        subscriptionRef.current = undefined;
        isSubscribed.current = false;
      }
      commentCache.current.clear();
    };
  }, [postId]); // Only re-run if postId changes

  const loadMoreComments = async () => {
    if (!hasMore || isLoading) return;

    try {
      setIsLoading(true);
      const nextPage = page + 1;
      const { comments: moreComments, hasMore: hasMoreComments } = 
        await getComments(postId, nextPage);
      
      setComments(prev => [...prev, ...moreComments]);
      setHasMore(hasMoreComments);
      setPage(nextPage);
    } catch (error) {
      console.error('Error loading more comments:', error);
      setError('Failed to load more comments');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!comment.trim() || isSubmitting) return;
    
    const commentText = comment.trim();
    setIsSubmitting(true);
    setError(null);
    setComment('');

    try {
      const newComment = await createComment(postId, commentText);

      // Add to cache to prevent duplication
      commentCache.current.add(newComment.id);
      
      setComments(prev => {
        // Avoid duplicate comments
        if (prev.some(c => c.id === newComment.id)) {
          return prev;
        }
        return [...prev, newComment];
      });
      
      setTotalComments(prev => prev + 1);
      onCommentAdded?.();
    } catch (error) {
      // Restore comment text if submission fails
      setComment(commentText);
      if (error instanceof Error) {
        if (error.message.includes('Failed to fetch')) {
          setError('Unable to post comment. Please check your connection and try again.');
        } else if (error.message.includes('Not authenticated')) {
          setError('Please sign in to comment.');
        } else {
          setError(error.message);
        }
      } else {
        setError('Failed to post comment. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      className="border-t border-dark-300"
    >
      <div className="p-4 space-y-4">
        {/* Comments List */}
        <div className="space-y-4">
          {isLoading ? (
            <CommentsSkeleton count={3} />
          ) : (
          <AnimatePresence mode="popLayout">
            {comments.map(comment => (
              <motion.div
                key={comment.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex space-x-3"
              >
                <img
                  src={comment.author.image}
                  alt={comment.author.name}
                  className="w-8 h-8 rounded-full object-cover border border-dark-400"
                />
                <div className="flex-1">
                  <div className="bg-dark-300 rounded-2xl px-4 py-2.5">
                    <div className="font-medium text-white">{comment.author.name}</div>
                    <p className="text-white text-sm">{comment.content}</p>
                  </div>
                  <div className="flex items-center space-x-4 mt-1 px-2">
                    <button className="text-xs text-dark-800 hover:text-gold-400 transition-colors">
                      Like
                    </button>
                    <button className="text-xs text-dark-800 hover:text-gold-400 transition-colors">
                      Reply
                    </button>
                    <span className="text-dark-800 text-xs">
                      {formatRelativeTime(comment.createdAt)}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
          )}
        </div>

        {/* Load More Button */}
        {hasMore && (
          <div className="text-center">
            <Button
              onClick={loadMoreComments}
              disabled={isLoading}
              variant="outline"
              className="text-dark-800 hover:text-white"
            >
              Load More Comments
            </Button>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="text-red-400 text-sm text-center">
            {error}
          </div>
        )}

        {/* Comment Input */}
        <form onSubmit={handleSubmit} className="relative flex items-center">
          <button
            type="button"
            className="absolute left-3 text-dark-800 hover:text-gold-400 transition-colors"
          >
            <Smile className="h-5 w-5" />
          </button>
          
          <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Write a comment..."
            className="flex-1 bg-dark-300 border border-dark-400 rounded-full pl-12 pr-12 py-2.5 text-white placeholder-dark-800"
          />
          
          <button
            type="submit"
            disabled={!comment.trim() || isSubmitting}
            className="absolute right-3 text-gold-400 hover:text-gold-500 disabled:text-dark-800 transition-colors"
          >
            {isSubmitting ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              <Send className="h-5 w-5" />
            )}
          </button>
        </form>
      </div>
    </motion.div>
  );
};

export default PostComments;