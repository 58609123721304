interface AddressProperties {
  name?: string;
  street?: string;
  housenumber?: string;
  city?: string;
  state?: string;
  country?: string;
  postcode?: string;
}

export function formatAddress(properties: AddressProperties): string {
  const parts: string[] = [];

  // Add street address
  const streetParts: string[] = [];
  if (properties.street) streetParts.push(properties.street);
  if (properties.housenumber) streetParts.push(properties.housenumber);
  if (streetParts.length) parts.push(streetParts.join(' '));

  // Add city
  if (properties.city) parts.push(properties.city);

  // Add state/region if available
  if (properties.state) parts.push(properties.state);

  return parts.join(', ');
}