import React from 'react';
import { VenueApiResult } from '../../../types/venue';
import { VenueSearchSection } from './VenueSearchSection';
import { useVenueSearch } from './hooks/useVenueSearch';
import { useVenueDistance } from './hooks/useVenueDistance';
import { useInfiniteScroll } from './hooks/useInfiniteScroll';
import VenueListItem from './components/VenueListItem';
import VenueListControls from './components/VenueListControls';
import SimpleMap from '../maps/SimpleMap';
import { useAddressSearch } from './hooks/useAddressSearch';
import { sortVenues } from '../../../lib/utils/venue-sorting';
import type { VenueSortOption } from '../../../lib/utils/venue-sorting';
import { cn } from '@/lib/utils';

interface VenueApiListProps {
  city: string;
}

const ITEMS_PER_PAGE = 40;

export function VenueApiList({ city }: VenueApiListProps) {
  const [sortBy, setSortBy] = React.useState<VenueSortOption>('distance');
  const [viewMode, setViewMode] = React.useState<'list' | 'map'>('list');
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  const {
    searchTerm,
    setSearchTerm,
    showOpenOnly,
    setShowOpenOnly,
    filteredVenues,
    isLoading: isVenuesLoading
  } = useVenueSearch(city);

  const {
    address,
    referencePoint,
    isLoading: isAddressLoading,
    error: addressError,
    setAddress,
    setReferencePoint,
    resetLocation
  } = useAddressSearch();

  // Get distances for filtered venues
  const distances = useVenueDistance(filteredVenues, referencePoint);

  // Sort venues based on current criteria
  const sortedVenues = React.useMemo(() => 
    sortVenues(filteredVenues, sortBy, distances),
    [filteredVenues, sortBy, distances]
  );

  const {
    page,
    loading: isLoadingMore,
    hasMore,
    lastElementRef,
    reset: resetScroll
  } = useInfiniteScroll({
    totalItems: sortedVenues.length,
    itemsPerPage: ITEMS_PER_PAGE
  });

  // Calculate displayed venues
  const displayedVenues = React.useMemo(() => 
    sortedVenues.slice(0, page * ITEMS_PER_PAGE),
    [sortedVenues, page]
  );

  // Handle refresh
  const handleRefresh = React.useCallback(async () => {
    resetScroll();
    setSearchTerm('');
    setSelectedTags([]);
    setShowOpenOnly(false);
    setSortBy('distance');
    await resetLocation();
  }, [resetScroll, setSearchTerm, setShowOpenOnly, setSortBy, resetLocation]);

  const handleTagSelect = (tag: string) => {
    setSelectedTags(prev => {
      if (prev.includes(tag)) {
        return [];
      }
      return [tag];
    });
    setSearchTerm(tag);
  };

  if (isVenuesLoading) {
    return (
      <div className="flex justify-center py-12">
        <div className="h-8 w-8 text-gold-400 animate-spin rounded-full border-2 border-current border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <VenueSearchSection
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        selectedTags={selectedTags}
        onTagSelect={handleTagSelect}
      />

      {/* Controls */}
      <VenueListControls
        totalVenues={filteredVenues.length}
        showOpenOnly={showOpenOnly}
        onShowOpenOnlyChange={setShowOpenOnly}
        sortBy={sortBy}
        onSortChange={setSortBy}
        onRefresh={handleRefresh}
        isLoading={isAddressLoading}
        viewMode={viewMode}
        onViewModeChange={setViewMode}
        address={address}
        onAddressChange={setAddress}
        onLocationSelect={setReferencePoint}
        isAddressLoading={isAddressLoading}
        addressError={addressError}
      />

      {viewMode === 'map' ? (
        <div className="h-[calc(100vh-12rem)]">
          <SimpleMap 
            venues={sortedVenues}
            className="w-full h-full"
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {displayedVenues.map((venue, index) => (
            <VenueListItem
              key={venue.id}
              venue={venue}
              distance={distances[venue.id]}
              isLast={index === displayedVenues.length - 1}
              lastElementRef={lastElementRef}
            />
          ))}

          {/* Loading indicator */}
          {isLoadingMore && hasMore && (
            <div className="flex justify-center py-4">
              <div className="h-6 w-6 text-gold-400 animate-spin rounded-full border-2 border-current border-t-transparent" />
            </div>
          )}

          {/* No more venues message */}
          {!isLoadingMore && !hasMore && displayedVenues.length > 0 && (
            <div className="text-center py-4 text-dark-800">
              No more venues to load
            </div>
          )}

          {/* No venues found message */}
          {!isLoadingMore && displayedVenues.length === 0 && (
            <div className="text-center py-12 bg-dark-300 rounded-xl">
              <svg className="h-12 w-12 text-dark-800 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
              <h3 className="text-lg font-medium text-white mb-2">No Venues Found</h3>
              <p className="text-dark-800">
                Try adjusting your search filters
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}