import React from 'react';
import { MapPin, UserX, Calendar } from 'lucide-react';
import { BlockedMember } from '../../../../types/member';

interface BlockedMembersListProps {
  members: BlockedMember[];
  onUnblock: (memberId: string) => void;
}

const BlockedMembersList: React.FC<BlockedMembersListProps> = ({
  members,
  onUnblock
}) => {
  if (members.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <UserX className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Blocked Members</h3>
        <p className="text-dark-800">You haven't blocked any members yet</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {members.map((member) => (
        <div
          key={member.id}
          className="bg-dark-200 rounded-xl p-4 border border-dark-300"
        >
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <img
                src={member.image}
                alt={member.name}
                className="w-16 h-16 rounded-full object-cover border-2 border-dark-400"
              />
            </div>
            <div className="flex-1">
              <div className="flex items-start justify-between">
                <div>
                  <button className="text-white font-medium hover:text-gold-400 transition-colors">
                    {member.name}
                  </button>
                  {(member.jobTitle || member.company) && (
                    <div className="text-dark-800 mt-1">
                      {member.jobTitle} {member.company && `at ${member.company}`}
                    </div>
                  )}
                  {member.city && (
                    <div className="flex items-center text-dark-800 mt-1">
                      <MapPin className="h-4 w-4 mr-1" />
                      {member.city}
                    </div>
                  )}
                  <div className="flex items-center text-dark-800 mt-1">
                    <Calendar className="h-4 w-4 mr-1" />
                    Blocked on {member.blockedDate}
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => onUnblock(member.id)}
                    className="px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-lg transition-colors"
                  >
                    Unblock
                  </button>
                </div>
              </div>

              <div className="mt-4 pt-4 border-t border-dark-300">
                <div className="flex items-center text-dark-800">
                  <UserX className="h-4 w-4 mr-2" />
                  <span>Member blocked • Cannot view their activity or send messages</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlockedMembersList;