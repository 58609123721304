// Sport emojis mapping
export const sportEmojis: Record<string, string> = { 
 // Racket Sports
  'Tennis': '🎾',
  'Padel': '🎾',
  'Badminton': '🏸',
  'Table Tennis': '🏓',
  'Squash': '🎾',
  'Pickleball': '🎾',
  
  // Ball Sports
  'Football': '⚽',
  'Basketball': '🏀',
  'Volleyball': '🏐',
  'Rugby': '🏉',
  'Cricket': '🏏',
  'Hockey': '🏑',
  'Softball': '🥎',
  'Billiards': '🎱',
  'Pool': '🎱',
  
  // Individual Sports
  'Golf': '⛳',
  'Running': '🏃‍♂️',
  'Cycling': '🚴‍♂️',
  'Swimming': '🏊‍♂️',
  'Climbing': '🧗‍♂️',
  'Surfing': '🏄‍♂️',
  'Skiing': '⛷️',
  'Snowboarding': '🏂',
  'Trekking': '🥾',
  'Athletic': '🏃',
  
  // Combat Sports
  'Boxing': '🥊',
  'Martial Arts': '🥋',
  'Karate': '🥋',
  'Taekwondo': '🥋',
  'Aikido': '🥋',
  
  // Equestrian Sports
  'Horse Riding': '🐎',
  'Polo': '🏇',
  'Horse Racing': '🏇',
  'Equestrian': '🐎',
  
  // Water Sports
  'Sailing': '⛵',
  'Wakeboarding': '🏄‍♂️',
  'Wakesurfing': '🏄‍♂️',
  'Sea Sports': '🛥️',
  'Aquatics': '🏊‍♂️',
  
  // Fitness & Wellness
  'Fitness': '💪',
  'Weight Training': '🏋️‍♀️',
  'Gym': '🏋️‍♂️',
  'Yoga': '🧘‍♀️',
  'Pilates': '🧘‍♂️',
  'Meditation': '🧘',
  'Aerobics': '💃',
  'Zumba': '💃',
  'Training': '🎯',
  'Wellness': '🧘‍♀️',
  'Spa': '💆‍♂️'
};

// API retry configuration
export const API_CONFIG = {
  MAX_RETRIES: 3,
  RETRY_DELAY: 1000,
  NETWORK_TIMEOUT: 5000
} as const;