import { MapLocation } from '../types';
import { filterLocationResults } from '../filters';
import { PHOTON_API_URL, SEARCH_PARAMS, DEFAULT_SEARCH_RADIUS } from '../constants';
import { formatAddress } from '../utils/address';
import { calculateDistance } from '../utils/coordinates';
import { normalizeText } from '../utils/text';

export class PhotonService {
  static async searchLocation(query: string, city?: string): Promise<MapLocation[]> {
    try {
      if (!query.trim() || !city) {
        return [];
      }

      // Build search query with city context
      const searchQuery = `${query} ${city}`;
      const url = new URL(`${PHOTON_API_URL}/api/`);
      url.searchParams.append('q', searchQuery);
      url.searchParams.append('limit', SEARCH_PARAMS.limit.toString());

      const response = await fetch(url.toString());
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (!data.features?.length) {
        return [];
      }

      // Process and filter results
      const locations = data.features
        .map(feature => {
          const props = feature.properties;
          const [lng, lat] = feature.geometry.coordinates;

          // Skip if no valid coordinates
          if (!lat || !lng) return null;

          // Create location object
          const location: MapLocation = {
            lat,
            lng,
            name: props.name,
            address: formatAddress({
              street: props.street,
              housenumber: props.housenumber,
              city: props.city || city,
              state: props.state,
              country: props.country
            }),
            type: props.type,
            city: props.city || city,
            country: props.country?.toLowerCase()
          };

          // Calculate relevance score
          const score = this.calculateRelevanceScore(location, city, query);
          if (score < SEARCH_PARAMS.minScore) return null;

          return location;
        })
        .filter((location): location is MapLocation => location !== null);

      // Filter results to ensure they're relevant to the target city
      return this.filterByCity(locations, city);
    } catch (error) {
      console.error('PhotonService searchLocation error:', error);
      return [];
    }
  }

  private static calculateRelevanceScore(location: MapLocation, targetCity: string, query: string): number {
    const normalizedCity = normalizeText(targetCity);
    const normalizedLocationCity = normalizeText(location.city || '');
    const normalizedAddress = normalizeText(location.address || '');
    const normalizedQuery = normalizeText(query);

    let score = 0;

    // Exact city match
    if (normalizedLocationCity === normalizedCity) {
      score += 0.5;
    }

    // City name appears in address
    if (normalizedAddress.includes(normalizedCity)) {
      score += 0.3;
    }

    // Query relevance
    if (normalizedAddress.includes(normalizedQuery)) {
      score += 0.2;
    }

    return score;
  }

  private static filterByCity(locations: MapLocation[], targetCity: string): MapLocation[] {
    const normalizedCity = normalizeText(targetCity);
    
    return locations.filter(location => {
      // Must have city information
      if (!location.city && !location.address) return false;

      // Check if location is in the target city
      const locationCity = normalizeText(location.city || '');
      const locationAddress = normalizeText(location.address || '');

      return locationCity === normalizedCity || 
             locationAddress.includes(normalizedCity);
    });
  }

  static async reverseGeocode(lat: number, lng: number): Promise<MapLocation | null> {
    try {
      const response = await fetch(`${PHOTON_API_URL}/reverse?lat=${lat}&lon=${lng}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.features?.length) {
        return null;
      }

      const feature = data.features[0];
      const props = feature.properties;
      const [lng2, lat2] = feature.geometry.coordinates;

      return {
        lat: lat2,
        lng: lng2,
        name: props.name,
        address: formatAddress({
          street: props.street,
          housenumber: props.housenumber,
          city: props.city,
          state: props.state,
          country: props.country
        }),
        type: props.type,
        city: props.city,
        country: props.country?.toLowerCase()
      };
    } catch (error) {
      console.error('PhotonService reverseGeocode error:', error);
      return null;
    }
  }
}