import { useState, useEffect } from 'react';
import { VenueApiResult } from '../../../../types/venue';
import { getUserLocation } from '../../../../lib/utils/location';
import { calculateDistance } from '../../../../lib/utils/coordinates';

export function useVenueDistance(
  venues: VenueApiResult[],
  referencePoint: { lat: number; lng: number } | null = null
) {
  const [distances, setDistances] = useState<Record<string, number>>({});

  useEffect(() => {
    let mounted = true;

    const calculateDistances = async () => {
      try {
        // Use provided reference point or get user location
        const location = referencePoint || await getUserLocation();
        const userLat = referencePoint?.lat || location.latitude;
        const userLng = referencePoint?.lng || location.longitude;
        
        if (!mounted) return;

        const newDistances: Record<string, number> = {};
        
        venues.forEach(venue => {
          if (venue.coordinates?.lat && venue.coordinates?.lng) {
            const distance = calculateDistance(
              userLat,
              userLng,
              venue.coordinates.lat,
              venue.coordinates.lng
            );
            newDistances[venue.id] = distance;
          } else {
            newDistances[venue.id] = -1;
          }
        });
        
        setDistances(newDistances);
      } catch (error) {
        console.error('Error calculating distances:', error);
        // Set -1 for all venues on error
        const fallbackDistances = venues.reduce((acc, venue) => {
          acc[venue.id] = -1;
          return acc;
        }, {} as Record<string, number>);
        setDistances(fallbackDistances);
      }
    };

    calculateDistances();

    return () => {
      mounted = false;
    };
  }, [venues, referencePoint]);

  return distances;
}