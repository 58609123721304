import React from 'react';
import VideoAnalysisDashboard from '../../videoAnalysis/VideoAnalysisDashboard';
import BlurredVideoAnalysisOverlay from '../overlays/BlurredVideoAnalysisOverlay';

const VideoAnalysisTab: React.FC = () => {
  return (
    <div className="relative min-h-[600px]">
      {/* Scrollable content with blur */}
      <div className="absolute inset-0 overflow-y-auto">
        <div className="blur-sm pointer-events-none">
          <VideoAnalysisDashboard />
        </div>
      </div>

      {/* Centered overlay */}
      <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
        <div className="pointer-events-auto">
          <BlurredVideoAnalysisOverlay />
        </div>
      </div>
    </div>
  );
};

export default VideoAnalysisTab;