import React, { useRef, useEffect, useState } from 'react';
import { VenueApiResult } from '../../../types/venue';
import { VenuePhoto } from './components/VenueCard/VenuePhoto';
import { VenueHeader } from './components/VenueCard/VenueHeader';
import { VenueContent } from './components/VenueCard/VenueContent';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface VenueCardProps {
  venue: VenueApiResult;
  distance?: number;
}

const VenueCard: React.FC<VenueCardProps> = ({ venue, distance }) => {
  const { isDarkMode } = useTheme();
  const cardRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Card needs to be 50% visible
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(venue.address);
    } catch (error) {
      console.error('Failed to copy address:', error);
    }
  };

  return (
    <div 
      ref={cardRef}
      className={cn(
        "flex flex-col md:flex-row bg-dark-200 rounded-xl overflow-hidden",
        "border transition-all duration-500",
        isVisible 
          ? "border-gold-400 shadow-lg shadow-dark-900/5 scale-[1.01]" 
          : "border-dark-300 scale-100",
        "hover:border-gold-400 hover:shadow-xl hover:shadow-dark-900/5 hover:scale-[1.01]"
      )}
    >
      <VenuePhoto venue={venue} />
      
      <div className="flex-1 p-3">
        <VenueHeader
          name={venue.name}
          address={venue.address}
          distance={distance}
          logo={venue.logo}
          onCopyAddress={handleCopyAddress}
        />
        
        <VenueContent venue={venue} />
      </div>
    </div>
  );
};

export default VenueCard;