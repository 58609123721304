import { OnboardingData } from '../types';

export const initialOnboardingData: OnboardingData = {
  fullName: '',
  email: '',
  company: '',
  jobTitle: '',
  dateOfBirth: '',
  gender: '',
  city: '',
  nationality: undefined,
  phone: undefined,
  sports: undefined,
  profilePic: undefined,
  linkedinUrl: undefined
};