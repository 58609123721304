import React, { useState } from 'react';
import { Send, CheckCircle2, Star } from 'lucide-react';
import { Button } from '../ui/button';
import { useNotificationStore } from '../../store/notificationStore';

interface FeedbackFormProps {
  onClose: () => void;
}

const FEEDBACK_TYPES = [
  { id: 'feature', label: 'Feature Request' },
  { id: 'improvement', label: 'Improvement Suggestion' },
  { id: 'bug', label: 'Bug Report' },
  { id: 'general', label: 'General Feedback' }
];

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onClose }) => {
  const [feedbackType, setFeedbackType] = useState('');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState<number>(0);
  const [hoveredRating, setHoveredRating] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { addNotification } = useNotificationStore();

  const handleSubmit = async () => {
    if (!feedbackType || !feedback.trim() || !rating) return;

    setIsSubmitting(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));

      // Show success notification
      addNotification({
        id: `feedback_${Date.now()}`,
        type: 'new_message',
        title: 'Feedback Submitted',
        message: 'Thank you for your feedback! We appreciate your input.',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/settings'
      });

      // Show success state
      setShowSuccess(true);

      // Close modal after delay
      setTimeout(() => {
        onClose();
        // Reset form after modal closes
        setFeedbackType('');
        setFeedback('');
        setRating(0);
        setShowSuccess(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showSuccess) {
    return (
      <div className="text-center py-6">
        <div className="w-16 h-16 bg-green-400/20 rounded-full flex items-center justify-center mx-auto mb-6">
          <CheckCircle2 className="h-8 w-8 text-green-400" />
        </div>
        <h3 className="text-xl font-semibold text-white mb-2">Thank You!</h3>
        <p className="text-dark-800">
          Your feedback has been submitted successfully. We appreciate your input!
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Star Rating */}
      <div>
        <label className="block text-dark-800 mb-2">Overall Rating</label>
        <div className="flex items-center justify-center space-x-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => setRating(star)}
              onMouseEnter={() => setHoveredRating(star)}
              onMouseLeave={() => setHoveredRating(0)}
              className="p-1 transition-colors focus:outline-none"
            >
              <Star
                className={`h-8 w-8 ${
                  star <= (hoveredRating || rating)
                    ? 'fill-gold-400 text-gold-400'
                    : 'text-dark-800'
                } transition-colors`}
              />
            </button>
          ))}
        </div>
        <div className="text-center text-dark-800 text-sm mt-2">
          {rating === 0 ? 'Select a rating' : `${rating} out of 5 stars`}
        </div>
      </div>

      {/* Feedback Type */}
      <div>
        <label className="block text-dark-800 mb-2">Type of Feedback</label>
        <div className="grid grid-cols-2 gap-2">
          {FEEDBACK_TYPES.map(type => (
            <button
              key={type.id}
              onClick={() => setFeedbackType(type.id)}
              className={`p-3 rounded-xl transition-colors ${
                feedbackType === type.id
                  ? 'bg-gold-400 text-dark-50'
                  : 'bg-dark-300 text-white hover:bg-dark-400'
              }`}
            >
              {type.label}
            </button>
          ))}
        </div>
      </div>

      {/* Feedback Content */}
      <div>
        <label className="block text-dark-800 mb-2">Your Feedback</label>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Share your thoughts, ideas, or report issues..."
          className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white placeholder-dark-800 resize-none"
        />
      </div>

      {/* Submit Button */}
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !feedbackType || !feedback.trim() || !rating}
        className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50"
      >
        <Send className="h-4 w-4 mr-2" />
        {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
      </Button>
    </div>
  );
};

export default FeedbackForm;