import React, { useState } from 'react';
import { Share2, MapPin, Calendar } from 'lucide-react';
import CitySearchInput from '../../trips/CitySearchInput';
import DateRangePicker from '../../ui/date-range-picker';
import { Trip } from '../../../types/trip';
import { Button } from '../../ui/button';
import { Switch } from '../../ui/switch';

interface NewTripFormProps {
  onSubmit: (trip: Trip) => void;
  onCancel: () => void;
  initialData?: Trip;
  isSubmitting?: boolean;
}

const NewTripForm: React.FC<NewTripFormProps> = ({ 
  onSubmit, 
  onCancel, 
  initialData,
  isSubmitting = false 
}) => {
  const [formData, setFormData] = useState({
    city: initialData?.city || '',
    dateRange: {
      start: initialData?.startDate || '',
      end: initialData?.endDate || ''
    },
    shareOnFeed: initialData?.shareOnFeed || false
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.city || !formData.dateRange.start || !formData.dateRange.end) return;
    
    const trip: Trip = {
      id: initialData?.id || Date.now().toString(),
      city: formData.city,
      startDate: new Date(formData.dateRange.start).toLocaleDateString('en-CA'),
      endDate: new Date(formData.dateRange.end).toLocaleDateString('en-CA'),
      shareOnFeed: formData.shareOnFeed,
      activities: [],
      createdAt: initialData?.createdAt || new Date().toISOString()
    };

    onSubmit(trip);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column: City Selection */}
        <div>
          <div className="bg-dark-300 rounded-xl p-6">
            <div className="flex items-center space-x-2 mb-4">
              <MapPin className="h-5 w-5 text-gold-400" />
              <h3 className="text-lg font-medium text-white">Where are you going?</h3>
            </div>
            <CitySearchInput
              value={formData.city}
              onChange={(city) => setFormData({ ...formData, city })}
              placeholder="Enter city..."
              className="w-full"
            />
          </div>
        </div>

        {/* Right Column: Date Selection */}
        <div>
          <div className="bg-dark-300 rounded-xl p-6">
            <div className="flex items-center space-x-2 mb-4">
              <Calendar className="h-5 w-5 text-gold-400" />
              <h3 className="text-lg font-medium text-white">When are you traveling?</h3>
            </div>
            <DateRangePicker
              dateRange={formData.dateRange}
              onDateRangeChange={(dateRange) => setFormData({ ...formData, dateRange })}
            />
          </div>
        </div>
      </div>

      {/* Share Option */}
      <div className="bg-dark-300 rounded-xl p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Share2 className="h-5 w-5 text-gold-400" />
            <div>
              <h3 className="text-lg font-medium text-white">Share on Feed</h3>
              <p className="text-dark-800 text-sm">Let your network know about your trip</p>
            </div>
          </div>
          <Switch
            checked={formData.shareOnFeed}
            onCheckedChange={(checked) => setFormData(prev => ({ ...prev, shareOnFeed: checked }))}
          />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-3">
        <Button
          type="button"
          onClick={onCancel}
          disabled={isSubmitting}
          variant="outline"
          className="border-dark-400 text-white hover:bg-dark-400"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !formData.city || !formData.dateRange.start || !formData.dateRange.end}
          className="bg-gold-400 hover:bg-gold-500 text-dark-50"
        >
          {isSubmitting ? 'Saving...' : initialData ? 'Update Trip' : 'Create Trip'}
        </Button>
      </div>
    </form>
  );
};

export default NewTripForm;