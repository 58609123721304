import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface EliteStore {
  pendingApplications: string[];
  addPendingApplication: (componentId: string) => void;
  hasPendingApplication: (componentId: string) => boolean;
}

export const useEliteStore = create<EliteStore>()(
  persist(
    (set, get) => ({
      pendingApplications: [],
      addPendingApplication: (componentId) => 
        set((state) => ({
          pendingApplications: [...state.pendingApplications, componentId]
        })),
      hasPendingApplication: (componentId) => 
        get().pendingApplications.includes(componentId)
    }),
    {
      name: 'elite-applications'
    }
  )
);