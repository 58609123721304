import { normalizeText } from '../utils/text';
import { CITY_ALIASES } from './constants';

export function normalizeCity(city: string): string {
  // Clean and normalize the input
  const normalizedInput = normalizeText(city);

  // Check for canonical name first
  const canonicalName = CITY_ALIASES[normalizedInput];
  if (canonicalName) {
    return canonicalName;
  }

  // Extract city name (first part before any comma)
  const cityPart = normalizedInput.split(',')[0].trim();

  // Clean up city name
  const cleanCity = cityPart
    .replace(/\s+city$/i, '') // Remove "city" suffix
    .replace(/\s+/g, ' ') // Normalize spaces
    .trim();

  // Check for canonical name again after cleanup
  const cleanCanonicalName = CITY_ALIASES[cleanCity];
  if (cleanCanonicalName) {
    return cleanCanonicalName;
  }

  // Return properly cased version of the city name
  return cleanCity.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}