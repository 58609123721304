import React from 'react';
import { Search } from 'lucide-react';
import ConversationList from '../conversations/ConversationList';
import type { Conversation } from '../../../types/chat';

interface SidebarProps {
  conversations: Conversation[];
  activeChat: string | null;
  searchTerm: string;
  onSearchChange: (term: string) => void;
  onChatSelect: (chatId: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  conversations,
  activeChat,
  searchTerm,
  onSearchChange,
  onChatSelect
}) => {
  return (
    <>
      {/* Fixed Search Header */}
      <div className="flex-shrink-0 p-4 border-b border-dark-300">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-dark-300 border border-dark-400 rounded-lg text-white placeholder-dark-800 text-sm"
          />
        </div>
      </div>

      {/* Scrollable Conversation List */}
      <div className="flex-1 overflow-y-auto">
        <ConversationList
          conversations={conversations}
          activeChat={activeChat}
          onChatSelect={onChatSelect}
        />
      </div>
    </>
  );
};

export default Sidebar;