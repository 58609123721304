import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import { useQuickMatch } from '../hooks/matchmaking/useQuickMatch';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';

export default function QuickPlayTransition() {
  const navigate = useNavigate();
  const { resetSearch } = useQuickMatch();

  useEffect(() => {
    resetSearch();
    const timer = setTimeout(() => {
      navigate('/dashboard/quick-play', { replace: true });
    }, 500); // Reduced to 500ms for a quicker transition
    return () => clearTimeout(timer);
  }, [navigate, resetSearch]);

  return (
    <DashboardLayout>
      <div className="flex items-center justify-center min-h-[calc(100vh-8rem)]">
        <div className="backdrop-blur-sm bg-dark-200/30 rounded-2xl p-8 max-w-md mx-auto text-center relative overflow-hidden">
          <div className="relative flex items-center justify-center h-48 w-40 mx-auto">
            <div className="absolute inset-0 flex items-center justify-center">
              {/* Wave effects */}
              <div 
                className="absolute w-16 h-16 rounded-full bg-gold-400/10"
                style={{ animation: 'wave-out 3s ease-out infinite' }}
              />
              <div 
                className="absolute w-16 h-16 rounded-full bg-gold-400/10"
                style={{ animation: 'wave-out 3s ease-out infinite 1s' }}
              />
              <div 
                className="absolute w-16 h-16 rounded-full bg-gold-400/10"
                style={{ animation: 'wave-out 3s ease-out infinite 2s' }}
              />
              {/* Radar sweep */}
              <div className="absolute w-32 h-32">
                <div 
                  className="absolute inset-0 bg-gradient-to-t from-gold-400/30 via-gold-400/10 to-transparent origin-bottom" 
                  style={{ 
                    clipPath: 'polygon(50% 50%, 60% 0, 40% 0)',
                    animation: 'radar-sweep 2s linear infinite',
                    transformOrigin: 'bottom center'
                  }} 
                />
              </div>
            </div>
            {/* Logo */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-dark-200/80 backdrop-blur-sm p-4 rounded-full w-16 h-16 flex items-center justify-center border border-gold-400/30 animate-[pulse-scale_2s_ease-in-out_infinite]">
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png"
                  alt="Playclub Symbol"
                  className="h-8 w-8"
                  style={{
                    animation: 'spin 3s linear infinite',
                    animationPlayState: 'running'
                  }}
                />
              </div>
            </div>
          </div>
          <h3 className="text-2xl font-semibold text-white mb-3 mt-8">
            Finding Players Near You
          </h3>
          <p className="text-lg text-dark-800">
            Searching for available players...
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
}