import React, { useRef, useState } from 'react';
import { Send, Image, Smile } from 'lucide-react';
import { Button } from '../ui/button';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface MessageInputProps {
  message: string;
  onMessageChange: (message: string) => void;
  onSend: () => void;
  onImageUpload: (file: File) => void;
  onEmojiToggle: () => void;
  disabled?: boolean;
}

const MessageInput: React.FC<MessageInputProps> = ({
  message,
  onMessageChange,
  onSend,
  onImageUpload,
  onEmojiToggle,
  disabled = false
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { isDarkMode } = useTheme();

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message.trim()) {
        onSend();
      }
    }
  };

  const handleSendClick = () => {
    if (message.trim()) {
      onSend();
    }
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    onMessageChange(message + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className="p-4 border-t border-dark-300">
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2">
          <div className="relative">
            <Button
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              variant="ghost"
              size="icon"
              className={cn(
                "text-dark-800 hover:text-gold-400",
                "dark:text-dark-800 dark:hover:text-gold-400",
                "light:text-gray-500 light:hover:text-gold-600"
              )}
            >
              <Smile className="h-5 w-5" />
            </Button>
            {showEmojiPicker && (
              <div className="absolute bottom-12 left-0 z-50">
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  theme={isDarkMode ? Theme.DARK : Theme.LIGHT}
                  searchPlaceHolder="Search emoji..."
                  width={300}
                  height={400}
                />
              </div>
            )}
          </div>
          <Button
            onClick={() => fileInputRef.current?.click()}
            variant="ghost"
            size="icon"
            className={cn(
              "text-dark-800 hover:text-gold-400",
              "dark:text-dark-800 dark:hover:text-gold-400",
              "light:text-gray-500 light:hover:text-gold-600"
            )}
          >
            <Image className="h-5 w-5" />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) onImageUpload(file);
              }}
              className="hidden"
            />
          </Button>
        </div>
        <div className="flex-1 min-w-0">
          <input
            type="text"
            value={message}
            onChange={(e) => onMessageChange(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
            className={cn(
              "w-full rounded-lg px-4 py-2",
              "text-sm",
              "focus:outline-none focus:ring-2 focus:ring-gold-400",
              "dark:bg-dark-300 dark:border-dark-400 dark:text-white dark:placeholder-dark-800",
              "light:bg-light-100 light:border-light-300 light:text-gray-900 light:placeholder-gray-500"
            )}
          />
        </div>
        <Button
          onClick={handleSendClick}
          disabled={disabled || !message.trim()}
          className={cn(
            "bg-gold-400 hover:bg-gold-500 text-dark-50 p-2 rounded-lg transition-colors",
            "disabled:opacity-50 disabled:cursor-not-allowed flex-shrink-0"
          )}
        >
          <Send className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export default MessageInput;