import React from 'react';
import { cn } from '@/lib/utils';

const MemberProfileSkeleton: React.FC = () => {
  return (
    <div className="space-y-6 animate-pulse">
      {/* Back Button Skeleton */}
      <div className="w-20 h-8 bg-dark-300 rounded-lg" />

      {/* Profile Header Skeleton */}
      <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
        {/* Cover Image Skeleton */}
        <div className="h-64 bg-dark-300" />
        
        <div className="relative px-6 pb-8">
          {/* Avatar Skeleton */}
          <div className="flex flex-col items-center -mt-16">
            <div className="w-32 h-32 rounded-full bg-dark-300 border-4 border-dark-200" />
            <div className="mt-4 text-center space-y-2">
              <div className="w-48 h-6 bg-dark-300 rounded mx-auto" />
              <div className="w-32 h-4 bg-dark-300 rounded mx-auto" />
              <div className="w-40 h-4 bg-dark-300 rounded mx-auto" />
              <div className="flex justify-center space-x-2 mt-2">
                <div className="w-8 h-8 bg-dark-300 rounded-full" />
                <div className="w-8 h-8 bg-dark-300 rounded-full" />
                <div className="w-8 h-8 bg-dark-300 rounded-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Grid Skeleton */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="bg-dark-200 rounded-xl p-4 border border-dark-300">
            <div className="w-full h-12 bg-dark-300 rounded" />
            <div className="w-2/3 h-4 bg-dark-300 rounded mt-2" />
          </div>
        ))}
      </div>

      {/* Action Buttons Skeleton */}
      <div className="flex flex-wrap gap-2">
        <div className="w-32 h-10 bg-dark-300 rounded-lg" />
        <div className="w-32 h-10 bg-dark-300 rounded-lg" />
        <div className="w-32 h-10 bg-dark-300 rounded-lg" />
      </div>

      {/* Tabs Skeleton */}
      <div className="flex items-center space-x-4 border-b border-dark-300">
        {[1, 2, 3].map((i) => (
          <div key={i} className="w-24 h-10 bg-dark-300 rounded" />
        ))}
      </div>

      {/* Content Skeleton */}
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-dark-200 rounded-xl p-6 border border-dark-300">
            <div className="w-full h-32 bg-dark-300 rounded" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberProfileSkeleton;