import React, { useEffect } from 'react';
import { Users, MapPin, Trophy } from 'lucide-react';
import { motion } from 'framer-motion';
import { useIntersectionObserver } from './utils/useIntersectionObserver';
import { useCounterAnimation } from '../hooks/useCounterAnimation';
import ProfileGrid from './stats/ProfileGrid';

interface StatsProps {
  memberCount: number;
  facilityCount: number;
  cityCount: number;
  counterRef: React.RefObject<HTMLDivElement>;
}

export const Stats: React.FC<StatsProps> = ({ 
  memberCount,
  facilityCount,
  cityCount,
  counterRef
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  
  const membersCounter = useCounterAnimation(memberCount, 2000, true);
  const facilitiesCounter = useCounterAnimation(facilityCount, 2000, true);
  const citiesCounter = useCounterAnimation(cityCount, 2000, true);
  
  const ref = useIntersectionObserver({
    onIntersect: () => {
      setIsVisible(true);
      membersCounter.startAnimation();
      facilitiesCounter.startAnimation();
      citiesCounter.startAnimation();
    },
    threshold: 0.2
  });

  const profiles = [
    { image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80', name: 'Sarah Connor' },
    { image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80', name: 'James Wilson' },
    { image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80', name: 'Elena Chen' },
    { image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80', name: 'Michael Zhang' },
    { image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?auto=format&fit=crop&w=300&q=80', name: 'Victoria Palmer' }
  ];

  return (
    <section ref={ref} className="py-8 -mt-16 sm:-mt-24 relative z-10"> {/* Adjusted margin for smaller screens */}
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
            className="bg-dark-200/50 backdrop-blur-sm border border-dark-300 rounded-2xl p-6 hover:border-gold-400/30 transition-all duration-300"
          >
            <div className="flex items-center space-x-4">
              <div className="bg-gold-400/10 p-3 rounded-xl">
                <Users className="h-6 w-6 text-gold-400" />
              </div>
              <div>
                <div className="text-3xl font-bold text-white">
                  {membersCounter.count.toLocaleString()}+
                </div>
                <div className="text-dark-800">Global Members</div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-dark-200/50 backdrop-blur-sm border border-dark-300 rounded-2xl p-6 hover:border-gold-400/30 transition-all duration-300"
          >
            <div className="flex items-center space-x-4">
              <div className="bg-gold-400/10 p-3 rounded-xl">
                <MapPin className="h-6 w-6 text-gold-400" />
              </div>
              <div>
                <div className="text-3xl font-bold text-white">
                  {facilitiesCounter.count.toLocaleString()}+
                </div>
                <div className="text-dark-800">Premium Facilities</div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="bg-dark-200/50 backdrop-blur-sm border border-dark-300 rounded-2xl p-6 hover:border-gold-400/30 transition-all duration-300"
          >
            <div className="flex items-center space-x-4">
              <div className="bg-gold-400/10 p-3 rounded-xl">
                <Trophy className="h-6 w-6 text-gold-400" />
              </div>
              <div>
                <div className="text-3xl font-bold text-white">
                  {citiesCounter.count.toLocaleString()}+
                </div>
                <div className="text-dark-800">Active Cities</div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="mt-16 text-center"> {/* Increased margin-top */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="text-xl text-dark-800 mb-8"
          >
            Join our growing network of elite professionals
          </motion.p>

          <ProfileGrid profiles={profiles} />
        </div>
      </div>
    </section>
  );
};