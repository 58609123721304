import React from 'react';
import { formatDateForDisplay } from '../../../lib/utils/date';

interface TripDatesProps {
  startDate: string;
  endDate: string;
}

const TripDates: React.FC<TripDatesProps> = ({ startDate, endDate }) => {
  return (
    <div className="text-dark-800">
      {formatDateForDisplay(startDate)} - {formatDateForDisplay(endDate)}
    </div>
  );
};

export default TripDates;