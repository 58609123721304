import React, { useState, useEffect } from 'react';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useProfile } from '../../hooks/useProfile';
import { cn } from '@/lib/utils';
import { supabase } from '../../lib/supabase';
import { Edit2, Save, User, MapPin, Building2, Briefcase } from 'lucide-react';

interface FieldGroupProps {
  fields: Record<string, {
    label: string;
    icon: React.FC<{ className?: string }>;
    required?: boolean;
    placeholder: string;
  }>;
  formData: FormData;
  setFormData: (data: FormData) => void;
  validationErrors: Record<string, string>;
  isEditing: boolean;
}

const FieldGroup: React.FC<FieldGroupProps> = ({
  fields,
  formData,
  setFormData,
  validationErrors,
  isEditing
}) => (
  <div className="space-y-6">
    {Object.entries(fields).map(([key, field]) => (
      <div key={key}>
        <label className="flex items-center text-dark-800 mb-2">
          <field.icon className="h-4 w-4 mr-2" />
          {field.label}
          {field.required && <span className="text-red-400 ml-1">*</span>}
        </label>
        {isEditing ? (
          <Input
            value={formData[key as keyof FormData] || ''}
            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
            placeholder={field.placeholder}
            className={cn(
              'bg-dark-300 border-dark-400 text-white',
              validationErrors[key] && 'border-red-400'
            )}
          />
        ) : (
          <div className="px-3 py-2 bg-dark-300 border border-dark-400 rounded-md text-white">
            {formData[key as keyof FormData] || field.placeholder}
          </div>
        )}
        {validationErrors[key] && (
          <p className="text-red-400 text-sm mt-1">{validationErrors[key]}</p>
        )}
      </div>
    ))}
  </div>
);

const PERSONAL_FIELDS = {
  fullName: {
    label: 'Full Name',
    icon: User,
    required: true,
    placeholder: 'Enter your full name',
  },
  city: {
    label: 'City',
    icon: MapPin,
    required: true,
    placeholder: 'Enter your city',
  },
};

const PROFESSIONAL_FIELDS = {
  company: {
    label: 'Company',
    icon: Building2,
    required: true,
    placeholder: 'Enter your company name',
  },
  jobTitle: {
    label: 'Job Title',
    icon: Briefcase,
    required: true,
    placeholder: 'Enter your job title',
  },
};

interface FormData {
  fullName: string;
  city: string;
  company: string;
  jobTitle: string;
}

export const PersonalInfoCard: React.FC = () => {
  const { profile, error, fetchProfile } = useProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    city: '',
    company: '',
    jobTitle: '',
  });
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [saveError, setSaveError] = useState<string | null>(null);

  // Fetch profile on mount
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  // Update formData when profile changes
  useEffect(() => {
    if (profile) {
      setFormData({
        fullName: profile.full_name || '',
        city: profile.city || '',
        company: profile.company || profile.user_profiles?.[0]?.preferences?.company || '',
        jobTitle: profile.job_type || profile.user_profiles?.[0]?.preferences?.jobTitle || '',
      });
    }
  }, [profile]);

  const validateForm = () => {
    const errors: Record<string, string> = {};
    Object.entries({ ...PERSONAL_FIELDS, ...PROFESSIONAL_FIELDS }).forEach(([key, field]) => {
      if (field.required && !formData[key as keyof FormData]?.trim()) {
        errors[key] = `${field.label} is required`;
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    setIsSaving(true);
    setSaveError(null);
    try {
      const updates = {
        full_name: formData.fullName.trim(),
        city: formData.city.trim(),
        company: formData.company.trim(),
        job_type: formData.jobTitle.trim(),
      };

      console.log("Sending update payload to Supabase:", updates);

      const { error } = await supabase
        .from('users')
        .update(updates)
        .eq('id', profile?.id);

      if (error) {
        console.error("Supabase update error:", error.message);
        setSaveError(error.message);
      } else {
        console.log("Update successful.");
        await fetchProfile(); // Refresh profile data
        setIsEditing(false);
      }
    } catch (err) {
      console.error("Error saving profile:", err);
      setSaveError(err instanceof Error ? err.message : 'Unknown error occurred');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValidationErrors({});
    if (profile) {
      setFormData({
        fullName: profile.full_name || '',
        city: profile.city || '',
        company: profile.company || '',
        jobTitle: profile.job_type || '',
      });
    }
  };

  if (error) {
    return (
      <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="text-sm underline mt-2 hover:text-red-400">
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="bg-dark-200 rounded-xl p-6 border border-dark-300">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl font-semibold text-white">My Details</h2>
        {!isEditing ? (
          <Button onClick={() => setIsEditing(true)} className="bg-gold-400 hover:bg-gold-500 text-dark-50">
            <Edit2 className="h-4 w-4 mr-2" />
            Edit Profile
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <Button onClick={handleCancel} variant="outline" className="border-dark-400">
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaving} className="bg-gold-400 hover:bg-gold-500 text-dark-50">
              {isSaving ? 'Saving...' : <><Save className="h-4 w-4 mr-2" />Save Changes</>}
            </Button>
          </div>
        )}
      </div>
      {saveError && <p className="text-red-500 mb-4">Error: {saveError}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FieldGroup fields={PERSONAL_FIELDS} formData={formData} setFormData={setFormData} validationErrors={validationErrors} isEditing={isEditing} />
        <FieldGroup fields={PROFESSIONAL_FIELDS} formData={formData} setFormData={setFormData} validationErrors={validationErrors} isEditing={isEditing} />
      </div>
    </div>
  );
};