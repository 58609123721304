import React, { useState } from 'react';
import { Star } from 'lucide-react';
import { VenueApiResult } from '../../../../../types/venue';
import { VenueGallery } from '../gallery/VenueGallery';
import { ReviewsModal } from '../modals/ReviewsModal';
import { cn } from '@/lib/utils';

interface VenuePhotoProps {
  venue: VenueApiResult;
}

export const VenuePhoto: React.FC<VenuePhotoProps> = ({ venue }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const hasPhotos = venue.photos && venue.photos.length > 0;

  const handlePhotoClick = () => {
    setShowGallery(true);
  };

  const handleRatingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowReviews(true);
  };

  return (
    <>
      <div className="relative w-full md:w-44 aspect-square md:aspect-auto flex-shrink-0 overflow-hidden">
        {hasPhotos && (
          <div 
            onClick={handlePhotoClick}
            className="w-full h-full cursor-pointer group/photo"
            role="button"
            tabIndex={0}
            aria-label="Open photo gallery"
          >
            <img
              src={venue.photos[0].url}
              alt={venue.name}
              className={cn(
                "w-full h-full object-cover",
                "transition-transform duration-300 group-hover/photo:scale-105"
              )}
            />
            {/* Dark overlay */}
            <div className={cn(
              "absolute inset-0",
              "bg-gradient-to-t from-black/80 via-black/30 to-transparent",
              "opacity-60 group-hover/photo:opacity-70 transition-opacity"
            )} />
            
            {/* Rating badge - Bottom left */}
            {venue.rating > 0 && (
              <div
                onClick={handleRatingClick}
                className={cn(
                  "absolute bottom-2 left-2",
                  "flex items-center",
                  "bg-black/60 backdrop-blur-sm",
                  "px-2 py-1 rounded-lg cursor-pointer",
                  "hover:bg-black/80 transition-colors"
                )}
                role="button"
                tabIndex={0}
                aria-label="View reviews"
              >
                <Star className="h-4 w-4 text-gold-400 mr-1" />
                <span className="text-white/100 font-medium">
                  {venue.rating}
                </span>
                {venue.user_ratings_total && (
                  <span className="text-white/80 text-sm ml-1">
                    ({venue.user_ratings_total})
                  </span>
                )}
              </div>
            )}

            {/* Multiple photos indicator - Bottom right */}
            {venue.photos.length > 1 && (
              <div className={cn(
                "absolute bottom-2 right-2 px-2 py-1",
                "bg-black/60 backdrop-blur-sm",
                "text-white/100 text-xs rounded-lg"
              )}>
                +{venue.photos.length - 1} photos
              </div>
            )}
          </div>
        )}
      </div>

      {/* Photo Gallery Modal */}
      {hasPhotos && (
        <VenueGallery
          photos={venue.photos}
          isOpen={showGallery}
          onClose={() => setShowGallery(false)}
          venueName={venue.name}
        />
      )}

      {/* Reviews Modal */}
      <ReviewsModal
        venue={venue}
        isOpen={showReviews}
        onClose={() => setShowReviews(false)}
      />
    </>
  );
};