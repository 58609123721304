import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { BackgroundSlideshow } from './hero/BackgroundSlideshow';
import { HeroContent } from './hero/HeroContent';

interface HeroProps {
  onSignIn: () => void;
  onRequestAccess: () => void;
}

export default function Hero({ onSignIn, onRequestAccess }: HeroProps) {
  const [blurAmount, setBlurAmount] = useState(0);
  const [parallaxOffset, setParallaxOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const heroHeight = window.innerHeight;
      
      if (scrolled <= heroHeight) {
        setBlurAmount(Math.min(scrolled / 100, 8));
        setParallaxOffset(scrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="relative min-h-screen overflow-hidden">
      {/* Background */}
      <BackgroundSlideshow
        blurAmount={blurAmount}
        parallaxOffset={parallaxOffset}
      />

      {/* Content */}
      <div className="relative min-h-screen flex items-center justify-center">
        <HeroContent 
          onSignIn={onSignIn}
          onRequestAccess={onRequestAccess}
        />
      </div>
    </section>
  );
}