import { countries } from 'countries-list';

// Map cities to their country codes
const CITY_COUNTRY_MAP: Record<string, string> = {
  // Europe
  'Monte Carlo': 'MC',
  'Monaco': 'MC',
  'Nice': 'FR',
  'Paris': 'FR',
  'London': 'GB',
  'Rome': 'IT',
  'Roma': 'IT',
  'Barcelona': 'ES',
  'Madrid': 'ES',
  'Luxembourg': 'LU',
  'Valencia': 'ES',
  'Milan': 'IT',
  'Milano': 'IT',
  'Frankfurt': 'DE',
  'Munich': 'DE',
  'München': 'DE',
  'Vienna': 'AT',
  'Wien': 'AT',
  'Amsterdam': 'NL',
  'Brussels': 'BE',
  'Bruxelles': 'BE',
  'Geneva': 'CH',
  'Genève': 'CH',
  'Zurich': 'CH',
  'Zürich': 'CH',
  'Moscow': 'RU',
  'Moskva': 'RU',
  'Saint Petersburg': 'RU',
  'Sankt-Peterburg': 'RU',
  'Stockholm': 'SE',
  'Copenhagen': 'DK',
  'København': 'DK',
  'Oslo': 'NO',
  'Helsinki': 'FI',
  'Athens': 'GR',
  'Athina': 'GR',
  'Prague': 'CZ',
  'Praha': 'CZ',
  'Warsaw': 'PL',
  'Warszawa': 'PL',
  'Budapest': 'HU',
  'Lisbon': 'PT',
  'Lisboa': 'PT',
  'Dublin': 'IE',

  // Middle East
  'Tel Aviv': 'IL',
  'Tel Aviv-Yafo': 'IL',
  'Jerusalem': 'IL',
  'Dubai': 'AE',
  'Abu Dhabi': 'AE',
  'Doha': 'QA',
  'Riyadh': 'SA',
  'Ar-Riyad': 'SA',
  'Istanbul': 'TR',
  'Ankara': 'TR',
  'Beirut': 'LB',
  'Kuwait City': 'KW',
  'Manama': 'BH',
  'Muscat': 'OM',

  // Asia Pacific
  'Singapore': 'SG',
  'Hong Kong': 'HK',
  'Tokyo': 'JP',
  'Osaka': 'JP',
  'Seoul': 'KR',
  'Busan': 'KR',
  'Beijing': 'CN',
  'Shanghai': 'CN',
  'Shenzhen': 'CN',
  'Guangzhou': 'CN',
  'Taipei': 'TW',
  'Bangkok': 'TH',
  'Jakarta': 'ID',
  'Kuala Lumpur': 'MY',
  'Manila': 'PH',
  'Ho Chi Minh City': 'VN',
  'Hanoi': 'VN',
  'Mumbai': 'IN',
  'New Delhi': 'IN',
  'Bangalore': 'IN',
  'Sydney': 'AU',
  'Melbourne': 'AU',
  'Brisbane': 'AU',
  'Auckland': 'NZ',
  'Wellington': 'NZ',

  // Americas
  'Washington DC': 'US',
  'Washington D.C.': 'US',
  'Washington': 'US',
  'New York': 'US',
  'Miami': 'US',
  'Los Angeles': 'US',
  'San Francisco': 'US',
  'Chicago': 'US',
  'Boston': 'US',
  'Houston': 'US',
  'Toronto': 'CA',
  'Vancouver': 'CA',
  'Montreal': 'CA',
  'São Paulo': 'BR',
  'Rio de Janeiro': 'BR',
  'Brasília': 'BR',
  'Mexico City': 'MX',
  'Ciudad de México': 'MX',
  'Buenos Aires': 'AR',
  'Santiago': 'CL',
  'Lima': 'PE',
  'Bogotá': 'CO',
  'Caracas': 'VE',

  // Africa
  'Cairo': 'EG',
  'Al-Qahirah': 'EG',
  'Lagos': 'NG',
  'Johannesburg': 'ZA',
  'Cape Town': 'ZA',
  'Nairobi': 'KE',
  'Casablanca': 'MA',
  'Dar es Salaam': 'TZ',
  'Accra': 'GH',
  'Addis Ababa': 'ET'
};

export function getCountryCode(city: string): string {
  if (!city) return '';
  
  // Normalize city name by removing case sensitivity and extra spaces
  const normalizedCity = city.toLowerCase().trim();
  
  // Try direct lookup first
  const directMatch = CITY_COUNTRY_MAP[city];
  if (directMatch) return directMatch;

  // Try case-insensitive lookup
  const caseInsensitiveMatch = Object.entries(CITY_COUNTRY_MAP).find(
    ([key]) => key.toLowerCase() === normalizedCity
  );
  if (caseInsensitiveMatch) return caseInsensitiveMatch[1];

  // Try partial matches (for cities with variations)
  const partialMatch = Object.entries(CITY_COUNTRY_MAP).find(
    ([key]) => normalizedCity.includes(key.toLowerCase()) || 
               key.toLowerCase().includes(normalizedCity)
  );
  if (partialMatch) return partialMatch[1];

  // Try matching by first part of city name (before comma)
  const cityFirstPart = normalizedCity.split(',')[0].trim();
  const firstPartMatch = Object.entries(CITY_COUNTRY_MAP).find(
    ([key]) => key.toLowerCase() === cityFirstPart ||
               cityFirstPart.includes(key.toLowerCase()) ||
               key.toLowerCase().includes(cityFirstPart)
  );
  if (firstPartMatch) return firstPartMatch[1];

  console.warn(`No country code found for city: ${city}`);
  return '';
}

function getCountryName(countryCode: string): string {
  return countries[countryCode as keyof typeof countries]?.name || countryCode;
}