// src/components/matchmaking/steps/DateSelectionStep.tsx
import React, { useState } from 'react';
import { Calendar } from 'lucide-react';
import { useTheme } from '../../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import { formatDateForInput } from '@/lib/utils/date';
import { motion } from 'framer-motion';

interface DateSelectionStepProps {
  selectedDate: string;
  onDateSelect: (date: string) => void;
}

const DateSelectionStep: React.FC<DateSelectionStepProps> = ({
  selectedDate,
  onDateSelect
}) => {
  const { isDarkMode } = useTheme();
  const [currentDate, setCurrentDate] = useState(new Date());
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  // Get today's date without time component
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const minDate = formatDateForInput(today);
  const maxDate = formatDateForInput(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000));

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDateSelect(e.target.value);
  };

  const isSameDay = (date1: Date, date2: string | null): boolean => {
    if (!date2) return false;
    const d2 = new Date(date2);
    return (
      date1.getFullYear() === d2.getFullYear() &&
      date1.getMonth() === d2.getMonth() &&
      date1.getDate() === d2.getDate()
    );
  };

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
    
    // Add empty slots for days before the first day of the month
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }
    
    // Add all days in the month
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }
    
    return days;
  };

  const handleMonthChange = (increment: number) => {
    setCurrentDate(prev => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() + increment);
      return newDate;
    });
  };

  const isPastDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date < today;
  };

  const isDateSelectable = (date: Date) => !isPastDate(date);

  const formatDisplayDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric', 
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      <div className="text-center space-y-2">
        <h3 className={cn(
          "text-xl font-semibold",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          When would you like to play?
        </h3>
        <p className={isDarkMode ? "text-dark-800" : "text-gray-500"}>
          Select your preferred date
        </p>
      </div>

      <div className={cn(
        "p-6 rounded-xl",
        isDarkMode ? "bg-dark-300" : "bg-gray-100"
      )}>
        {/* Month Navigation */}
        <div className="flex items-center justify-between mb-6">
          <button
            onClick={() => handleMonthChange(-1)}
            className={cn(
              "p-2 rounded-lg transition-colors",
              isDarkMode 
                ? "hover:bg-dark-400 text-dark-800 hover:text-white"
                : "hover:bg-gray-200 text-gray-500 hover:text-gray-700"
            )}
          >
            <Calendar className="h-5 w-5 rotate-180" />
          </button>
          
          <h4 className={cn(
            "text-lg font-medium",
            isDarkMode ? "text-white" : "text-gray-900"
          )}>
            {currentDate.toLocaleString('default', { 
              month: 'long',
              year: 'numeric'
            })}
          </h4>
          
          <button
            onClick={() => handleMonthChange(1)}
            className={cn(
              "p-2 rounded-lg transition-colors",
              isDarkMode 
                ? "hover:bg-dark-400 text-dark-800 hover:text-white"
                : "hover:bg-gray-200 text-gray-500 hover:text-gray-700"
            )}
          >
            <Calendar className="h-5 w-5" />
          </button>
        </div>

        {/* Calendar Grid */}
        <div className="space-y-4">
          {/* Weekday Headers */}
          <div className="grid grid-cols-7 mb-2">
            {weekDays.map((day, index) => (
              <div
                key={index}
                className={cn(
                  "text-center text-sm font-medium py-2",
                  isDarkMode ? "text-dark-800" : "text-gray-500"
                )}
              >
                {day}
              </div>
            ))}
          </div>

          {/* Calendar Days */}
          <div className="grid grid-cols-7 gap-2">
            {getDaysInMonth(currentDate).map((date, index) => (
              <div key={index} className="aspect-square">
                {date && (
                  <motion.button
                    whileHover={{ scale: isDateSelectable(date) ? 1.1 : 1 }}
                    whileTap={{ scale: isDateSelectable(date) ? 0.95 : 1 }}
                    onClick={() => {
                      if (isDateSelectable(date)) {
                        onDateSelect(formatDateForInput(date));
                      }
                    }}
                    disabled={!isDateSelectable(date)}
                    className={cn(
                      "w-full h-full flex items-center justify-center rounded-lg text-sm transition-colors",
                      isSameDay(date, selectedDate)
                        ? "bg-gold-400 text-dark-50"
                        : isPastDate(date)
                        ? isDarkMode
                          ? "text-dark-800 cursor-not-allowed"
                          : "text-gray-400 cursor-not-allowed"
                        : isDarkMode
                        ? "text-white hover:bg-dark-400"
                        : "text-gray-700 hover:bg-gray-200"
                    )}
                  >
                    {date.getDate()}
                  </motion.button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Selected Date Display */}
        {selectedDate && (
          <div className={cn(
            "mt-6 pt-4 border-t text-center",
            isDarkMode ? "border-dark-400" : "border-gray-200"
          )}>
            <div className="flex items-center justify-center space-x-2">
              <Calendar className={cn(
                "h-4 w-4",
                isDarkMode ? "text-gold-400" : "text-gold-600"
              )} />
              <span className={cn(
                isDarkMode ? "text-white" : "text-gray-900",
                "font-medium"
              )}>
                {formatDisplayDate(selectedDate)}
              </span>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default DateSelectionStep;