import React, { useState, useEffect } from 'react';
import { Linkedin, Facebook, Instagram, Globe, Save, Edit2 } from 'lucide-react';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { supabase } from '../../../lib/supabase';

function cn(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}


export const SocialLinksCard: React.FC = () => {
  const [socialLinks, setSocialLinks] = useState<{
    linkedinUrl: string;
    facebookUrl: string;
    instagramUrl: string;
  }>({
    linkedinUrl: '',
    facebookUrl: '',
    instagramUrl: '',
  });
  const [profile, setProfile] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);

  // Fetch profile data on load
  const fetchProfile = async () => {
  try {
    console.log('Fetching profile data...');
    const { data: authData, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;

    const userId = authData.user?.id;
    console.log('Authenticated User ID:', userId);

    const { data: profileData, error } = await supabase
      .from('user_profiles')
      .select('preferences')
      .eq('user_id', '45ee082b-552a-42dc-997e-fba9d34f3e36') // Use the correct user_id
      .single();

    if (error) throw error;

    console.log('Fetched Profile Data:', profileData);

    setProfile(profileData);
    setSocialLinks({
      linkedinUrl: profileData.preferences?.linkedinUrl || '',
      facebookUrl: profileData.preferences?.facebookUrl || '',
      instagramUrl: profileData.preferences?.instagramUrl || '',
    });
  } catch (error) {
    console.error('Error fetching profile:', error);
  }
};


  useEffect(() => {
    fetchProfile();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    setSaveError(null);

    try {
      console.log('Fetching authenticated user...');
      const { data: authData, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;

      console.log('Authenticated User:', authData.user);
      if (!authData.user) throw new Error('Not authenticated');

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', authData.user.id)
        .single();

      if (userError) throw userError;
      if (!userData) throw new Error('User not found');

      console.log('User Data:', userData);

      // Call RPC function to update social links
      const { error: updateError } = await supabase.rpc('update_social_links', {
        user_id_param: userData.id,
        linkedin_url: socialLinks.linkedinUrl.trim() || null,
        facebook_url: socialLinks.facebookUrl.trim() || null,
        instagram_url: socialLinks.instagramUrl.trim() || null,
      });

      if (updateError) throw updateError;

      console.log('Social links updated successfully.');

      // Refetch the profile data after save
      await fetchProfile();

      setIsEditing(false);
    } catch (error) {
      console.error('Save Error:', error);
      setSaveError(error instanceof Error ? error.message : 'Failed to save social links');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-xl font-semibold text-white">Social Links</h2>
          <p className="text-dark-800 mt-1">Connect your social media accounts</p>
        </div>
        {!isEditing ? (
          <Button
            onClick={() => setIsEditing(true)}
            className="bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            <Edit2 className="h-4 w-4 mr-2" />
            Edit Links
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <Button
              onClick={() => setIsEditing(false)}
              variant="outline"
              className="border-dark-400"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={isSaving}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              {isSaving ? 'Saving...' : (
                <>
                  <Save className="h-4 w-4 mr-2" />
                  Save Links
                </>
              )}
            </Button>
          </div>
        )}
      </div>

      {saveError && (
        <div className="mb-4 p-3 bg-red-500/10 text-red-500 text-sm rounded-lg border border-red-500/20">
          {saveError}
        </div>
      )}

      <div className="space-y-4">
        <div>
          <Label className="flex items-center text-dark-800 mb-2">
            <Linkedin className="h-4 w-4 mr-2" />
            LinkedIn Profile
          </Label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={socialLinks.linkedinUrl}
              placeholder={isEditing ? "https://linkedin.com/in/username" : "Not set"}
              onChange={(e) => setSocialLinks((prev) => ({ ...prev, linkedinUrl: e.target.value }))}
              className={cn(
                "pl-10 bg-dark-300 border-dark-400",
                !isEditing && !socialLinks.linkedinUrl.trim() ? "text-dark-800" : "text-white"
              )}
              disabled={!isEditing}
            />
          </div>
        </div>

        <div>
          <Label className="flex items-center text-dark-800 mb-2">
            <Facebook className="h-4 w-4 mr-2" />
            Facebook Profile
          </Label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={socialLinks.facebookUrl}
              placeholder={isEditing ? "https://facebook.com/username" : "Not set"}
              onChange={(e) => setSocialLinks((prev) => ({ ...prev, facebookUrl: e.target.value }))}
              className={cn(
                "pl-10 bg-dark-300 border-dark-400",
                !isEditing && !socialLinks.facebookUrl.trim() ? "text-dark-800" : "text-white"
              )}
              disabled={!isEditing}
            />
          </div>
        </div>

        <div>
          <Label className="flex items-center text-dark-800 mb-2">
            <Instagram className="h-4 w-4 mr-2" />
            Instagram Profile
          </Label>
          <div className="relative">
            <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={socialLinks.instagramUrl}
              placeholder={isEditing ? "https://instagram.com/username" : "Not set"}
              onChange={(e) => setSocialLinks((prev) => ({ ...prev, instagramUrl: e.target.value }))}
              className={cn(
                "pl-10 bg-dark-300 border-dark-400",
                !isEditing && !socialLinks.instagramUrl.trim() ? "text-dark-800" : "text-white"
              )}
              disabled={!isEditing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
