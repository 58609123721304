import React from 'react';
import { AlertCircle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../ui/dialog';
import { Button } from '../../../ui/button';

interface UnblockConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  memberName: string;
}

const UnblockConfirmModal: React.FC<UnblockConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  memberName
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <div className="mx-auto w-12 h-12 rounded-full bg-dark-300 flex items-center justify-center mb-4">
            <AlertCircle className="h-6 w-6 text-gold-400" />
          </div>
          <DialogTitle>Unblock Member</DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <p className="text-center text-dark-800">
            Are you sure you want to unblock {memberName}? They will be able to:
          </p>
          <ul className="mt-4 space-y-2 text-dark-800">
            <li className="flex items-center">
              <span className="w-1.5 h-1.5 bg-gold-400 rounded-full mr-2" />
              Send you connection requests
            </li>
            <li className="flex items-center">
              <span className="w-1.5 h-1.5 bg-gold-400 rounded-full mr-2" />
              Message you
            </li>
            <li className="flex items-center">
              <span className="w-1.5 h-1.5 bg-gold-400 rounded-full mr-2" />
              See your activity and posts
            </li>
          </ul>
        </div>

        <DialogFooter className="sm:justify-center">
          <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              className="sm:order-1 border-dark-400"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={onConfirm}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50 sm:order-2"
            >
              Unblock Member
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UnblockConfirmModal;