import React from 'react';
import { cn } from '@/lib/utils';

interface ComboboxItemProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  selected?: boolean;
}

export const ComboboxItem: React.FC<ComboboxItemProps> = ({
  children,
  className,
  onClick,
  selected
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        "w-full flex items-start px-3 py-2 text-left",
        "hover:bg-dark-300 transition-colors",
        selected && "bg-dark-300",
        className
      )}
    >
      {children}
    </button>
  );
};