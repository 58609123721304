import { useState, useMemo, useEffect } from 'react';
import { Activity } from '../types/activity';

import { CACHE_DURATION, activitiesCache } from '../lib/cache/activities';

interface UseActivitiesProps {
  activities: Activity[];
  filters: {
    searchTerm: string;
    selectedSport: string;
    selectedCity: string;
    selectedStatus: string;
    sortOrder: 'recent' | 'oldest';
  };
}

export const useActivities = (activities: Activity[], filters: UseActivitiesProps['filters']) => {
  const [selectedMatch, setSelectedMatch] = useState<Activity | null>(null);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  // Check cache on mount
  useEffect(() => {
    const cached = activitiesCache.get('activities');
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      console.log('Using cached activities');
      setSelectedMatch(null);
      setShowManageModal(false);
      setShowDeclineModal(false);
    }
  }, []);

  // Update cache when activities change
  useEffect(() => {
    if (activities.length > 0) {
      activitiesCache.set('activities', {
        data: activities,
        timestamp: Date.now()
      });
    }
  }, [activities]);

  const filteredActivities = useMemo(() => {
    return activities.filter(activity => {
      const searchTermLower = filters.searchTerm.toLowerCase();
      const matchesSearch = !filters.searchTerm || 
        (activity.opponent.name && activity.opponent.name.toLowerCase().includes(searchTermLower)) ||
        (activity.venue.name && activity.venue.name.toLowerCase().includes(searchTermLower)) ||
        (activity.venue.city && activity.venue.city.toLowerCase().includes(searchTermLower));
      
      const matchesSport = filters.selectedSport === 'all' || activity.sport === filters.selectedSport;
      const matchesCity = filters.selectedCity === 'all' || activity.venue.city === filters.selectedCity;
      const matchesStatus = filters.selectedStatus === 'all' || activity.status === filters.selectedStatus;
      
      return matchesSearch && matchesSport && matchesCity && matchesStatus;
    }).sort((a, b) => {
      const dateA = new Date(`${a.date} ${a.time}`).getTime();
      const dateB = new Date(`${b.date} ${b.time}`).getTime();
      return filters.sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
    });
  }, [activities, filters]);

  const handleViewDetails = (match: Activity) => {
    setSelectedMatch(match);
  };

  const handleManageSession = (match: Activity) => {
    setSelectedMatch(match);
    setShowManageModal(true);
  };

  const handleRematch = (match: Activity) => {
    // Rematch logic here
  };

  const handleDeclineRequest = (match: Activity) => {
    setSelectedMatch(match);
    setShowDeclineModal(true);
  };

  return {
    filteredActivities,
    selectedMatch,
    showManageModal,
    showDeclineModal,
    setSelectedMatch,
    setShowManageModal,
    setShowDeclineModal,
    handleViewDetails,
    handleManageSession,
    handleRematch,
    handleDeclineRequest
  };
};