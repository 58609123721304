import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';
import PostHeader from './post/PostHeader';
import PostContent from './post/PostContent';
import PostActions from './post/PostActions';
import PostComments from './post/PostComments';
import PostMenu from './post/PostMenu';
import SharePopover from './SharePopover';
import { Post } from '../../types/post';
import { usePostInteractions } from '../../hooks/social/usePostInteractions';
import { formatPostTime } from '../../lib/utils/time';
import { storage } from '../../lib/supabase/storage';
import { supabase } from '../../lib/supabase';
import { cn } from '../../lib/utils';

const ProgressiveImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageRef.current) return;

    // Use Intersection Observer for lazy loading
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && imageRef.current) {
            imageRef.current.src = src;
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '50px' }
    );

    observer.observe(imageRef.current);

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [src]);

  return (
    <div className="relative aspect-video w-full overflow-hidden bg-dark-300">
      {!isLoaded && !isError && (
        <div className="absolute inset-0 animate-pulse bg-dark-300" />
      )}
      <img
        ref={imageRef}
        data-src={src} // Use data-src for lazy loading
        alt={alt}
        className={cn(
          "w-full h-full object-cover transition-opacity duration-300",
          isLoaded ? "opacity-100" : "opacity-0"
        )}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
      />
    </div>
  );
};

const PostCard: React.FC<Post> = (post) => {
  const navigate = useNavigate();
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState<number>(post.comments || 0);
  const commentCountRef = useRef(post.comments || 0);
  const isSubscribed = useRef(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState<string>(post.content);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [formattedContent, setFormattedContent] = useState<React.ReactNode>(post.content);
  const [formattedTime, setFormattedTime] = useState(formatPostTime(post.timeAgo));

  // Format content to make @mentions clickable
  useEffect(() => {
    const formatContent = async () => {
      try {
        // Get all users to match mentions against
        const { data: users } = await supabase
          .from('users')
          .select('auth_id, full_name');

        if (!users) return;

        // Split content by @mentions
        const parts = post.content.split(/(@\S+)/g);
        
        const formattedParts = parts.map((part, index) => {
          if (part.startsWith('@')) {
            const name = part.slice(1); // Remove @ symbol
            const user = users.find(u => u.full_name === name);
            
            if (user) {
              return (
                <Link
                  key={index}
                  to={`/dashboard/members/${user.auth_id}`}
                  className="text-gold-400 hover:text-gold-500 transition-colors"
                >
                  {part}
                </Link>
              );
            }
          }
          return part;
        });

        setFormattedContent(formattedParts);
      } catch (error) {
        console.error('Error formatting mentions:', error);
        setFormattedContent(post.content);
      }
    };

    formatContent();
  }, [post.content]);
  const {
    isLiked,
    likesCount,
    sharesCount,
    isLoading,
    error: interactionError,
    handleLike,
    handleShare
  } = usePostInteractions({
    postId: post.id,
    initialLikes: post.likes,
    initialShares: post.shares
  });

  // Check post ownership
  useEffect(() => {
    const checkOwnership = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsOwner(user?.id === post.author.id);
    };
    checkOwnership();
  }, [post.author.id]);

  // Update formatted time periodically
  useEffect(() => {
    const timer = setInterval(() => {
      setFormattedTime(formatPostTime(post.timeAgo));
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [post.timeAgo]);

  useEffect(() => {
    // Update ref when prop changes
    commentCountRef.current = post.comments || 0;
    setCommentCount(commentCountRef.current);

    // Subscribe to new comments
    if (isSubscribed.current) return;
    isSubscribed.current = true;

    const channel = supabase
      .channel(`comments:${post.id}`)
      .on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'comments', filter: `post_id=eq.${post.id}` },
        (payload) => {
          setCommentCount(prev => prev + 1);
          commentCountRef.current += 1;
        }
      )
      .on(
        'postgres_changes',
        { event: 'DELETE', schema: 'public', table: 'comments', filter: `post_id=eq.${post.id}` },
        (payload) => {
          setCommentCount(prev => Math.max(0, prev - 1));
          commentCountRef.current = Math.max(0, commentCountRef.current - 1);
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
      isSubscribed.current = false;
    };
  }, [post.id, post.comments]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    if (editedContent === post.content) {
      setIsEditing(false);
      return;
    }

    setIsUpdating(true);
    try {
      const { error } = await supabase
        .from('posts')
        .update({ content: editedContent })
        .eq('id', post.id);

      if (error) throw error;
      post.content = editedContent;
    } catch (error) {
      console.error('Failed to update post:', error);
    } finally {
      setIsUpdating(false);
      setIsEditing(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      // Delete post image if exists
      if (post.media) {
        const userId = (await supabase.auth.getUser()).data.user?.id;
        if (!userId) throw new Error('Not authenticated');
        
        const imagePath = post.media.split('/').pop();
        if (imagePath) {
          await storage.deleteFile('social-feed', `${userId}/${imagePath}`);
        }
      }

      // Delete post
      const { error } = await supabase
        .from('posts')
        .delete()
        .eq('id', post.id);

      if (error) throw error;
      
      setShowDeleteDialog(false);
      // Post will be removed from feed through realtime subscription
    } catch (error) {
      console.error('Failed to delete post:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAuthorClick = () => {
    navigate(`/dashboard/members/${post.author.id}`);
  };

  return (
    <motion.div 
      layout
      className="bg-dark-200 rounded-2xl border border-dark-300 overflow-hidden hover:border-gold-400 transition-all duration-300 w-full"
    >
      <div className="p-6">
        <div className="flex items-start justify-between">
          <PostHeader 
            author={post.author}
            timeAgo={formattedTime}
            achievement={post.achievement}
            onAuthorClick={handleAuthorClick}
          />
          <PostMenu
            isOwner={isOwner}
            onEdit={handleEdit}
            onDelete={() => setShowDeleteDialog(true)}
          />
        </div>

        {isEditing ? (
          <div className="mt-4">
            <textarea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl p-4 text-white resize-none"
            />
            <div className="flex justify-end space-x-2 mt-2">
              <Button
                variant="outline"
                onClick={() => setIsEditing(false)}
                className="border-dark-400"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveEdit}
                disabled={isUpdating}
                className="bg-gold-400 hover:bg-gold-500 text-dark-50"
              >
                {isUpdating ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        ) : (
          <PostContent 
            content={post.content}
            formattedContent={formattedContent}
            media={post.media}
            endorsements={post.endorsements}
          />
        )}

        <PostActions
          isLiked={isLiked}
          likesCount={likesCount}
          commentsCount={commentCount}
          sharesCount={sharesCount}
          showComments={showComments}
          onLike={handleLike}
          onComment={() => setShowComments(!showComments)}
          shareButton={
            <SharePopover
              post={post}
              shareCount={sharesCount}
              onShare={handleShare}
            />
          }
        />
      </div>

      {showComments && (
        <PostComments postId={post.id} />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent className="bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>Delete Post</DialogTitle>
          </DialogHeader>
          <p className="text-dark-800">
            Are you sure you want to delete this post? This action cannot be undone.
          </p>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowDeleteDialog(false)}
              className="border-dark-400"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              disabled={isDeleting}
              className="bg-red-500 hover:bg-red-600"
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </motion.div>
  );
};

export default PostCard;