/**
 * Normalizes text by converting to lowercase, removing diacritics,
 * and special characters for consistent comparison
 */
export function normalizeText(text: string): string {
  return text.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^a-z0-9\s]/g, "") // Remove special characters
    .trim();
}

/**
 * Sanitizes user input by removing potentially harmful characters
 */
function sanitizeInput(input: string): string {
  return input.trim()
    .replace(/[<>]/g, '') // Remove potential HTML/XML injection
    .replace(/[;'"]/g, ''); // Remove potential SQL injection
}

/**
 * Formats text for display by capitalizing first letter of each word
 */
function formatDisplayText(text: string): string {
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}