import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

interface CommentsSkeletonProps {
  count?: number;
  className?: string;
}

const CommentsSkeleton: React.FC<CommentsSkeletonProps> = ({
  count = 3,
  className
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={cn("space-y-4", className)}
    >
      {Array.from({ length: count }).map((_, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="flex space-x-3 animate-pulse"
        >
          {/* Avatar */}
          <div className="w-8 h-8 rounded-full bg-dark-300 flex-shrink-0" />
          
          <div className="flex-1 space-y-2">
            {/* Comment Bubble */}
            <div className="bg-dark-300 rounded-2xl p-4 space-y-2">
              {/* Author Name */}
              <div className="h-4 w-24 bg-dark-400 rounded" />
              
              {/* Comment Text - Multiple lines */}
              <div className="space-y-1">
                <div className="h-3 w-full bg-dark-400 rounded" />
                <div className="h-3 w-3/4 bg-dark-400 rounded" />
              </div>
            </div>
            
            {/* Actions */}
            <div className="flex items-center space-x-4 px-2">
              <div className="h-3 w-8 bg-dark-300 rounded" />
              <div className="h-3 w-8 bg-dark-300 rounded" />
              <div className="h-3 w-16 bg-dark-300 rounded" />
            </div>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default CommentsSkeleton;