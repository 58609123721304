import { City } from '../../../types/city';
import { PhotonService } from '../photon';
import { NominatimService } from './nominatim';
import { GeocodingCache } from './cache';

export class GeocodingService {
  static async searchCities(query: string): Promise<City[]> {
    if (!query?.trim()) {
      return [];
    }

    // Check cache first
    const cachedResults = GeocodingCache.get(query);
    if (cachedResults) {
      return cachedResults;
    }

    try {
      // Try Photon first
      let results = await PhotonService.searchCities(query);
      
      // Fallback to Nominatim if no results
      if (!results.length) {
        results = await NominatimService.searchCities(query);
      }

      // Cache results
      GeocodingCache.set(query, results);

      return results;
    } catch (error) {
      console.error('GeocodingService error:', error);
      return [];
    }
  }
}