const primarySports = [
  { id: 'padel', emoji: '🎱', name: 'Padel' },
  { id: 'running', emoji: '🏃‍♂️', name: 'Running' },
  { id: 'football', emoji: '⚽', name: '5-a-side Football' },
  { id: 'pilates', emoji: '🏋️‍♀️', name: 'Pilates' },
  { id: 'yoga', emoji: '🧘‍♀️', name: 'Yoga' },
  { id: 'tennis', emoji: '🎾', name: 'Tennis' }
];

const additionalSports = [
  { id: 'golf', emoji: '⛳', name: 'Golf' },
  { id: 'swimming', emoji: '🏊‍♂️', name: 'Swimming' },
  { id: 'cycling', emoji: '🚴‍♀️', name: 'Cycling' },
  { id: 'basketball', emoji: '🏀', name: 'Basketball' },
  { id: 'badminton', emoji: '🏸', name: 'Badminton' },
  { id: 'table-tennis', emoji: '🏓', name: 'Table Tennis' }
];

export const timePeriods = [
  { value: 'morning', label: 'Morning', time: '6 AM - 11 AM' },
  { value: 'noon', label: 'Noon', time: '11 AM - 2 PM' },
  { value: 'afternoon', label: 'Afternoon', time: '2 PM - 5 PM' },
  { value: 'evening', label: 'Evening', time: '5 PM - 8 PM' },
  { value: 'night', label: 'Night', time: '8 PM - 11 PM' },
  { value: 'any', label: 'Any time', time: 'All day' }
];