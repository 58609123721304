import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import ConnectionsList from './ConnectionsList';
import PendingConnectionsList from './PendingConnectionsList';
import ConnectionFilters from './ConnectionFilters';
import MutualConnectionsModal from '../../modals/MutualConnectionsModal';
import ConnectionSkeleton from './ConnectionSkeleton';

interface ConnectionsTabProps {
  connections: any[];
  pendingRequests: any[];
  memberId?: string;
  isLoading: boolean;
  error: string | null;
  onAccept: (connectionId: string) => Promise<void>;
  onDecline: (connectionId: string) => Promise<void>;
}

const ConnectionsTab: React.FC<ConnectionsTabProps> = ({
  connections,
  pendingRequests,
  memberId,
  isLoading,
  error,
  onAccept,
  onDecline
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'name' | 'distance' | 'mutual'>('name');
  const [selectedMutuals, setSelectedMutuals] = useState<{ id: string; name: string; count: number } | null>(null);
  const [activeView, setActiveView] = useState<'connections' | 'pending'>('connections');
  const isOwnProfile = !memberId || memberId === user?.id;

  const filteredConnections = useMemo(() => {
    if (!Array.isArray(connections) || (!isOwnProfile && !Array.isArray(pendingRequests))) {
      console.error('Invalid connections or pendingRequests:', { connections, pendingRequests });
      return []; 
    }

    // Only transform accepted connections
    const transformedConnections = connections
      .filter(conn => conn.status === 'accepted')
      .map(conn => {
      // Safely access otherUser data with fallbacks
      const otherUser = conn.otherUser || {};
      const preferences = otherUser.preferences || {};
      
      return {
        id: conn.id,
        status: conn.status,
        created_at: conn.created_at,
        isInitiator: conn.isInitiator,
        otherUser: {
          id: otherUser.auth_id || 'unknown',
          auth_id: otherUser.auth_id || 'unknown',
          full_name: (otherUser.full_name || 'Unknown User').trim(),
          city: otherUser.city,
          skillLevel: otherUser.skill_level || 'beginner',
          sports: otherUser.user_sports ? otherUser.user_sports.map(us => 
            `${us.sports.emoji} ${us.sports.name}`
          ) : [],
          preferences: preferences
        },
        initiator: {
          id: conn.initiator?.id || 'unknown',
          auth_id: conn.initiator?.auth_id || 'unknown',
          full_name: conn.initiator?.full_name || 'Unknown User',
          city: conn.initiator?.city || 'Unknown Location'
        },
        receiver: {
          id: conn.receiver?.id || 'unknown',
          auth_id: conn.receiver?.auth_id || 'unknown',
          full_name: conn.receiver?.full_name || 'Unknown User',
          city: conn.receiver?.city || 'Unknown Location'
        }
      };
    });

    // Only include pending requests for own profile
    const transformedRequests = isOwnProfile ? pendingRequests.map(req => {
      const otherUser = req.otherUser || {};
      const preferences = otherUser.preferences || {};
      
      return {
        id: req.id,
        status: req.status,
        created_at: req.created_at,
        isInitiator: req.isInitiator,
        otherUser: {
          id: otherUser.auth_id || 'unknown',
          auth_id: otherUser.auth_id || 'unknown',
          full_name: (otherUser.full_name || 'Unknown User').trim(),
          city: otherUser.city,
          skillLevel: otherUser.skill_level || 'beginner',
          sports: otherUser.user_sports ? otherUser.user_sports.map(us => 
            `${us.sports.emoji} ${us.sports.name}`
          ) : [],
          preferences: preferences
        },
        initiator: {
          id: req.initiator?.id || 'unknown',
          auth_id: req.initiator?.auth_id || 'unknown',
          full_name: req.initiator?.full_name || 'Unknown User',
          city: req.initiator?.city || 'Unknown Location'
        },
        receiver: {
          id: req.receiver?.id || 'unknown',
          auth_id: req.receiver?.auth_id || 'unknown',
          full_name: req.receiver?.full_name || 'Unknown User',
          city: req.receiver?.city || 'Unknown Location'
        }
      };
    }) : [];

    const allConnections = [...transformedConnections, ...(isOwnProfile ? transformedRequests : [])]
      .filter(connection => {
        if (!searchTerm) return true;
        
        const otherUserName = connection.otherUser?.full_name || '';
        const otherUserCity = connection.otherUser?.city || '';
        const sports = connection.otherUser?.sports || [];
        
        return (
          otherUserName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          otherUserCity.toLowerCase().includes(searchTerm.toLowerCase()) ||
          sports.some(sport => 
            sport.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'distance':
            return (a.otherUser?.distance || 0) - (b.otherUser?.distance || 0);
          case 'mutual':
            return (b.otherUser?.mutualFriends || 0) - (a.otherUser?.mutualFriends || 0);
          default:
            const nameA = a.otherUser?.full_name || '';
            const nameB = b.otherUser?.full_name || '';
            return nameA.localeCompare(nameB);
        }
      });

    // Debug log the transformed data
    if (process.env.NODE_ENV === 'development') {
      console.log('Transformed connections data:', {
        total: allConnections.length,
        accepted: transformedConnections.length,
        pending: isOwnProfile ? transformedRequests.length : 0,
        byStatus: {
          pending: allConnections.filter(c => c.status === 'pending').length,
          accepted: allConnections.filter(c => c.status === 'accepted').length
        },
        byInitiator: {
          initiated: allConnections.filter(c => c.isInitiator).length,
          received: allConnections.filter(c => !c.isInitiator).length
        }
      });
    }
    return allConnections;
  }, [connections, pendingRequests, searchTerm, sortBy, isOwnProfile, user?.id]);

  const handleMessageClick = (connectionId: string, connectionName: string) => {
    navigate('/dashboard/messages', { 
      state: { 
        activeChat: connectionId,
        initialMessage: `Hi ${connectionName}!`,
        showMobileList: false
      }
    });
  };

  return (
    <div className="space-y-6">
      {isOwnProfile && (
        <ConnectionFilters
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          sortBy={sortBy}
          onSortChange={setSortBy}
          establishedConnections={Array.isArray(connections) ? connections.filter(c => c.status === 'accepted').length : 0}
          pendingCount={Array.isArray(pendingRequests) ? pendingRequests.length : 0}
        />
      )}

      {error && (
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="text-sm underline mt-2 hover:text-red-400"
          >
            Try Again
          </button>
        </div>
      )}

      {isLoading && <ConnectionSkeleton count={5} />}

      <ConnectionsList
        connections={filteredConnections}
        isLoading={isLoading}
        onShowMutuals={setSelectedMutuals}
        onMessageClick={handleMessageClick}
        onAccept={onAccept}
        onDecline={onDecline}
      />

      {selectedMutuals && (
        <MutualConnectionsModal
          isOpen={true}
          onClose={() => setSelectedMutuals(null)}
          connectionName={selectedMutuals.name}
          mutualCount={selectedMutuals.count}
        />
      )}
    </div>
  );
};

export default ConnectionsTab;