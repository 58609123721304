import { VenueApiResult } from '../../types/venue';

// Default location (Valencia)
const DEFAULT_LOCATION = {
  latitude: 39.4699,
  longitude: -0.3763
};

export async function getUserLocation(): Promise<{ latitude: number; longitude: number }> {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      resolve(DEFAULT_LOCATION);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      () => {
        // Fallback to default location on error
        resolve(DEFAULT_LOCATION);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 300000 // 5 minutes
      }
    );
  });
}

function getVenueCoordinates(venue: VenueApiResult): { lat: number; lng: number } | null {
  if (!venue.coordinates?.lat || !venue.coordinates?.lng) {
    return null;
  }

  const { lat, lng } = venue.coordinates;

  // Validate coordinates
  if (isNaN(lat) || isNaN(lng) || 
      Math.abs(lat) > 90 || Math.abs(lng) > 180) {
    return null;
  }

  return { lat, lng };
}