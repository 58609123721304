import React from 'react';
import { motion } from 'framer-motion';
import { City } from '../../types/city';
import CityCard from './CityCard';

interface CityCardListProps {
  cities: City[];
  onCityClick: (city: string) => void;
}

const CityCardList: React.FC<CityCardListProps> = ({ cities, onCityClick }) => {
  return (
    <div className="flex flex-col space-y-6">
      {cities.map((city, index) => (
        <motion.div
          key={city.name}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.3,
            delay: index * 0.1, // Stagger the animations
            ease: [0.4, 0, 0.2, 1] // Smooth easing
          }}
        >
          <CityCard
            city={city.name}
            image={city.image}
            active={city.active}
            onClick={() => onCityClick(city.name)}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default CityCardList;