import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import FacilityManagement from '../components/facilities/FacilityManagement';
import WelcomeCities from '../components/facilities/WelcomeCities';

const FacilityBooking: React.FC = () => {
  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Facility Management */}
        <FacilityManagement />
      </div>
    </DashboardLayout>
  );
};

export default FacilityBooking;