import React from 'react';
import { Activity } from '../../types/activity';
import { formatActivityDateTime } from '../../lib/date';
import { getVenueCoordinates } from '../../lib/coordinates';
import LocationActions from '../facilities/LocationActions';
import CopyAddressButton from './buttons/CopyAddressButton';
import AddToCalendarButton from './buttons/AddToCalendarButton';
import VenueSelector from '../facilities/venues-api/components/VenueSelector';
import { useState } from 'react';
import { Button } from '../ui/button';
import { MapPin } from 'lucide-react';

// Helper function to check if activity is in the past
const isPastDate = (date: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const activityDate = new Date(date);
  return activityDate < today;
};

interface ActivityDetailsProps {
  activity: Activity;
  isPastActivity?: boolean;
  onUpdateVenue?: (venue: { name: string; address: string; city: string }) => void;
}

const ActivityDetails: React.FC<ActivityDetailsProps> = ({ activity, isPastActivity = false, onUpdateVenue }) => {
  const dateTimeStr = formatActivityDateTime(activity.date, activity.time);
  const coordinates = getVenueCoordinates(activity.venue.city);
  const fullAddress = activity.venue.address ? `${activity.venue.name}, ${activity.venue.address}` : '';
  const [showVenueSelector, setShowVenueSelector] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(activity.venue);
  const isActivityInPast = isPastActivity || isPastDate(activity.date);
  const isMissedActivity = activity.status === 'missed';

  const handleVenueSelect = (venue: { name: string; address: string; city: string }) => {
    setSelectedVenue(venue);
  };

  const handleSaveVenue = () => {
    if (onUpdateVenue) {
      onUpdateVenue(selectedVenue);
      // Update activity venue locally
      activity.venue = selectedVenue;
    }
    setShowVenueSelector(false);
  };

  // Calculate event start and end times (assuming 1 hour duration)
  const startDate = new Date(`${activity.date}T${activity.time}`);
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

  const calendarEvent = {
    title: `${activity.sport} with ${activity.opponent.name}`,
    description: `Sports session at ${activity.venue.name}`,
    location: fullAddress,
    startTime: startDate.toISOString(),
    endTime: endDate.toISOString(),
  };

  return (
    <div className="mt-4">
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="flex items-center text-dark-800">
          {dateTimeStr}
        </div>
      </div>

      <div className="space-y-2">
        {activity.venue.name && activity.venue.address && !isMissedActivity ? (
          <div className="flex items-center text-dark-800">
            <span>{activity.venue.address}</span>
            <CopyAddressButton address={activity.venue.address} />
          </div>
        ) : (!isActivityInPast && !isMissedActivity ? (
          <div className="space-y-4">
            {!isActivityInPast && !showVenueSelector ? (
              <button
                onClick={() => setShowVenueSelector(true)}
                className="flex items-center space-x-2 px-4 py-2 bg-dark-300 hover:bg-dark-400 rounded-lg text-white transition-colors w-full"
              >
                <MapPin className="h-4 w-4 text-gold-400" />
                <span>Choose where to play</span>
              </button>
            ) : (
              <div className="space-y-4">
                <VenueSelector
                  venue={selectedVenue}
                  onVenueChange={handleVenueSelect}
                  city={activity.venue.city}
                />
                <div className="flex justify-end space-x-2">
                  <Button
                    variant="outline"
                    onClick={() => setShowVenueSelector(false)}
                    className="border-dark-400"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSaveVenue}
                    className="bg-gold-400 hover:bg-gold-500 text-dark-50"
                    disabled={!selectedVenue.name || !selectedVenue.address}
                  >
                    Save Location
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : null
        )}

        {/* Show location actions and calendar button only for non-past activities */}
        {!isActivityInPast && activity.venue.name && activity.venue.address && !isMissedActivity && (
          <LocationActions
            venueName={activity.venue.name}
            address={activity.venue.address}
            coordinates={coordinates}
            hideViewMap={true}
          />
        )}
        {!isActivityInPast && !isMissedActivity && (
          <AddToCalendarButton
            event={{
              title: `${activity.sport} with ${activity.opponent.name}`,
              description: `Sports session at ${activity.venue.name}`,
              location: fullAddress,
              startTime: startDate.toISOString(),
              endTime: endDate.toISOString(),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;