// City coordinates lookup
const cityCoordinates: Record<string, { lat: number; lng: number }> = {
  'Valencia': { lat: 39.4699, lng: -0.3763 },
  'Monte Carlo': { lat: 43.7384, lng: 7.4246 },
  'Nice': { lat: 43.7102, lng: 7.2620 },
  'Paris': { lat: 48.8566, lng: 2.3522 },
  'London': { lat: 51.5074, lng: -0.1278 },
  'Singapore': { lat: 1.3521, lng: 103.8198 },
  'Dubai': { lat: 25.2048, lng: 55.2708 },
  'Rome': { lat: 41.9028, lng: 12.4964 },
  'Barcelona': { lat: 41.3851, lng: 2.1734 },
  'Madrid': { lat: 40.4168, lng: -3.7038 },
  'Luxembourg': { lat: 49.6116, lng: 6.1319 },
  'Washington DC': { lat: 38.8977, lng: -77.0365 }
};

const calculateDistance = {
  // Get coordinates for a city
  getCityCoordinates: (city: string) => {
    return cityCoordinates[city];
  },

  // Calculate distance between two points using Haversine formula
  haversine: (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  }
};

export const getVenueCoordinates = (city: string) => {
  return cityCoordinates[city] || { lat: 0, lng: 0 };
};