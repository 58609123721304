import React from 'react';
import { cn } from '@/lib/utils';
import { VenuePhoto } from '@/types/venue';

interface GalleryThumbnailsProps {
  photos: VenuePhoto[];
  currentIndex: number;
  onSelect: (index: number) => void;
}

export const GalleryThumbnails: React.FC<GalleryThumbnailsProps> = ({
  photos,
  currentIndex,
  onSelect
}) => {
  return (
    <div className="p-4 bg-dark-200">
      <div className="flex space-x-2 overflow-x-auto">
        {photos.map((photo, index) => (
          <button
            key={index}
            onClick={() => onSelect(index)}
            className={cn(
              "flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden transition-all",
              index === currentIndex ? "ring-2 ring-gold-400" : "opacity-60 hover:opacity-100"
            )}
            aria-label={`View photo ${index + 1}`}
            aria-current={index === currentIndex}
          >
            <img
              src={photo.url}
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </button>
        ))}
      </div>
      <div className="text-dark-800 text-sm mt-2 text-center">
        {currentIndex + 1} / {photos.length}
      </div>
    </div>
  );
};