import React from 'react';
import { Trophy, Users, Globe, Shield } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

interface WelcomeStepProps {
  onNext: () => void;
}

export default function WelcomeStep({ onNext }: WelcomeStepProps) {
  return (
    <div className="h-full flex flex-col justify-center relative px-2 sm:px-4 md:px-6">
      <motion.h1 
        className={cn(
          "text-xl xs:text-2xl sm:text-3xl md:text-4xl",
          "font-bold text-transparent bg-clip-text",
          "bg-gradient-to-r from-white via-gold-200 to-gold-400",
          "text-center mb-6 sm:mb-8"
        )}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Ready to Play the World?
      </motion.h1>

      <div className="grid grid-cols-1 xs:grid-cols-2 gap-2 xs:gap-3 sm:gap-4 max-w-lg mx-auto w-full">
        {[
          {
            icon: Users,
            title: "Elite Network",
            description: "Connect with top professionals"
          },
          {
            icon: Trophy,
            title: "Quick Match",
            description: "Find your perfect match"
          },
          {
            icon: Globe,
            title: "Global Access",
            description: "Premium venues worldwide"
          },
          {
            icon: Shield,
            title: "Exclusive Club",
            description: "Verified members only"
          }
        ].map((feature, index) => (
          <motion.div
            key={index}
            className={cn(
              "bg-dark-300/30 backdrop-blur-sm rounded-lg",
              "p-2 xs:p-3 sm:p-4 text-center",
              "hover:bg-dark-300/50 transition-all duration-300",
              "border border-dark-400/30 hover:border-gold-400/30"
            )}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <div className="bg-dark-400 w-6 h-6 xs:w-8 xs:h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center mx-auto mb-2">
              <feature.icon className="h-4 w-4 sm:h-5 sm:w-5 text-gold-400" />
            </div>
            <h3 className="text-sm xs:text-base font-medium text-white mb-1">{feature.title}</h3>
            <p className="text-dark-800 text-xs">{feature.description}</p>
          </motion.div>
        ))}
      </div>
      
      <div className="flex justify-end mt-8">
        <motion.button
          onClick={onNext}
          className={cn(
            "px-4 sm:px-6 py-2 sm:py-3 rounded-lg sm:rounded-xl",
            "bg-gradient-to-br from-gold-400 to-gold-500",
            "hover:from-gold-500 hover:to-gold-600",
            "text-dark-50 text-base sm:text-lg font-medium",
            "shadow-lg hover:shadow-xl",
            "transform hover:scale-105 active:scale-95",
            "transition-all duration-300"
          )}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get Started
        </motion.button>
      </div>
    </div>
  );
}