import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ArrowUpDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import type { VenueSortOption } from '../../../../lib/utils/venue-sorting';

interface SortPopoverProps {
  value: VenueSortOption;
  onChange: (value: VenueSortOption) => void;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const SortPopover: React.FC<SortPopoverProps> = ({
  value,
  onChange,
  isOpen,
  onOpenChange
}) => {
  const options = [
    { value: 'rating', label: 'Highest Rated' },
    { value: 'reviews', label: 'Most Reviewed' },
    { value: 'distance', label: 'Nearest First' }
  ];

  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className={cn(
            "dark:border-dark-400 border-light-300",
            "hover:bg-dark-400/10 transition-colors",
            isOpen && "bg-dark-400/20"
          )}
          title="Sort venues"
        >
          <ArrowUpDown className="h-4 w-4 text-dark-800" />
        </Button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-48 p-2 bg-dark-200 border-dark-300" 
        align="end"
        sideOffset={8}
      >
        <div className="space-y-1">
          {options.map(option => (
            <button
              key={option.value}
              onClick={() => {
                onChange(option.value as VenueSortOption);
                onOpenChange(false);
              }}
              className={cn(
                "w-full text-left px-3 py-2 rounded-lg text-sm",
                "transition-colors",
                value === option.value
                  ? "bg-dark-400 text-white"
                  : "text-dark-800 hover:bg-dark-300 hover:text-white"
              )}
            >
              {option.label}
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};