import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MapPin, Loader2 } from 'lucide-react';
import { City } from '@/types/city';
import { searchCities } from '@/lib/services/geocoding';
import { cn } from '@/lib/utils';
import { useDebounce } from 'use-debounce';

interface CitySearchInputProps {
  value: string;
  onChange: (city: string) => void;
  placeholder: string;
  className?: string;
  error?: string;
}

const CitySearchInput: React.FC<CitySearchInputProps> = ({
  value,
  onChange,
  placeholder = "City",
  className,
  error
}) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Sync internal state with external value
  useEffect(() => {
    setSearchTerm(value || '');
  }, [value]);

  const handleSearch = useCallback(async (query: string) => {
    if (!query.trim() || query.length < 2) {
      setSuggestions([]);
      setSearchError(null);
      return;
    }

    setIsLoading(true);
    setSearchError(null);

    try {
      const results = await searchCities(query);
      
      // Filter out any results that don't have a city name or are countries/regions
      const cityResults = results.filter(city => 
        city.name && 
        !city.name.includes(',') && // Exclude entries with commas (likely country/region names)
        city.type !== 'country' && 
        city.type !== 'state' && 
        city.type !== 'region'
      );
      
      setSuggestions(cityResults);
      if (cityResults.length === 0) {
        setSearchError('Please enter a valid city name');
      }
    } catch (error) {
      console.error('Error searching cities:', error);
      setSearchError('Unable to search cities at this time');
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    onChange(newValue); // Notify parent of change
    setIsOpen(true);
    setSearchError(null);
  };

  const handleSuggestionClick = (city: City) => {
    const cityName = city.name;
    setSearchTerm(cityName);
    onChange(cityName);
    setIsOpen(false);
    setSearchError(null);
    if (containerRef.current) {
      const input = containerRef.current.querySelector('input');
      input?.focus();
    }
  };

  return (
    <div 
      ref={containerRef} 
      className={cn("relative", className)}
    >
      <div className="relative">
        <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800 pointer-events-none" />
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onBlur={() => {
            // Delay closing to allow click events on suggestions
            setTimeout(() => setIsOpen(false), 200);
          }}
          placeholder={placeholder}
          className={cn(
            "w-full pl-10 pr-4 py-2 bg-dark-300 border border-dark-400 rounded-xl",
            "text-white placeholder-dark-800 text-sm font-medium",
            "focus:outline-none focus:ring-2 focus:ring-gold-400",
            "hover:border-gold-400/50",
            (error || searchError) && "border-red-500",
            className
          )}
          autoComplete="off"
          spellCheck="false"
        />
        {isLoading && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <Loader2 className="h-4 w-4 text-dark-800 animate-spin" />
          </div>
        )}
      </div>

      {(error || searchError) && (
        <p className="text-red-500 text-sm mt-1">{error || searchError}</p>
      )}
      
      {isOpen && suggestions.length > 0 && (
        <div className="absolute z-50 w-full mt-1 bg-dark-200 border border-dark-400 rounded-xl shadow-lg">
          <div className="py-1 max-h-[200px] overflow-y-auto">
            {suggestions.map((city, index) => (
              <button
                key={`${city.name}-${index}`}
                onClick={() => handleSuggestionClick(city)}
                className={cn(
                  "w-full flex items-center px-3 py-2 transition-colors text-left",
                  "hover:bg-dark-300 focus:bg-dark-300 focus:outline-none",
                  searchTerm === city.name && "bg-dark-300"
                )}
                type="button"
              >
                <MapPin className="h-4 w-4 text-dark-800 mr-2" />
                <div>
                  <div className="text-white text-sm font-medium">{city.name}</div>
                  {(city.state || city.country) && (
                    <div className="text-dark-800 text-sm">
                      {[city.state, city.country].filter(Boolean).join(', ')}
                    </div>
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CitySearchInput;