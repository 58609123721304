import React from 'react';
import { Trip } from '../../../types/trip';
import CountryFlag from '../../ui/CountryFlag';
import { getCountryCode } from '../../../lib/utils/country';
import TripDates from './TripDates';
import TripActivitiesCount from './TripActivitiesCount';

interface TripHeaderProps {
  trip: Trip;
}

const TripHeader: React.FC<TripHeaderProps> = ({ trip }) => {
  const countryCode = getCountryCode(trip.city);

  // Debug logging
  console.log('Trip city:', trip.city);
  console.log('Country code:', countryCode);

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        {countryCode && (
          <CountryFlag 
            countryCode={countryCode} 
            size="sm"
            className="shadow-sm"
          />
        )}
        <span className="text-white font-medium">{trip.city}</span>
      </div>
      
      <TripDates startDate={trip.startDate} endDate={trip.endDate} />
      
      {trip.activities.length > 0 && (
        <TripActivitiesCount count={trip.activities.length} />
      )}
    </div>
  );
};

export default TripHeader;