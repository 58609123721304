import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users2, MessageCircle, Clock, X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface PendingConnectionsListProps {
  connections: any[];
  onAccept: (connectionId: string) => Promise<void>;
  onDecline: (connectionId: string) => Promise<void>;
  onMessageClick: (connectionId: string, connectionName: string) => void;
}

const PendingConnectionsList: React.FC<PendingConnectionsListProps> = ({
  connections,
  onAccept,
  onDecline,
  onMessageClick
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const handleProfileClick = (connection: any) => {
    if (connection.otherUser?.auth_id) {
      navigate(`/dashboard/members/${connection.otherUser.auth_id}`);
    }
  };

  if (connections.length === 0) {
    return (
      <div className={cn(
        "text-center py-12 rounded-xl border",
        isDarkMode 
          ? "bg-dark-300 border-dark-400" 
          : "bg-gray-50 border-gray-200"
      )}>
        <Users2 className={cn(
          "h-12 w-12 mx-auto mb-4",
          isDarkMode ? "text-dark-800" : "text-gray-400"
        )} />
        <h3 className={cn(
          "text-lg font-medium mb-2",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          No Pending Requests
        </h3>
        <p className={isDarkMode ? "text-dark-800" : "text-gray-600"}>
          You don't have any pending connection requests
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Sent Requests */}
      {connections.filter(c => c.isInitiator).length > 0 && (
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-white">Sent Requests</h3>
          {connections
            .filter(c => c.isInitiator)
            .map((connection) => (
              <div
                key={connection.id}
                className={cn(
                  "bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
                )}
              >
                <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
                  {/* Profile Image */}
                  <button
                    onClick={() => handleProfileClick(connection)}
                    className="relative group"
                  >
                    <img
                      src={connection.otherUser?.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'}
                      alt={connection.otherUser?.full_name || 'User avatar'}
                      className={cn(
                        "w-20 h-20 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                      )}
                    />
                  </button>

                  {/* Info and Actions */}
                  <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
                    <div className="text-center sm:text-left">
                      <button
                        onClick={() => handleProfileClick(connection)}
                        className={cn(
                          "font-medium hover:text-gold-400 transition-colors text-left truncate",
                          isDarkMode ? "text-white" : "text-gray-900"
                        )}
                      >
                        {connection.otherUser?.full_name}
                      </button>
                      <div className={cn(
                        "text-sm",
                        isDarkMode ? "text-dark-800" : "text-gray-500"
                      )}>
                        {connection.otherUser?.city}
                      </div>
                      <div className="flex items-center mt-2 text-dark-800 text-sm">
                        <Clock className="h-4 w-4 mr-1" />
                        <span>Request sent {new Date(connection.created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}</span>
                      </div>
                    </div>

                    {/* Cancel Request Button */}
                    <button
                      onClick={() => onDecline(connection.id)}
                      className={cn(
                        "px-4 py-2 rounded-lg transition-colors",
                        "bg-red-500 hover:bg-red-600 text-white"
                      )}
                    >
                      Cancel Request
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      {/* Received Requests */}
      {connections.filter(c => !c.isInitiator).length > 0 && (
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-white">Received Requests</h3>
          {connections
            .filter(c => !c.isInitiator)
            .map((connection) => (
              <div
                key={connection.id}
                className={cn(
                  "bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
                )}
              >
                <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
                  {/* Profile Image */}
                  <button
                    onClick={() => handleProfileClick(connection)}
                    className="relative group"
                  >
                    <img
                      src={connection.otherUser?.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'}
                      alt={connection.otherUser?.full_name || 'User avatar'}
                      className={cn(
                        "w-20 h-20 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                      )}
                    />
                  </button>

                  {/* Info and Actions */}
                  <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
                    <div className="text-center sm:text-left">
                      <button
                        onClick={() => handleProfileClick(connection)}
                        className={cn(
                          "font-medium hover:text-gold-400 transition-colors text-left truncate",
                          isDarkMode ? "text-white" : "text-gray-900"
                        )}
                      >
                        {connection.otherUser?.full_name}
                      </button>
                      <div className={cn(
                        "text-sm",
                        isDarkMode ? "text-dark-800" : "text-gray-500"
                      )}>
                        {connection.otherUser?.city}
                      </div>
                      <div className="flex items-center mt-2 text-dark-800 text-sm">
                        <Clock className="h-4 w-4 mr-1" />
                        <span>Request received {new Date(connection.created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}</span>
                      </div>
                    </div>

                    {/* Accept/Decline Buttons */}
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => onAccept(connection.id)}
                        className={cn(
                          "px-4 py-2 rounded-lg transition-colors",
                          "bg-green-500 hover:bg-green-600 text-white"
                        )}
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => onDecline(connection.id)}
                        className={cn(
                          "px-4 py-2 rounded-lg transition-colors",
                          "bg-red-500 hover:bg-red-600 text-white"
                        )}
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default PendingConnectionsList;