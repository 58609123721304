import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users2, MessageCircle } from 'lucide-react';
import { Connection } from '../../../../types/connection';
import { useEffect } from 'react';
import { useTheme } from '@/contexts/ThemeContext';
import { useAuth } from '@/contexts/AuthContext';
import ConnectionButton from '../../ConnectionButton';
import { cn } from '@/lib/utils';
import ConnectionCard from './ConnectionCard';

interface ConnectionsListProps {
  connections: any[];
  isLoading?: boolean;
  onShowMutuals: (data: { id: string; name: string; count: number }) => void;
  onMessageClick: (connectionId: string, connectionName: string) => void;
  onAccept: (connectionId: string) => Promise<void>;
  onDecline: (connectionId: string) => Promise<void>;
  memberId?: string;
}

const ConnectionsList: React.FC<ConnectionsListProps> = ({
  connections = [], // Provide default empty array
  isLoading = false,
  onShowMutuals,
  onMessageClick,
  onAccept,
  onDecline,
  memberId
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const isOwnProfile = !memberId || memberId === user?.id;

  // Log connections only on mount or when length changes significantly
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('ConnectionsList received data:', {
        timestamp: new Date().toISOString(),
        total: connections.length,
        summary: {
          status: {
            pending: connections.filter(c => c.status === 'pending').length,
            accepted: connections.filter(c => c.status === 'accepted').length
          },
          byRelationship: {
            initiatedByMe: connections.filter(c => c.isInitiator).length,
            receivedByMe: connections.filter(c => !c.isInitiator).length
          },
          pendingBreakdown: {
            sentByMe: connections.filter(c => c.status === 'pending' && c.isInitiator).length,
            receivedByMe: connections.filter(c => c.status === 'pending' && !c.isInitiator).length
          }
        }
      });
    }
  }, []); // Only run on mount

  if (isLoading) {
    return (
      <div className={cn(
        "text-center py-12 rounded-xl border",
        isDarkMode ? "bg-dark-300 border-dark-400" : "bg-gray-50 border-gray-200"
      )}>
        <div className="flex flex-col items-center justify-center">
          <div className="w-12 h-12 border-4 border-t-gold-400 border-dark-400 rounded-full animate-spin mb-4" />
          <p className={cn(
            "text-lg font-medium",
            isDarkMode ? "text-white" : "text-gray-900"
          )}>
            Loading Connections...
          </p>
        </div>
      </div>
    );
  }

  // Validate connections prop
  if (!connections || !Array.isArray(connections)) {
    console.error('Invalid connections prop:', connections);
    return (
      <div className={cn(
        "text-center py-12 rounded-xl border",
        isDarkMode ? "bg-dark-300 border-dark-400" : "bg-gray-50 border-gray-200"
      )}>
        <Users2 className={cn(
          "h-12 w-12 mx-auto mb-4",
          isDarkMode ? "text-dark-800" : "text-gray-400"
        )} />
        <h3 className={cn(
          "text-lg font-medium mb-2",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          Error Loading Connections
        </h3>
      </div>
    );
  }

  const handleProfileClick = (connection: any) => {
    // Use the pre-computed otherUser data
    if (connection.otherUser?.auth_id) {
      navigate(`/dashboard/members/${connection.otherUser.auth_id}`);
    }
  };

  if (connections.length === 0) {
    return (
      <div className={cn(
        "text-center py-12 rounded-xl border",
        isDarkMode 
          ? "bg-dark-300 border-dark-400" 
          : "bg-gray-50 border-gray-200"
      )}>
        <Users2 className={cn(
          "h-12 w-12 mx-auto mb-4",
          isDarkMode ? "text-dark-800" : "text-gray-400"
        )} />
        <h3 className={cn(
          "text-lg font-medium mb-2",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          No Connections Found
        </h3>
        <p className={isDarkMode ? "text-dark-800" : "text-gray-600"}>
          Try adjusting your search criteria
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {connections.filter(c => c.status === 'accepted').map((connection) => (
        <div
          key={connection.id}
          className={cn(
            "bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          )}
        >
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
            {/* Profile Image */}
            <button
              onClick={() => handleProfileClick(connection)}
              className="relative group"
              title={connection.otherUser?.full_name}
            >
              <img
                src={connection.otherUser?.avatar_url?.startsWith('data:image') 
                  ? 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
                  : connection.otherUser?.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'}
                alt={connection.otherUser?.full_name || 'User avatar'}
                className={cn(
                  "w-20 h-20 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                )}
                loading="eager"
                onError={(e) => {
                  const img = e.target as HTMLImageElement;
                  const defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';
                  if (img.src !== defaultAvatar) {
                    img.src = defaultAvatar;
                  }
                }}
              />
            </button>

            {/* Info and Actions */}
            <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
              <div className="text-center sm:text-left">
                <button
                  onClick={() => handleProfileClick(connection)}
                  className={cn(
                    "font-medium hover:text-gold-400 transition-colors text-left truncate",
                    isDarkMode ? "text-white" : "text-gray-900"
                  )}
                >
                  {connection.otherUser?.full_name || connection.name}
                </button>
                <div className={cn(
                  "text-sm",
                  isDarkMode ? "text-dark-800" : "text-gray-500"
                )}>
                  {connection.otherUser?.city || connection.city}
                </div>
              </div>

              {/* Actions */}
              <div className="flex items-center gap-2 w-full sm:w-auto justify-end">
                {isOwnProfile && connection.status === 'pending' && connection.isInitiator === false ? (
                  <div className="flex items-center gap-2 w-full sm:w-auto justify-center sm:justify-start">
                    <button
                      onClick={() => onAccept(connection.id)}
                      className={cn(
                        "flex-1 sm:flex-initial px-4 py-2 rounded-lg transition-colors",
                        "bg-green-500 hover:bg-green-600 text-white"
                      )}
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => onDecline(connection.id)}
                      className={cn(
                        "flex-1 sm:flex-initial px-4 py-2 rounded-lg transition-colors",
                        "bg-red-500 hover:bg-red-600 text-white"
                      )}
                    >
                      Decline
                    </button>
                  </div>
                ) : isOwnProfile && connection.status === 'pending' && connection.isInitiator === true ? (
                  <div className={cn(
                    "w-full sm:w-auto px-4 py-2 rounded-lg flex items-center justify-center",
                    isDarkMode 
                      ? "bg-dark-400/50 text-dark-800 border border-dark-500" 
                      : "bg-gray-100 text-gray-500 border border-gray-200"
                  )}>
                    <div className="flex items-center space-x-2">
                      <div className="w-2 h-2 bg-gold-400 rounded-full animate-pulse" />
                      <span>Request Pending</span>
                    </div>
                  </div>
                ) : connection.status === 'accepted' && (
                  <button 
                    onClick={() => onMessageClick(
                      connection.id,
                      connection.otherUser?.full_name || connection.name
                    )}
                    className={cn(
                      "p-2 rounded-lg transition-colors flex-shrink-0 hover:scale-105",
                      isDarkMode 
                        ? "bg-dark-400 hover:bg-dark-500 text-white" 
                        : "bg-gray-100 hover:bg-gray-200 text-gray-700"
                    )}
                  >
                    <MessageCircle className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConnectionsList;