import React, { useRef } from 'react';
import { Camera } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ProfileImage } from './ProfileImage';

interface ProfileImageUploadProps {
  value?: string;
  onChange: (value: string) => void;
  className?: string;
}

export const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  value,
  onChange,
  className
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      alert('Image size must be less than 2MB');
      return;
    }

    // Validate file type
    if (!file.type.startsWith('image/')) {
      alert('Please select an image file');
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(reader.result as string);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error reading file:', error);
      alert('Failed to read image file');
    }
  };

  const handleRemove = () => {
    onChange('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={cn("relative group flex flex-col items-center", className)}>
      <div className="relative">
        <ProfileImage 
          src={value} 
          className="mx-auto overflow-hidden shadow-xl relative"
          size={128}
        />
        
        {/* Glow Effect */}
        <div className={cn(
          "absolute -inset-1",
          "bg-gradient-to-r from-gold-400/10 to-gold-500/10",
          "rounded-full blur-lg",
          "opacity-0 group-hover:opacity-100",
          "transition-opacity duration-500",
          "-z-10"
        )} />

        {/* Upload Button */}
        <label
          htmlFor="profile-image-upload"
          className={cn(
            "absolute inset-0 cursor-pointer overflow-hidden",
            "flex items-center justify-center",
            "bg-dark-900/0",
            "opacity-0 group-hover:opacity-100",
            "transition-opacity duration-200"
          )}
        >
          <div className={cn(
            "absolute inset-0",
            "bg-dark-900/40",
            "rounded-full",
            "flex items-center justify-center",
            "transition-all duration-300",
            "backdrop-blur-sm"
          )}>
            <Camera className="h-6 w-6 text-white transform scale-75 group-hover:scale-100 transition-transform" />
          </div>
        </label>
      </div>

      <input
        ref={fileInputRef}
        id="profile-image-upload"
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="sr-only"
        aria-label="Upload profile picture"
      />
    </div>
  );
};