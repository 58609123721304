import React, { useState } from 'react';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { cn } from '@/lib/utils';

const PreferenceSettings: React.FC = () => {
  const [preferences, setPreferences] = useState({
    dateFormat: 'MM/DD/YYYY',
    timeFormat: '24h',
    distanceUnit: 'km',
    theme: 'dark'
  });

  return (
    <div className="bg-white dark:bg-dark-200 rounded-2xl p-6 border border-gray-200 dark:border-dark-300">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Preferences</h2>

      <div className="space-y-6">
        <div>
          <Label className="text-gray-700 dark:text-dark-800">Date Format</Label>
          <Select
            value={preferences.dateFormat}
            onValueChange={(value) => setPreferences({ ...preferences, dateFormat: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className={cn(
              "bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400",
              "[&_[cmdk-item]:hover]:bg-gray-100 dark:[&_[cmdk-item]:hover]:bg-dark-400",
              "[&_[cmdk-item]]:text-gray-900 dark:[&_[cmdk-item]]:text-white"
            )}>
              <SelectItem value="MM/DD/YYYY">MM/DD/YYYY</SelectItem>
              <SelectItem value="DD/MM/YYYY">DD/MM/YYYY</SelectItem>
              <SelectItem value="YYYY-MM-DD">YYYY-MM-DD</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Time Format</Label>
          <Select
            value={preferences.timeFormat}
            onValueChange={(value) => setPreferences({ ...preferences, timeFormat: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className={cn(
              "bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400",
              "[&_[cmdk-item]:hover]:bg-gray-100 dark:[&_[cmdk-item]:hover]:bg-dark-400",
              "[&_[cmdk-item]]:text-gray-900 dark:[&_[cmdk-item]]:text-white"
            )}>
              <SelectItem value="12h">12-hour</SelectItem>
              <SelectItem value="24h">24-hour</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Distance Unit</Label>
          <Select
            value={preferences.distanceUnit}
            onValueChange={(value) => setPreferences({ ...preferences, distanceUnit: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className={cn(
              "bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400",
              "[&_[cmdk-item]:hover]:bg-gray-100 dark:[&_[cmdk-item]:hover]:bg-dark-400",
              "[&_[cmdk-item]]:text-gray-900 dark:[&_[cmdk-item]]:text-white"
            )}>
              <SelectItem value="km">Kilometers</SelectItem>
              <SelectItem value="mi">Miles</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-dark-800">Theme</Label>
          <Select
            value={preferences.theme}
            onValueChange={(value) => setPreferences({ ...preferences, theme: value })}
          >
            <SelectTrigger className="mt-1 bg-white dark:bg-dark-300 border-gray-300 dark:border-dark-400 text-gray-900 dark:text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className={cn(
              "bg-white dark:bg-dark-300 border-gray-200 dark:border-dark-400",
              "[&_[cmdk-item]:hover]:bg-gray-100 dark:[&_[cmdk-item]:hover]:bg-dark-400",
              "[&_[cmdk-item]]:text-gray-900 dark:[&_[cmdk-item]]:text-white"
            )}>
              <SelectItem value="dark">Dark</SelectItem>
              <SelectItem value="light">Light</SelectItem>
              <SelectItem value="system">System</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex justify-end">
          <Button className="bg-gold-400 hover:bg-gold-500 text-dark-50">
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceSettings;