import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Message } from '../../../types/chat';
import Sidebar from './Sidebar';
import ChatWindow from '../ChatWindow';
import { useChat } from '../../../hooks/useChat';

interface ChatLayoutProps {
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  messageInput: string;
  setMessageInput: (value: string) => void;
  isTyping: boolean;
  onSendMessage: (receiverId: string) => void;
  onMarkAsRead: (messageId: string) => void;
  initialChat?: string;
  showMobileList?: boolean;
}

const ChatLayout: React.FC<ChatLayoutProps> = ({
  messages,
  isLoading,
  error,
  messageInput,
  setMessageInput,
  isTyping,
  onSendMessage,
  onMarkAsRead,
  initialChat,
  showMobileList: initialShowMobileList
}) => {
  const navigate = useNavigate();
  const [activeChat, setActiveChat] = useState<string | null>(initialChat || null);
  const [showMobileList, setShowMobileList] = useState(initialShowMobileList ?? true);

  // Initialize showMobileList based on screen size
  React.useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setShowMobileList(isMobile && !activeChat);
  }, [activeChat]);

  // Handle window resize
  React.useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      if (isMobile && !activeChat) {
        setShowMobileList(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeChat]);

  const handleChatSelect = useCallback((chatId: string) => {
    setActiveChat(chatId);
    if (window.innerWidth < 768) {
      setShowMobileList(false);
    }
  }, []);

  if (error) {
    return (
      <div className="fixed inset-x-0 bottom-16 top-16 lg:left-64 flex items-center justify-center">
        <div className="text-red-400 bg-dark-200 rounded-xl p-6 border border-dark-300">
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-dark-300 hover:bg-dark-400 rounded-lg transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-x-0 bottom-16 top-16 lg:left-64 flex">
      {/* Left Sidebar */}
      <div className={`w-full md:w-[380px] md:flex-shrink-0 flex flex-col border-r border-dark-300 ${
        !showMobileList ? 'hidden md:flex' : ''
      }`}>
        <Sidebar
          activeChat={activeChat}
          onChatSelect={handleChatSelect}
        />
      </div>

      {/* Right Chat Area */}
      <div className={`flex-1 ${showMobileList ? 'hidden md:block' : ''}`}>
        {activeChat ? (
          <ChatWindow
            messages={messages}
            isLoading={isLoading}
            error={error}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            isTyping={isTyping}
            onSendMessage={() => onSendMessage(activeChat)}
            onMarkAsRead={onMarkAsRead}
            onBack={() => setShowMobileList(true)}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-dark-800">
            Select a conversation to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatLayout;