export function normalizeText(text: string): string {
  return text.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^a-z0-9\s]/g, "") // Remove special characters
    .trim();
}

function locationContainsCity(
  location: { name?: string; address?: string; city?: string },
  targetCity: string,
  cityDistricts: string[] = []
): boolean {
  const normalizedCity = normalizeText(targetCity);
  
  // Check location name
  if (location.name) {
    const normalizedName = normalizeText(location.name);
    if (normalizedName.includes(normalizedCity)) return true;
  }
  
  // Check location address
  if (location.address) {
    const normalizedAddress = normalizeText(location.address);
    if (normalizedAddress.includes(normalizedCity)) return true;
  }
  
  // Check location city
  if (location.city) {
    const normalizedLocationCity = normalizeText(location.city);
    if (normalizedLocationCity === normalizedCity) return true;
    
    // Check if it's a district of the city
    if (cityDistricts.some(district => normalizeText(district) === normalizedLocationCity)) {
      return true;
    }
  }
  
  return false;
}