import { PhotonService } from './services/photon.service';

// Export types
export * from './types';

// Export constants
export * from './constants';

// Export utilities
export * from './utils/coordinates';
export * from './utils/address';
export * from './utils/text';

// Export filters
export * from './filters';

// Export commonly used functions with simpler names
export const searchLocation = PhotonService.searchLocation.bind(PhotonService);
const reverseGeocode = PhotonService.reverseGeocode.bind(PhotonService);

// Export service for direct access if needed
;