// Cache configuration
export const PROFILE_CACHE_DURATION = 60 * 1000; // 1 minute cache

// Memory cache for profile data
export const profileCache = new Map<string, {
  data: any;
  timestamp: number;
}>();

// Cache management functions
function getCachedProfile(userId: string): any | null {
  const cached = profileCache.get(userId);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < PROFILE_CACHE_DURATION) {
    return cached.data;
  }

  profileCache.delete(userId);
  return null;
}

function setCachedProfile(userId: string, data: any) {
  profileCache.set(userId, {
    data,
    timestamp: Date.now()
  });
}

function clearProfileCache() {
  profileCache.clear();
}