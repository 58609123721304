import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, CheckCircle } from 'lucide-react';
import { cn } from '@/lib/utils';

interface FloatingNavigationProps {
  onBack?: () => void;
  onNext: () => void;
  isNextDisabled?: boolean;
  isSubmitting?: boolean;
}

const FloatingNavigation: React.FC<FloatingNavigationProps> = ({
  onBack,
  onNext,
  isNextDisabled = false,
  isSubmitting = false
}) => {
  return (
    <div className={cn(
      "fixed bottom-8 inset-x-0 z-50",
      "px-4 sm:px-12",
      "pointer-events-none"
    )}>
      <div className="max-w-2xl mx-auto relative">
        <div className="flex items-center justify-between">
        {onBack && (
          <motion.button
            onClick={onBack}
            className={cn(
              "w-10 h-10 flex items-center justify-center rounded-lg",
              "bg-dark-300/90 backdrop-blur-sm border border-dark-400/50",
              "hover:bg-dark-400/90 hover:border-gold-400/50",
              "text-dark-800 hover:text-white",
              "transition-all duration-300",
              "shadow-lg hover:shadow-xl",
              "pointer-events-auto"
            )}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <ArrowLeft className="h-5 w-5" />
          </motion.button>
        )}

        <motion.button
          onClick={onNext}
          disabled={isNextDisabled || isSubmitting}
          className={cn(
              "w-10 h-10 flex items-center justify-center rounded-lg",
              "bg-gradient-to-br from-gold-400 to-gold-500 backdrop-blur-sm",
              "hover:from-gold-500 hover:to-gold-600 hover:scale-105",
              "text-dark-50",
              "transition-all duration-300",
              "shadow-lg hover:shadow-xl", 
              "disabled:opacity-50 disabled:cursor-not-allowed",
              "disabled:from-dark-300 disabled:to-dark-400 disabled:text-dark-800",
              "pointer-events-auto"
            )}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <CheckCircle className="h-5 w-5" />
        </motion.button>
        </div>
      </div>
    </div>
  );
};

export default FloatingNavigation;