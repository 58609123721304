import { useState, useCallback, useEffect } from 'react';
import { Message } from '../types/chat';
import { 
  getMessages, 
  sendMessage, 
  subscribeToMessages, 
  markMessageAsRead 
} from '../lib/supabase/chat';

export function useChat() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [messageInput, setMessageInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const fetchMessages = useCallback(async (conversationId: string) => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await getMessages(conversationId);
      setMessages(data);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError(err instanceof Error ? err.message : 'Failed to load messages');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSendMessage = useCallback(async (receiverId: string) => {
    if (!messageInput.trim()) return;

    try {
      const message = await sendMessage(receiverId, messageInput);
      setMessages(prev => [...prev, message]);
      setMessageInput('');
    } catch (err) {
      console.error('Error sending message:', err);
      setError(err instanceof Error ? err.message : 'Failed to send message');
    }
  }, [messageInput]);

  const handleMarkAsRead = useCallback(async (messageId: string) => {
    try {
      await markMessageAsRead(messageId);
      setMessages(prev => 
        prev.map(msg => 
          msg.id === messageId ? { ...msg, status: 'read' } : msg
        )
      );
    } catch (err) {
      console.error('Error marking message as read:', err);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToMessages((message) => {
      setMessages(prev => [...prev, message]);
      
      // Mark received messages as read
      if (!message.isSender) {
        handleMarkAsRead(message.id);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [handleMarkAsRead]);

  return {
    messages,
    isLoading,
    error,
    messageInput,
    setMessageInput,
    isTyping,
    handleSendMessage,
    handleMarkAsRead
  };
}