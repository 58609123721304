import React from 'react';
import { Check, MessageCircle, Calendar } from 'lucide-react';
import { motion } from 'framer-motion';

interface MatchSuccessMessageProps {
  playerName: string;
  onClose: () => void;
  onViewActivities: () => void;
  onStartChat: () => void;
}

const MatchSuccessMessage: React.FC<MatchSuccessMessageProps> = ({
  playerName,
  onClose,
  onViewActivities,
  onStartChat
}) => {
  return (
    <motion.div 
      className="text-center py-8"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
        className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6"
      >
        <Check className="h-8 w-8 text-dark-50" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <h3 className="text-xl font-semibold text-white mb-2">Play Request Sent!</h3>
        <p className="text-dark-800 mb-6">
          Your request has been sent to {playerName}.
        </p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="flex flex-col gap-3"
      >
        <button
          onClick={onStartChat}
          className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 px-4 py-2 rounded-xl transition-colors flex items-center justify-center space-x-2"
        >
          <MessageCircle className="h-5 w-5" />
          <span>Send Message</span>
        </button>

        <button
          onClick={onViewActivities}
          className="w-full bg-dark-300 hover:bg-dark-400 text-white px-4 py-2 rounded-xl transition-colors flex items-center justify-center space-x-2"
        >
          <Calendar className="h-5 w-5" />
          <span>View Activities</span>
        </button>

        <button
          onClick={onClose}
          className="text-dark-800 hover:text-white transition-colors"
        >
          Close
        </button>
      </motion.div>
    </motion.div>
  );
};

export default MatchSuccessMessage;