import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Activity } from '../../types/activity';
import { updateActivityVenue } from '../../lib/supabase/activities';
import ActivityHeader from './ActivityHeader';
import ActivityDetails from './ActivityDetails';
import ActivityFooter from './ActivityFooter';
import DeclineRequestModal from './modals/DeclineRequestModal';

interface ActivityCardProps {
  activity: Activity;
  isOwnProfile: boolean;
  isPastActivity?: boolean;
  onManageSession: (activity: Activity) => void;
  onAccept: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: (activity: Activity, reason?: string) => void;
  onRate?: (activity: Activity) => void;
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  activity: initialActivity,
  isOwnProfile,
  isPastActivity = false,
  onManageSession,
  onAccept,
  onRematch,
  onDeclineRequest,
  onRate
}) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState(initialActivity);
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  // Update local state when prop changes
  useEffect(() => {
    setActivity(initialActivity);
  }, [initialActivity]);

  // Debug logging
  useEffect(() => {
    console.log('ActivityCard:', {
      id: activity.id,
      status: activity.status,
      direction: activity.direction,
      opponent: activity.opponent.name,
      isOwnProfile
    });
  }, [activity, isOwnProfile]);

  const handleProfileClick = () => {
    navigate(`/dashboard/members/${activity.opponent.id}`);
  };

  const handleAccept = () => {
    setActivity(prev => ({
      ...prev,
      status: 'confirmed',
      direction: undefined
    }));
  };

  const handleDeclineClick = () => {
    setShowDeclineModal(true);
  };

  const handleDeclineConfirm = (reason: string) => {
    setShowDeclineModal(false);
    onDeclineRequest(activity, reason);
  };

  const handleRateClick = () => onRate?.(activity);

  const handleVenueUpdate = async (venue: { name: string; address: string; city: string }) => {
    try {
      await updateActivityVenue(activity.id, venue);
      // Update local state
      setActivity(prev => ({
        ...prev,
        venue: venue
      }));
    } catch (error) {
      console.error('Failed to update venue:', error);
    }
  };

  return (
    <>
      <div className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300">
        <ActivityHeader 
          activity={activity}
          isOwnProfile={isOwnProfile}
          onProfileClick={handleProfileClick}
        />
        
        <ActivityDetails 
          activity={activity}
          isPastActivity={isPastActivity}
          onUpdateVenue={handleVenueUpdate}
        />
        
        <ActivityFooter
          activity={activity}
          isOwnProfile={isOwnProfile}
          onManageSession={onManageSession}
          onRematch={onRematch}
          onDeclineRequest={() => setShowDeclineModal(true)}
          onAccept={() => onAccept(activity)}
          onRate={handleRateClick}
        />
      </div>

      <DeclineRequestModal
        isOpen={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        onConfirm={handleDeclineConfirm}
      />
    </>
  );
};

export default ActivityCard;