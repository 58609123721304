import React from 'react';
import { BackButton } from './header/BackButton';
import { UserAvatar } from './header/UserAvatar';
import { UserStatus } from './header/UserStatus';
import { ChatMenu } from './menus/ChatMenu';

interface ChatHeaderProps {
  conversation: {
    name: string;
    image: string;
    location: string;
    online: boolean;
  };
  showMenu: boolean;
  onMenuToggle: () => void;
  onBackClick: () => void;
  onBlockUser: () => void;
  onReportUser: () => void;
  onClearChat: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  conversation,
  showMenu,
  onMenuToggle,
  onBackClick,
  onBlockUser,
  onReportUser,
  onClearChat
}) => {
  return (
    <div className="p-4 border-b border-dark-300 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <BackButton onClick={onBackClick} />
        <UserAvatar image={conversation.image} name={conversation.name} />
        <div>
          <h4 className="text-base font-medium text-white">{conversation.name}</h4>
          <UserStatus location={conversation.location} isOnline={conversation.online} />
        </div>
      </div>
      
      <ChatMenu
        show={showMenu}
        onToggle={onMenuToggle}
        onBlock={onBlockUser}
        onReport={onReportUser}
      />
    </div>
  );
};

export default ChatHeader;