import React from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '@/lib/utils';

interface FAQItem {
  question: string;
  answer: string;
  category: string;
}

const faqs: FAQItem[] = [
  // Account & Profile
  {
    category: "Account & Profile",
    question: "How do I verify my professional status?",
    answer: "Upload your business card, connect your LinkedIn profile, or verify through your company email. Our team will review and verify your status within 24 hours."
  },
  {
    category: "Account & Profile",
    question: "What are Elite points and how do I earn them?",
    answer: "Elite points are earned through various activities: playing matches (100pts), winning tournaments (500pts), training sessions (75pts), making connections (50pts), and visiting new cities (200pts). Higher points unlock exclusive features and status levels."
  },
  
  // Matchmaking
  {
    category: "Matchmaking",
    question: "How does the matchmaking system work?",
    answer: "Our AI-powered system matches you based on sport preferences, skill level, location, availability, and professional background. Match percentages indicate compatibility across these factors."
  },
  {
    category: "Matchmaking",
    question: "Can I schedule matches while traveling?",
    answer: "Yes! Use our Trip Planning feature to set your travel dates and location. You'll be matched with local players and can pre-book facilities for your visit."
  },
  
  // Facilities
  {
    category: "Facilities",
    question: "How do I book premium facilities?",
    answer: "Browse available facilities in your chosen city, select your preferred time slot, and book instantly. Elite members get priority booking and special rates."
  },
  {
    category: "Facilities",
    question: "What's included in facility bookings?",
    answer: "Bookings typically include court/facility access, basic equipment rental, and locker room access. Premium amenities vary by location and are clearly listed in the facility details."
  },
  
  // Training & Coaching
  {
    category: "Training & Coaching",
    question: "How are trainers verified?",
    answer: "All trainers undergo thorough verification of their certifications, professional experience, and references. They must maintain a minimum 4.5-star rating to remain on the platform."
  },
  {
    category: "Training & Coaching",
    question: "Can I get performance analytics?",
    answer: "Elite members receive detailed performance analytics, including AI-powered technique analysis, progress tracking, and personalized improvement recommendations."
  },
  
  // Privacy & Security
  {
    category: "Privacy & Security",
    question: "How is my personal information protected?",
    answer: "We use enterprise-grade encryption and strict access controls. Your contact details are only shared with confirmed matches, and you can control your privacy settings."
  },
  {
    category: "Privacy & Security",
    question: "Can I control who sees my profile?",
    answer: "Yes, you can set your profile visibility to 'Public', 'Members Only', or 'Connections Only'. You can also block specific users if needed."
  },
  
  // Billing & Membership
  {
    category: "Billing & Membership",
    question: "What's included in Elite membership?",
    answer: "Elite membership includes priority matchmaking, advanced analytics, premium facility access, dedicated concierge service, and exclusive networking events."
  },
  {
    category: "Billing & Membership",
    question: "How do I upgrade my membership?",
    answer: "Go to Settings > Membership to view available plans and upgrade options. Elite membership requires application approval to maintain network quality."
  }
];

const FAQSection: React.FC = () => {
  const [openItems, setOpenItems] = React.useState<Record<string, boolean>>({});
  const [selectedCategory, setSelectedCategory] = React.useState<string>("Account & Profile");

  const categories = Array.from(new Set(faqs.map(faq => faq.category)));
  const filteredFaqs = faqs.filter(faq => faq.category === selectedCategory);

  const toggleItem = (index: number) => {
    setOpenItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className="space-y-6">
      {/* Category Selection */}
      <div className="flex flex-wrap gap-2">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={cn(
              "px-4 py-2 rounded-xl transition-colors",
              selectedCategory === category
                ? "bg-gold-400 text-dark-50"
                : "bg-dark-300 text-white hover:bg-dark-400"
            )}
          >
            {category}
          </button>
        ))}
      </div>

      {/* FAQ Items */}
      <div className="space-y-4">
        {filteredFaqs.map((faq, index) => (
          <div
            key={index}
            className="bg-dark-300 rounded-xl overflow-hidden border border-dark-400 hover:border-gold-400 transition-all duration-300"
          >
            <button
              onClick={() => toggleItem(index)}
              className="w-full px-6 py-4 text-left flex items-center justify-between"
            >
              <span className="text-white font-medium">{faq.question}</span>
              <ChevronDown
                className={cn(
                  "h-5 w-5 text-dark-800 transition-transform duration-200",
                  openItems[index] && "transform rotate-180"
                )}
              />
            </button>
            
            {openItems[index] && (
              <div className="px-6 pb-4 text-dark-800">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;