import React from 'react';
import { LucideIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface BottomNavItemProps {
  name: string;
  icon: LucideIcon;
  isActive: boolean;
  notificationCount?: number;
  onClick: () => void;
  isPlay?: boolean;
}

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  name,
  icon: Icon,
  isActive,
  notificationCount,
  onClick,
  isPlay
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "relative flex flex-col items-center justify-center min-w-[48px] py-1.5",
        isPlay && "-mt-6"
      )}
    >
      <div className="relative">
        {isPlay ? (
          <div className="w-14 h-14 bg-gold-400 rounded-full shadow-lg flex items-center justify-center hover:bg-gold-500 transition-all duration-300 hover:scale-110">
            <Icon className="h-7 w-7 text-dark-50" />
          </div>
        ) : (
          <Icon className={cn(
            "h-[22px] w-[22px] min-h-[22px] min-w-[22px]", // Increased from 18px to 22px
            isActive ? "text-gold-400" : "text-app-muted"
          )} />
        )}
        {notificationCount > 0 && !isPlay && (
          <span className="absolute -top-1.5 -right-2.5 w-4 h-4 bg-gold-400 rounded-full text-dark-50 text-[10px] flex items-center justify-center">
            {notificationCount}
          </span>
        )}
      </div>
      <span className={cn(
        "text-[11px] mt-1 leading-tight", // Increased from 10px to 12px
        isActive ? "text-gold-400" : "text-app-muted"
      )}>
        {name}
      </span>
    </button>
  );
};

export default BottomNavItem;