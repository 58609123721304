import React, { useState } from 'react';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { useChat } from '../../hooks/useChat';
import { chatState } from '../../lib/chat/state';
import { BlockUserModal } from '../chat/modals/BlockUserModal';
import { BlockSuccess } from '../chat/modals/BlockSuccess';
import { ReportUserModal } from '../chat/modals/ReportUserModal';
import { ReportSuccess } from '../chat/modals/ReportSuccess';

interface ChatWindowProps {
  conversation?: {
    id: string;
    name: string;
    image: string;
    location: string;
    online: boolean;
  };
  onBack: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ 
  conversation,
  onBack 
}) => {
  const {
    messageInput,
    setMessageInput,
    handleMessageSend,
    isTyping,
    handleImageUpload
  } = useChat();

  const [showMenu, setShowMenu] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showBlockSuccess, setShowBlockSuccess] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportSuccess, setShowReportSuccess] = useState(false);

  // If no conversation is selected, show a placeholder
  if (!conversation) {
    return (
      <div className="h-full flex items-center justify-center text-dark-800">
        Select a conversation to start messaging
      </div>
    );
  }

  // Get messages for this specific conversation
  const conversationMessages = chatState.messages[conversation.id] || [];

  const handleBlockUser = async () => {
    setShowBlockModal(false);
    setShowBlockSuccess(true);
  };

  const handleReportUser = async (reason: string, details: string) => {
    setShowReportModal(false);
    setShowReportSuccess(true);
  };

  return (
    <div className="h-full flex flex-col relative z-50">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-dark-200 border-b border-dark-300">
        <ChatHeader
          conversation={conversation}
          showMenu={showMenu}
          onMenuToggle={() => setShowMenu(!showMenu)}
          onBackClick={onBack}
          onBlockUser={() => setShowBlockModal(true)}
          onReportUser={() => setShowReportModal(true)}
          onClearChat={() => {}}
        />
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto">
        <MessageList
          messageGroups={{ Today: conversationMessages }}
          isTyping={isTyping}
          typingName={conversation.name}
        />
      </div>

      {/* Input */}
      <div className="sticky bottom-0 bg-dark-200 border-t border-dark-300">
        <MessageInput
          message={messageInput}
          onMessageChange={setMessageInput}
          onSend={() => handleMessageSend(conversation.id)}
          onImageUpload={handleImageUpload}
          onEmojiToggle={() => {}}
        />
      </div>

      {/* Modals */}
      <BlockUserModal
        isOpen={showBlockModal}
        onClose={() => setShowBlockModal(false)}
        onConfirm={handleBlockUser}
        username={conversation.name}
      />

      <BlockSuccess
        isOpen={showBlockSuccess}
        onClose={() => setShowBlockSuccess(false)}
        username={conversation.name}
      />

      <ReportUserModal
        isOpen={showReportModal}
        onClose={() => setShowReportModal(false)}
        onSubmit={handleReportUser}
        username={conversation.name}
      />

      <ReportSuccess
        isOpen={showReportSuccess}
        onClose={() => setShowReportSuccess(false)}
        username={conversation.name}
      />
    </div>
  );
};

export default ChatWindow;