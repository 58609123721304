import React, { useState } from 'react';
import { Calendar } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';

interface AddToCalendarButtonProps {
  event: {
    title: string;
    description: string;
    location: string;
    startTime: string;
    endTime: string;
  };
}

const AddToCalendarButton: React.FC<AddToCalendarButtonProps> = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  const formatGoogleCalendarUrl = () => {
    const { title, description, location, startTime, endTime } = event;
    const baseUrl = 'https://calendar.google.com/calendar/render';
    const params = new URLSearchParams({
      action: 'TEMPLATE',
      text: title,
      details: description,
      location,
      dates: `${startTime}/${endTime}`.replace(/[-:]/g, ''),
    });
    return `${baseUrl}?${params.toString()}`;
  };

  const formatOutlookCalendarUrl = () => {
    const { title, description, location, startTime, endTime } = event;
    const baseUrl = 'https://outlook.live.com/calendar/0/deeplink/compose';
    const params = new URLSearchParams({
      subject: title,
      body: description,
      location,
      startdt: startTime,
      enddt: endTime,
    });
    return `${baseUrl}?${params.toString()}`;
  };

  const formatICalContent = () => {
    const { title, description, location, startTime, endTime } = event;
    return `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
DTSTART:${startTime.replace(/[-:]/g, '')}
DTEND:${endTime.replace(/[-:]/g, '')}
END:VEVENT
END:VCALENDAR`;
  };

  const downloadICalFile = () => {
    const content = formatICalContent();
    const blob = new Blob([content], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'event.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="flex items-center justify-center space-x-2 w-full px-4 py-2 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors mt-2"
      >
        <Calendar className="h-4 w-4 text-gold-400" />
        <span>Add to Calendar</span>
      </button>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>Add to Calendar</DialogTitle>
          </DialogHeader>

          <div className="space-y-3 py-4">
            <a
              href={formatGoogleCalendarUrl()}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center space-x-3 w-full px-4 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
            >
              <span>Google Calendar</span>
            </a>

            <a
              href={formatOutlookCalendarUrl()}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center space-x-3 w-full px-4 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
            >
              <span>Outlook Calendar</span>
            </a>

            <button
              onClick={downloadICalFile}
              className="flex items-center justify-center space-x-3 w-full px-4 py-3 bg-dark-300 hover:bg-dark-400 text-white rounded-xl transition-colors"
            >
              <span>Apple Calendar (iCal)</span>
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddToCalendarButton;