interface NavigationApp {
  name: string;
  getUrl: (coordinates: { lat: number; lng: number }, name: string) => string;
  icon: string;
}

export const navigationApps: NavigationApp[] = [
  {
    name: 'Google Maps',
    icon: '🗺️',
    getUrl: (coordinates, name) => 
      `https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}&destination_place_id=${encodeURIComponent(name)}`
  },
  {
    name: 'Apple Maps',
    icon: '🍎',
    getUrl: (coordinates, name) => 
      `maps://maps.apple.com/?daddr=${coordinates.lat},${coordinates.lng}&dname=${encodeURIComponent(name)}`
  },
  {
    name: 'Waze',
    icon: '🚗',
    getUrl: (coordinates) => 
      `https://www.waze.com/ul?ll=${coordinates.lat},${coordinates.lng}&navigate=yes&zoom=17`
  }
];