import React from 'react';
import { RefreshCw, List, MapIcon } from 'lucide-react';
import { Button } from '../../../ui/button';
import { Switch } from '../../../ui/switch';
import { Label } from '../../../ui/label';
import { LocationPopover } from './LocationPopover';
import { SortPopover } from './SortPopover';
import { cn } from '@/lib/utils';
import type { VenueSortOption } from '../../../../lib/utils/venue-sorting';

interface VenueListControlsProps {
  totalVenues: number;
  showOpenOnly: boolean;
  onShowOpenOnlyChange: (value: boolean) => void;
  sortBy: VenueSortOption;
  onSortChange: (value: VenueSortOption) => void;
  onRefresh: () => void;
  isLoading: boolean;
  viewMode: 'list' | 'map';
  onViewModeChange: (mode: 'list' | 'map') => void;
  address: string;
  onAddressChange: (address: string) => void;
  onLocationSelect: (location: { lat: number; lng: number }) => void;
  isAddressLoading?: boolean;
  addressError?: string;
}

const VenueListControls: React.FC<VenueListControlsProps> = ({
  totalVenues,
  showOpenOnly,
  onShowOpenOnlyChange,
  sortBy,
  onSortChange,
  onRefresh,
  isLoading,
  viewMode,
  onViewModeChange,
  address,
  onAddressChange,
  onLocationSelect,
  isAddressLoading,
  addressError
}) => {
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [showLocationPopover, setShowLocationPopover] = React.useState(false);
  const [showSortPopover, setShowSortPopover] = React.useState(false);

  const handleRefresh = async () => {
    if (isRefreshing) return;
    
    setIsRefreshing(true);
    await onRefresh();
    
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };

  return (
    <div className={cn(
      "flex flex-col sm:flex-row items-stretch sm:items-center justify-between gap-4",
      "rounded-xl p-3",
      "dark:bg-dark-300/50 bg-light-100",
      "dark:border-dark-300 border-light-300 border"
    )}>
      {/* Left section - Found count and Open Now toggle */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
        <span className="dark:text-dark-800 text-light-600 whitespace-nowrap">
          Found {totalVenues} clubs
        </span>

        <div className="flex items-center space-x-2">
          <Switch
            id="open-now"
            checked={showOpenOnly}
            onCheckedChange={onShowOpenOnlyChange}
          />
          <Label 
            htmlFor="open-now" 
            className="dark:text-dark-800 text-light-600 cursor-pointer whitespace-nowrap"
          >
            Open Now
          </Label>
        </div>
      </div>
      
      {/* Right section - Controls */}
      <div className="flex items-center gap-2 sm:gap-4 flex-wrap sm:flex-nowrap">
        {/* Search again button */}
        <Button
          variant="outline"
          size="icon"
          onClick={handleRefresh}
          className={cn(
            "dark:border-dark-400 border-light-300",
            "hover:bg-dark-400/10 transition-colors",
            "disabled:opacity-50"
          )}
          disabled={isLoading || isRefreshing}
        >
          <RefreshCw className={cn(
            "h-4 w-4",
            (isLoading || isRefreshing) && "animate-spin"
          )} />
        </Button>

        {/* View mode toggles */}
        <div className="flex items-center space-x-1">
          <button
            onClick={() => onViewModeChange('list')}
            className={cn(
              "p-2 rounded-md transition-colors",
              viewMode === 'list' 
                ? "bg-dark-400 text-gold-400" 
                : "text-dark-800 hover:text-white hover:bg-dark-300"
            )}
            aria-label="List view"
          >
            <List className="h-5 w-5" />
          </button>
          <button
            onClick={() => onViewModeChange('map')}
            className={cn(
              "p-2 rounded-md transition-colors",
              viewMode === 'map' 
                ? "bg-dark-400 text-gold-400" 
                : "text-dark-800 hover:text-white hover:bg-dark-300"
            )}
            aria-label="Map view"
          >
            <MapIcon className="h-5 w-5" />
          </button>
        </div>

        {/* Location button */}
        <LocationPopover
          address={address}
          onAddressChange={onAddressChange}
          onLocationSelect={onLocationSelect}
          isLoading={isAddressLoading}
          error={addressError}
          isOpen={showLocationPopover}
          onOpenChange={setShowLocationPopover}
        />

        {/* Sort button */}
        <SortPopover
          value={sortBy}
          onChange={onSortChange}
          isOpen={showSortPopover}
          onOpenChange={setShowSortPopover}
        />
      </div>
    </div>
  );
};

export default VenueListControls;