import React from 'react';
import { LogoProps } from './types';
import { getLogoAsset } from './assets';
import { cn } from '@/lib/utils';

export const Logo: React.FC<LogoProps> = ({
  variant = 'full',
  colorScheme = 'color',
  background = 'transparent',
  platform = 'web',
  className,
  width,
  height,
  onClick
}) => {
  const asset = getLogoAsset(variant, colorScheme, background);
  
  // Default dimensions based on variant
  const defaultDimensions = {
    full: { width: 120, height: 40 },
    symbol: { width: 32, height: 32 },
    text: { width: 100, height: 30 },
    combined: { width: 160, height: 40 }
  };

  // Responsive classes for different platforms
  const platformClasses = {
    web: 'w-auto h-auto',
    mobile: 'w-8 h-8 sm:w-10 sm:h-10',
    social: 'w-12 h-12'
  };

  const dimensions = defaultDimensions[variant as keyof typeof defaultDimensions] || defaultDimensions.full;

  return (
    <img
      src={asset.url}
      alt={asset.alt}
      width={width || dimensions.width}
      height={height || dimensions.height}
      onClick={onClick}
      className={cn(
        'object-contain transition-all duration-200',
        platformClasses[platform],
        asset.className,
        className
      )}
      style={{
        width: width || undefined,
        height: height || undefined
      }}
    />
  );
};

