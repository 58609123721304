import { VenueApiResult } from '../../../types/venue';

interface CacheEntry {
  data: VenueApiResult[];
  timestamp: number;
}

export class VenueCache {
  private static cache = new Map<string, CacheEntry>();
  private static readonly DEFAULT_DURATION = 5 * 60 * 1000; // 5 minutes

  static get(key: string): VenueApiResult[] | null {
    const entry = this.cache.get(key);
    if (!entry) return null;

    if (Date.now() - entry.timestamp > this.DEFAULT_DURATION) {
      this.cache.delete(key);
      return null;
    }

    return entry.data;
  }

  static set(key: string, data: VenueApiResult[]): void {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  static clear(): void {
    this.cache.clear();
  }
}