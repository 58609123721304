export function sortQuickPlayResults<T extends {
  distance: number;
  matchPercentage: number;
  mutualConnections?: number;
}>(results: T[]): T[] {
  return [...results].sort((a, b) => {
    if (a.distance !== b.distance) {
      return a.distance - b.distance;
    }
    
    if (a.matchPercentage !== b.matchPercentage) {
      return b.matchPercentage - a.matchPercentage;
    }
    
    return (b.mutualConnections || 0) - (a.mutualConnections || 0);
  });
}