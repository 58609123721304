import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import { useQuickMatch } from '../hooks/matchmaking/useQuickMatch';
import QuickPlayFilters from '../components/matchmaking/filters/QuickPlayFilters';
import PlayerResults from '../components/matchmaking/modals/PlayerResults';
import { useNavigate } from 'react-router-dom';
import { createActivity } from '../lib/supabase/activities';
import { motion } from 'framer-motion';
import { Player } from '../types/matchmaking';

export default function QuickPlay() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = React.useState(false);
  const searchKeyRef = React.useRef<number | undefined>(undefined);

  const {
    potentialPlayers,
    searchState,
    isInitialSearch,
    isLoading,
    error,
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    sports,
    setSearchTerm,
    setSelectedSport,
    setSelectedGender,
    setSelectedRating,
    setShowConnectionsOnly,
    filterPlayers,
    resetSearch
  } = useQuickMatch();

  const handleSearchAgain = React.useCallback(() => {
    resetSearch();
  }, [resetSearch]);

  const handleMatchSelect = React.useCallback(async (player: Player) => {
    try {
      // Add match creation logic here
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    // Only trigger search if searchKey has changed
    if (location.state?.searchKey !== searchKeyRef.current) {
      searchKeyRef.current = location.state?.searchKey;
      // Clear any existing search state
      setShowFilters(false);
      resetSearch();
    }
  }, [location.state?.searchKey, resetSearch]);


  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Title Section */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <h1 className="text-2xl font-bold text-white">Quick Play</h1>
          <p className="text-dark-800 mt-1">Find your perfect match</p>
        </div>

        {/* Filters */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <QuickPlayFilters
            searchTerm={searchTerm}
            selectedSport={selectedSport}
            selectedGender={selectedGender}
            selectedRating={selectedRating}
            showConnectionsOnly={showConnectionsOnly}
            onSearchChange={setSearchTerm}
            onSportChange={setSelectedSport}
            onGenderChange={setSelectedGender}
            onRatingChange={setSelectedRating}
            onConnectionsOnlyChange={setShowConnectionsOnly}
            sports={sports}
            showFilters={showFilters}
            onToggleFilters={() => setShowFilters(!showFilters)}
            onSearchAgain={handleSearchAgain}
          />
        </div>

        {/* Loading State */}
        {isLoading && (
          <div className="flex items-center justify-center min-h-[60vh] px-4">
            <motion.div
              className="w-full max-w-md mx-auto text-center relative overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="relative mx-auto" style={{ aspectRatio: '1/1', maxWidth: '24rem' }}>
                <div className="absolute inset-0 flex items-center justify-center">
                  {/* Wave effects */}
                  <div 
                    className="absolute w-1/2 h-1/2 rounded-full bg-gold-400/10"
                    style={{ animation: 'wave-out 3s ease-out infinite' }}
                  />
                  <div 
                    className="absolute w-1/2 h-1/2 rounded-full bg-gold-400/10"
                    style={{ animation: 'wave-out 3s ease-out infinite 1s' }}
                  />
                  <div 
                    className="absolute w-1/2 h-1/2 rounded-full bg-gold-400/10"
                    style={{ animation: 'wave-out 3s ease-out infinite 2s' }}
                  />
                  {/* Radar sweep */}
                  <div className="absolute w-full h-full">
                    <div 
                      className="absolute inset-0 bg-gradient-to-t from-gold-400/40 via-gold-400/20 to-transparent origin-center" 
                      style={{ 
                        clipPath: 'polygon(50% 50%, 65% 0, 35% 0)',
                        animation: 'spin 2s linear infinite',
                        animationPlayState: 'running'
                      }} 
                    />
                  </div>
                </div>
                {/* Logo */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-1/4 h-1/4 relative">
                    <img
                      src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png"
                      alt="Playclub Symbol"
                      className="w-full h-full object-contain"
                      style={{
                        animation: 'spin 2s linear infinite',
                        animationPlayState: 'running'
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3 className="text-lg font-medium text-white mt-1">
                Finding Players Near You
              </h3>
              <div className="flex justify-center items-center mt-4 space-x-2">
                <span className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse"></span>
                <span className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse delay-150"></span>
                <span className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse delay-300"></span>
              </div>
            </motion.div>
          </div>
        )}

        {/* Results */}
        {!isLoading && <PlayerResults players={potentialPlayers} />}
      </div>
    </DashboardLayout>
  );
}
