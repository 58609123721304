import { VenueApiResult } from '../../../types/venue';
import { VenueCache } from './cache';
import { VenueError } from './error';
import { formatVenueData } from './venue.formatter';

const LUXEMBOURG_VENUES_URL = 'https://raw.githubusercontent.com/uri100/json_pc/refs/heads/main/luxembourg_places_details.json';
const CACHE_KEY = 'luxembourg_venues';

export class LuxembourgVenueService {
  static async getVenues(): Promise<VenueApiResult[]> {
    try {
      // Check cache first
      const cachedData = VenueCache.get(CACHE_KEY);
      if (cachedData) return cachedData;

      const response = await fetch(LUXEMBOURG_VENUES_URL);
      
      if (!response.ok) {
        throw new VenueError(
          'Failed to fetch Luxembourg venues',
          'FETCH_ERROR',
          response.status
        );
      }

      const data = await response.json();
      
      if (!data || typeof data !== 'object') {
        throw new VenueError(
          'Invalid venue data format',
          'INVALID_DATA'
        );
      }

      const venues = Object.values(data)
        .filter((venue): venue is any => (
          this.isValidVenue(venue)
        ))
        .map(venue => ({
          ...formatVenueData(venue),
          city: 'Luxembourg'
        }));

      // Cache the results
      VenueCache.set(CACHE_KEY, venues);

      return venues;

    } catch (error) {
      const venueError = VenueError.fromError(error);
      console.error('Error fetching Luxembourg venues:', venueError);
      
      // Return cached data if available
      return VenueCache.get(CACHE_KEY) || [];
    }
  }

  private static isValidVenue(venue: unknown): boolean {
    return Boolean(
      venue &&
      typeof venue === 'object' &&
      'name' in venue &&
      'geometry' in venue &&
      (venue as any).geometry?.location?.lat &&
      (venue as any).geometry?.location?.lng
    );
  }
}