import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScrollText, MapPin, Plus, Calendar, MessageSquare } from 'lucide-react';
import { getPendingRequestsCount } from '@/lib/utils/activities';
import { getUnreadMessagesCount } from '@/lib/utils/chat';
import { activities } from '@/data/activities';
import { chatState } from '@/lib/chat/state';
import BottomNavItem from './BottomNavItem';

const bottomNavItems = [
  { name: 'Feed', icon: ScrollText, path: '/dashboard' },
  { name: 'Cities', icon: MapPin, path: '/dashboard/facilities' },
  { name: 'Play', icon: Plus, path: '/dashboard/quick-play' },
  { name: 'My Activities', icon: Calendar, path: '/dashboard/activities' },
  { name: 'Messages', icon: MessageSquare, path: '/dashboard/messages' }
];

const BottomNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pendingRequests = getPendingRequestsCount(activities);
  const unreadMessages = getUnreadMessagesCount(chatState.conversations);

  const handleNavigation = (path: string) => {
    if (path === '/dashboard/quick-play') {
      navigate(path, {
        replace: true,
        state: { searchKey: Date.now() }
      });
    } else {
      navigate(path);
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-app border-t border-dark-300 z-40">
      <div className="w-full px-2 py-2 pb-safe">
        <div className="grid grid-cols-5 gap-0 max-w-screen-lg mx-auto">
          {bottomNavItems.map((item) => (
            <BottomNavItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              isActive={location.pathname === item.path}
              notificationCount={
                item.name === 'My Activities' ? pendingRequests :
                item.name === 'Messages' ? unreadMessages :
                undefined
              }
              onClick={() => handleNavigation(item.path)}
              isPlay={item.name === 'Play'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomNavigation;