// City aliases and canonical names
export const CITY_ALIASES: Record<string, string> = {
  'singapore': 'Singapore',
  'monte carlo': 'Monte Carlo',
  'monaco': 'Monte Carlo',
  'washington dc': 'Washington DC',
  'washington d.c.': 'Washington DC',
  'vienna, austria': 'Vienna',
  'vienna': 'Vienna',
  'wien': 'Vienna',
  'tel aviv, israel': 'Tel Aviv',
  'tel aviv-yafo': 'Tel Aviv',
  'tel aviv': 'Tel Aviv',
  'washington': 'Washington DC',
  'dubai': 'Dubai',
  'rome': 'Rome',
  'roma': 'Rome',
  'paris': 'Paris',
  'london': 'London',
  'barcelona': 'Barcelona',
  'madrid': 'Madrid',
  'luxembourg': 'Luxembourg',
  'nice': 'Nice',
  'valencia': 'Valencia'
};

// Regions/states to ignore in city matching
export const IGNORED_REGIONS = [
  'lazio',
  'lombardy',
  'catalonia',
  'israel',
  'austria',
  'ile-de-france',
  'austria',
  'greater london',
  'district of columbia',
  'dubai emirate',
  'comunidad valenciana',
  'provence-alpes-cote d\'azur'
];