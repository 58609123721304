import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CitiesPlayedModal from '../members/CitiesPlayedModal';
import MemberProfileSkeleton from '../members/MemberProfileSkeleton';
import NetworkTab from '../members/tabs/NetworkTab';
import TrainingTab from '../members/tabs/TrainingTab';
import PostsTab from '../members/tabs/PostsTab';
import MyTripsTab from './trips/MyTripsTab';
import PerformanceTracker from '../performance/PerformanceTracker';
import ProfileTabs from './ProfileTabs';
import AboutTab from './AboutTab';
import ProfileHeader from '../members/profile/ProfileHeader';
import ProfileStats from './ProfileStats';
import ReviewsSection from '../members/ReviewsSection';
import { cityLandmarks } from '../../data/cities';
import { useProfile } from '../../hooks/useProfile';
import { supabase } from '../../lib/supabase';
import { storage } from '../../lib/supabase/storage';
import { useConnectionStore } from '../../store/connectionStore';
import { Post } from '../../types/post';
import { Member } from '../../types/member';
import { Linkedin, Facebook, Instagram } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface ProfileSectionProps {
  defaultTab?: string;
  hideDetails?: boolean;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ defaultTab = 'about', hideDetails = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<'about' | 'posts' | 'network' | 'trips' | 'training' | 'performance' | 'ratings'>(defaultTab as any);
  const [showCitiesModal, setShowCitiesModal] = useState(false);
  const [memberPosts, setMemberPosts] = useState<Post[]>([]);
  const [imageLoading, setImageLoading] = useState(false); 
  const [imageError, setImageError] = useState<string | null>(null);
  const [member, setMember] = useState<Member | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { profile, isLoading: profileLoading, error: profileError, fetchProfile, updateProfile } = useProfile();
  const { pendingRequests } = useConnectionStore();
  const { isDarkMode } = useTheme();

  const renderSocialLinks = () => (
    <div className="flex items-center justify-center space-x-2 mt-4">
      <Facebook className="h-5 w-5 text-dark-800" />
    
    </div>
  );

  const currentUser: Member = {
    id: 'current-user',
    name: profile?.full_name || 'User',
    image: profile?.avatar_url?.startsWith('data:image') 
      ? 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
      : profile?.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
    city: profile?.city || 'Location',
    jobTitle: profile?.job_type || 'Role',
    company: profile?.company || 'Company',
    skillLevel: 'Advanced',
    connections: 0,
    matchesPlayed: 0,
    citiesPlayed: ['Valencia', 'London', 'Paris'],
    achievements: ['Tournament Winner', 'Elite Status'],
    rating: 4.9,
    joinDate: '2024-10-01',
    industry: 'Technology',
    points: {
      matches: 6200,
      training: 4500,
      connections: 16200,
      cities: 1800,
      events: 3200,
      achievements: 5000,
      total: 36900
    }
  };

  useEffect(() => {
    fetchProfile();
    setMember(currentUser);
    setIsLoading(false);
  }, [fetchProfile]);

  // Fetch profile on mount
  useEffect(() => {
    fetchProfile();
  }, []); // Remove fetchProfile from deps as it's stable

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const cityImage = currentUser.city
    ? cityLandmarks[currentUser.city.toLowerCase()] ||
      'https://images.unsplash.com/photo-1575540203949-54ccd7a66d98?auto=format&fit=crop&w=1600&q=80'
    : 'https://images.unsplash.com/photo-1575540203949-54ccd7a66d98?auto=format&fit=crop&w=1600&q=80';


  if (isLoading || profileLoading) {
    return <MemberProfileSkeleton />;
  }

  if (error || profileError) {
    return (
      <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
        <p>
          {(error || profileError) === 'Failed to fetch' 
            ? 'Unable to connect to server. Please check your connection.' 
            : error || profileError}
        </p>
        <button
          onClick={() => fetchProfile()}
          className="text-sm underline mt-2 hover:text-red-400"
        >
          Try Again
        </button>
        {profile && (
          <p className="mt-4 text-sm text-dark-800">
            Showing cached profile data
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <ProfileHeader
        member={currentUser}
        profile={profile}
        cityImage={cityImage}
        renderSocialLinks={renderSocialLinks}
        onImageChange={async (file) => {
          try {
            const { data: uploadData } = await storage.uploadFile({
              bucket: 'avatars',
              file,
              path: `avatars/${currentUser.id}/${file.name}`
            });
            
            if (uploadData?.url) {
              await updateProfile({ avatar_url: uploadData.url });
            }
          } catch (error) {
            console.error('Failed to update profile image:', error);
          }
        }}
      />

      <ProfileStats
        member={currentUser}
        onShowCities={() => setShowCitiesModal(true)}
      />

      <ProfileTabs 
        activeTab={activeTab} 
        onTabChange={setActiveTab}
        pendingCount={pendingRequests.length}
      />

      {activeTab === 'about' && (
        <AboutTab member={currentUser} achievements={currentUser.achievements || []} profile={profile} />
      )}

      {activeTab === 'posts' && (
        <PostsTab posts={memberPosts} memberName={currentUser.name} />
      )}

      {activeTab === 'network' && <NetworkTab />}

      {activeTab === 'trips' && <MyTripsTab />}

      {activeTab === 'training' && <TrainingTab />}

      {activeTab === 'performance' && <PerformanceTracker />}

      {activeTab === 'ratings' && (
        <ReviewsSection
          memberId={currentUser.id}
          memberName={currentUser.name}
          rating={currentUser.rating}
          reviewCount={0}
        />
      )}

      {showCitiesModal && (
        <CitiesPlayedModal
          isOpen={true}
          cities={currentUser.citiesPlayed}
          memberName={currentUser.name}
          onClose={() => setShowCitiesModal(false)}
        />
      )}
    </div>
  );
};

export default ProfileSection;
