import { Member } from '../types/member';
import { trainers } from './trainers';
import { calculatePoints } from './points';

// Regular members data
const regularMembers: Member[] = [
  {
    id: '51',
    name: 'Sarah Connor',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80',
    city: 'Monte Carlo',
    sports: ['Tennis', 'Swimming', 'Yoga'],
    jobTitle: 'Investment Director',
    company: 'BlackRock',
    connections: 312,
    matchesPlayed: 85,
    citiesPlayed: ['Monte Carlo', 'Paris', 'New York', 'Dubai', 'Singapore', 'Hong Kong'],
    achievements: ['Tennis Champion', 'Marathon Finisher', 'Elite Network', 'Global Ambassador'],
    rating: 4.9,
    skillLevel: 'Professional',
    availability: ['morning', 'afternoon'],
    age: 35,
    industry: 'Finance',
    gender: 'female',
    isConnected: true,
    joinDate: '2024-09-01',
    linkedinUrl: 'https://linkedin.com/in/sarahconnor',
    facebookUrl: 'https://facebook.com/sarahconnor',
    instagramUrl: 'https://instagram.com/sarahconnor',
    invitedBy: {
      id: '55',
      name: 'Uri Schneider',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 6200,
      training: 4500,
      connections: 16200,
      cities: 1800,
      events: 3200,
      achievements: 5000,
      total: 36900
    }
  },
  {
    id: '52',
    name: 'James Wilson',
    image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80',
    city: 'Monte Carlo',
    sports: ['Tennis', 'Golf'],
    jobTitle: 'Managing Director',
    company: 'Goldman Sachs',
    connections: 278,
    matchesPlayed: 93,
    citiesPlayed: ['Monte Carlo', 'London', 'Geneva', 'Dubai'],
    achievements: ['Golf Tournament Champion', 'Elite Status', 'Network Leader'],
    rating: 4.8,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 45,
    industry: 'Banking',
    gender: 'male',
    isConnected: true,
    joinDate: '2024-08-15',
    linkedinUrl: 'https://linkedin.com/in/jameswilson',
    facebookUrl: 'https://facebook.com/jameswilson',
    instagramUrl: 'https://instagram.com/jameswilson',
    invitedBy: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 5800,
      training: 3900,
      connections: 14200,
      cities: 1600,
      events: 2800,
      achievements: 4500,
      total: 32800
    }
  },
  {
    id: '60',
    name: 'Elena Petrova',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
    city: 'Monte Carlo',
    sports: ['Tennis', 'Yoga', 'Swimming'],
    jobTitle: 'Private Banker',
    company: 'UBS',
    connections: 245,
    matchesPlayed: 68,
    citiesPlayed: ['Monte Carlo', 'Geneva', 'London', 'Dubai'],
    achievements: ['Tennis Elite', 'Wellness Champion'],
    rating: 4.8,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 32,
    industry: 'Banking',
    gender: 'female',
    isConnected: false,
    joinDate: '2024-01-15',
    linkedinUrl: 'https://linkedin.com/in/elenapetrova',
    facebookUrl: 'https://facebook.com/elenapetrova',
    instagramUrl: 'https://instagram.com/elenapetrova',
    invitedBy: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 5200,
      training: 3800,
      connections: 13350,
      cities: 1500,
      events: 2700,
      achievements: 4100,
      total: 30450
    }
  },
  {
    id: '61',
    name: 'Marcus Chen',
    image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80',
    city: 'Singapore',
    sports: ['Tennis', 'Golf', 'Swimming'],
    jobTitle: 'Managing Director',
    company: 'Morgan Stanley',
    connections: 289,
    matchesPlayed: 74,
    citiesPlayed: ['Singapore', 'Hong Kong', 'Tokyo', 'Dubai'],
    achievements: ['Tennis Champion', 'Corporate League Winner'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 38,
    industry: 'Finance',
    gender: 'male',
    isConnected: true,
    joinDate: '2024-02-01',
    linkedinUrl: 'https://linkedin.com/in/marcuschen',
    facebookUrl: 'https://facebook.com/marcuschen',
    instagramUrl: 'https://instagram.com/marcuschen',
    invitedBy: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 4800,
      training: 3500,
      connections: 12800,
      cities: 1400,
      events: 2500,
      achievements: 3800,
      total: 28800
    }
  },
  {
    id: '62',
    name: 'Isabella Martinez',
    image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80',
    city: 'Madrid',
    sports: ['Tennis', 'Yoga', 'Pilates'],
    jobTitle: 'Investment Banker',
    company: 'Santander',
    connections: 234,
    matchesPlayed: 56,
    citiesPlayed: ['Madrid', 'Barcelona', 'Paris', 'London'],
    achievements: ['Wellness Ambassador', 'Tennis Elite'],
    rating: 4.8,
    skillLevel: 'Advanced',
    availability: ['morning', 'afternoon'],
    age: 29,
    industry: 'Banking',
    gender: 'female',
    isConnected: false,
    joinDate: '2024-03-01',
    linkedinUrl: 'https://linkedin.com/in/isabellamartinez',
    facebookUrl: 'https://facebook.com/isabellamartinez',
    instagramUrl: 'https://instagram.com/isabellamartinez',
    invitedBy: {
      id: '55',
      name: 'Uri Schneider',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 4200,
      training: 3200,
      connections: 11500,
      cities: 1200,
      events: 2200,
      achievements: 3500,
      total: 25800
    }
  },
  {
    id: '63',
    name: 'Alexander Weber',
    image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=300&q=80',
    city: 'Luxembourg',
    sports: ['Tennis', 'Golf', 'Running'],
    jobTitle: 'Private Equity Partner',
    company: 'CVC Capital',
    connections: 267,
    matchesPlayed: 82,
    citiesPlayed: ['Luxembourg', 'London', 'Frankfurt', 'Paris'],
    achievements: ['Golf Champion', 'Marathon Finisher'],
    rating: 4.9,
    skillLevel: 'Professional',
    availability: ['morning', 'evening'],
    age: 42,
    industry: 'Private Equity',
    gender: 'male',
    isConnected: true,
    joinDate: '2024-01-10',
    linkedinUrl: 'https://linkedin.com/in/alexanderweber',
    facebookUrl: 'https://facebook.com/alexanderweber',
    instagramUrl: 'https://instagram.com/alexanderweber',
    invitedBy: {
      id: '51',
      name: 'Sarah Connor',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 5500,
      training: 4100,
      connections: 13800,
      cities: 1600,
      events: 2900,
      achievements: 4300,
      total: 32200
    }
  },
  {
    id: '64',
    name: 'Sophie Laurent',
    image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80',
    city: 'Paris',
    sports: ['Tennis', 'Swimming', 'Yoga'],
    jobTitle: 'Investment Director',
    company: 'BNP Paribas',
    connections: 223,
    matchesPlayed: 63,
    citiesPlayed: ['Paris', 'London', 'Geneva', 'Nice'],
    achievements: ['Swimming Champion', 'Wellness Leader'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['afternoon', 'evening'],
    age: 34,
    industry: 'Banking',
    gender: 'female',
    isConnected: false,
    joinDate: '2024-02-15',
    linkedinUrl: 'https://linkedin.com/in/sophielaurent',
    facebookUrl: 'https://facebook.com/sophielaurent',
    instagramUrl: 'https://instagram.com/sophielaurent',
    invitedBy: {
      id: '52',
      name: 'James Wilson',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 4600,
      training: 3400,
      connections: 11800,
      cities: 1300,
      events: 2400,
      achievements: 3600,
      total: 27100
    }
  },
  {
    id: '65',
    name: 'Richard Thompson',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80',
    city: 'London',
    sports: ['Golf', 'Tennis', 'Running'],
    jobTitle: 'Managing Director',
    company: 'Barclays',
    connections: 298,
    matchesPlayed: 89,
    citiesPlayed: ['London', 'Edinburgh', 'Dubai', 'Singapore'],
    achievements: ['Golf Tournament Winner', 'Corporate League Champion'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['morning', 'afternoon'],
    age: 47,
    industry: 'Banking',
    gender: 'male',
    isConnected: true,
    joinDate: '2024-01-05',
    linkedinUrl: 'https://linkedin.com/in/richardthompson',
    facebookUrl: 'https://facebook.com/richardthompson',
    instagramUrl: 'https://instagram.com/richardthompson',
    invitedBy: {
      id: '55',
      name: 'Uri Schneider',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 5900,
      training: 4300,
      connections: 14500,
      cities: 1700,
      events: 3000,
      achievements: 4600,
      total: 34000
    }
  },
  {
    id: '66',
    name: 'Maria Silva',
    image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?auto=format&fit=crop&w=300&q=80',
    city: 'Madrid',
    sports: ['Tennis', 'Yoga', 'Swimming'],
    jobTitle: 'Investment Manager',
    company: 'BBVA',
    connections: 212,
    matchesPlayed: 58,
    citiesPlayed: ['Madrid', 'Barcelona', 'Valencia', 'Lisbon'],
    achievements: ['Tennis Elite', 'Wellness Ambassador'],
    rating: 4.7,
    skillLevel: 'Advanced',
    availability: ['morning', 'evening'],
    age: 31,
    industry: 'Banking',
    gender: 'female',
    isConnected: false,
    joinDate: '2024-03-01',
    linkedinUrl: 'https://linkedin.com/in/mariasilva',
    facebookUrl: 'https://facebook.com/mariasilva',
    instagramUrl: 'https://instagram.com/mariasilva',
    invitedBy: {
      id: '62',
      name: 'Isabella Martinez',
      image: 'https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 4400,
      training: 3300,
      connections: 11200,
      cities: 1200,
      events: 2300,
      achievements: 3400,
      total: 25800
    }
  },
  {
    id: '67',
    name: 'Daniel Kim',
    image: 'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?auto=format&fit=crop&w=300&q=80',
    city: 'Singapore',
    sports: ['Golf', 'Tennis', 'Swimming'],
    jobTitle: 'Managing Director',
    company: 'DBS Bank',
    connections: 256,
    matchesPlayed: 71,
    citiesPlayed: ['Singapore', 'Hong Kong', 'Seoul', 'Tokyo'],
    achievements: ['Golf Tournament Champion', 'Corporate League Winner'],
    rating: 4.8,
    skillLevel: 'Professional',
    availability: ['morning', 'afternoon'],
    age: 41,
    industry: 'Banking',
    gender: 'male',
    isConnected: true,
    joinDate: '2024-01-20',
    linkedinUrl: 'https://linkedin.com/in/danielkim',
    facebookUrl: 'https://facebook.com/danielkim',
    instagramUrl: 'https://instagram.com/danielkim',
    invitedBy: {
      id: '61',
      name: 'Marcus Chen',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80'
    },
    points: {
      matches: 5100,
      training: 3800,
      connections: 12900,
      cities: 1400,
      events: 2600,
      achievements: 4000,
      total: 29800
    }
  }
];

// Add trainer profiles to members array with enhanced points
const trainerMembers = trainers.map(trainer => ({
  id: `trainer-${trainer.id}`,
  name: trainer.name,
  image: trainer.image,
  city: trainer.city,
  sports: trainer.sports,
  jobTitle: 'Professional Trainer',
  company: 'Elite Sports Academy',
  connections: Math.floor(Math.random() * (500 - 200 + 1)) + 200,
  matchesPlayed: Math.floor(Math.random() * 200) + 100,
  citiesPlayed: [trainer.city, 'Monte Carlo', 'London', 'Paris', 'Dubai', 'Singapore', 'New York'].slice(0, Math.floor(Math.random() * 4) + 4),
  achievements: [...trainer.achievements, 'Elite Trainer Status', 'Global Mentor', 'Performance Excellence'],
  rating: trainer.rating,
  skillLevel: 'Professional',
  availability: trainer.availability,
  age: Math.floor(Math.random() * 15) + 30,
  industry: 'Sports & Fitness',
  gender: trainer.gender || 'rather_not_say',
  isTrainer: true,
  isConnected: false,
  joinDate: '2024-10-01',
  linkedinUrl: `https://linkedin.com/in/${trainer.name.toLowerCase().replace(/\s+/g, '')}`,
  facebookUrl: `https://facebook.com/${trainer.name.toLowerCase().replace(/\s+/g, '')}`,
  instagramUrl: `https://instagram.com/${trainer.name.toLowerCase().replace(/\s+/g, '')}`,
  invitedBy: {
    id: '55',
    name: 'Uri Schneider',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=300&q=80'
  },
  trainerInfo: {
    yearsExperience: trainer.yearsExperience,
    priceRange: trainer.priceRange,
    specialties: trainer.specialties,
    certifications: trainer.certifications,
    languages: trainer.languages,
    reviews: trainer.reviews
  },
  points: {
    matches: 7800,
    training: 5200,
    connections: 21200,
    cities: 2000,
    events: 3900,
    achievements: 6500,
    total: 46600
  }
}));

// Calculate total points for each member
const membersWithTotalPoints = [...regularMembers, ...trainerMembers].map(member => {
  const pointsWithoutTotal = { ...member.points };
  delete pointsWithoutTotal.total;
  return {
    ...member,
    points: {
      ...member.points,
      total: calculatePoints(pointsWithoutTotal)
    }
  };
});

export const members: Member[] = membersWithTotalPoints;