import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Progress } from '../ui/progress';
import { TOTAL_ONBOARDING_STEPS, ONBOARDING_STEPS } from './constants';
import { OnboardingData } from './types';
import { initialOnboardingData } from './constants/initial-data';
import WelcomeStep from './steps/WelcomeStep';
import ProfileStep from './steps/ProfileStep';
import SportsSelectionStep from './steps/SportsSelectionStep';
import PasswordStep from './steps/PasswordStep';
import CompletionStep from './steps/CompletionStep';
import { signUpWithEmail } from '../../lib/supabase/auth';
import { cn } from '../../lib/utils';

interface OnboardingFlowProps {
  onComplete: () => void;
  inviteToken?: string;
}

export default function OnboardingFlow({ onComplete, inviteToken }: OnboardingFlowProps) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(ONBOARDING_STEPS.WELCOME);
  const [formData, setFormData] = useState<OnboardingData>(initialOnboardingData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isPasswordStepMounted, setIsPasswordStepMounted] = useState(false);
  const progress = (currentStep / TOTAL_ONBOARDING_STEPS) * 100;

  // Debug logging
  useEffect(() => {
    console.log('OnboardingFlow State:', {
      currentStep,
      formData,
      isSubmitting,
      submitError,
      progress,
      isPasswordStepMounted
    });
  }, [currentStep, formData, isSubmitting, submitError, progress, isPasswordStepMounted]);

  // Set password step mounted state
  useEffect(() => {
    setIsPasswordStepMounted(currentStep === ONBOARDING_STEPS.PASSWORD);
  }, [currentStep]);

  const handlePasswordSubmit = async (password: string) => {
    console.log('Handling password submit:', { password: '***' });
    setIsSubmitting(true);
    setSubmitError(null);
    
    try {
      await signUpWithEmail(formData.email, password, formData);
      console.log('Sign up successful, moving to completion step');
      setCurrentStep(ONBOARDING_STEPS.COMPLETION);
    } catch (err) {
      console.error('Sign up error:', err);
      setSubmitError(err instanceof Error ? err.message : 'Failed to create account');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = () => {
    console.log('Moving to next step:', currentStep + 1);
    console.log('Handling next step:', { currentStep, nextStep: currentStep + 1 });
    if (currentStep < TOTAL_ONBOARDING_STEPS) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate('/dashboard');
    }
  };

  const handleBack = () => {
    console.log('Handling back step:', { currentStep, prevStep: currentStep - 1 });
    console.log('Moving to previous step:', currentStep - 1);
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateFormData = (data: Partial<OnboardingData>) => {
    console.log('Updating form data:', data);
    console.log('Updating form data:', data);
    setFormData({ ...formData, ...data });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-dark-50 to-dark-100 overflow-hidden flex items-center justify-center p-2 sm:p-4">
      <div className="w-full max-w-3xl mx-auto max-h-[95vh] overflow-y-auto">
        {/* Progress bar - Only show for steps 2-5 */}
        {currentStep > 1 && (
          <div className="mb-6 sm:mb-8">
            <Progress 
              value={progress} 
              className="h-1.5 sm:h-2 md:h-3 bg-dark-300/50 rounded-full overflow-hidden"
            />
          </div>
        )}

        {/* Steps */}
        <motion.div
          key={currentStep}
          className={cn(
            "flex flex-col relative",
            "min-h-[450px] sm:min-h-[500px] w-full",
            "bg-dark-200/95 rounded-xl border border-dark-300 shadow-xl",
            "p-4 sm:p-6 md:p-8",
            "overflow-hidden",
            "shadow-xl shadow-dark-900/10"
          )}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ 
            duration: 0.3,
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          {currentStep === ONBOARDING_STEPS.WELCOME && (
            <WelcomeStep onNext={handleNext} />
          )}

          {currentStep === ONBOARDING_STEPS.PROFILE && (
            <ProfileStep
              data={formData}
              onUpdate={updateFormData}
              onNext={handleNext}
              onBack={handleBack}
            />
          )}

          {currentStep === ONBOARDING_STEPS.SPORTS && (
            <SportsSelectionStep
              data={formData}
              onUpdate={updateFormData}
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
      
          {currentStep === ONBOARDING_STEPS.PASSWORD && (
            <div className="flex-1">
              <PasswordStep
                isSubmitting={isSubmitting}
                error={submitError}
                onNext={handlePasswordSubmit}
                onBack={handleBack}
              />
            </div>
          )}

          {currentStep === ONBOARDING_STEPS.COMPLETION && (
            <CompletionStep onComplete={onComplete} />
          )}
        </motion.div>
      </div>
    </div>
  );
}