import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import ProfileSection from '../components/profile/ProfileSection';

interface LocationState {
  activeTab?: string;
}

const Profile: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [activeTab, setActiveTab] = useState(state?.activeTab || 'posts');

  // Update active tab when location state changes
  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [state?.activeTab]);

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <ProfileSection defaultTab={activeTab} />
      </div>
    </DashboardLayout>
  );
};

export default Profile;