import React, { useState } from 'react';
import { UserPlus, UserMinus, Clock } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { useConnection } from '../../hooks/useConnection';
import { useNotificationStore } from '../../store/notificationStore';

interface ConnectionButtonProps {
  isConnected: boolean;
  memberId: string;
  memberName: string;
  memberImage: string;
  onDisconnect: () => Promise<void>;
  className?: string;
}

const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  isConnected,
  memberId,
  memberName,
  memberImage,
  onConnect,
  onDisconnect,
  className
}) => {
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);
  const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);
  const { connect, error } = useConnection();
  const { addNotification } = useNotificationStore();

  const handleClick = async () => {
    console.log('Member connection button clicked:', {
      isConnected,
      memberId,
      memberName,
      isPending,
      isLoading
    });

    if (isConnected) {
      console.log('Showing disconnect confirmation');
      setShowDisconnectConfirm(true);
      return;
    }

    if (isPending) {
      console.log('Showing withdraw confirmation');
      setShowWithdrawConfirm(true);
      return;
    }

    setIsLoading(true);
    try {
      console.log('Initiating member connection request');
      if (!memberId || !memberName || !memberImage) {
        throw new Error('Missing required member information');
      }

      const success = await connect(memberId, memberName, memberImage);
      if (success) {
        console.log('Member connection request successful, setting pending state');
        setIsPending(true);
        addNotification({
          id: `connection_${Date.now()}`,
          type: 'connection_request',
          title: 'Connection Request Sent',
          message: `Your connection request has been sent to ${memberName}`,
          timestamp: new Date().toISOString(),
          read: false,
          actionUrl: '/dashboard/profile',
          metadata: {
            activeTab: 'network'
          }
        });
      }
    } catch (error) {
      console.error('Failed to connect:', error);
      console.log('Member connection request failed:', {
        error: error instanceof Error ? error.message : 'Unknown error',
        memberId,
        memberName
      });
      addNotification({
        id: `error_${Date.now()}`,
        type: 'new_message',
        title: 'Connection Error',
        message: error instanceof Error ? error.message : 'Failed to send connection request',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/network'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = async () => {
    console.log('Handling member disconnect request:', { memberId, memberName });
    setIsLoading(true);
    try {
      await onDisconnect();
      console.log('Member disconnect successful');
      setIsPending(false);
    } catch (error) {
      console.error('Failed to disconnect:', error);
      console.log('Member disconnect failed:', {
        error: error instanceof Error ? error.message : 'Unknown error',
        memberId
      });
    } finally {
      setIsLoading(false);
      setShowDisconnectConfirm(false);
    }
  };

  const handleWithdraw = async () => {
    console.log('Handling member withdraw request:', { memberId, memberName });
    setIsLoading(true);
    try {
      await onDisconnect();
      console.log('Member withdraw successful');
      setIsPending(false);
    } catch (error) {
      console.error('Failed to withdraw request:', error);
      console.log('Member withdraw failed:', {
        error: error instanceof Error ? error.message : 'Unknown error',
        memberId
      });
    } finally {
      setIsLoading(false);
      setShowWithdrawConfirm(false);
    }
  };

  if (showWithdrawConfirm) {
    return (
      <div className="flex items-center gap-2">
        <button
          onClick={handleWithdraw}
          disabled={isLoading}
          className={cn(
            "flex-1 xs:flex-initial flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors min-w-[100px]",
            isDarkMode
              ? "bg-red-500 hover:bg-red-600 text-white"
              : "bg-red-500 hover:bg-red-600 text-white",
            className
          )}
        >
          {isLoading ? (
            <span>Withdrawing...</span>
          ) : (
            <>
              <UserMinus className="h-4 w-4" />
              <span>Withdraw Request</span>
            </>
          )}
        </button>
        <button
          onClick={() => setShowWithdrawConfirm(false)}
          className={cn(
            "px-4 py-2 rounded-lg transition-colors",
            isDarkMode
              ? "bg-dark-300 hover:bg-dark-400 text-white"
              : "bg-gray-100 hover:bg-gray-200 text-gray-900"
          )}
        >
          Cancel
        </button>
      </div>
    );
  }

  if (showDisconnectConfirm) {
    return (
      <div className="flex items-center gap-2">
        <button
          onClick={handleDisconnect}
          disabled={isLoading}
          className={cn(
            "flex-1 xs:flex-initial flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors min-w-[100px]",
            isDarkMode
              ? "bg-red-500 hover:bg-red-600 text-white"
              : "bg-red-500 hover:bg-red-600 text-white",
            className
          )}
        >
          {isLoading ? (
            <span>Disconnecting...</span>
          ) : (
            <>
              <UserMinus className="h-4 w-4" />
              <span>Disconnect</span>
            </>
          )}
        </button>
        <button
          onClick={() => setShowDisconnectConfirm(false)}
          className={cn(
            "px-4 py-2 rounded-lg transition-colors",
            isDarkMode
              ? "bg-dark-300 hover:bg-dark-400 text-white"
              : "bg-gray-100 hover:bg-gray-200 text-gray-900"
          )}
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={handleClick}
      disabled={isLoading}
      className={cn(
        "flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors",
        isConnected
          ? isDarkMode
            ? "bg-dark-300 hover:bg-dark-400 text-white"
            : "bg-gray-100 hover:bg-gray-200 text-gray-900"
          : isPending
            ? isDarkMode
              ? "bg-dark-300 hover:bg-dark-400 text-white"
              : "bg-gray-100 hover:bg-gray-200 text-gray-900"
            : isDarkMode
              ? "bg-gold-400 hover:bg-gold-500 text-dark-50"
              : "bg-gold-400 hover:bg-gold-500 text-dark-50",
        className
      )}
    >
      {isLoading ? (
        <span>Connecting...</span>
      ) : isPending ? (
        <>
          <Clock className="h-4 w-4" />
          <span>Pending</span>
        </>
      ) : isConnected ? (
        <>
          <UserMinus className="h-4 w-4" />
          <span>Connected</span>
        </>
      ) : (
        <>
          <UserPlus className="h-4 w-4" />
          <span>Connect</span>
        </>
      )}
    </button>
  );
};

export default ConnectionButton;