import React from 'react';
import { MapPin } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface VenueHeaderProps {
  name: string;
  address: string;
  distance?: number;
  logo?: string;
}

export const VenueHeader: React.FC<VenueHeaderProps> = ({
  name,
  address,
  distance,
  logo
}) => {
  const { isDarkMode } = useTheme();

  return (
    <div className="mb-3">
      <div className="flex items-center gap-3 mb-2">
        {logo && (
          <div className={cn(
            "flex-shrink-0 w-8 h-8 rounded-lg overflow-hidden border",
            isDarkMode ? "border-dark-400" : "border-gray-200"
          )}>
            <img
              src={logo}
              alt={`${name} logo`}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <h3 className="text-lg font-medium text-white">
          {name}
        </h3>
      </div>

      {/* Address and Distance Row */}
      <div className="flex items-center justify-between">
        <div className="flex items-center text-xs text-dark-800 min-w-0">
          <MapPin className="h-3.5 w-3.5 flex-shrink-0 mr-1" />
          <span className="truncate">{address}</span>
        </div>

        {distance !== undefined && (
          <div className="text-xs text-dark-800 ml-3">
            {distance}km away
          </div>
        )}
      </div>
    </div>
  );
};