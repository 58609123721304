import React from 'react';
import { cn } from '@/lib/utils';

interface CountryFlagProps {
  countryCode: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ 
  countryCode, 
  size = 'md',
  className 
}) => {
  // Early return if no country code
  if (!countryCode) return null;

  const sizeClasses = {
    sm: 'w-5 h-3',
    md: 'w-6 h-4',
    lg: 'w-8 h-5'
  };

  return (
    <img
      src={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w80/${countryCode.toLowerCase()}.png 2x`}
      width="40"
      alt={`${countryCode} flag`}
      className={cn(
        'inline-block rounded-[1px] object-cover shadow-sm',
        sizeClasses[size],
        className
      )}
      loading="lazy"
    />
  );
};

export default CountryFlag;