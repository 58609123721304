import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MapPin, Loader2 } from 'lucide-react';
import { City } from '@/types/city';
import { searchCities } from '@/lib/services/geocoding';
import { cn } from '@/lib/utils';
import { useDebounce } from 'use-debounce';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxList } from './ComboboxList';
import { ComboboxItem } from './ComboboxItem';

interface CitySearchComboboxProps {
  value: string;
  onChange: (city: string) => void;
  placeholder?: string;
  className?: string;
  error?: string;
  country?: string;
}

export const CitySearchCombobox: React.FC<CitySearchComboboxProps> = ({
  value,
  onChange,
  placeholder = "Enter city...",
  className,
  error,
  country
}) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSearch = useCallback(async (query: string) => {
    if (!query.trim() || query.length < 2) {
      setSuggestions([]);
      setSearchError(null);
      return;
    }

    setIsLoading(true);
    setSearchError(null);

    try {
      const results = await searchCities(query);
      const filteredResults = country 
        ? results.filter(city => city.country?.toLowerCase() === country.toLowerCase())
        : results;
      
      setSuggestions(filteredResults);
      if (filteredResults.length === 0) {
        setSearchError('No cities found matching your search');
      }
    } catch (error) {
      console.error('Error searching cities:', error);
      setSearchError('Unable to search cities at this time');
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  }, [country]);

  useEffect(() => {
    handleSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    onChange(newValue);
    setIsOpen(true);
  };

  const handleSuggestionClick = (city: City) => {
    const displayName = [
      city.name,
      city.state,
      city.country
    ].filter(Boolean).join(', ');
    
    setSearchTerm(displayName);
    onChange(displayName);
    setIsOpen(false);
    setSearchError(null);
  };

  return (
    <div ref={containerRef} className={cn("relative", className)}>
      <ComboboxInput
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        placeholder={placeholder}
        leftIcon={<MapPin className="h-5 w-5 text-dark-800" />}
        rightIcon={isLoading && <Loader2 className="h-4 w-4 text-dark-800 animate-spin" />}
        error={error || searchError}
      />

      {isOpen && suggestions.length > 0 && (
        <ComboboxList>
          {suggestions.map((city, index) => (
            <ComboboxItem
              key={`${city.name}-${index}`}
              onClick={() => handleSuggestionClick(city)}
              selected={searchTerm === city.name}
            >
              <MapPin className="h-4 w-4 text-dark-800 mr-2" />
              <div>
                <div className="text-white">{city.name}</div>
                {(city.state || city.country) && (
                  <div className="text-dark-800 text-sm">
                    {[city.state, city.country].filter(Boolean).join(', ')}
                  </div>
                )}
              </div>
            </ComboboxItem>
          ))}
        </ComboboxList>
      )}
    </div>
  );
};