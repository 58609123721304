import { Venue } from '../types/venue';

// Venue categories for better organization
const venueCategories = {
  TENNIS: 'Tennis',
  GOLF: 'Golf',
  SWIMMING: 'Swimming',
  FITNESS: 'Fitness',
  MULTI_SPORT: 'Multi-Sport'
} as const;

// Venue types for consistent classification
const venueTypes = {
  CLUB: 'Club',
  CENTER: 'Center',
  ACADEMY: 'Academy',
  RESORT: 'Resort',
  COMPLEX: 'Complex'
} as const;

export const venues: Venue[] = [
  // Monte Carlo
  {
    id: 'mc-tennis-1',
    name: 'Monte Carlo Country Club',
    type: venueTypes.CLUB,
    category: venueCategories.TENNIS,
    location: 'Monte Carlo',
    address: '155 Avenue Princess Grace',
    amenities: ['Pro Shop', 'Restaurant', 'Locker Rooms', 'Training Programs'],
    rating: 4.9,
    coordinates: { lat: 43.7496, lng: 7.4388 }
  },
  {
    id: 'mc-tennis-2',
    name: 'Monte Carlo Tennis Academy',
    type: venueTypes.ACADEMY,
    category: venueCategories.TENNIS,
    location: 'Monte Carlo',
    address: '23 Boulevard Albert 1er',
    amenities: ['Pro Coaching', 'Equipment Rental', 'Video Analysis'],
    rating: 4.8,
    coordinates: { lat: 43.7373, lng: 7.4205 }
  },
  
  // Paris
  {
    id: 'paris-tennis-1',
    name: 'Roland Garros Tennis Club',
    type: venueTypes.CLUB,
    category: venueCategories.TENNIS,
    location: 'Paris',
    address: '2 Avenue Gordon Bennett',
    amenities: ['Pro Courts', 'Museum', 'Restaurant', 'Shop'],
    rating: 4.9,
    coordinates: { lat: 48.8474, lng: 2.2523 }
  },
  {
    id: 'paris-tennis-2',
    name: 'Racing Club de France',
    type: venueTypes.CLUB,
    category: venueCategories.MULTI_SPORT,
    location: 'Paris',
    address: '154 Rue de Saussure',
    amenities: ['Tennis Courts', 'Pool', 'Fitness Center'],
    rating: 4.7,
    coordinates: { lat: 48.8891, lng: 2.3120 }
  },

  // London
  {
    id: 'london-tennis-1',
    name: 'Queen\'s Club',
    type: venueTypes.CLUB,
    category: venueCategories.TENNIS,
    location: 'London',
    address: 'Palliser Road, West Kensington',
    amenities: ['Pro Courts', 'Restaurant', 'Pro Shop'],
    rating: 4.9,
    coordinates: { lat: 51.4847, lng: -0.2134 }
  },
  {
    id: 'london-tennis-2',
    name: 'Hurlingham Club',
    type: venueTypes.CLUB,
    category: venueCategories.MULTI_SPORT,
    location: 'London',
    address: 'Ranelagh Gardens',
    amenities: ['Tennis Courts', 'Pool', 'Restaurant'],
    rating: 4.8,
    coordinates: { lat: 51.4686, lng: -0.2089 }
  },

  // Dubai
  {
    id: 'dubai-tennis-1',
    name: 'Dubai Tennis Stadium',
    type: venueTypes.COMPLEX,
    category: venueCategories.TENNIS,
    location: 'Dubai',
    address: 'Dubai Tennis Stadium, Garhoud',
    amenities: ['Pro Courts', 'Training Academy', 'Fitness Center'],
    rating: 4.8,
    coordinates: { lat: 25.2399, lng: 55.3392 }
  },
  {
    id: 'dubai-tennis-2',
    name: 'Jumeirah Beach Tennis Club',
    type: venueTypes.CLUB,
    category: venueCategories.TENNIS,
    location: 'Dubai',
    address: 'Jumeirah Beach Road',
    amenities: ['Beach Courts', 'Restaurant', 'Pro Shop'],
    rating: 4.7,
    coordinates: { lat: 25.2084, lng: 55.2405 }
  }
];