export const PHOTON_CONFIG = {
  API_URL: 'https://photon.komoot.io/api',
  SEARCH_LIMIT: 30,
  MIN_POPULATION: 1000,
  MIN_ADMIN_LEVEL: 2,
  MAX_ADMIN_LEVEL: 8
};

const CITY_TYPES = [
  'city',
  'town',
  'village',
  'district',
  'borough',
  'suburb',
  'quarter',
  'municipality'
] as const;

const OSM_TAGS = [
  'place:city',
  'place:town',
  'place:municipality',
  'place:village',
  'boundary:administrative',
  'place:capital',
  'place:county',
  'place:state',
  'place:region'
].join(',');

const ADMIN_LEVELS = {
  COUNTRY: '2',
  STATE: '4',
  REGION: '5',
  COUNTY: '6',
  CITY: '8'
} as const;