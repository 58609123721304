import React, { useState } from 'react';
import { Calendar } from 'lucide-react';
import { cn } from '@/lib/utils';
import { formatDateForDisplay } from '@/lib/utils/date';

interface DateSelectorProps {
  selectedDate: string;
  onDateChange: (date: string) => void;
  className?: string;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  selectedDate,
  onDateChange,
  className
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDateChange(e.target.value);
  };

  const minDate = new Date().toISOString().split('T')[0];
  const maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

  return (
    <div className={cn("space-y-2", className)}>
      <label className="block text-dark-800">Date</label>
      <div className="relative">
        <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800 z-10" />
        <div className="relative">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            min={minDate}
            max={maxDate}
            className={cn(
              "w-full bg-dark-300 border border-dark-400 rounded-xl",
              "h-10 pl-10 pr-4",
              "focus:outline-none focus:ring-2 focus:ring-gold-400",
              !isFocused && "text-transparent",
              "appearance-none", // Hide default date picker styling
              "[&::-webkit-calendar-picker-indicator]:opacity-0",
              "[&::-webkit-calendar-picker-indicator]:absolute",
              "[&::-webkit-calendar-picker-indicator]:inset-0",
              "[&::-webkit-calendar-picker-indicator]:w-full",
              "[&::-webkit-calendar-picker-indicator]:h-full",
              "[&::-webkit-calendar-picker-indicator]:cursor-pointer",
              className
            )}
            style={{ 
              // Force text alignment
              textAlign: 'left',
              paddingLeft: '2.5rem', // 40px, matching the pl-10 class
              lineHeight: '2.5rem' // 40px, matching input height
            }}
          />
          {!isFocused && (
            <div 
              className={cn(
                "absolute inset-0 flex items-center pointer-events-none",
                "text-white"
              )}
              style={{
                paddingLeft: '2.5rem', // Exactly match input padding
                paddingRight: '1rem'
              }}
            >
              {selectedDate ? formatDateForDisplay(selectedDate) : 'Select date'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateSelector;