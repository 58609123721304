import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/dialog';
import { FormData } from '../../../lib/types';
import { useFormSubmission } from '../../../hooks/useFormSubmission';
import { RequestAccessHeader } from './RequestAccessHeader';
import { RequestAccessBenefits } from './RequestAccessBenefits';
import { RequestAccessFields } from './RequestAccessFields';
import { RequestAccessSuccess } from './RequestAccessSuccess';
import { RequestAccessWelcome } from './RequestAccessWelcome';

interface RequestAccessFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_FORM_DATA: FormData = {
  fullName: '',
  gender: '',
  email: '',
  company: '',
  role: '',
  country: '',
  city: '',
  dateOfBirth: '',
};

export const RequestAccessForm: React.FC<RequestAccessFormProps> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState<'welcome' | 'form'>('welcome');
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [showSuccess, setShowSuccess] = useState(false);
  const { isSubmitting, submitError, validationErrors, handleSubmit } = useFormSubmission();

  const handleFieldBlur = (fieldName: string) => {
    if (showSuccess) return;
    setTouched(prev => ({
      ...prev,
      [fieldName]: true
    }));
  };

  const getFieldError = (fieldName: string) => {
    if (showSuccess || !touched[fieldName]) return null;
    return validationErrors[fieldName] || null;
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const allFields = Object.keys(formData).reduce((acc, key) => ({
      ...acc,
      [key]: true
    }), {});
    setTouched(allFields);

    const success = await handleSubmit(formData);
    if (success) {
      setShowSuccess(true);
      setFormData(INITIAL_FORM_DATA);
      setTouched({});
    }
  };

  const handleClose = () => {
    setShowSuccess(false);
    setStep('welcome');
    setFormData(INITIAL_FORM_DATA);
    setTouched({});
    onClose();
  };

  if (showSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle className="sr-only">Application Success</DialogTitle>
          </DialogHeader>
          <RequestAccessSuccess onClose={handleClose} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300 max-h-[95vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="sr-only">
            {step === 'welcome' ? 'Welcome to Playclub' : 'Apply to Playclub'}
          </DialogTitle>
        </DialogHeader>

        {step === 'welcome' ? (
          <RequestAccessWelcome onNext={() => setStep('form')} />
        ) : (
          <>
            <RequestAccessHeader />
            <RequestAccessBenefits />
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <RequestAccessFields
                formData={formData}
                setFormData={setFormData}
                handleFieldBlur={handleFieldBlur}
                getFieldError={getFieldError}
                isSubmitting={isSubmitting}
                submitError={submitError}
              />
            </form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};