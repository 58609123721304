// src/components/Footer.tsx
import React from 'react';
import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark-200 border-t border-dark-300">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          {/* Copyright Notice */}
          <div className="text-dark-800 text-sm">
            © {currentYear} Playclub™. All rights reserved.
          </div>

          {/* Links */}
          <div className="flex items-center space-x-6">
            <Link 
              to="/privacy" 
              className={cn(
                "text-dark-800 hover:text-gold-400",
                "transition-colors text-sm"
              )}
            >
              Privacy Policy
            </Link>
            
            <a 
              href="mailto:info@playclub.ai"
              className={cn(
                "text-dark-800 hover:text-gold-400",
                "transition-colors text-sm",
                "flex items-center"
              )}
            >
              <Mail className="h-4 w-4 mr-2" />
              Contact
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
