// Facility amenities with emojis
export const FACILITY_AMENITIES = {
  // Core facilities
  GYM: '🏋️‍♂️ Gym',
  FITNESS_CENTER: '💪 Fitness Center',
  POOL: '🏊‍♂️ Swimming Pool',
  COURTS: '🎾 Courts',
  STUDIO: '🧘‍♀️ Studio',
  
  // Services
  TRAINING: '👨‍🏫 Personal Training',
  CLASSES: '👥 Group Classes',
  LESSONS: '📚 Private Lessons',
  COACHING: '🎯 Professional Coaching',
  
  // Comfort amenities
  LOCKER_ROOMS: '🔐 Locker Rooms',
  SHOWERS: '🚿 Showers',
  SAUNA: '🧖‍♂️ Sauna',
  SPA: '💆‍♂️ Spa',
  
  // Equipment & retail
  EQUIPMENT_RENTAL: '🎽 Equipment Rental',
  PRO_SHOP: '🛍️ Pro Shop',
  RETAIL: '🏪 Sports Retail',
  
  // Additional amenities
  CAFE: '☕ Café',
  RESTAURANT: '🍽️ Restaurant',
  PARKING: '🅿️ Parking',
  WIFI: '📶 Wi-Fi',
  CHILDCARE: '👶 Childcare'
} as const;