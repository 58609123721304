import React from 'react';

interface TripCancelConfirmationProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const TripCancelConfirmation: React.FC<TripCancelConfirmationProps> = ({
  onConfirm,
  onCancel
}) => {
  return (
    <div className="mt-4 p-4 bg-dark-300 rounded-xl">
      <p className="text-white mb-4">Are you sure you want to cancel this trip?</p>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={onCancel}
          className="px-4 py-2 bg-dark-400 hover:bg-dark-500 text-white rounded-lg transition-colors"
        >
          Keep Trip
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors"
        >
          Cancel Trip
        </button>
      </div>
    </div>
  );
};

export default TripCancelConfirmation;