// Cache configuration
export const PLAYERS_CACHE_DURATION = 60 * 1000; // 1 minute cache

// Memory cache for players
export const playersCache = new Map<string, {
  data: any[];
  timestamp: number;
}>();

// Cache management functions
function getCachedPlayers(): any[] | null {
  const cached = playersCache.get('players');
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < PLAYERS_CACHE_DURATION) {
    return cached.data;
  }

  playersCache.delete('players');
  return null;
}

function setCachedPlayers(data: any[]) {
  playersCache.set('players', {
    data,
    timestamp: Date.now()
  });
}

function clearPlayersCache() {
  playersCache.clear();
}