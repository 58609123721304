import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import SpinningLogo from '../../ui/loading/SpinningLogo';

interface SearchingStateProps {
  onSearchComplete: () => void;
}

const SearchingState: React.FC<SearchingStateProps> = ({ onSearchComplete }) => {
  const { isDarkMode } = useTheme();
  
  useEffect(() => {
    // Trigger search complete after animation
    const timer = setTimeout(() => {
      onSearchComplete();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onSearchComplete]);

  return (
    <div className="flex flex-col items-center">
      {/* Logo and animation section */}
      <motion.div 
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative flex items-center justify-center h-48 w-40"
      >
        {/* Pulsing background circles */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className={cn(
            "absolute w-24 h-24 rounded-full opacity-20",
            isDarkMode ? "bg-gold-400" : "bg-gold-600",
            "animate-ping"
          )} />
          <div className={cn(
            "absolute w-28 h-28 rounded-full opacity-10",
            isDarkMode ? "bg-gold-400" : "bg-gold-600",
            "animate-ping",
            "animation-delay-200"
          )} />
        </div>

        {/* Spinning logo */}
        <div className="absolute inset-0 flex items-center justify-center">
          <SpinningLogo />
        </div>
      </motion.div>

      {/* Text content */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="mt-8 mb-8 text-center"
      >
        <h3 className={cn(
          "text-2xl font-semibold mb-3",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          Finding Players Near You
        </h3>
        
        <p className={cn(
          "text-lg mb-6",
          isDarkMode ? "text-dark-800" : "text-gray-500"
        )}>
          Searching for available players...
        </p>

        {/* Loading dots */}
        <div className="flex items-center justify-center space-x-2">
          {[0, 1, 2].map((i) => (
            <motion.div
              key={i}
              className={cn(
                "w-2 h-2 rounded-full",
                isDarkMode ? "bg-gold-400" : "bg-gold-600"
              )}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
                delay: i * 0.2
              }}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default SearchingState;