import React from 'react';
import { motion } from 'framer-motion';
import { Player } from '../../types/matchmaking';
import PlayerCard from './PlayerCard';

interface PlayerCardListProps {
  players: Player[];
  onMatchSelect?: (match: any) => void;
}

const PlayerCardList: React.FC<PlayerCardListProps> = ({ players, onMatchSelect }) => {
  return (
    <div className="space-y-4">
      {players.map((player, index) => (
        <motion.div
          key={player.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.3,
            delay: index * 0.1, // Stagger the animations
            ease: [0.4, 0, 0.2, 1] // Smooth easing
          }}
        >
          <PlayerCard
            player={player}
            sports={player.sports}
            dates={[new Date().toISOString().split('T')[0]]}
            timeSlots={['morning', 'afternoon', 'evening']}
            onMatchSelect={onMatchSelect}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default PlayerCardList;