import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import TrainingDashboard from '../components/training/TrainingDashboard';
import BlurOverlay from '../components/ui/BlurOverlay';

const Training: React.FC = () => {
  return (
    <DashboardLayout>
      <div className="relative min-h-[calc(100vh-8rem)]">
        {/* Scrollable content with blur */}
        <div className="absolute inset-0 overflow-y-auto">
          <div className="blur-sm pointer-events-none">
            <TrainingDashboard />
          </div>
        </div>

        {/* Centered overlay */}
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
          <div className="pointer-events-auto">
            <BlurOverlay 
              componentId="training"
              title="Elite Training Network"
              description="Connect with elite trainers worldwide. Available exclusively for Elite members."
              mode="contained"
              className="rounded-xl"
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Training;