import React, { useState } from 'react';
import { Switch } from '../../ui/switch';
import { Label } from '../../ui/label';

const NotificationSettings: React.FC = () => {
  const [settings, setSettings] = useState({
    email: {
      enabled: true
    },
    push: {
      enabled: true
    }
  });

  const handleToggle = (category: 'email' | 'push') => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        enabled: !prev[category].enabled
      }
    }));
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <h2 className="text-xl font-semibold text-white mb-6">Notification Settings</h2>

      {/* Email Notifications */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-white mb-4">Email Notifications</h3>
        <div className="flex items-center justify-between">
          <Label className="text-dark-800">
            Receive email notifications
          </Label>
          <Switch
            checked={settings.email.enabled}
            onCheckedChange={() => handleToggle('email')}
          />
        </div>
      </div>

      {/* Push Notifications */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-white mb-4">Push Notifications</h3>
        <div className="flex items-center justify-between">
          <Label className="text-dark-800">
            Receive push notifications
          </Label>
          <Switch
            checked={settings.push.enabled}
            onCheckedChange={() => handleToggle('push')}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;