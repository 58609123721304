import React, { useState, useEffect } from 'react';
import { Users, UserX, Clock } from 'lucide-react';
import ConnectionsTab from './network/ConnectionsTab';
import BlockedMembersTab from './network/BlockedMembersTab';
import PendingConnectionsList from './network/PendingConnectionsList';
import { useConnectionStore } from '../../../store/connectionStore';
import { useConnection } from '../../../hooks/useConnection';
import ConnectionSkeleton from './network/ConnectionSkeleton';
import { useAuth } from '../../../contexts/AuthContext';

interface NetworkTabProps {
  memberId?: string;
  defaultTab?: 'connections' | 'blocked' | 'pending';
}

const NetworkTab: React.FC<NetworkTabProps> = ({ memberId, defaultTab = 'connections' }) => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState<'connections' | 'blocked' | 'pending'>(defaultTab);
  const { 
    connections, 
    pendingRequests, 
    fetchConnections,
    isLoading,
    error 
  } = useConnectionStore();

  const { acceptRequest, declineRequest } = useConnection(); 

  const isOwnProfile = !memberId || memberId === user?.id;
  
  // Calculate counts
  const establishedConnectionsCount = connections.filter(c => c.status === 'accepted').length;
  const pendingRequestsCount = pendingRequests.length;
  
  // Fetch connections when tab is mounted or memberId changes
  useEffect(() => {
    fetchConnections(memberId);
  }, [memberId, fetchConnections]);

  const handleAccept = async (connectionId: string) => {
    const success = await acceptRequest(connectionId);
    if (success) {
      // Update local state instead of fetching
      const request = pendingRequests.find(r => r.id === connectionId);
      if (request) {
        useConnectionStore.setState(state => ({
          pendingRequests: state.pendingRequests.filter(r => r.id !== connectionId),
          connections: [...state.connections, { ...request, status: 'accepted' }]
        }));
      }
    }
  };

  const handleDecline = async (connectionId: string) => {
    const success = await declineRequest(connectionId);
    if (success) {
      // Update local state instead of fetching
      useConnectionStore.setState(state => ({
        pendingRequests: state.pendingRequests.filter(r => r.id !== connectionId)
      }));
    }
  };

  return (
    <div className="space-y-6">
      {/* Tab Navigation - Only show for own profile */}
      {isOwnProfile && (
        <div className="flex space-x-4 border-b border-dark-300">
          {activeTab === 'pending' ? (
            <button
              onClick={() => setActiveTab('connections')}
              className="flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 border-transparent text-dark-800 hover:text-white transition-colors"
            >
              <Users className="h-4 w-4" />
              <span>Back to Connections</span>
            </button>
          ) : (
            <>
          <button
            onClick={() => setActiveTab('connections')}
            className={`flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 transition-colors ${
              activeTab === 'connections'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            <Users className="h-4 w-4" />
            <span>Connections ({establishedConnectionsCount})</span>
          </button>
          <button
            onClick={() => setActiveTab('pending')}
            className={`flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 transition-colors ${
              activeTab === 'pending'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            <Clock className="h-4 w-4" />
            <span>Pending ({pendingRequestsCount})</span>
          </button>
          <button
            onClick={() => setActiveTab('blocked')}
            className={`flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 transition-colors ${
              activeTab === 'blocked'
                ? 'border-gold-400 text-gold-400'
                : 'border-transparent text-dark-800 hover:text-white'
            }`}
          >
            <UserX className="h-4 w-4" />
            <span className="hidden sm:inline">Blocked</span>
            <span className="sm:hidden">Blocked</span>
          </button>
            </>
          )}
        </div>
      )}

      {/* Tab Content */}
      {activeTab === 'connections' && (
        <ConnectionsTab 
          connections={connections}
          pendingRequests={pendingRequests}
          memberId={memberId}
          isLoading={isLoading}
          error={error}
          onAccept={handleAccept}
          onDecline={handleDecline}
        />
      )}
      
      {activeTab === 'pending' && (
        <PendingConnectionsList
          connections={pendingRequests}
          onAccept={handleAccept}
          onDecline={handleDecline}
          onMessageClick={(id, name) => {
            navigate('/dashboard/messages', { 
              state: { 
                activeChat: id,
                initialMessage: `Hi ${name}!`,
                showMobileList: false
              }
            });
          }}
        />
      )}
      
      {activeTab === 'blocked' && (
        <BlockedMembersTab />
      )}
    </div>
  );
};

export default NetworkTab;