import React from 'react';

interface ManageButtonProps {
  onClick: () => void;
}

const ManageButton: React.FC<ManageButtonProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="bg-dark-300 hover:bg-dark-400 text-white px-4 py-2 rounded-lg transition-colors"
  >
    Manage
  </button>
);

export default ManageButton;