import React, { useState, useMemo } from 'react';
import { ChevronDown } from 'lucide-react';
import { VenueApiResult } from '../../../../../types/venue';
import { VenueActivities } from './VenueActivities';
import { VenueDetails } from './VenueDetails';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface VenueContentProps {
  venue: VenueApiResult;
}

export const VenueContent: React.FC<VenueContentProps> = ({ venue }) => {
  const { isDarkMode } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const { shortDescription, hasMore } = useMemo(() => {
    if (!venue.description) return { shortDescription: '', hasMore: false };
    
    const description = String(venue.description || '');
    const sentences = description.split(/[.!?]+\s+/);
    const firstSentence = sentences[0];

    if (!firstSentence || firstSentence.length === 0) {
      // If no sentence ending found, take first 100 characters
      return {
        shortDescription: description.slice(0, 100) + '...',
        hasMore: description.length > 100
      };
    }

    return {
      shortDescription: firstSentence + '.',
      hasMore: description.length > firstSentence.length
    };
  }, [venue.description]);

  return (
    <div className="flex-1">
      <VenueActivities activities={venue.activities} />

      {/* Description */}
      {venue.description && (
        <div className={cn(
          "mb-4 p-4 rounded-xl transition-all duration-300",
          isDarkMode 
            ? "bg-dark-300/50 border border-dark-300/50" 
            : "bg-light-100 border border-light-300"
        )}>
          <h4 className={cn(
            "text-sm font-medium mb-2",
            isDarkMode ? "text-white" : "text-gray-900"
          )}>
            About {venue.name}
          </h4>
          <div className="space-y-2">
            <p className={cn(
              "text-sm leading-relaxed",
              isDarkMode ? "text-dark-800" : "text-gray-600"
            )}>
              {isExpanded ? (venue.description || '') : shortDescription}
            </p>
            
            {hasMore && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={cn(
                  "flex items-center space-x-1 text-xs transition-colors",
                  isDarkMode 
                    ? "text-gold-400 hover:text-gold-500" 
                    : "text-gold-600 hover:text-gold-700"
                )}
              >
                <span>{isExpanded ? 'Show less' : 'Read more'}</span>
                <ChevronDown className={cn(
                  "h-3 w-3 transition-transform duration-200",
                  isExpanded && "transform rotate-180"
                )} />
              </button>
            )}
          </div>
        </div>
      )}
      
      <VenueDetails venue={venue} />
    </div>
  );
};