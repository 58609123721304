import React from 'react';
import { ArrowLeft } from 'lucide-react';

interface BackButtonProps {
  onClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="md:hidden text-dark-800 hover:text-white p-2 -ml-2 transition-colors"
  >
    <ArrowLeft className="h-5 w-5" />
  </button>
);