// Validate URL format
export function isValidUrl(url: string | undefined): boolean {
  if (!url) return false;
  
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

// Clean URL string
function cleanUrl(url: string | undefined): string | undefined {
  if (!url || url === 'null' || url === 'undefined') {
    return undefined;
  }
  return url;
}