import React, { useMemo } from 'react';
import { Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { useTheme } from '../../../contexts/ThemeContext';
import { getRandomGenderEmoji } from '../../../lib/utils/emoji';

interface SportSelectionStepProps {
  selectedSport: string;
  onSportSelect: (emoji: string, name: string) => void;
  memberName: string;
  city: string;
}

const SportSelectionStep: React.FC<SportSelectionStepProps> = ({
  selectedSport,
  onSportSelect,
  memberName,
  city
}) => {
  const { isDarkMode } = useTheme();
  const firstName = memberName?.split(' ')?.[0] || 'Player';

  // Generate random emojis for available sports
  const availableSports = useMemo(() => [
    { emoji: getRandomGenderEmoji('run'), name: 'Run Together' },
    { emoji: getRandomGenderEmoji('walk'), name: 'Walk & Talk' },
    { emoji: getRandomGenderEmoji('wellness'), name: 'Wellness' }
  ], []); // Empty dependency array ensures emojis are randomized only on mount

  // Coming soon sports
  const comingSoonSports = [
    { emoji: '🏸', name: 'Padel' },
    { emoji: '🚴‍♂️', name: 'Cycling' },
    { emoji: '🎾', name: 'Tennis' },
    { emoji: '⛳', name: 'Golf' }
  ];

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className={cn(
          "text-xl font-semibold",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          What would you like to do with {firstName} in {city}?
        </h3>
      </div>

      {/* Available Sports */}
      <div className="grid grid-cols-3 gap-3">
        {availableSports.map(({ emoji, name }) => {
          const isSelected = selectedSport === `${emoji} ${name}`;
          return (
            <motion.button
              key={name}
              onClick={() => onSportSelect(emoji, name)}
              className={cn(
                "flex flex-col items-center p-6 rounded-xl transition-all duration-300",
                "transform hover:scale-105",
                isSelected
                  ? "bg-gold-400 text-dark-50"
                  : isDarkMode
                    ? "bg-dark-300 text-white hover:bg-dark-400"
                    : "bg-gray-100 text-gray-900 hover:bg-gray-200"
              )}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="text-4xl mb-3">{emoji}</span>
              <span className="text-base font-medium">{name}</span>
            </motion.button>
          );
        })}
      </div>

      {/* Coming Soon Sports */}
      <div className="space-y-3">
        <div className={cn(
          "text-sm font-medium px-2",
          isDarkMode ? "text-dark-800" : "text-gray-500"
        )}>
          Coming Soon
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
          {comingSoonSports.map(({ emoji, name }) => (
            <div
              key={name}
              className={cn(
                "flex flex-col items-center p-4 rounded-xl opacity-50 cursor-not-allowed",
                isDarkMode ? "bg-dark-300" : "bg-gray-100"
              )}
            >
              <span className="text-3xl mb-2 grayscale">{emoji}</span>
              <span className={cn(
                "text-sm font-medium",
                isDarkMode ? "text-dark-800" : "text-gray-500"
              )}>
                {name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SportSelectionStep;