import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { cn } from "@/lib/utils"
import { X } from "lucide-react"
import { useTheme } from "@/contexts/ThemeContext"

const Dialog = DialogPrimitive.Root
const DialogTrigger = DialogPrimitive.Trigger
const DialogPortal = DialogPrimitive.Portal
const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-black/80 backdrop-blur-sm",
      "data-[state=open]:animate-in data-[state=closed]:animate-out",
      "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

interface DialogContentProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  position?: 'center' | 'top' | 'bottom';
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, position = 'center', children, ...props }, ref) => { 
  const { isDarkMode } = useTheme();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 640);
  const [isLandscape, setIsLandscape] = React.useState(window.innerWidth > window.innerHeight);

  const containerClasses = cn(
    // Base styles
    "fixed z-50 gap-4",
    // Responsive sizing
    "w-[calc(100%-2rem)] max-h-[90vh]",
    "sm:w-auto sm:min-w-[425px]",
    "md:min-w-[600px]",
    "lg:min-w-[700px]",
    // Positioning
    "left-1/2 -translate-x-1/2",
    position === 'center' ? [
      "top-[50%] -translate-y-[50%]",
      "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
      "data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]",
      "data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]"
    ] : position === 'bottom' ? [
      "bottom-0",
      !isMobile && "sm:bottom-auto sm:top-[50%] sm:-translate-y-[50%]",
      "data-[state=closed]:slide-out-to-bottom",
      "data-[state=open]:slide-in-from-bottom"
    ] : [
      "top-4",
      "data-[state=closed]:slide-out-to-top-0",
      "data-[state=open]:slide-in-from-top-2"
    ],
    // Border and background
    "border shadow-lg duration-200",
    "overflow-y-auto overscroll-contain",
    "rounded-2xl",
    isDarkMode 
      ? "bg-dark-200/98 border-dark-300/90" 
      : "bg-white/98 border-gray-200/90",
    // Animation
    "data-[state=open]:animate-in data-[state=closed]:animate-out",
    "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
    // Mobile padding
    isMobile ? [
      "p-5",
      position === 'bottom' && "pb-safe pt-6",
      isLandscape && "max-h-[90vh]"
    ] : "p-6",
    className
  );

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
      setIsLandscape(window.innerWidth > window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <DialogPortal>
      <DialogOverlay className={cn(
        "backdrop-blur-[2px]",
        isMobile && "touch-none"
      )} />
      <DialogPrimitive.Content
        ref={ref}
        className={containerClasses}
        {...props}
      >
        {children}
        <DialogPrimitive.Close 
          className={cn(
            "absolute right-4 top-4 p-2 rounded-full opacity-70",
            "hover:opacity-100 focus:outline-none",
            "disabled:pointer-events-none",
            "transition-all duration-200",
            "active:scale-95",
            isDarkMode 
              ? "text-white hover:bg-dark-300" 
              : "text-gray-500 hover:bg-gray-100"
          )}
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPortal>
  )
})
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  return (
    <DialogPrimitive.Description
      ref={ref}
      className={cn(
        "text-sm",
        isDarkMode ? "text-dark-800" : "text-gray-500",
        className
      )}
      {...props}
    />
  );
})
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}