import React, { createContext, useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { AuthContextType } from './types';

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const ACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
  const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

  // Function to refresh session
  const refreshSession = useCallback(async () => {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (session) {
      setLastActivity(Date.now());
    }
  }, []);

  // Set up session refresh interval
  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(refreshSession, REFRESH_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, refreshSession]);

  // Handle activity-based session extension
  useEffect(() => {
    if (isAuthenticated) {
      const handleActivity = () => {
        const now = Date.now();
        if (now - lastActivity > ACTIVITY_TIMEOUT) {
          refreshSession();
        }
        setLastActivity(now);
      };

      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
      window.addEventListener('touchstart', handleActivity);
      window.addEventListener('scroll', handleActivity);
      window.addEventListener('click', handleActivity);
      window.addEventListener('focus', handleActivity);

      return () => {
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('keydown', handleActivity);
        window.removeEventListener('touchstart', handleActivity);
        window.removeEventListener('scroll', handleActivity);
        window.removeEventListener('click', handleActivity);
        window.removeEventListener('focus', handleActivity);
      };
    }
  }, [isAuthenticated, lastActivity, refreshSession]);

  const login = useCallback(async (email: string, password: string): Promise<{ success: boolean; error?: string }> => {
    try {
      setIsLoading(true);
      // Clear any existing session first
      await supabase.auth.signOut();
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) {
        console.error('Login error:', error);
        if (error.message?.toLowerCase().includes('invalid login credentials')) {
          return { success: false, error: 'Invalid email or password' };
        } else if (error.message?.toLowerCase().includes('network')) {
          return { success: false, error: 'Network error. Please check your connection.' };
        }
        throw error;
      }

      if (data.user) {
        setIsAuthenticated(true);
        setUser(data.user);
        return { success: true };
      }

      return { success: false, error: 'No user data returned' };
    } catch (error) {
      console.error('Login error:', error);
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Failed to login' 
      };
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      // Immediately update UI state
      setIsAuthenticated(false);
      setUser(null);

      // Then sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      // Clear any cached data
      localStorage.clear();
      sessionStorage.clear();
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, []);

  // Check for existing session on mount and handle refresh token
  React.useEffect(() => {
    const initAuth = async () => {
      setIsLoading(true);
      try {
        // Get initial session
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          if (error.message?.includes('refresh_token_not_found')) {
            // Clear invalid session
            await supabase.auth.signOut();
            setIsAuthenticated(false);
            setUser(null);
            return;
          }
          throw error;
        }

        // Set auth state based on session
        if (session) {
          setIsAuthenticated(true);
          setUser(session.user);
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        console.error('Session error:', error);
        // Handle network errors gracefully
        if (error instanceof Error && error.message.includes('Failed to fetch')) {
          console.log('Network error during session check - will retry on reconnect');
        }
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    initAuth();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setIsLoading(true);
      try {
        console.log('Auth state change:', event);
        switch (event) {
          case 'SIGNED_IN':
            setIsAuthenticated(true);
            setUser(session?.user || null);
            break;
          case 'SIGNED_OUT':
            setIsAuthenticated(false);
            setUser(null);
            break;
          case 'TOKEN_REFRESHED':
            if (session) {
              setIsAuthenticated(true);
              setUser(session.user);
            }
            break;
          case 'USER_UPDATED':
            setUser(session?.user || null);
            break;
          case 'USER_DELETED':
            setIsAuthenticated(false);
            setUser(null);
            await supabase.auth.signOut();
            break;
        }
      } catch (error) {
        console.error('Auth state change error:', error);
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    });


    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      user,
      isLoading,
      login, 
      logout 
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export { useAuth } from './hooks/useAuth';