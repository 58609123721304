import React, { useState } from 'react';
import { MessageCircle, Plus } from 'lucide-react';
import ConnectionButton from '../ConnectionButton';
import { useTheme } from '@/contexts/ThemeContext';
import { useConnection } from '../../../hooks/useConnection';
import { cn } from '@/lib/utils';

interface ActionButtonsProps {
  isConnected: boolean;
  memberId: string;
  memberName: string;
  memberImage: string;
  skillLevel: string;
  onDisconnect: () => Promise<void>;
  onMessage: () => void;
  onPlay: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isConnected,
  memberId,
  memberName,
  memberImage,
  skillLevel,
  onDisconnect,
  onMessage,
  onPlay
}) => {
  const { isDarkMode } = useTheme();
  const { connect, error } = useConnection();
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = async () => {
    console.log('Handling connect:', { memberId, memberName, memberImage });
    setIsConnecting(true);
    try {
      const success = await connect(memberId, memberName, memberImage);
      console.log('Connect result:', { success, error });
    } catch (err) {
      console.error('Connect error:', err);
    } finally {
      setIsConnecting(false);
    }
  };

  const buttonBaseClasses = "h-10 px-4 text-sm font-medium flex items-center justify-center space-x-2 rounded-lg transition-colors min-w-[100px]";

  return (
    <div className={cn(
      "flex flex-col xs:flex-row items-center gap-3 mt-4 pt-4",
      isDarkMode ? "border-t border-dark-300" : "border-t border-gray-200"
    )}>
      <div className="flex items-center gap-2 w-full xs:w-auto order-2 xs:order-1">
        <ConnectionButton
          isConnected={isConnected}
          memberId={memberId}
          memberName={memberName}
          memberImage={memberImage}
          onConnect={handleConnect}
          onDisconnect={onDisconnect}
          className={buttonBaseClasses}
        />
        <button 
          onClick={onPlay}
          className={`${buttonBaseClasses} bg-gold-400 hover:bg-gold-500 text-dark-50`}
        >
          <Plus className="h-4 w-4" />
          <span>Play</span>
        </button>
        <button 
          onClick={onMessage}
          className={cn(
            "h-10 w-10 flex items-center justify-center rounded-lg transition-colors flex-shrink-0",
            isDarkMode 
              ? "bg-dark-300 hover:bg-dark-400 text-white" 
              : "bg-gray-100 hover:bg-gray-200 text-gray-700"
          )}
        >
          <MessageCircle className="h-5 w-5" />
        </button>
      </div>
      <div className={cn(
        "flex items-center order-1 xs:order-2 xs:ml-auto",
        isDarkMode ? "text-dark-800" : "text-gray-500"
      )}>
        <span className="text-sm">{skillLevel}</span>
      </div>
    </div>
  );
};

export default ActionButtons;