import { FormData } from './types';

export interface ValidationErrors {
  fullName?: string;
  email?: string;
  company?: string;
  role?: string;
  country?: string;
  dateOfBirth?: string;
}

export function validateFormData(data: FormData): ValidationErrors {
  const errors: ValidationErrors = {};
  console.log('Validating form data:', data);

  if (!data.fullName.trim()) {
    errors.fullName = 'Full Name is required';
  }

  if (!data.email.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = 'Please enter a valid email address';
  }

  console.log('Validation errors:', errors);
  return errors;
}