import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Navigation2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import AddressSearch from './AddressSearch';
import { cn } from '@/lib/utils';

interface LocationPopoverProps {
  address: string;
  onAddressChange: (address: string) => void;
  onLocationSelect: (location: { lat: number; lng: number }) => void;
  isLoading?: boolean;
  error?: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const LocationPopover: React.FC<LocationPopoverProps> = ({
  address,
  onAddressChange,
  onLocationSelect,
  isLoading,
  error,
  isOpen,
  onOpenChange
}) => {
  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className={cn(
            "dark:border-dark-400 border-light-300",
            "hover:bg-dark-400/10 transition-colors",
            isOpen && "bg-dark-400/20"
          )}
          title="Set location"
        >
          <Navigation2 className="h-4 w-4 text-gold-400" />
        </Button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-80 p-4 bg-dark-200 border-dark-300" 
        align="end"
        sideOffset={8}
      >
        <div className="space-y-2">
          <label className="text-sm text-dark-800 block">
            Distance reference point:
          </label>
          <AddressSearch
            value={address}
            onChange={onAddressChange}
            onLocationSelect={onLocationSelect}
            isLoading={isLoading}
            error={error}
            placeholder="Enter address..."
            className="w-full"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};