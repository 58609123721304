import { create } from 'zustand';
import { Connection } from '../types/connection';
import { supabase } from '../lib/supabase';
import { devtools } from 'zustand/middleware';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

interface ConnectionState {
  connections: Connection[];
  pendingRequests: Connection[];
  isLoading: boolean;
  error: string | null;
  lastFetched: number | null;
  fetchConnections: (memberId?: string) => Promise<void>;
  addConnection: (connection: Connection) => void;
  removeConnection: (connectionId: string) => void;
  updateConnectionStatus: (connectionId: string, status: 'accepted' | 'declined') => void;
}

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export const useConnectionStore = create<ConnectionState>()(
  devtools(
    (set, get) => ({
      connections: [],
      pendingRequests: [],
      isLoading: false,
      error: null,
      lastFetched: null,

      fetchConnections: async (memberId?: string) => {
        const CACHE_DURATION = 30000; // 30 seconds
        const DEBUG = process.env.NODE_ENV === 'development';
        const cacheKey = memberId ? `connections-${memberId}` : 'my-connections';

        // Check if we've fetched recently (within last 30 seconds)
        const now = Date.now();
        const lastFetched = get().lastFetched;
        // Only use cache for own profile
        if (!memberId && lastFetched && now - lastFetched < CACHE_DURATION) {
          if (DEBUG) {
            console.log('Using cached connections data', {
              age: Math.round((now - lastFetched) / 1000) + 's',
              connections: get().connections.length,
              pending: get().pendingRequests.length,
              cacheExpiry: new Date(lastFetched + CACHE_DURATION).toLocaleString()
            });
          }
          return;
        }

        set({ isLoading: true, error: null });
        
        try {
          // Get current user with retry
          const { data: { user }, error: userError } = await fetchWithRetry(() => 
            supabase.auth.getUser()
          );

          if (userError) throw userError;
          if (!user) throw new Error('Not authenticated');

          // Get target user's database ID (either current user or specified member)
          const { data: userData, error: userDataError } = await fetchWithRetry(() =>
            supabase
              .from('users')
              .select('id')
              .eq('auth_id', memberId || user.id)
              .single()
          );

          if (userDataError) throw userDataError;
          if (!userData) throw new Error('Member not found');

          // Fetch connections with a single query
          const { data, error } = await fetchWithRetry(() =>
            supabase.from('connections').select(`
              *,
              initiator:initiator_id(
                id, 
                auth_id, 
                full_name, 
                avatar_url,
                city,
                skill_level,
                created_at,
                user_profiles (
                  bio,
                  preferences
                ),
                user_sports (
                  sports (
                    name,
                    emoji
                  )
                )
              ),
              receiver:receiver_id(
                id, 
                auth_id, 
                full_name, 
                avatar_url,
                city,
                skill_level,
                created_at,
                user_profiles (
                  bio,
                  preferences
                ),
                user_sports (
                  sports (
                    name,
                    emoji
                  )
                )
              )
            `)
            .or(`initiator_id.eq.${userData.id},receiver_id.eq.${userData.id}`)
            .order('created_at', { ascending: false })
          );

          if (error) throw error;
          if (!data) throw new Error('No data returned from database');
          
          // Log connection data in development
          if (DEBUG) {
            console.log('Connection request results:', {
              requestTime: new Date().toISOString(),
              userId: userData.id,
              isSelf: !memberId || memberId === user.id,
              total: data.length,
              rawData: data,
              detailedSummary: data.map(c => ({
                id: c.id,
                status: c.status,
                isInitiator: c.initiator_id === userData.id,
                relationship: c.initiator_id === userData.id ? 'initiator' : 'receiver',
                initiator: {
                  id: c.initiator.id,
                  name: c.initiator.full_name,
                  city: c.initiator.city,
                  skillLevel: c.initiator.skill_level,
                  sports: c.initiator.user_sports?.map(us => 
                    `${us.sports.emoji} ${us.sports.name}`
                  ),
                  preferences: c.initiator.user_profiles?.[0]?.preferences
                },
                receiver: {
                  id: c.receiver.id,
                  name: c.receiver.full_name,
                  city: c.receiver.city,
                  skillLevel: c.receiver.skill_level,
                  sports: c.receiver.user_sports?.map(us => 
                    `${us.sports.emoji} ${us.sports.name}`
                  ),
                  preferences: c.receiver.user_profiles?.[0]?.preferences
                },
                timestamps: {
                  created: new Date(c.created_at).toLocaleString(),
                  updated: new Date(c.updated_at).toLocaleString()
                }
              }))
            });
          }

          // Transform data once
          const transformedData = data.map(connection => {
            const isInitiator = connection.initiator.id === userData.id;
            const otherUser = isInitiator ? connection.receiver : connection.initiator;
            
            // Filter out base64 avatar URLs
            const cleanAvatarUrl = (url: string | null) => {
              if (!url) return null;
              if (url.startsWith('data:image')) return null;
              return url;
            };

            return {
              id: connection.id,
              status: connection.status,
              created_at: connection.created_at,
              isInitiator,
              relationship: isInitiator ? 'initiator' : 'receiver',
              otherUser: {
                id: otherUser.id,
                auth_id: otherUser.auth_id,
                full_name: otherUser.full_name,
                avatar_url: cleanAvatarUrl(otherUser.avatar_url),
                city: otherUser.city,
                skillLevel: otherUser.skill_level,
                sports: otherUser.user_sports?.map(us => 
                  `${us.sports.emoji} ${us.sports.name}`
                ),
                preferences: otherUser.user_profiles?.[0]?.preferences
              },
              initiator: {
                id: connection.initiator.id,
                auth_id: connection.initiator.auth_id,
                full_name: connection.initiator.full_name,
                avatar_url: cleanAvatarUrl(connection.initiator.avatar_url),
                city: connection.initiator.city
              },
              receiver: {
                id: connection.receiver.id,
                auth_id: connection.receiver.auth_id,
                full_name: connection.receiver.full_name,
                avatar_url: cleanAvatarUrl(connection.receiver.avatar_url),
                city: connection.receiver.city
              }
            };
          });

          const connections = transformedData.filter(c => c.status === 'accepted');
          const pendingRequests = transformedData.filter(c => c.status === 'pending');

          // Log transformed data in development
          if (DEBUG) {
            console.log('Processed connections:', {
              accepted: connections.length,
              pending: pendingRequests.length,
              memberId: memberId || 'self',
              currentUserId: userData.id,
              relationships: {
                initiatedByMe: transformedData.filter(c => c.isInitiator).length,
                receivedByMe: transformedData.filter(c => !c.isInitiator).length
              },
              timestamp: new Date().toISOString(),
              cacheExpiry: new Date(now + CACHE_DURATION).toLocaleString()
            });
          }

          set({ 
            connections, 
            pendingRequests,
            isLoading: false,
            lastFetched: memberId ? null : now, // Only cache lastFetched for own profile
            error: null
          }, false, 'fetchConnections/success');
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Failed to load connections',
            isLoading: false 
          });
        }
      },

      addConnection: (connection) => {
        set((state) => ({
          connections: [connection, ...state.connections]
        }));
      },

      removeConnection: (connectionId) => {
        set((state) => ({
          connections: state.connections.filter(c => c.id !== connectionId),
          pendingRequests: state.pendingRequests.filter(c => c.id !== connectionId),
          lastFetched: null // Force refresh on next fetch
        }));
      },

      updateConnectionStatus: (connectionId, status) => {
        set((state) => {
          const pendingRequest = state.pendingRequests.find(c => c.id === connectionId);
          
          if (!pendingRequest) {
            return state;
          }

          const newPendingRequests = state.pendingRequests.filter(c => c.id !== connectionId);
          
          const newConnections = status === 'accepted' 
            ? [...state.connections, { 
                ...pendingRequest,
                status: 'accepted',
                isConnected: true 
              }]
            : state.connections;
          
          return {
            pendingRequests: newPendingRequests,
            connections: newConnections,
            lastFetched: null // Force refresh on next fetch
          };
        });
      }
    })
  )
);