import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext'; 
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ScrollToTop from './components/ScrollToTop';
import LandingPage from './pages/LandingPage';
import Features from './pages/Features';
import Dashboard from './pages/Dashboard';
import MatchMaking from './pages/MatchMaking';
import QuickPlay from './pages/QuickPlay';
import QuickPlayTransition from './pages/QuickPlayTransition';
import FacilityBooking from './pages/FacilityBooking';
import Training from './pages/Training';
import Events from './pages/Events';
import Messages from './pages/Messages';
import Profile from './pages/Profile';
import Activities from './pages/Activities';
import Leaderboard from './pages/Leaderboard';
import MemberProfile from './pages/MemberProfile';
import OnboardingFlow from './components/onboarding/OnboardingFlow';
import PrivateRoute from './components/PrivateRoute';
import Settings from './pages/Settings';
import Search from './pages/Search';
import Privacy from './pages/Privacy';

const AppRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth();

  // Show loading state while checking auth
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-dark-50">
        <div className="relative flex items-center justify-center h-48 w-40">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="absolute w-24 h-24 rounded-full opacity-20 bg-gold-400 animate-ping" />
            <div className="absolute w-28 h-28 rounded-full opacity-10 bg-gold-400 animate-ping animation-delay-200" />
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png"
              alt="Loading..."
              className="w-12 h-12 drop-shadow-lg animate-spin"
              style={{ animationDuration: '2s' }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <LandingPage />} />
      <Route path="/features" element={<Features />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/onboarding" element={<OnboardingFlow onComplete={() => {}} />} />

      {/* Protected routes */}
      <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/dashboard/search" element={<PrivateRoute><Search /></PrivateRoute>} />
      <Route path="/dashboard/match" element={<PrivateRoute><MatchMaking /></PrivateRoute>} />
      <Route path="/dashboard/quick-play" element={<PrivateRoute><QuickPlay /></PrivateRoute>} />
      <Route path="/dashboard/quick-play-transition" element={<PrivateRoute><QuickPlayTransition /></PrivateRoute>} />
      <Route path="/dashboard/facilities" element={<PrivateRoute><FacilityBooking /></PrivateRoute>} />
      <Route path="/dashboard/training" element={<PrivateRoute><Training /></PrivateRoute>} />
      <Route path="/dashboard/events" element={<PrivateRoute><Events /></PrivateRoute>} />
      <Route path="/dashboard/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
      <Route path="/dashboard/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/dashboard/activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
      <Route path="/dashboard/leaderboard" element={<PrivateRoute><Leaderboard /></PrivateRoute>} />
      <Route path="/dashboard/members/:id" element={<PrivateRoute><MemberProfile /></PrivateRoute>} />
      <Route path="/dashboard/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router basename="/">
          <ScrollToTop />
          <AppRoutes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}