export const GEOCODING_CONFIG = {
  photon: {
    baseUrl: 'https://photon.komoot.io',
    endpoints: {
      search: '/api/',
      reverse: '/reverse'
    },
    defaultLimit: 30,
    timeout: 5000,
    retries: 2
  },
  nominatim: {
    baseUrl: 'https://nominatim.openstreetmap.org',
    endpoints: {
      search: '/search',
      reverse: '/reverse'
    },
    defaultLimit: 10,
    timeout: 5000,
    retries: 1,
    userAgent: 'Playclub City Search'
  },
  common: {
    minQueryLength: 2,
    cacheExpiry: 5 * 60 * 1000, // 5 minutes
    minPopulation: 1000,
    maxResults: 50
  }
};

const CITY_TYPES = [
  'city',
  'town',
  'village',
  'municipality',
  'district',
  'borough',
  'suburb',
  'administrative'
];

const ADMIN_LEVELS = {
  COUNTRY: '2',
  STATE: '4',
  REGION: '6',
  CITY: '8'
};