import React from 'react';
import { LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { cn } from '@/lib/utils';
import { settingsTabs, type SettingsTab } from './SettingsPanel';

interface SettingsSidebarProps {
  activeTab: SettingsTab;
  onTabChange: (tab: SettingsTab) => void;
}

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({
  activeTab,
  onTabChange
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-4 border border-dark-300">
      <nav className="flex flex-col h-full">
        <div className="flex-1 space-y-1">
          {settingsTabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => onTabChange(tab.id as SettingsTab)}
              className={cn(
                "w-full flex items-center space-x-3 px-4 py-3 rounded-xl transition-colors",
                activeTab === tab.id
                  ? "bg-dark-300 text-gold-400"
                  : "text-dark-800 hover:bg-dark-300 hover:text-white"
              )}
            >
              <tab.icon className={cn(
                "h-5 w-5 flex-shrink-0",
                activeTab === tab.id ? "text-gold-400" : "text-current"
              )} />
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        <div className="pt-4 mt-4 border-t border-dark-300">
          <button
            onClick={handleLogout}
            className={cn(
              "w-full flex items-center space-x-3 px-4 py-3 rounded-xl",
              "text-red-400 hover:bg-dark-300 transition-colors"
            )}
          >
            <LogOut className="h-5 w-5 flex-shrink-0" />
            <span>Logout</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default SettingsSidebar;