import React from 'react';
import { cn } from '@/lib/utils';

interface MomentsSkeletonProps {
  count?: number;
  className?: string;
}

const MomentsSkeleton: React.FC<MomentsSkeletonProps> = ({
  count = 6,
  className
}) => {
  return (
    <div className={cn(
      "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4",
      className
    )}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="bg-dark-200 rounded-xl border border-dark-300 overflow-hidden animate-pulse flex flex-col h-[500px]"
        >
          {/* Author Info */}
          <div className="p-4 flex items-center space-x-3">
            <div className="w-10 h-10 rounded-full bg-dark-300" />
            <div className="flex-1">
              <div className="h-4 w-24 bg-dark-300 rounded mb-2" />
              <div className="h-3 w-20 bg-dark-300 rounded" />
            </div>
          </div>
          
          {/* Image Placeholder */}
          <div className="aspect-square w-full bg-dark-300 flex-shrink-0" />
          
          {/* Content */}
          <div className="p-4 flex flex-col flex-1">
            {/* Action Buttons */}
            <div className="flex items-center space-x-6 mb-4">
              {/* Like */}
              <div className="flex items-center space-x-2">
                <div className="h-6 w-6 bg-dark-300 rounded-full" />
                <div className="h-4 w-8 bg-dark-300 rounded" />
              </div>
              {/* Comment */}
              <div className="flex items-center space-x-2">
                <div className="h-6 w-6 bg-dark-300 rounded-full" />
                <div className="h-4 w-8 bg-dark-300 rounded" />
              </div>
              {/* Share */}
              <div className="h-6 w-6 bg-dark-300 rounded-full" />
            </div>

            {/* Content Placeholder */}
            <div className="space-y-2 flex-1">
              <div className="h-4 w-3/4 bg-dark-300 rounded" />
              <div className="h-4 w-1/2 bg-dark-300 rounded" />
            </div>

            {/* Timestamp */}
            <div className="h-3 w-24 bg-dark-300 rounded mt-4" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MomentsSkeleton;