import React from 'react';
import { UserMinus, AlertTriangle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../ui/dialog';
import { Button } from '../../ui/button';

interface BlockUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  username: string;
}

export const BlockUserModal: React.FC<BlockUserModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  username
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <div className="flex justify-center mb-4">
            <div className="bg-red-400/20 p-3 rounded-full">
              <AlertTriangle className="h-6 w-6 text-red-400" />
            </div>
          </div>
          <DialogTitle className="text-center">Block {username}?</DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <div className="text-dark-800">
            <div className="mb-2">This user will no longer be able to:</div>
            <ul className="space-y-1 list-disc list-inside">
              <li>Send you messages</li>
              <li>See your activity</li>
              <li>Send you match requests</li>
            </ul>
          </div>
        </div>

        <DialogFooter className="flex-col sm:flex-row gap-2">
          <Button
            variant="outline"
            onClick={onClose}
            className="flex-1 border-dark-400 text-white hover:bg-dark-400"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            className="flex-1 bg-red-500 hover:bg-red-600 text-white"
          >
            <UserMinus className="h-4 w-4 mr-2" />
            Block User
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};