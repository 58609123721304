import { create } from 'zustand';
import { BlockedMember } from '../types/member';

// Initial blocked members data
const initialBlockedMembers: BlockedMember[] = [
  {
    id: 'blocked-1',
    name: 'Thomas Weber',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80',
    blockedDate: '2024-02-15'
  },
  {
    id: 'blocked-2',
    name: 'Anna Lee',
    image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&w=300&q=80',
    blockedDate: '2024-02-10'
  }
];

interface BlockStore {
  blockedMembers: BlockedMember[];
  blockMember: (member: Omit<BlockedMember, 'blockedDate'>) => void;
  unblockMember: (memberId: string) => Promise<void>;
  isBlocked: (memberId: string) => boolean;
}

export const useBlockStore = create<BlockStore>((set, get) => ({
  // Initialize with sample blocked members
  blockedMembers: initialBlockedMembers,
  
  blockMember: (member) => 
    set((state) => ({
      blockedMembers: [
        ...state.blockedMembers,
        {
          ...member,
          blockedDate: new Date().toLocaleDateString()
        }
      ]
    })),
    
  unblockMember: async (memberId) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    set((state) => ({
      blockedMembers: state.blockedMembers.filter(member => member.id !== memberId)
    }));
  },
  
  isBlocked: (memberId) => {
    return get().blockedMembers.some(member => member.id === memberId);
  }
}));