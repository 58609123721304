import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ACTIVITY_SHORTCUTS } from '../../constants/activities';

interface ActivityTagsProps {
  selectedTags: string[];
  onTagSelect: (tag: string) => void;
  className?: string;
}

export const ActivityTags: React.FC<ActivityTagsProps> = ({
  selectedTags,
  onTagSelect,
  className
}) => {
  const [showAll, setShowAll] = useState(false);

  const visibleActivities = showAll 
    ? ACTIVITY_SHORTCUTS 
    : ACTIVITY_SHORTCUTS.slice(0, 6);

  return (
    <div className={cn("flex flex-wrap gap-2", className)}>
      {visibleActivities.map((activity) => (
        <button
          key={activity.name}
          onClick={() => onTagSelect(activity.name)}
          className={cn(
            "flex items-center space-x-2 px-3 py-1.5 rounded-full text-sm",
            "hover:shadow-md transform hover:scale-105 transition-all",
            selectedTags.includes(activity.name)
              ? "bg-gold-400 text-dark-50 hover:bg-gold-500"
              : "bg-dark-300 text-white hover:bg-dark-400"
          )}
        >
          <span>{activity.emoji}</span>
          <span>{activity.name}</span>
        </button>
      ))}
      
      <button
        onClick={() => setShowAll(!showAll)}
        className={cn(
          "flex items-center space-x-2 px-3 py-1.5 rounded-full text-sm",
          "bg-dark-300 text-white hover:bg-dark-400",
          "hover:shadow-md transform hover:scale-105 transition-all"
        )}
      >
        <span>{showAll ? 'Less' : 'More'}</span>
        <ChevronDown className={cn(
          "h-4 w-4 transition-transform",
          showAll && "rotate-180"
        )} />
      </button>
    </div>
  );
};