import React from 'react';
import { Upload, Play, Clock, Activity } from 'lucide-react';

const VideoAnalysisDashboard = () => {
  const analysisStats = [
    { label: 'Videos Analyzed', value: '24', icon: Play },
    { label: 'Hours Recorded', value: '12', icon: Clock },
    { label: 'Improvements', value: '15', icon: Activity }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="text-2xl font-bold text-white">Video Analysis</h1>
            <p className="text-dark-800 mt-1">AI-powered performance analysis</p>
          </div>
          <button className="flex items-center space-x-2 bg-gold-400 hover:bg-gold-500 text-dark-50 px-6 py-2 rounded-xl transition-colors">
            <Upload className="h-5 w-5" />
            <span>Upload Video</span>
          </button>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {analysisStats.map((stat) => (
          <div
            key={stat.label}
            className="bg-dark-200 rounded-xl p-6 border border-dark-300 hover:border-gold-400 transition-colors"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-dark-800">{stat.label}</p>
                <p className="text-2xl font-semibold text-white mt-1">{stat.value}</p>
              </div>
              <div className="bg-dark-300 p-3 rounded-lg">
                <stat.icon className="h-6 w-6 text-gold-400" />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Video Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {[1, 2].map((index) => (
          <div
            key={index}
            className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="relative group">
              <img
                src={`https://images.unsplash.com/photo-${index === 1 ? '1554068865-24cecd4e34b8' : '1587280501635-68a0e82cd5ff'}?auto=format&fit=crop&w=800&q=80`}
                alt={`Analysis ${index}`}
                className="w-full aspect-video object-cover"
              />
              <div className="absolute inset-0 bg-dark-900/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <button className="p-4 bg-gold-400 rounded-full transform scale-75 group-hover:scale-100 transition-transform">
                  <Play className="h-6 w-6 text-dark-50" />
                </button>
              </div>
              <span className="absolute bottom-2 right-2 px-2 py-1 bg-dark-900/75 text-white text-sm rounded">
                {index === 1 ? '4:32' : '3:45'}
              </span>
            </div>

            <div className="p-6">
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="text-white font-medium">
                    {index === 1 ? 'Serve Analysis' : 'Backhand Review'}
                  </h3>
                  <p className="text-dark-800 text-sm mt-1">
                    {index === 1 ? '2 days ago' : '1 week ago'}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                {['Technique', 'Power', 'Accuracy'].map((metric) => (
                  <div key={metric}>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-dark-800">{metric}</span>
                      <span className="text-white">{85 + Math.floor(Math.random() * 10)}%</span>
                    </div>
                    <div className="w-full h-2 bg-dark-400 rounded-full">
                      <div
                        className="h-2 bg-gold-400 rounded-full"
                        style={{ width: `${85 + Math.floor(Math.random() * 10)}%` }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoAnalysisDashboard;