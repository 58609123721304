import React from 'react';
import { DialogTitle } from '../../ui/dialog';

export const RequestAccessHeader = () => (
  <>
    <div className="flex justify-center mb-4">
      <div className="bg-gold-400/20 p-3 rounded-full">
        <img
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/lines_gold-removebg-k1iVBout4rs8n3KtWk0IgssRYsSoTG.png"
          alt="Playclub Symbol"
          className="h-6 w-6"
        />
      </div>
    </div>
    <h2 className="text-2xl font-bold text-center mb-6">Apply to Playclub</h2>
  </>
);