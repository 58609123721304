import React, { useState, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ImagePlus, Send, X, Loader2, Image, Smile } from "lucide-react";
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { cn } from "@/lib/utils";
import { supabase } from '../../lib/supabase';
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from '@/contexts/ThemeContext';
import { useNotificationStore } from '../../store/notificationStore';
import { useEffect } from 'react';

interface Member {
  id: string;
  auth_id: string;
  full_name: string;
  avatar_url: string | null;
  city: string;
}

interface PostComposerProps {
  onPost: (content: string, image?: File) => Promise<void>;
  isSubmitting?: boolean;
  onCancel?: () => void;
  placeholder?: string | ((isSubmitting: boolean) => string);
}

export default function PostComposer({ 
  onPost,
  isSubmitting = false,
  onCancel,
  placeholder = (isSubmitting) => isSubmitting ? 
    ["✨ Crafting your post with magic...",
     "🌟 Adding a sprinkle of stardust...",
     "🎨 Painting your story...",
     "🎭 Setting the stage for your moment...",
     "🎪 Preparing your spotlight...",
     "🎬 Creating magic..."][Math.floor(Math.random() * 6)] : 
    "What's on your mind?"
}: PostComposerProps) {
  const [content, setContent] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const placeholders = [
    "✨ Crafting your post with magic...",
    "🌟 Adding a sprinkle of stardust...", 
    "🎨 Painting your story...",
    "🎭 Setting the stage for your moment...",
    "🎪 Preparing your spotlight...",
    "🎬 Creating magic..."
  ];

  // Rotate placeholders during submission
  useEffect(() => {
    if (isSubmitting) {
      const interval = setInterval(() => {
        setPlaceholderIndex(prev => (prev + 1) % placeholders.length);
      }, 2000); // Change every 2 seconds
      return () => clearInterval(interval);
    } else {
      setPlaceholderIndex(0);
    }
  }, [isSubmitting]);

  const currentPlaceholder = isSubmitting 
    ? placeholders[placeholderIndex]
    : "What's on your mind?";
  const [mentionSearch, setMentionSearch] = useState('');
  const [showMentions, setShowMentions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [members, setMembers] = useState<Member[]>([]);
  const { isDarkMode } = useTheme();
  const { addNotification } = useNotificationStore();

  // Retry configuration
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  const fetchWithRetry = async <T,>(
    operation: () => Promise<T>,
    retries = MAX_RETRIES
  ): Promise<T> => {
    try {
      return await operation();
    } catch (error) {
      if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
        console.log(`Network error, retrying... (${retries} attempts remaining)`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return fetchWithRetry(operation, retries - 1);
      }
      throw error;
    }
  };

  // Fetch members from Supabase
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const { data: { user } } = await fetchWithRetry(() => supabase.auth.getUser());
        if (!user) return;

        const { data, error } = await fetchWithRetry(() =>
          supabase
            .from('users')
            .select('id, auth_id, full_name, avatar_url, city')
            .neq('auth_id', user.id)
        );

        if (error) throw error;
        setMembers(data || []);
      } catch (error) {
        console.error('Error fetching members:', error);
        // Only show error notification for non-network errors
        if (!(error instanceof Error && error.message.includes('Failed to fetch'))) {
          addNotification({
            id: `error_${Date.now()}`,
            type: 'new_message',
            title: 'Error',
            message: 'Failed to load members. Some features may be limited.',
            timestamp: new Date().toISOString(),
            read: false,
            actionUrl: '/dashboard'
          });
        }
        // Return empty array to allow basic functionality
        setMembers([]);
      }
    };

    fetchMembers();
  }, []);
  // Filter members based on mention search
  const filteredMembers = React.useMemo(() => {
    if (!mentionSearch) return [];
    return members
      .filter(member => 
        member.full_name.toLowerCase().includes(mentionSearch.toLowerCase())
      )
      .slice(0, 5);
  }, [mentionSearch]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    const curPos = e.target.selectionStart;
    setCursorPosition(curPos);
    
    const lastAtSymbol = newContent.lastIndexOf('@', curPos);
    if (lastAtSymbol !== -1) {
      const textAfterAt = newContent.slice(lastAtSymbol + 1, curPos);
      const hasSpace = /\s/.test(textAfterAt);
      
      if (!hasSpace) {
        // Calculate @ symbol position
        const textBeforeAt = newContent.slice(0, lastAtSymbol);
        const lines = textBeforeAt.split('\n');
        const lineNumber = lines.length - 1;
        const lineStart = textBeforeAt.lastIndexOf('\n') + 1;
        
        // Create a temporary span to measure text width
        const span = document.createElement('span');
        span.style.font = window.getComputedStyle(textareaRef.current!).font;
        span.style.visibility = 'hidden';
        span.style.position = 'absolute';
        span.textContent = textBeforeAt.slice(lineStart);
        document.body.appendChild(span);
        
        const textWidth = span.offsetWidth;
        document.body.removeChild(span);
        
        // Calculate position relative to textarea
        const textareaRect = textareaRef.current!.getBoundingClientRect();
        const lineHeight = parseInt(window.getComputedStyle(textareaRef.current!).lineHeight);
        
        setMentionPosition({
          top: lineNumber * lineHeight,
          left: textWidth
        });

        setMentionSearch(textAfterAt);
        setShowMentions(true);
        return;
      }
    }
    
    setShowMentions(false);
    setMentionSearch('');
  };

  const insertMention = (member: Member) => {
    if (!textareaRef.current) return;
    
    const textBeforeCursor = content.slice(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf('@');
    const textAfterCursor = content.slice(cursorPosition);
    
    const newContent = 
      textBeforeCursor.slice(0, lastAtSymbol) + 
      `@${member.full_name} ` + 
      textAfterCursor;
    
    setContent(newContent);
    setShowMentions(false);
    setMentionSearch('');
    
    // Focus back on textarea
    textareaRef.current.focus();
  };

  const handleSubmit = async () => {
    if ((!content.trim() && !selectedImage) || isSubmitting) {
      return;
    }
    
    // Store content and image for submission
    const submissionContent = content;
    const submissionImage = selectedImage;
    
    // Immediately reset form and collapse
    setContent("");
    setIsExpanded(false);
    setSelectedImage(null);
    setImagePreview(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    try {
      // Extract mentions from content
      const mentions = submissionContent.match(/@([a-zA-Z\s]+)/g) || [];
      const mentionedMembers = mentions
        .map(mention => {
          const fullName = mention.slice(1).trim();
          return members.find(m => m.full_name === fullName);
        })
        .filter((m): m is Member => !!m);

      await onPost(submissionContent, submissionImage || undefined);
      
      // Send notifications to mentioned members
      mentionedMembers.forEach(member => {
        addNotification({
          id: `mention_${Date.now()}_${member.auth_id}`,
          type: 'mention',
          title: 'New Mention',
          message: `${member.full_name} mentioned you in a post`,
          timestamp: new Date().toISOString(),
          read: false,
          actionUrl: '/dashboard'
        });
      });
    } catch (error) {
      console.error('Failed to create post:', error);
      addNotification({
        id: `error_${Date.now()}`,
        type: 'new_message',
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to create post',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });
    }
  };

  const handleImageSelect = (file: File) => {
    // Validate file type
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      setError('Invalid file type. Please select a JPEG, PNG, or GIF image.');
      return;
    }

    // Validate file size (5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('File size exceeds 5MB limit.');
      return;
    }

    setSelectedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
    setIsExpanded(true);
    setError(null);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) {
      handleImageSelect(file);
    }
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    setContent(prev => prev + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div 
      className={cn(
        "bg-dark-200 rounded-xl p-4 border transition-all duration-300 relative overflow-hidden",
        isDragging ? "border-gold-400 shadow-lg" : showMentions ? "border-gold-400" : "border-dark-300",
        isExpanded && "shadow-lg"
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >

      <div className="relative">
        <Textarea
          ref={textareaRef}
          value={content}
          onChange={handleTextChange}
          placeholder={currentPlaceholder}
          disabled={isSubmitting}
          className={cn(
            "w-full resize-none bg-dark-300 border-dark-400 text-white",
            "placeholder:text-dark-800 focus:ring-1 focus:ring-gold-400",
            "placeholder:text-lg placeholder:transition-all placeholder:duration-500",
            "transition-all duration-300",
            isExpanded ? "h-32" : "h-16",
            isSubmitting && "opacity-50 cursor-not-allowed"
          )}
          onFocus={() => setIsExpanded(true)}
        />
        
        {/* Mention suggestions */}
        {showMentions && filteredMembers.length > 0 && (
          <div 
            className={cn(
              "fixed w-64 bg-dark-300 rounded-lg border border-dark-400 shadow-lg overflow-hidden z-[100]"
            )}
            style={{
              top: textareaRef.current 
                ? textareaRef.current.getBoundingClientRect().top + mentionPosition.top + window.scrollY
                : 0,
              left: textareaRef.current
                ? textareaRef.current.getBoundingClientRect().left + mentionPosition.left
                : 0
            }}
          >
            {filteredMembers.map((member) => (
              <button
                key={member.id}
                onClick={() => insertMention(member)}
                className={cn(
                  "w-full flex items-center space-x-3 p-3 transition-colors text-left",
                  "hover:bg-dark-400 focus:bg-dark-400 outline-none",
                  "border-l-2 border-transparent hover:border-gold-400"
                )}
              >
                <img
                  src={member.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'}
                  alt={member.full_name}
                  className="w-8 h-8 rounded-full object-cover"
                />
                <div>
                  <div className="text-white font-medium">{member.full_name}</div>
                  <div className="text-dark-800 text-sm">{member.city}</div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
      
      <AnimatePresence>
        {imagePreview && (
          <motion.div 
            key="image-preview"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="relative mt-4 rounded-lg overflow-hidden bg-dark-300"
          >
            <img
              src={imagePreview}
              alt="Preview"
              className="max-h-64 w-full object-cover rounded-lg"
            />
            <button
              onClick={() => {
                setSelectedImage(null);
                setImagePreview(null);
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}
              className="absolute top-2 right-2 p-1 bg-dark-900/80 rounded-full hover:bg-dark-900 transition-colors"
            >
              <X className="h-5 w-5 text-white" />
            </button>
          </motion.div>
        )}

        {error && (
          <motion.div
            key="error-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-4 text-red-400 text-sm bg-red-400/10 p-3 rounded-lg border border-red-400/20"
          >
            {error}
          </motion.div>
        )}

        {isExpanded && (
          <motion.div
            key="expanded-controls"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="flex items-center justify-between mt-4"
          >
            <div className="flex items-center gap-2">
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) handleImageSelect(file);
                }}
                className="hidden"
                id="post-image"
              />
              <label 
                htmlFor="post-image"
                className={cn(
                  "p-2 rounded-lg transition-colors cursor-pointer",
                  "hover:bg-dark-400 text-dark-800 hover:text-white",
                  "flex items-center gap-2"
                )}
              >
                <ImagePlus className="h-5 w-5" />
                <span className="text-sm">Add Photo</span>
              </label>
              <div className="relative">
                <button 
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className={cn(
                    "p-2 rounded-lg transition-colors",
                    "hover:bg-dark-400 text-dark-800 hover:text-white",
                    showEmojiPicker && "bg-dark-400 text-white"
                  )}
                >
                  <Smile className="h-5 w-5" />
                </button>
                {showEmojiPicker && (
                  <div className="absolute bottom-12 left-0 z-50">
                    <EmojiPicker
                      onEmojiClick={onEmojiClick}
                      autoFocusSearch={false}
                      theme={isDarkMode ? Theme.DARK : Theme.LIGHT}
                      searchPlaceHolder="Search emoji..."
                      width={300}
                      height={400}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-2">
              {onCancel && (
                <Button
                  type="button"
                  onClick={onCancel}
                  variant="outline"
                  className="border-dark-400"
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={handleSubmit}
                disabled={(!content.trim() && !selectedImage) || isSubmitting}
                className={cn(
                  "min-w-[100px]",
                  "bg-gold-400 hover:bg-gold-500",
                  "text-dark-50 font-medium",
                  "flex items-center justify-center gap-2",
                  "px-4 py-2 rounded-lg",
                  "transform hover:scale-[1.02] active:scale-[0.98]",
                  "transition-all duration-200",
                  "focus:outline-none focus:ring-2 focus:ring-gold-400 focus:ring-offset-2 focus:ring-offset-dark-200",
                  "disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none",
                  "disabled:hover:scale-100 disabled:bg-gold-400/50",
                  isSubmitting && "pointer-events-none"
                )}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    <span>Posting...</span>
                  </>
                ) : (
                  <>
                    <Send className="h-4 w-4 mr-2" />
                    <span>Post</span>
                  </>
                )}
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}