import React from 'react';
import { Plus, MessageCircle } from 'lucide-react';
import ConnectionButton from '../ConnectionButton';

interface ProfileActionsProps {
  member: {
    id: string;
    name: string;
    image: string;
    isConnected?: boolean;
  };
  onConnect: () => Promise<void>;
  onDisconnect: () => Promise<void>;
  onRequestMatch: () => void;
  onMessage: () => void;
}

const ProfileActions: React.FC<ProfileActionsProps> = ({
  member,
  onConnect,
  onDisconnect,
  onRequestMatch,
  onMessage
}) => {
  const buttonBaseClasses = "h-10 px-4 text-sm font-medium flex items-center justify-center space-x-2 rounded-xl transition-colors";

  return (
    <div className="flex flex-wrap items-center gap-2">
      {/* Primary Actions */}
      <div className="flex items-center gap-2">
        <ConnectionButton
          isConnected={member.isConnected || false}
          memberId={member.id}
          memberName={member.name}
          memberImage={member.image}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          className={buttonBaseClasses}
        />
        <button 
          onClick={onRequestMatch}
          className={`${buttonBaseClasses} bg-gold-400 hover:bg-gold-500 text-dark-50`}
        >
          <Plus className="h-4 w-4" />
          <span>Play</span>
        </button>
        <button 
          onClick={onMessage}
          className={`${buttonBaseClasses} bg-dark-300 hover:bg-dark-400 text-white`}
        >
          <MessageCircle className="h-4 w-4" />
          <span>Message</span>
        </button>
      </div>
    </div>
  );
};

export default ProfileActions;