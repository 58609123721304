import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { Notification } from '../types/notification';
import { 
  getNotifications, 
  markAsRead, 
  markAllAsRead as markAllAsReadInDb,
  subscribeToNotifications 
} from '../lib/supabase/notifications';

interface NotificationStore {
  notifications: Notification[];
  unreadCount: number;
  isLoading: boolean;
  error: string | null;
  addNotification: (notification: Notification) => void;
  markAsRead: (id: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  removeNotification: (id: string) => void;
  fetchNotifications: () => Promise<void>;
  initializeSubscription: () => () => void;
}

export const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  isLoading: false,
  error: null,

  addNotification: (notification) =>
    set((state) => {
      const notifications = [notification, ...state.notifications];
      const unreadCount = notifications.filter(n => !n.read).length;
      return { notifications, unreadCount };
    }),

  markAsRead: async (id) => {
    try {
      await markAsRead(id);
      set((state) => ({
        notifications: state.notifications.map((n) =>
          n.id === id ? { ...n, read: true } : n
        ),
        unreadCount: state.notifications.filter(n => !n.read && n.id !== id).length,
      }));
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  },

  markAllAsRead: async () => {
    try {
      await markAllAsReadInDb();
      set((state) => ({
        notifications: state.notifications.map((n) => ({ ...n, read: true })),
        unreadCount: 0,
      }));
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  },

  removeNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
      unreadCount: state.notifications.filter(n => !n.read && n.id !== id).length,
    })),

  fetchNotifications: async () => {
    set({ isLoading: true, error: null });
    try {
      const fetchWithRetry = async (retries = 3) => {
        try {
          // Check if we have an active session first
          const { data: { session }, error: sessionError } = await supabase.auth.getSession();
          if (sessionError || !session) {
            // Handle auth errors silently - user may not be logged in yet
            return { notifications: [], unreadCount: 0 };
          }

          const notifications = await getNotifications();
          return { 
            notifications, 
            unreadCount: notifications.filter(n => !n.read).length 
          };
        } catch (error) {
          if (retries > 0 && error instanceof Error && 
              error.message.includes('Failed to fetch')) {
            // Wait before retrying
            await new Promise(resolve => setTimeout(resolve, 1000));
            return fetchWithRetry(retries - 1);
          }
          throw error;
        }
      };

      const result = await fetchWithRetry();
      set({ 
        notifications: result.notifications, 
        unreadCount: result.unreadCount, 
        isLoading: false 
      });
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      // Keep existing state on error, just update loading state
      set(state => ({
        ...state,
        isLoading: false,
        error: null // Don't show errors to users
      }));
    }
  },

  initializeSubscription: () => {
    // Fetch initial notifications with automatic retries
    get().fetchNotifications();

    // Subscribe to new notifications
    const unsubscribe = subscribeToNotifications((notification) => {
      get().addNotification(notification);
    });

    // Return cleanup function
    return () => {
      unsubscribe();
    };
  }
}));