import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import SocialFeed from '../components/social/SocialFeed';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <div className="space-y-6 max-w-[1400px] mx-auto">
        <SocialFeed />
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;