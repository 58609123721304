/**
 * Default filter values for Quick Play search
 */
export const DEFAULT_FILTERS = {
  searchTerm: '',
  sport: 'all',
  gender: 'all',
  rating: 'all',
  connectionsOnly: false
} as const;

/**
 * Reset filters to their default state
 */
function resetFilters() {
  return { ...DEFAULT_FILTERS };
}

/**
 * Check if filters are in their default state
 */
function areFiltersDefault(filters: typeof DEFAULT_FILTERS) {
  return Object.entries(filters).every(
    ([key, value]) => value === DEFAULT_FILTERS[key as keyof typeof DEFAULT_FILTERS]
  );
}