import React from 'react';
import { cn } from '@/lib/utils';
import { useTheme } from '../../../contexts/ThemeContext';

const SpinningLogo: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="relative flex items-center justify-center w-24 h-24">
      {/* Outer spinning ring */}
      <div 
        className={cn(
          "absolute inset-0",
          "border-[3px] rounded-full",
          isDarkMode ? "border-gold-400" : "border-gold-600",
          "border-t-transparent",
          "animate-spin"
        )}
        style={{ animationDuration: '2s' }}
      />
      
      {/* Inner spinning ring (opposite direction) */}
      <div 
        className={cn(
          "absolute inset-2",
          "border-[2px] rounded-full",
          isDarkMode ? "border-gold-400/70" : "border-gold-600/70",
          "border-b-transparent",
          "animate-spin"
        )}
        style={{ animationDuration: '1.5s', animationDirection: 'reverse' }}
      />

      {/* Logo container */}
      <div className="relative z-10">
        <img
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/lines_gold-removebg-k1iVBout4rs8n3KtWk0IgssRYsSoTG.png"
          alt="Playclub"
          className="w-12 h-12 drop-shadow-lg"
        />
      </div>
    </div>
  );
};

export default SpinningLogo;