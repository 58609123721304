import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';

const backgroundImages = [
  "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/padel%203-Da5mXaEDfVEVUblufI8E3pMcW9mXYe.webp",
  "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/running%202-YoV5MktlEI3tgHfWURPKZSj63bEdHP.webp",
  "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/padel%201-nqaUsaTuDagu9UywhDe22habKsIYrP.webp",
  "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/pilatis%201-mfYbMVt3RWjJsrRsdcgoQEye8Guy4D.webp",
  "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/soccer-hXKyjSwmLhVfLQF1dK2VtjLRVPNWon.webp"
];

interface BackgroundSlideshowProps {
  blurAmount: number;
  parallaxOffset: number;
}

export const BackgroundSlideshow: React.FC<BackgroundSlideshowProps> = ({
  blurAmount,
  parallaxOffset
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % backgroundImages.length;
        setNextImageIndex((nextIndex + 1) % backgroundImages.length);
        return nextIndex;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute inset-0">
      {/* Current Image */}
      <div
        className={cn(
          "absolute inset-0 bg-cover bg-center will-change-transform transition-[filter] duration-300",
          "after:absolute after:inset-0 after:bg-gradient-to-b after:from-black/0 after:via-black/60 after:to-black/90"
        )}
        style={{
          backgroundImage: `url(${backgroundImages[currentImageIndex]})`,
          filter: `blur(${blurAmount}px)`,
          transform: `translate3d(0, ${-parallaxOffset * 0.5}px, 0) scale(1.1)`,
        }}
      />

      {/* Next Image (Preload) */}
      <div
        className={cn(
          "absolute inset-0 bg-cover bg-center opacity-0 will-change-transform transition-[filter] duration-300",
          "after:absolute after:inset-0 after:bg-gradient-to-b after:from-black/0 after:via-black/60 after:to-black/90"
        )}
        style={{
          backgroundImage: `url(${backgroundImages[nextImageIndex]})`,
          filter: `blur(${blurAmount}px)`,
          transform: `translate3d(0, ${-parallaxOffset * 0.5}px, 0) scale(1.1)`,
        }}
      />

      {/* Single unified gradient overlay */}
      <div 
        className="absolute inset-0 pointer-events-none transition-opacity duration-300"
        style={{
          transform: `translate3d(0, ${-parallaxOffset * 0.5}px, 0)`,
          background: `linear-gradient(to bottom, 
            transparent 0%,
            rgba(0,0,0,${Math.min(blurAmount * 0.1, 0.6)}) 50%,
            rgba(0,0,0,${Math.min(blurAmount * 0.15, 0.9)}) 100%
          )`
        }}
      />
    </div>
  );
};

