import React from 'react';
import { CheckCircle2, X } from 'lucide-react';

interface EliteApplicationSuccessProps {
  onClose: () => void;
}

const EliteApplicationSuccess: React.FC<EliteApplicationSuccessProps> = ({ onClose }) => {
  return (
    <div className="backdrop-blur-sm bg-dark-200/30 rounded-2xl p-8 max-w-md mx-auto text-center relative">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 text-dark-800 hover:text-white transition-colors rounded-lg hover:bg-dark-400"
      >
        <X className="h-5 w-5" />
      </button>

      <div className="bg-green-400/20 p-4 rounded-full w-16 h-16 mx-auto mb-6 flex items-center justify-center">
        <CheckCircle2 className="h-8 w-8 text-green-400" />
      </div>
      <h3 className="text-2xl font-semibold text-white mb-2">Application Received</h3>
      <p className="text-lg mb-4">We review each application to maintain a high-quality network. Not all are accepted, but that's what keeps us exceptional. Stay tuned! 🌟</p>
    </div>
  );
};

export default EliteApplicationSuccess;