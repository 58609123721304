import React from 'react';
import { Users, Globe, Trophy } from 'lucide-react';

export const RequestAccessBenefits = () => (
  <div className="grid grid-cols-3 gap-3 mb-4">
    <div className="bg-dark-300 rounded-xl p-3 text-center hover:bg-dark-400 transition-all duration-300">
      <div className="bg-dark-400 p-3 rounded-lg w-12 h-12 mx-auto mb-2 flex items-center justify-center">
        <Users className="h-6 w-6 text-gold-400" />
      </div>
      <div className="text-xs">Elite Network</div>
    </div>
    <div className="bg-dark-300 rounded-xl p-3 text-center hover:bg-dark-400 transition-all duration-300">
      <div className="bg-dark-400 p-3 rounded-lg w-12 h-12 mx-auto mb-2 flex items-center justify-center">
        <Globe className="h-6 w-6 text-gold-400" />
      </div>
      <div className="text-xs">Global Access</div>
    </div>
    <div className="bg-dark-300 rounded-xl p-3 text-center hover:bg-dark-400 transition-all duration-300">
      <div className="bg-dark-400 p-3 rounded-lg w-12 h-12 mx-auto mb-2 flex items-center justify-center">
        <Trophy className="h-6 w-6 text-gold-400" />
      </div>
      <div className="text-xs">Premium Venues</div>
    </div>
  </div>
);