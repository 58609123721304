import React, { useState, useEffect } from 'react';
import { MapPin, Users, Building2, Clock } from 'lucide-react';
import { members } from '../../data/members';
import { VenueService } from '../../lib/services/venues/venue.service';
import { cn } from '@/lib/utils';

interface CityCardProps {
  city: string;
  image: string;
  active: boolean;
  onClick: () => void;
}

const CityCard: React.FC<CityCardProps> = ({ city, image, active, onClick }) => {
  const [venueCount, setVenueCount] = useState<number>(0);
  
  // Calculate number of members in this city
  const memberCount = members.filter(member => member.city === city).length;

  useEffect(() => {
    const loadVenueCount = async () => {
      if (city.toLowerCase() === 'singapore' || city.toLowerCase() === 'luxembourg') {
        const venues = await VenueService.getVenues(city);
        setVenueCount(venues.length);
      } else {
        // For other cities, show placeholder count
        setVenueCount(Math.floor(Math.random() * (25 - 15) + 15));
      }
    };

    loadVenueCount();
  }, [city]);

  return (
    <button
      onClick={active ? onClick : undefined}
      className={cn(
        "relative w-full h-64 rounded-2xl overflow-hidden group",
        active ? "cursor-pointer" : "cursor-default"
      )}
    >
      <img
        src={image}
        alt={`${city} landmark`}
        className={cn(
          "w-full h-full object-cover transition-transform duration-300 group-hover:scale-105",
          !active && "grayscale brightness-50"
        )}
      />
      {/* Strong gradient overlay to ensure text visibility in both modes */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/40 to-transparent group-hover:from-black/65 group-hover:via-black/45 transition-all duration-300" />

      <div className="absolute bottom-0 left-0 right-0 p-6">
        <div className="flex items-center space-x-2 mb-2">
          <MapPin className="h-5 w-5 text-gold-400" />
          <h3 className="text-xl font-semibold [color:white] !important">
            {city}
          </h3>
        </div>
        {active ? (
          <div className="flex items-center space-x-3">
            <div className="flex items-center px-3 py-1 bg-black/60 backdrop-blur-sm rounded-full">
              <Users className="h-4 w-4 text-gold-400 mr-2" />
              <span className="[color:white] !important">
                {memberCount} Members
              </span>
            </div>
            <div className="flex items-center px-3 py-1 bg-black/60 backdrop-blur-sm rounded-full">
              <Building2 className="h-4 w-4 text-gold-400 mr-2" />
              <span className="[color:white] !important">
                {venueCount} Clubs
              </span>
            </div>
          </div>
        ) : (
          <div className="flex items-center px-3 py-1 bg-black/60 backdrop-blur-sm rounded-full">
            <Clock className="h-4 w-4 text-gold-400 mr-2" />
            <span className="[color:white] !important">
              Coming Soon
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

export default CityCard;