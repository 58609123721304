import React, { useState, useEffect } from 'react';
import { ChevronUp } from 'lucide-react';
import { cn } from '@/lib/utils';

interface FloatingCTAProps {
  show: boolean;
}

export const FloatingCTA: React.FC<FloatingCTAProps> = ({ show }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 200;
      setIsVisible(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <button
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      className={cn(
        "fixed bottom-24 right-8 bg-gold-400 p-4 rounded-full shadow-lg hover:bg-gold-500 transition-all duration-300",
        "transform translate-y-2",
        isVisible && show ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10 pointer-events-none"
      )}
    >
      <ChevronUp className="w-6 h-6" />
    </button>
  );
};