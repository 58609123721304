import React from 'react';
import { cn } from '@/lib/utils';

interface ComboboxListProps {
  children: React.ReactNode;
  className?: string;
}

export const ComboboxList: React.FC<ComboboxListProps> = ({ children, className }) => {
  return (
    <div 
      className={cn(
        "absolute z-50 w-full mt-1 bg-dark-200 border border-dark-400 rounded-xl shadow-lg",
        "max-h-[200px] overflow-y-auto",
        className
      )}
    >
      {children}
    </div>
  );
};