import React from 'react';
import { cn } from '@/lib/utils';

interface PostSkeletonProps {
  count?: number;
  className?: string;
}

const PostSkeleton: React.FC<PostSkeletonProps> = ({
  count = 3,
  className
}) => {
  return (
    <div className={cn("space-y-6", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="bg-dark-200 rounded-2xl p-6 border border-dark-300 animate-pulse"
        >
          {/* Author Info */}
          <div className="flex items-start space-x-4 mb-4">
            <div className="w-20 h-20 rounded-full bg-dark-300" />
            <div className="flex-1 space-y-2">
              <div className="h-4 w-32 bg-dark-300 rounded" />
              <div className="flex items-center space-x-2">
                <div className="h-3 w-24 bg-dark-300 rounded" />
                <div className="h-3 w-16 bg-dark-300 rounded" />
              </div>
            </div>
          </div>

          {/* Post Content */}
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="h-4 w-full bg-dark-300 rounded" />
              <div className="h-4 w-3/4 bg-dark-300 rounded" />
              <div className="h-4 w-1/2 bg-dark-300 rounded" />
            </div>

            {/* Image Placeholder - Show for every other post */}
            {index % 2 === 0 && (
              <div className="aspect-video w-full bg-dark-300 rounded-xl" />
            )}

            {/* Action Buttons */}
            <div className="flex items-center justify-between pt-4 mt-4 border-t border-dark-300">
              <div className="flex items-center space-x-6">
                <div className="flex items-center space-x-2">
                  <div className="h-5 w-5 bg-dark-300 rounded" />
                  <div className="h-3 w-8 bg-dark-300 rounded" />
                </div>
                <div className="flex items-center space-x-2">
                  <div className="h-5 w-5 bg-dark-300 rounded" />
                  <div className="h-3 w-8 bg-dark-300 rounded" />
                </div>
                <div className="flex items-center space-x-2">
                  <div className="h-5 w-5 bg-dark-300 rounded" />
                  <div className="h-3 w-8 bg-dark-300 rounded" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostSkeleton;