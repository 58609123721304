import React from 'react';
import { cn } from '@/lib/utils';

interface PlayerSkeletonProps {
  count?: number;
  className?: string;
}

const PlayerSkeleton: React.FC<PlayerSkeletonProps> = ({
  count = 3,
  className
}) => {
  return (
    <div className={cn("space-y-4", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="bg-dark-200 rounded-xl p-4 border border-dark-300 animate-pulse"
        >
          <div className="flex flex-col sm:flex-row gap-4">
            {/* Profile Image & Rating */}
            <div className="flex flex-col items-center sm:items-start gap-2">
              <div className="w-20 h-20 rounded-full bg-dark-300" />
              <div className="flex items-center px-3 py-1.5 rounded-full bg-dark-300 w-16" />
            </div>

            {/* Member Info */}
            <div className="flex-1 min-w-0">
              <div className="flex flex-col sm:flex-row sm:items-start justify-between text-center sm:text-left">
                <div className="min-w-0 space-y-2">
                  {/* Name and Location */}
                  <div className="h-5 w-40 bg-dark-300 rounded mb-1" />
                  <div className="h-4 w-32 bg-dark-300 rounded" />
                  <div className="h-4 w-48 bg-dark-300 rounded" />
                </div>
                <div className="mt-2 sm:mt-0">
                  <div className="h-6 w-24 bg-dark-300 rounded" />
                </div>
              </div>

              {/* Sports Grid */}
              <div className="flex flex-wrap justify-center sm:justify-start gap-2 mt-4">
                {[1, 2, 3].map((i) => (
                  <div
                    key={i}
                    className="h-8 w-24 bg-dark-300 rounded-full"
                  />
                ))}
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col xs:flex-row items-center gap-3 mt-4 pt-4 border-t border-dark-300">
                <div className="flex items-center gap-2 w-full xs:w-auto">
                  <div className="h-10 w-28 bg-dark-300 rounded-lg" />
                  <div className="h-10 w-24 bg-dark-300 rounded-lg" />
                  <div className="h-10 w-10 bg-dark-300 rounded-lg" />
                </div>
                <div className="h-6 w-24 bg-dark-300 rounded ml-auto" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayerSkeleton;