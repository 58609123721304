import React from 'react';
import { Activity } from '../../types/activity';
import RematchButton from './RematchButton';
import ManageButton from './buttons/ManageButton';
import AcceptButton from './buttons/AcceptButton';
import DeclineButton from './buttons/DeclineButton';
import RateActivityButton from './buttons/RateActivityButton';
import { useAuth } from '../../contexts/AuthContext';

interface ActivityActionsProps {
  activity: Activity;
  onManageSession: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: () => void;
  onAccept: () => void;
  onRate: () => void;
}

const ActivityActions: React.FC<ActivityActionsProps> = ({
  activity,
  onManageSession,
  onRematch,
  onDeclineRequest,
  onAccept,
  onRate = () => {}
}) => {
  const { user } = useAuth();
  
  // Check if current user is the initiator (player 1)
  const isInitiator = user?.id === activity.player1Id;

  // Determine which buttons to show
  const showAcceptDecline = activity.status === 'new_request' && !isInitiator;
  
  const showManage = activity.status === 'confirmed' || 
                     (activity.status === 'new_request' && isInitiator);
  
  // Show rate button for played activities that haven't been rated yet
  const showRate = activity.status === 'played' && !activity.isRated;
  const showRematch = activity.status === 'played';

  return (
    <div className="flex items-center gap-4">
      {showRate && (
        <RateActivityButton onClick={onRate} />
      )}
      
      {showRematch && (
        <RematchButton activity={activity} onRematch={onRematch} />
      )}
      
      {showAcceptDecline && (
        <div className="flex items-center gap-4">
          <AcceptButton onClick={onAccept} />
          <DeclineButton onClick={onDeclineRequest} />
        </div>
      )}
      
      {showManage && (
        <ManageButton onClick={() => onManageSession(activity)} />
      )}
    </div>
  );
};

export default ActivityActions;