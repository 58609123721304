import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '../../ui/dialog';
import { Activity } from '../../../types/activity';
import ManageActivityForm from './form/ManageActivityForm';
import SuccessMessage from './SuccessMessage';

interface ManageActivityModalProps {
  activity: Activity;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (activity: Activity) => void;
}

const ManageActivityModal: React.FC<ManageActivityModalProps> = ({
  activity,
  isOpen,
  onClose,
  onUpdate
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (updatedActivity: Activity) => {
    setIsSubmitting(true);
    try {
      console.log('ManageActivityModal submitting activity:', updatedActivity);
      await onUpdate(updatedActivity);
      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to update activity:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      setShowSuccess(false);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300 max-h-[90vh] overflow-y-auto">
        <DialogTitle className="sr-only">Manage Activity</DialogTitle>
        {showSuccess ? (
          <SuccessMessage onClose={handleClose} />
        ) : (
          <ManageActivityForm
            activity={activity}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            isSubmitting={isSubmitting}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ManageActivityModal;