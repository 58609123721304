import { supabase } from '../supabase';
import { Message } from '../../types/chat';
import { formatTime } from '../utils/time';

export async function getMessages(conversationId: string): Promise<Message[]> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    const { data, error } = await supabase
      .from('messages')
      .select(`
        id,
        content,
        sender_id,
        receiver_id,
        read_status,
        timestamp,
        sender:sender_id (
          id,
          auth_id,
          full_name,
          avatar_url
        ),
        receiver:receiver_id (
          id,
          auth_id,
          full_name,
          avatar_url
        )
      `)
      .or(`sender_id.eq.${userData.id},receiver_id.eq.${userData.id}`)
      .order('timestamp', { ascending: true });

    if (error) throw error;

    return data.map(message => ({
      id: message.id,
      content: message.content,
      time: formatTime(new Date(message.timestamp)),
      isSender: message.sender_id === userData.id,
      status: message.read_status ? 'read' : 'delivered'
    }));
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
}

export async function sendMessage(receiverId: string, content: string): Promise<Message> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    const { data, error } = await supabase
      .from('messages')
      .insert({
        sender_id: userData.id,
        receiver_id: receiverId,
        content,
        read_status: false
      })
      .select(`
        id,
        content,
        timestamp,
        read_status
      `)
      .single();

    if (error) throw error;

    return {
      id: data.id,
      content: data.content,
      time: formatTime(new Date(data.timestamp)),
      isSender: true,
      status: 'sent'
    };
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
}

export function subscribeToMessages(callback: (message: Message) => void) {
  const subscription = supabase
    .channel('public:messages')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'messages'
      },
      async (payload) => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        // Get user's database ID
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData) return;

        // Only process messages where user is sender or receiver
        if (payload.new.sender_id === userData.id || payload.new.receiver_id === userData.id) {
          callback({
            id: payload.new.id,
            content: payload.new.content,
            time: formatTime(new Date(payload.new.timestamp)),
            isSender: payload.new.sender_id === userData.id,
            status: payload.new.read_status ? 'read' : 'sent'
          });
        }
      }
    )
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
}

export async function markMessageAsRead(messageId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('messages')
      .update({ read_status: true })
      .eq('id', messageId);

    if (error) throw error;
  } catch (error) {
    console.error('Error marking message as read:', error);
    throw error;
  }
}