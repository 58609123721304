import React, { useRef, useEffect } from 'react';
import MessageGroup from './MessageGroup';
import TypingIndicator from './TypingIndicator';
import type { Message } from '../../types/chat';

interface MessageListProps {
  messageGroups: Record<string, Message[]>;
  isTyping: boolean;
  typingName: string;
  onDelete?: (messageId: string) => void;
  onEdit?: (messageId: string, newContent: string) => void;
  onReply?: (message: Message) => void;
  editingMessage?: string | null;
}

const MessageList: React.FC<MessageListProps> = ({
  messageGroups,
  isTyping,
  typingName,
  onDelete,
  onEdit,
  onReply,
  editingMessage
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageGroups]);

  return (
    <div className="flex-1 overflow-y-auto px-4 py-6">
      {Object.entries(messageGroups).map(([date, messages]) => (
        <MessageGroup
          key={date}
          date={date}
          messages={messages}
          onDelete={onDelete}
          onEdit={onEdit}
          onReply={onReply}
          editingId={editingMessage}
        />
      ))}
      <div ref={messagesEndRef} />
      {isTyping && <TypingIndicator name={typingName} />}
    </div>
  );
};

export default MessageList;