import React, { useState } from 'react';
import { Activity, Trophy, MapPin, Briefcase, Edit2, Save } from 'lucide-react';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import PhotoGallery from './PhotoGallery';
import SportsSelector from './SportsSelector';
import { Member } from '../../types/member';

interface AboutTabProps {
  member: Member;
  profile?: any;
  sports: string[];
  achievements: string[];
}

const AboutTab: React.FC<AboutTabProps> = ({ member, profile, sports, achievements }) => {
  const [isEditing, setIsEditing] = useState(false);
  // Add debug logging for profile and sports data
  console.log('Profile data:', {
    profile,
    userSports: profile?.user_sports,
    memberSports: sports
  });

  const [formData, setFormData] = useState({
    fullName: member.name,
    bio: profile?.user_profiles?.[0]?.bio || 'Passionate about sports and technology.',
    currentCity: member.city,
    company: profile?.user_profiles?.[0]?.preferences?.company || member.company || '',
    jobTitle: profile?.user_profiles?.[0]?.preferences?.jobTitle || member.jobTitle || '',
    sports: profile?.user_sports?.map(us => `${us.sports.emoji} ${us.sports.name}`) || sports,
    photos: profile?.photos || [
      'https://images.unsplash.com/photo-1554068865-24cecd4e34b8?auto=format&fit=crop&w=800&q=80',
      'https://images.unsplash.com/photo-1587974928442-77dc3e0dba72?auto=format&fit=crop&w=800&q=80'
    ]
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }

    if (!formData.currentCity.trim()) {
      newErrors.currentCity = 'Current city is required';
    }

    if (formData.bio.length > 500) {
      newErrors.bio = 'Bio must be less than 500 characters';
    }

    if (formData.sports.length === 0) {
      newErrors.sports = 'Please select at least one sport';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Get user's database ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();

      if (userError) throw userError;

      // Update user profile with all info
      const existingPreferences = profile?.user_profiles?.[0]?.preferences || {};
      const { error: updateError } = await supabase
        .from('user_profiles')
        .upsert({
          user_id: userData.id,
          bio: formData.bio,
          preferences: {
            ...existingPreferences,
            company: formData.company,
            jobTitle: formData.jobTitle
          }
        });

      if (updateError) throw updateError;

      setIsEditing(false);
      
      // Trigger profile update event
      window.dispatchEvent(new CustomEvent('profile-updated'));

      // Refresh the page to show updated data
      window.location.reload();
    } catch (error) {
      console.error('Error saving profile:', error);
      setIsEditing(true); // Keep editing mode on error
    }
  };

  const handlePhotoUpload = (file: File) => {
    const url = URL.createObjectURL(file);
    setFormData(prev => ({
      ...prev,
      photos: [...prev.photos, url]
    }));
  };

  const handlePhotoDelete = (photoUrl: string) => {
    setFormData(prev => ({
      ...prev,
      photos: prev.photos.filter(url => url !== photoUrl)
    }));
  };

  const handleSportsChange = (sports: string[]) => {
    setFormData(prev => ({
      ...prev,
      sports
    }));
    // Clear any previous sports-related errors
    if (errors.sports) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.sports;
        return newErrors;
      });
    }
  };

  return (
    <div className="space-y-6">
    
      {/* Photo Gallery */}
      <PhotoGallery
        photos={formData.photos}
        onUpload={handlePhotoUpload}
        onDelete={handlePhotoDelete}
      />

     
    </div>
  );
};

export default AboutTab;