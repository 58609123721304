import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useVirtualizer } from '@tanstack/react-virtual';
import PostComposer from './PostComposer';
import { useNotificationStore } from '../../store/notificationStore';
import { postsCache } from '../../lib/cache/instances';
import PostSkeleton from './PostSkeleton';
import FeedHeader from './FeedHeader';
import PostList from './PostList';
import WelcomeMessage from '../dashboard/WelcomeMessage';
import { getPosts, createPost, subscribeToNewPosts } from '../../lib/supabase/posts';
import { Post } from '../../types/post';
import { Loader2, RefreshCw, Filter, TrendingUp, Clock, Star, PenSquare } from 'lucide-react';
import { cn } from '@/lib/utils';

const SocialFeed: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState('recent');
  const [showComposer, setShowComposer] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastPostId, setLastPostId] = useState<string | undefined>();
  const loadingMoreRef = useRef(false);
  const postsRef = useRef<Set<string>>(new Set());
  const postTimeoutRef = useRef<NodeJS.Timeout>();
  const parentRef = useRef<HTMLDivElement>(null);
  const { addNotification } = useNotificationStore();

  const rowVirtualizer = useVirtualizer({
    count: posts.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => {
      // More accurate size estimation based on content
      const post = posts[index];
      const baseHeight = 200; // Base card height
      const contentHeight = post.content.length * 0.5; // Estimate content height
      const imageHeight = post.media ? 300 : 0; // Image height if present
      return baseHeight + contentHeight + imageHeight;
    },
    overscan: 5
  });

  const loadPosts = React.useCallback(async () => {
    const isInitialLoad = posts.length === 0;
    if (isInitialLoad) {
      setIsLoading(true);
    }
    setError(null);

    try {
      const data = await getPosts();
      setPosts(data || []); // Ensure we always set an array
      // Sort posts by date immediately after fetching
      setPosts(prev => [...prev].sort((a, b) => 
        new Date(b.timeAgo).getTime() - new Date(a.timeAgo).getTime()
      ));
    } catch (err) {
      console.error('Error loading posts:', err);
      // Handle network errors gracefully
      if (err instanceof Error && err.message === 'Failed to fetch') {
        console.log('Network error loading posts, showing empty state');
        setPosts([]);
      } else {
        setError(err instanceof Error ? err.message : 'Failed to load posts');
      }
      setPosts([]); // Set empty array on error
    } finally {
      if (isInitialLoad) {
        setIsLoading(false);
      }
    }
  }, []); // No dependencies needed since we use functional updates

  // Intersection observer for infinite scroll
  useEffect(() => {
    if (!parentRef.current || !hasMore || isLoading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loadingMoreRef.current) {
          loadingMoreRef.current = true;
          loadPosts(lastPostId).finally(() => {
            loadingMoreRef.current = false;
          });
        }
      },
      { threshold: 0.5 }
    );

    const el = parentRef.current;
    observer.observe(el);

    return () => {
      if (el) observer.unobserve(el);
    };
  }, [hasMore, isLoading, lastPostId]);

  // Function to handle refresh - defined after loadPosts
  const handleRefresh = React.useCallback(async () => {
    if (isRefreshing) return;
    
    setIsRefreshing(true);
    try {
      await loadPosts();
    } catch (error) {
      console.error('Error refreshing feed:', error);
    } finally {
      setIsRefreshing(false);
    }
  }, [loadPosts, addNotification, isRefreshing]);

  useEffect(() => {
    loadPosts();

    // Clear any existing timeout
    if (postTimeoutRef.current) {
      clearTimeout(postTimeoutRef.current);
    }

    // Subscribe to new posts
    const cleanup = subscribeToNewPosts((newPost) => {
      // Check if we've already seen this post
      if (postsRef.current.has(newPost.id)) {
        return;
      }
      
      // Add post with a delay to prevent duplicates
      postTimeoutRef.current = setTimeout(() => {
        postsRef.current.add(newPost.id);
        setPosts(prev => {
          // Double-check we haven't already added this post
          if (prev.some(p => p.id === newPost.id)) {
            return prev;
          }
          return [newPost, ...prev];
        });
      }, 500); // Half second delay
    });

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
      // Clear timeout on cleanup
      if (postTimeoutRef.current) {
        clearTimeout(postTimeoutRef.current);
      }
      // Clear post tracking set
      postsRef.current.clear();
    };
  }, [loadPosts]);

  const handleNewPost = async (content: string, image?: File) => {
    try {
      setIsPosting(true);
      if (!content.trim() && !image) {
        throw new Error('Please add some content or an image to your post');
      }

      const newPost = await createPost(content, image);
      // Add post ID to our tracking set
      postsRef.current.add(newPost.id);
      // Add new post to the beginning of the list
      setPosts(prev => [newPost, ...prev]);

      addNotification({
        id: `post_${Date.now()}`,
        type: 'new_message',
        title: 'Post Created',
        message: 'Your post has been published successfully!',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });

      return newPost;
    } catch (err) {
      console.error('Error creating post:', err);
      
      addNotification({
        id: `error_${Date.now()}`,
        type: 'new_message',
        title: 'Error',
        message: err instanceof Error ? err.message : 'Failed to create post. Please try again.',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });

      throw err; // Re-throw to let PostComposer handle the error state
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6 w-full"
    >
      {/* Welcome Message */}
      <WelcomeMessage />
      
      {/* Feed Header */}
      <motion.div 
        className="max-w-4xl mx-auto text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300 mb-6">
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
               
                
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setActiveFilter('trending')}
                  className={cn(
                    "flex items-center justify-center h-10 px-4",
                    "hover:scale-105 active:scale-95",
                    "rounded-lg transition-all duration-200",
                    activeFilter === 'trending'
                      ? "bg-gold-400 text-dark-50"
                      : "bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white"
                  )}
                >
                  <TrendingUp className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Trending</span>
                </button>
                <button
                  onClick={() => setActiveFilter('recent')}
                  className={cn(
                    "flex items-center justify-center h-10 px-4",
                    "hover:scale-105 active:scale-95",
                    "rounded-lg transition-all duration-200",
                    activeFilter === 'recent'
                      ? "bg-gold-400 text-dark-50"
                      : "bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white"
                  )}
                >
                  <Clock className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Recent</span>
                </button>
                <button
                  onClick={() => setActiveFilter('top')}
                  className={cn(
                    "flex items-center justify-center h-10 px-4",
                    "hover:scale-105 active:scale-95",
                    "rounded-lg transition-all duration-200",
                    activeFilter === 'top'
                      ? "bg-gold-400 text-dark-50"
                      : "bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white"
                  )}
                >
                  <Star className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Top</span>
                </button>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <motion.button
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={cn(
                  "p-2 rounded-lg transition-colors",
                  "hover:bg-dark-300 text-dark-800 hover:text-white",
                  "active:scale-95 transform",
                  isRefreshing && "animate-spin"
                )}
                title="Refresh feed"
              >
                <RefreshCw className="h-5 w-5" />
              </motion.button>
              <button
                onClick={() => setShowComposer(true)}
                className={cn(
                  "flex items-center justify-center h-10 w-10",
                  "bg-gold-400 hover:bg-gold-500 text-dark-50",
                  "rounded-lg transition-colors",
                  "hover:scale-105 active:scale-95"
                )}
              >
                <PenSquare className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Post Composer - Only show when expanded */}
      {showComposer && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
          <PostComposer 
            onPost={async (content, image) => {
              await handleNewPost(content, image);
              setShowComposer(false);
            }}
            onCancel={() => setShowComposer(false)}
            isSubmitting={isPosting}
          />
        </motion.div>
      )}

      {/* Error State */}
      {error && (
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
          <p>{error === 'Failed to fetch' ? 'Unable to connect to server. Please check your connection.' : error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="text-sm underline mt-2 hover:text-red-400"
          >
            Try Again
          </button>
        </div>
      )}

      {/* Loading State */}
      {isLoading && (
        <PostSkeleton count={3} />
      )}

      {/* Post List */}
      {!isLoading && (
        <div 
          ref={parentRef}
          className="relative min-h-[200px]"
        >
          <PostList posts={posts} activeFilter={activeFilter} />
          {/* Loading indicator */}
          {hasMore && (
            <div className="flex justify-center py-4">
              <Loader2 className="h-6 w-6 text-gold-400 animate-spin" />
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default SocialFeed;