import React from 'react';
import { CheckCircle2 } from 'lucide-react';
import { Button } from '../../ui/button';

interface RequestAccessSuccessProps {
  onClose: () => void;
}

export const RequestAccessSuccess: React.FC<RequestAccessSuccessProps> = ({ onClose }) => (
  <div className="text-center py-6">
    <div className="bg-green-400/20 p-4 rounded-full w-16 h-16 mx-auto mb-6 flex items-center justify-center">
      <CheckCircle2 className="h-8 w-8 text-green-400" />
    </div>
    <h2 className="text-2xl font-bold mb-4">Application Received</h2>
    <p className="text-lg mb-4">
      We review each application to maintain a high-quality network. Not all are accepted, but that's what keeps us exceptional. Stay tuned! 🌟
    </p>
    <Button onClick={onClose} className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 py-3 text-lg">
      Close
    </Button>
  </div>
);