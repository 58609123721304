import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Command, CommandList } from '../components/ui/command';
import { useSearchStore } from '../store/searchStore';
import { SearchResults } from '../components/search/SearchResults';
import { useTheme } from '../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import DashboardLayout from '../components/DashboardLayout';
import SearchInput from '../components/ui/search/SearchInput';

const Search = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { 
    query, 
    setQuery, 
    search, 
    results, 
    isLoading,
    hasSearched,
    clearResults 
  } = useSearchStore();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus search input on mount
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (query) {
      search(query);
    }
  }, [query, search]);

  const handleSelect = (type: string, id: string) => {
    clearResults();
    
    switch (type) {
      case 'player':
      case 'trainer': {
        // Remove 'trainer-' prefix if present
        const memberId = id.replace('trainer-', '');
        navigate(`/dashboard/members/${memberId}`);
        break;
      }
      case 'facility': {
        const facility = results.facilities.find(f => f.id === id);
        if (facility) {
          navigate('/dashboard/facilities', { 
            state: { 
              selectedCity: facility.location,
              activeTab: 'venues', // Set the active tab to venues
              showFacilityDetails: true,
              facilityId: id
            }
          });
        }
        break;
      }
      case 'post':
        navigate(`/dashboard?post=${id}`);
        break;
    }
  };

  const handleClearSearch = () => {
    setQuery('');
    clearResults();
  };

  return (
    <DashboardLayout>
      <div className="max-w-3xl mx-auto">
        <Command 
          className={cn(
            "rounded-lg border",
            isDarkMode ? "bg-dark-200 border-dark-300" : "bg-white border-gray-200"
          )} 
          shouldFilter={false}
        >
          <SearchInput
            ref={inputRef}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onClear={handleClearSearch}
            placeholder="Search for players, sports, locations, companies..."
            className={cn(
              "flex h-14 w-full rounded-t-lg bg-transparent py-3 text-base outline-none border-b",
              isDarkMode 
                ? "border-dark-300" 
                : "border-gray-200"
            )}
          />
          <CommandList className="max-h-[calc(100vh-13rem)] overflow-y-auto p-2">
            <SearchResults
              isLoading={isLoading}
              query={query}
              results={results}
              hasSearched={hasSearched}
              onSelect={handleSelect}
            />
          </CommandList>
        </Command>
      </div>
    </DashboardLayout>
  );
};

export default Search;