import React from 'react';
import { PersonalInfoCard } from '../../profile/PersonalInfoCard';
import { SocialLinksCard } from './SocialLinksCard';

const MyAccountSettings: React.FC = () => {
  return (
    <div className="space-y-6">
      {/* Personal Info Card */}
      <PersonalInfoCard />
      
      {/* Social Links */}
      <SocialLinksCard />
    </div>
  );
};

export default MyAccountSettings;