import { VenueApiResult } from '../../../types/venue';
import { SingaporeVenueService } from './singapore.service';
import { LuxembourgVenueService } from './luxembourg.service';

export class VenueService {
  static async getVenues(city: string): Promise<VenueApiResult[]> {
    try {
      if (!city) {
        throw new Error('City parameter is required');
      }

      const normalizedCity = city.toLowerCase();
      
      switch (normalizedCity) {
        case 'singapore':
          return await SingaporeVenueService.getVenues();
        case 'luxembourg':
          return await LuxembourgVenueService.getVenues();
        default:
          return [];
      }

    } catch (error) {
      console.error('Error fetching venues:', error);
      return [];
    }
  }
}