import React from 'react';

interface TypingIndicatorProps {
  name: string;
}

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ name }) => {
  return (
    <div className="flex items-center space-x-3 text-dark-800 mt-4 px-4">
      <div className="flex space-x-1">
        <div className="w-2 h-2 bg-dark-800 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
        <div className="w-2 h-2 bg-dark-800 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
        <div className="w-2 h-2 bg-dark-800 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
      </div>
      <span className="text-sm">{name} is typing...</span>
    </div>
  );
};

export default TypingIndicator;