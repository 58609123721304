import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../../ui/button';
import { OnboardingStepProps } from '../types';
import { getSports } from '../../../lib/supabase/sports';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { ArrowLeft, CheckCircle } from 'lucide-react';
import { cn } from '@/lib/utils';
import { sportEmojis } from '../../../lib/constants';

const SPORT_CATEGORIES = [
  { 
    name: 'Racket Sports', 
    sports: [
      { name: 'Tennis', emoji: sportEmojis['Tennis'] },
      { name: 'Padel', emoji: sportEmojis['Padel'] },
      { name: 'Badminton', emoji: sportEmojis['Badminton'] },
      { name: 'Table Tennis', emoji: sportEmojis['Table Tennis'] },
      { name: 'Squash', emoji: sportEmojis['Squash'] },
      { name: 'Pickleball', emoji: sportEmojis['Pickleball'] }
    ]
  },
  { 
    name: 'Fitness & Wellness', 
    sports: [
      { name: 'Fitness', emoji: sportEmojis['Fitness'] },
      { name: 'Weight Training', emoji: sportEmojis['Weight Training'] },
      { name: 'Gym', emoji: sportEmojis['Gym'] },
      { name: 'Yoga', emoji: sportEmojis['Yoga'] },
      { name: 'Pilates', emoji: sportEmojis['Pilates'] },
      { name: 'Meditation', emoji: sportEmojis['Meditation'] },
      { name: 'Aerobics', emoji: sportEmojis['Aerobics'] },
      { name: 'Zumba', emoji: sportEmojis['Zumba'] }
    ]
  },
  { 
    name: 'Ball Sports', 
    sports: [
      { name: 'Baseball', emoji: '⚾' },
      { name: 'Football', emoji: sportEmojis['Football'] },
      { name: 'American Football', emoji: '🏈' },
      { name: 'Basketball', emoji: sportEmojis['Basketball'] },
      { name: 'Volleyball', emoji: sportEmojis['Volleyball'] },
      { name: 'Rugby', emoji: sportEmojis['Rugby'] },
      { name: 'Cricket', emoji: sportEmojis['Cricket'] },
      { name: 'Hockey', emoji: sportEmojis['Hockey'] },
      { name: 'Softball', emoji: sportEmojis['Softball'] },
      { name: 'Billiards', emoji: sportEmojis['Billiards'] }
    ]
  },
  { 
    name: 'Individual Sports', 
    sports: [
      { name: 'Athletics', emoji: sportEmojis['Athletic'] },
      { name: 'Golf', emoji: sportEmojis['Golf'] },
      { name: 'Running', emoji: sportEmojis['Running'] },
      { name: 'Cycling', emoji: sportEmojis['Cycling'] },
      { name: 'Swimming', emoji: sportEmojis['Swimming'] },
      { name: 'Climbing', emoji: sportEmojis['Climbing'] },
      { name: 'Surfing', emoji: sportEmojis['Surfing'] },
      { name: 'Skiing', emoji: sportEmojis['Skiing'] },
      { name: 'Snowboarding', emoji: sportEmojis['Snowboarding'] },
      { name: 'Trekking', emoji: sportEmojis['Trekking'] }
    ]
  },
  {
    name: 'Combat Sports',
    sports: [
      { name: 'Boxing', emoji: sportEmojis['Boxing'] },
      { name: 'Martial Arts', emoji: sportEmojis['Martial Arts'] },
      { name: 'Karate', emoji: sportEmojis['Karate'] },
      { name: 'Taekwondo', emoji: sportEmojis['Taekwondo'] },
      { name: 'Aikido', emoji: sportEmojis['Aikido'] }
    ]
  },
  {
    name: 'Equestrian',
    sports: [
      { name: 'Horse Riding', emoji: sportEmojis['Horse Riding'] },
      { name: 'Polo', emoji: sportEmojis['Polo'] },
      { name: 'Horse Racing', emoji: sportEmojis['Horse Racing'] },
      { name: 'Equestrian', emoji: sportEmojis['Equestrian'] }
    ]
  },
  {
    name: 'Water Sports',
    sports: [
      { name: 'Swimming', emoji: sportEmojis['Swimming'] },
      { name: 'Sailing', emoji: sportEmojis['Sailing'] },
      { name: 'Wakeboarding', emoji: sportEmojis['Wakeboarding'] },
      { name: 'Wakesurfing', emoji: sportEmojis['Wakesurfing'] },
      { name: 'Sea Sports', emoji: sportEmojis['Sea Sports'] },
      { name: 'Aquatics', emoji: sportEmojis['Aquatics'] }
    ]
  }
];

const SportsSelectionStep: React.FC<OnboardingStepProps> = ({
  data,
  onUpdate,
  onNext,
  onBack
}) => {
  const selectedSports = data?.sports || [];
  const [activeCategory, setActiveCategory] = useState(SPORT_CATEGORIES[0].name);

  const toggleSport = (sportName: string) => {
    const newSports = selectedSports.includes(sportName)
      ? selectedSports.filter(s => s !== sportName)
      : [...selectedSports, sportName];
    onUpdate?.({ sports: newSports });
  };

  const filteredSports = SPORT_CATEGORIES.find(cat => cat.name === activeCategory)?.sports || [];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="h-full flex flex-col bg-dark-200 overflow-hidden pt-4 sm:pt-6"
    >
      <div className="flex-shrink-0 text-center mb-4 sm:mb-6 px-2 sm:px-4">
        <motion.h2 
          className={cn(
            "text-xl xs:text-2xl sm:text-3xl md:text-4xl font-bold",
            "text-transparent bg-clip-text",
            "bg-gradient-to-r from-white via-gold-200 to-gold-400",
            "mb-2"
          )}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What do you play?
        </motion.h2>
        <p className="text-dark-800 text-xs sm:text-sm md:text-base">
          {selectedSports.length} {selectedSports.length === 1 ? 'sport' : 'sports'} selected
        </p>
      </div>

      {/* Categories */}
      <div className="relative flex-shrink-0 mb-3 sm:mb-4">
        {/* Gradient Indicators */}
        <div className="absolute left-0 top-0 bottom-0 w-12 bg-gradient-to-r from-dark-200 to-transparent pointer-events-none z-10" />
        <div className="absolute right-0 top-0 bottom-0 w-12 bg-gradient-to-l from-dark-200 to-transparent pointer-events-none z-10" />
        
        {/* Scroll Container */}
        <div className="flex overflow-x-auto pb-1 sm:pb-2 px-2 sm:px-4 scrollbar-none relative">
          <div className="flex space-x-2 mx-auto">
          {SPORT_CATEGORIES.map(category => (
            <button
              key={category.name}
              onClick={() => setActiveCategory(category.name)}
              className={cn(
                "flex items-center space-x-1 sm:space-x-2",
                "px-3 sm:px-4 py-1.5 sm:py-2",
                "rounded-lg whitespace-nowrap",
                "transition-all duration-300",
                "text-xs sm:text-sm font-medium border-2",
                "relative",
                "after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2",
                "after:w-1.5 after:h-1.5 after:rounded-full",
                "after:transition-all after:duration-300",
                activeCategory === category.name
                  ? [
                      "bg-gradient-to-br from-gold-400 to-gold-500",
                      "text-dark-50 border-gold-300",
                      "after:bg-gold-400"
                    ]
                  : [
                      "bg-dark-300/30 text-white",
                      "hover:bg-dark-400/50",
                      "border-dark-400/30 hover:border-gold-400/30",
                      "after:bg-transparent"
                    ]
              )}
            >
              <span className="text-base sm:text-xl">{category.sports[0].emoji}</span>
              <span>{category.name}</span>
            </button>
          ))}
          </div>
        </div>
      </div>

      {/* Sports Grid */}
      <div className="flex-1 overflow-y-auto min-h-0">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="grid grid-cols-3 sm:grid-cols-4 gap-3 px-4"
          >
            {filteredSports.map((sport) => (
              <motion.button
                key={sport.name}
                onClick={() => toggleSport(sport.name)}
                className={cn(
                  "relative group p-2 rounded-lg transition-colors duration-300",
                  "border-2",
                  selectedSports.includes(sport.name)
                    ? "bg-gradient-to-br from-gold-400 to-gold-500 text-dark-50 border-gold-300"
                    : "bg-dark-300/30 text-white hover:bg-dark-400/50 border-transparent hover:border-gold-400/30"
                )}
              >
                <div className="flex flex-col items-center space-y-2">
                  <motion.span 
                    className="text-2xl block"
                    whileHover={{ scale: 1.2 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    {sport.emoji}
                  </motion.span>
                  <span className="text-sm font-medium text-center">
                    {sport.name}
                  </span>
                </div>
              </motion.button>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>

      <FloatingNavigation 
        onBack={onBack}
        onNext={onNext}
        isNextDisabled={selectedSports.length === 0}
      />
    </motion.div>
  );
};

export default SportsSelectionStep;