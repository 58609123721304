import React from 'react';
import { Activity } from '../../types/activity';
import ActivityActions from './ActivityActions';
import ActivityStatus from './ActivityStatus';

interface ActivityFooterProps {
  activity: Activity;
  isOwnProfile: boolean;
  onManageSession: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: () => void;
  onAccept: () => void;
  onRate?: () => void;
}

const ActivityFooter: React.FC<ActivityFooterProps> = ({
  activity,
  isOwnProfile,
  onManageSession,
  onRematch,
  onDeclineRequest,
  onAccept,
  onRate
}) => {
  return (
    <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-300">
      <div className="flex items-center gap-4">
        <ActivityActions
          activity={activity}
          onManageSession={onManageSession}
          onRematch={onRematch}
          onDeclineRequest={onDeclineRequest}
          onAccept={onAccept}
          onRate={onRate}
        />
      </div>
      {isOwnProfile && (
        <ActivityStatus 
          status={activity.status} 
          direction={activity.direction} 
        />
      )}
    </div>
  );
};

export default ActivityFooter;