import React from 'react';
import { cn } from '@/lib/utils';
import { loadGoogleMaps } from '@/lib/utils/maps';
import { VenueApiResult } from '../../../types/venue';
import VenueMarker from './VenueMarker';
import { useMediaQuery } from '@/hooks/useMediaQuery';

interface SimpleMapProps {
  venues?: VenueApiResult[];
  className?: string;
}

const SimpleMap: React.FC<SimpleMapProps> = ({ 
  venues = [],
  className 
}) => {
  const mapRef = React.useRef<HTMLDivElement>(null);
  const mapInstanceRef = React.useRef<google.maps.Map | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  // Initialize map
  React.useEffect(() => {
    let mounted = true;

    const initMap = async () => {
      if (!mapRef.current) return;

      try {
        await loadGoogleMaps();
        
        if (!mounted) return;

        const map = new google.maps.Map(mapRef.current, {
          center: { lat: 1.3521, lng: 103.8198 }, // Singapore
          zoom: isDesktop ? 12 : 11,
          styles: [
            {
              featureType: "all",
              elementType: "geometry",
              stylers: [{ color: "#242f3e" }]
            },
            {
              featureType: "all",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#242f3e" }]
            },
            {
              featureType: "all",
              elementType: "labels.text.fill",
              stylers: [{ color: "#746855" }]
            }
          ],
          // Control positions and options
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          mapTypeControl: isDesktop,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT,
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
              google.maps.MapTypeId.HYBRID
            ]
          },
          scaleControl: true,
          streetViewControl: isDesktop,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
          },
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
          },
          // Interaction settings
          gestureHandling: "greedy",
          scrollwheel: true,
          draggable: true,
          disableDoubleClickZoom: false,
          // Zoom limits
          minZoom: 3, // Allow zooming out to see more context
          maxZoom: 21, // Maximum zoom level for detailed view
          // Performance optimizations
          optimized: true,
          clickableIcons: false,
          // Tilt settings for 45° imagery where available
          tilt: 45
        });

        mapInstanceRef.current = map;

        // Add click listener to close info windows when clicking on map
        map.addListener('click', () => {
          google.maps.event.trigger(map, 'closeAllInfoWindows');
        });

      } catch (error) {
        console.error('Error initializing map:', error);
        setError('Failed to load map');
      }
    };

    initMap();

    return () => {
      mounted = false;
      if (mapInstanceRef.current) {
        google.maps.event.clearInstanceListeners(mapInstanceRef.current);
        mapInstanceRef.current = null;
      }
    };
  }, [isDesktop]);

  // Update markers when venues change
  React.useEffect(() => {
    if (!mapInstanceRef.current || venues.length === 0) return;

    const bounds = new google.maps.LatLngBounds();
    venues.forEach(venue => {
      if (venue.coordinates?.lat && venue.coordinates?.lng) {
        bounds.extend(venue.coordinates);
      }
    });

    mapInstanceRef.current.fitBounds(bounds, {
      padding: isDesktop ? 80 : 40
    });
    
    // Don't zoom in too far on small areas
    const listener = google.maps.event.addListenerOnce(mapInstanceRef.current, 'bounds_changed', () => {
      const zoom = mapInstanceRef.current?.getZoom();
      if (zoom && zoom > 17) {
        mapInstanceRef.current?.setZoom(17);
      }
    });

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [venues, isDesktop]);

  if (error) {
    return (
      <div className="bg-dark-300 rounded-xl p-6 text-center">
        <h3 className="text-lg font-medium text-white mb-2">Failed to Load Map</h3>
        <p className="text-dark-800">{error}</p>
      </div>
    );
  }

  return (
    <div 
      ref={mapRef}
      className={cn("w-full h-full", className)}
    >
      {mapInstanceRef.current && venues.map(venue => (
        <VenueMarker
          key={venue.id}
          venue={venue}
          map={mapInstanceRef.current!}
        />
      ))}
    </div>
  );
};

export default SimpleMap;