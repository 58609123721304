import React from 'react';
import { motion } from 'framer-motion';

interface ProfileGridProps {
  profiles: { image: string; name: string }[];
}

const ProfileGrid: React.FC<ProfileGridProps> = ({ profiles }) => {
  return (
    <div className="flex justify-center">
      <div className="flex -space-x-4">
        {profiles.map((profile, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="relative"
          >
            <img
              src={profile.image}
              alt={profile.name}
              className="w-12 h-12 rounded-full border-2 border-dark-200 object-cover hover:z-10 transition-all duration-200 hover:scale-110"
              title={profile.name}
            />
            <div className="absolute inset-0 rounded-full bg-gradient-to-tr from-gold-400/20 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-200" />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ProfileGrid;