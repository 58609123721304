import React, { useState } from 'react';
import { Search, Filter, MapPin, Calendar, Activity } from 'lucide-react';
import TripCard from './TripCard';
import { Trip } from '../../../types/trip';
import { pastTrips } from '../../../data/trips';

const PastTrips: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCity, setSelectedCity] = useState('all');
  const [sortBy, setSortBy] = useState<'date' | 'activities'>('date');
  const [trips] = useState<Trip[]>(pastTrips);

  const filteredTrips = trips.filter(trip => {
    const matchesSearch = trip.city.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCity = selectedCity === 'all' || trip.city === selectedCity;
    return matchesSearch && matchesCity;
  }).sort((a, b) => {
    if (sortBy === 'date') {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
    return b.activities.length - a.activities.length;
  });

  const cities = ['all', ...new Set(trips.map(trip => trip.city))];

  return (
    <div className="space-y-6">
      {/* Filters */}
      <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <input
              type="text"
              placeholder="Search trips..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-dark-300 border border-dark-400 rounded-lg text-white placeholder-dark-800"
            />
          </div>
          <select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            className="bg-dark-300 border border-dark-400 rounded-lg px-4 py-2 text-white"
          >
            <option value="all">All Cities</option>
            {cities.filter(city => city !== 'all').map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as 'date' | 'activities')}
            className="bg-dark-300 border border-dark-400 rounded-lg px-4 py-2 text-white"
          >
            <option value="date">Sort by Date</option>
            <option value="activities">Sort by Activities</option>
          </select>
        </div>
      </div>

      {/* Stats Summary */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="text-dark-800">Total Trips</div>
          <div className="text-2xl font-bold text-white mt-1">{trips.length}</div>
        </div>
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="text-dark-800">Cities Visited</div>
          <div className="text-2xl font-bold text-white mt-1">{new Set(trips.map(t => t.city)).size}</div>
        </div>
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
          <div className="text-dark-800">Total Activities</div>
          <div className="text-2xl font-bold text-white mt-1">
            {trips.reduce((sum, trip) => sum + trip.activities.length, 0)}
          </div>
        </div>
      </div>

      {/* Trips List */}
      <div className="space-y-4">
        {filteredTrips.length === 0 ? (
          <div className="text-center py-12 text-dark-800">
            No past trips found
          </div>
        ) : (
          filteredTrips.map(trip => (
            <TripCard
              key={trip.id}
              trip={trip}
              type="past"
            />
          ))
        )}
      </div>
    </div>
  );
};

export default PastTrips;