import React, { useState } from 'react';
import { Plus, Calendar, X } from 'lucide-react';
import NewTripForm from './NewTripForm';
import TripCard from './TripCard';
import { Trip } from '../../../types/trip';
import { Button } from '../../ui/button';

const FutureTrips: React.FC = () => {
  const [showNewTripForm, setShowNewTripForm] = useState(false);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAddTrip = async (trip: Trip) => {
    try {
      setIsSubmitting(true);
      setError(null);

      // Validate dates
      const startDate = new Date(trip.startDate);
      const endDate = new Date(trip.endDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (startDate < today) {
        throw new Error('Start date cannot be in the past');
      }

      if (endDate < startDate) {
        throw new Error('End date must be after start date');
      }

      // Add trip
      setTrips(prev => [...prev, {
        ...trip,
        id: Date.now().toString(),
        activities: [],
        createdAt: new Date().toISOString()
      }]);

      setShowNewTripForm(false);
    } catch (error) {
      console.error('Failed to add trip:', error);
      setError(error instanceof Error ? error.message : 'Failed to add trip');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditTrip = (tripId: string, updatedTrip: Trip) => {
    setTrips(prev => prev.map(trip => 
      trip.id === tripId ? updatedTrip : trip
    ));
  };

  const handleCancelTrip = (tripId: string) => {
    setTrips(prev => prev.filter(trip => trip.id !== tripId));
  };

  const EmptyState = () => (
  <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
    <Calendar className="h-12 w-12 text-dark-800 mx-auto mb-4" />
    <p className="text-dark-800">No upcoming trips shared</p>
    <Button
      onClick={() => setShowNewTripForm(true)}
      className="mt-4 bg-dark-400 hover:bg-dark-500 text-white"
    >
      Share Your First Trip
    </Button>
  </div>
);

  const AddTripButton = () => (
    <button
      onClick={() => setShowNewTripForm(true)}
      className="w-full bg-dark-300 hover:bg-dark-400 rounded-xl p-4 border border-dark-400 border-dashed flex items-center justify-center space-x-2 text-dark-800 hover:text-white transition-colors"
    >
      <Plus className="h-5 w-5" />
      <span>Add New Trip</span>
    </button>
  );

  const NewTripFormSection = () => (
    <div className="bg-dark-300 rounded-xl p-4 sm:p-6 border border-dark-400">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-lg font-medium text-white">Share New Trip</h3>
          {error && (
            <p className="text-red-400 text-sm mt-1">{error}</p>
          )}
        </div>
        <button
          onClick={() => setShowNewTripForm(false)}
          className="text-dark-800 hover:text-white transition-colors"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      <NewTripForm 
        onSubmit={handleAddTrip} 
        onCancel={() => setShowNewTripForm(false)} 
        isSubmitting={isSubmitting}
      />
    </div>
  );

  const TripsList = () => (
    <div className="grid grid-cols-1 gap-4">
      {trips.map((trip) => (
        <TripCard
          key={trip.id}
          trip={trip}
          type="future"
          onEdit={handleEditTrip}
          onCancel={handleCancelTrip}
        />
      ))}
    </div>
  );

  return (
    <div className="space-y-4">
      {!showNewTripForm && <AddTripButton />}
      {showNewTripForm && <NewTripFormSection />}
      {trips.length === 0 ? <EmptyState /> : <TripsList />}
    </div>
  );
};

export default FutureTrips;