import React, { useRef, useEffect, useState } from 'react';
import ActivityCardList from './ActivityCardList';
import { Activity } from '../../types/activity';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import NewRatingModal from './modals/NewRatingModal';
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';

interface ActivityListProps {
  activities: Activity[];
  isOwnProfile: boolean;
  initialLoadCount?: number;
  batchSize?: number;
  activeTab?: 'current' | 'past';
  onManageSession: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onAccept: (activity: Activity) => void;
  onDeclineRequest: (activity: Activity, reason?: string) => void;
  onRate?: (activity: Activity, rating: number, feedback: string, categories: any) => void;
}

const ActivityList: React.FC<ActivityListProps> = ({
  activities,
  isOwnProfile,
  initialLoadCount = 20,
  batchSize = 10,
  activeTab = 'current',
  onManageSession,
  onRematch,
  onAccept,
  onDeclineRequest,
  onRate
}) => {
  const { user } = useAuth();
  const [displayedActivities, setDisplayedActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadingTimeoutRef = useRef<NodeJS.Timeout>();

  const {
    page: currentPage,
    lastElementRef
  } = useInfiniteScroll(activities, page, setPage, setIsLoadingMore);

  // Initialize with first batch
  useEffect(() => {
    if (!user) {
      setError('Authentication required');
      return;
    }

    const initial = activities.slice(0, initialLoadCount);
    setDisplayedActivities(initial);
    setHasMore(initial.length < activities.length);
  }, [activities, initialLoadCount, user]);

  // Setup intersection observer for infinite scroll
  useEffect(() => {
    if (!user) return;

    // Cleanup function
    const cleanup = () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };

    // Cleanup previous observer
    cleanup();

    // Create new observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading && hasMore) {
          setIsLoading(true);
          
          // Use timeout to prevent rapid firing
          loadingTimeoutRef.current = setTimeout(() => {
            const currentLength = displayedActivities.length;
            const nextBatch = activities.slice(
              currentLength,
              currentLength + batchSize
            );
            
            if (nextBatch.length > 0) {
              setDisplayedActivities(prev => [...prev, ...nextBatch]);
              setHasMore(currentLength + nextBatch.length < activities.length);
            } else {
              setHasMore(false);
            }
            
            setIsLoading(false);
          }, 300);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    // Observe loader element
    if (loaderRef.current) {
      observerRef.current.observe(loaderRef.current);
    }

    // Cleanup on unmount or when dependencies change
    return cleanup;
  }, [activities, displayedActivities.length, hasMore, isLoading, user, batchSize]);

  const handleRateActivity = async (activity: Activity) => {
    if (!activity || activity.status !== 'played' || activity.isRated) {
      return;
    }
    
    setSelectedActivity(activity);
    setShowRatingModal(true);
  };

  const handleRatingSubmit = async (ratings: Record<string, number>, feedback: string) => {
    if (!selectedActivity) return;
    
    setIsSubmitting(true);
    try {
      await onRate?.(
        selectedActivity,
        ratings,
        feedback,
        {
          punctuality: ratings.punctuality >= 4,
          sportsmanship: ratings.sportsmanship >= 4,
          skillLevelMatch: ratings.skillLevelMatch >= 4,
          communication: ratings.communication >= 4
        }
      );
      
      setShowRatingModal(false);
      setSelectedActivity(null);
    } catch (error) {
      console.error('Error submitting rating:', error);
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  if (error) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <p className="text-dark-800">{error}</p>
      </div>
    );
  }

  if (activities.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <p className="text-dark-800">No activities found</p>
      </div>
    );
  }

  return (
    <div className="space-y-4 relative">
      {displayedActivities.map((activity, index) => {
        // Check if activity is past and played but not rated
        const canRate = activity.status === 'played' && !activity.isRated;
        
        return (
          <ActivityCardList
            key={`list-${activity.id}`}
            activities={[activity]}
            isOwnProfile={isOwnProfile}
            isPastActivity={activeTab === 'past'}
            onManageSession={onManageSession}
            onAccept={onAccept}
            onRematch={onRematch}
            onDeclineRequest={onDeclineRequest}
            onRate={canRate ? handleRateActivity : undefined}
            lastElementRef={index === displayedActivities.length - 1 ? lastElementRef : undefined}
          />
        );
      })}
      
      {/* Loading indicator */}
      {hasMore && (
        <div 
          ref={loaderRef}
          className={cn(
            "flex justify-center py-4",
            "transition-opacity duration-300",
            isLoading ? "opacity-100" : "opacity-0"
          )}
        >
          <Loader2 className="h-6 w-6 text-gold-400 animate-spin" />
        </div>
      )}

      {isLoadingMore && (
        <div className="text-center text-dark-800">
          No more activities to load
        </div>
      )}
      
      {/* Rating Modal */}
      {selectedActivity && (
        <NewRatingModal
          isOpen={showRatingModal}
          onClose={() => {
            setShowRatingModal(false);
            setSelectedActivity(null);
          }}
          onSubmit={handleRatingSubmit}
          playerName={selectedActivity.opponent.name}
          venueName={selectedActivity.venue.name}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default ActivityList;