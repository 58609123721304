import React, { useRef, useEffect, useState } from 'react';
import { Camera, X, ImagePlus } from 'lucide-react';
import { Dialog, DialogContent, DialogTitle } from '../ui/dialog';
import { supabase } from '../../lib/supabase';
import { useNotificationStore } from '../../store/notificationStore';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';

interface PhotoGalleryProps {
  photos: string[];
  onUpload: (file: File) => void;
  onDelete?: (photoUrl: string) => void;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, onUpload, onDelete }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addNotification } = useNotificationStore();
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const [postPhotos, setPostPhotos] = useState<string[]>([]);
  const [uploadedPhotos, setUploadedPhotos] = useState<string[]>([]);
  const [deletedPhotoUrls, setDeletedPhotoUrls] = useState<Set<string>>(new Set());
  const photoTimestampsRef = useRef<Map<string, number>>(new Map());

  // Subscribe to deleted_photos changes
  useEffect(() => {
    const subscription = supabase
      .channel('deleted_photos_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'deleted_photos'
        },
        async (payload) => {
          // Get current user
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) return;

          // Get user's database ID
          const { data: userData } = await supabase
            .from('users')
            .select('id')
            .eq('auth_id', user.id)
            .single();

          if (!userData) return;

          // Refresh deleted photos list
          const { data: deletedPhotos } = await supabase
            .from('deleted_photos')
            .select('photo_url')
            .eq('user_id', userData.id);

          const newDeletedUrls = new Set(deletedPhotos?.map(dp => dp.photo_url) || []);
          setDeletedPhotoUrls(newDeletedUrls);

          // Update photo lists
          setUploadedPhotos(prev => prev.filter(url => !newDeletedUrls.has(url)));
          setPostPhotos(prev => prev.filter(url => !newDeletedUrls.has(url)));
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Fetch both uploaded photos and post photos
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        // Get current user
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        // Get user's database ID
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData) return;

        // Get deleted photos first
        const { data: deletedPhotos } = await supabase
          .from('deleted_photos')
          .select('photo_url')
          .eq('user_id', userData.id);

        const deletedUrls = new Set(deletedPhotos?.map(dp => dp.photo_url) || []);
        setDeletedPhotoUrls(deletedUrls);

        // List files from storage
        const { data: storageFiles } = await supabase.storage
          .from('social-feed')
          .list(`user_directory/${user.id}`);
        
        if (storageFiles) {
          // Create a map of photo URLs to timestamps
          const timestamps = new Map<string, number>();
          
          const photoUrls = await Promise.all(
            storageFiles
              .filter(file => !file.id.startsWith('.')) // Filter out hidden files
              .map(async (file) => {
                const { data: { publicUrl } } = supabase.storage
                  .from('social-feed')
                  .getPublicUrl(`user_directory/${user.id}/${file.name}`);
                
                // Extract timestamp from filename (assuming format: timestamp-filename.ext)
                const timestamp = parseInt(file.name.split('-')[0]);
                if (!isNaN(timestamp)) {
                  timestamps.set(publicUrl, timestamp);
                }
                
                return publicUrl;
              })
          );
          photoTimestampsRef.current = timestamps;
          setUploadedPhotos(photoUrls.filter(url => !deletedUrls.has(url)));
        }

        // Get photos from posts
        const { data: posts } = await supabase
          .from('posts')
          .select('media_url')
          .eq('user_id', userData.id)
          .not('media_url', 'is', null);

        if (posts) {
          // Add post photo timestamps
          const timestamps = new Map(photoTimestampsRef.current);
          posts.forEach(post => {
            if (post.media_url && post.created_at) {
              timestamps.set(post.media_url, new Date(post.created_at).getTime());
            }
          });
          photoTimestampsRef.current = timestamps;
          
          const mediaUrls = posts
            .map(post => post.media_url)
            .filter((url): url is string => Boolean(url) && !deletedUrls.has(url));
          setPostPhotos(mediaUrls);
        }
      } catch (error) {
        console.error('Error fetching photos:', error);
        setError('Failed to load photos');
      }
    };

    fetchPhotos();
  }, []); // Only fetch on mount since we handle updates in delete handler

  const handlePhotoClick = (photo: string) => {
    setSelectedPhoto(photo);
  };

  const handleFileUpload = async (file: File) => {
    try {
      setIsLoading(true);
      setError(null);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('File size must be less than 5MB');
      }

      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        throw new Error('Only JPEG, PNG and GIF images are allowed');
      }

      const timestamp = Date.now();
      const extension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      const sanitizedName = file.name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
      const filePath = `user_directory/${user.id}/${timestamp}-${sanitizedName}.${extension}`;

      const { error: uploadError } = await supabase.storage
        .from('social-feed')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false,
          contentType: file.type
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = await supabase.storage
        .from('social-feed')
        .getPublicUrl(filePath);

      // Call onUpload with the file
      await onUpload(file);

      // Add to uploaded photos
      setUploadedPhotos(prev => [publicUrl, ...prev]);

      // Show success notification
      addNotification({
        id: `photo_upload_${Date.now()}`,
        type: 'new_message',
        title: 'Photo Added',
        message: 'Your photo has been added to the gallery',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/profile'
      });

      // Reset input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError(error instanceof Error ? error.message : 'Failed to upload photo');
      
      addNotification({
        id: `error_${Date.now()}`,
        type: 'new_message',
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to upload photo',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/profile'
      });
    } finally {
      setIsLoading(false);
    }
  };
  // Fetch photos from posts
  useEffect(() => {
    const fetchPostPhotos = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        // Get user's database ID
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData) return;

        // Get photos from posts
        const { data: posts } = await supabase
          .from('posts')
          .select('media_url')
          .eq('user_id', userData.id)
          .not('media_url', 'is', null);

        if (posts) {
          const mediaUrls = posts
            .map(post => post.media_url)
            .filter((url): url is string => Boolean(url));
          setPostPhotos(mediaUrls);
        }
      } catch (error) {
        console.error('Error fetching post photos:', error);
      }
    };

    fetchPostPhotos();
  }, []);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    await handleFileUpload(file);
  };

  const handleDelete = async (photoUrl: string) => {
    if (onDelete) {
      try {
        setError(null);

        // Get current user
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('Not authenticated');

        // Get user's database ID
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData) throw new Error('User profile not found');

        // Extract storage path from URL
        const storagePath = photoUrl.includes('social-feed') 
          ? `user_directory/${user.id}/${photoUrl.split('/').pop()}`
          : null;

        if (!photoUrl || !storagePath) {
          setError('Invalid photo URL or storage path.');
          return;
        }

        // Call handle_photo_deletion function with correct parameter order
        const { error: deleteError } = await supabase.rpc(
          'handle_photo_deletion',
          { 
            photo_url_param: photoUrl,
            storage_path_param: storagePath,
            user_id_param: userData.id
          }
        );

        if (deleteError) {
          console.error('Error deleting photo:', deleteError);
          setError(deleteError.message || 'Failed to delete photo.');
          return;
        }

        // Delete from storage if path exists
        if (storagePath) {
          const { error: storageError } = await supabase.storage
            .from('social-feed')
            .remove([storagePath]);

          if (storageError && !storageError.message.includes('Object not found')) {
            console.error('Error removing photo from storage:', storageError);
            setError(storageError.message || 'Failed to remove photo from storage.');
            return;
          }
        }

        // Update local state
        setPostPhotos(prev => prev.filter(p => p !== photoUrl));
        setUploadedPhotos(prev => prev.filter(p => p !== photoUrl));
        setDeletedPhotoUrls(prev => new Set([...prev, photoUrl]));

        await onDelete(photoUrl);

        addNotification({
          id: `photo_delete_${Date.now()}`,
          type: 'new_message',
          title: 'Photo Deleted',
          message: 'Your photo has been removed from the gallery',
          timestamp: new Date().toISOString(),
          read: false,
          actionUrl: '/dashboard/profile'
        });
      } catch (error) {
        console.error('Error deleting photo:', error);
        const message = error instanceof Error 
          ? error.message 
          : 'Failed to delete photo';
        
        setError(message);
        addNotification({
          id: `error_${Date.now()}`,
          type: 'new_message',
          title: 'Error',
          message,
          timestamp: new Date().toISOString(),
          read: false,
          actionUrl: '/dashboard/profile'
        });
      }
    }
  };

  // Combine direct uploads and post photos, excluding deleted ones
  const allPhotos = [...new Set([...uploadedPhotos, ...postPhotos])]
    .filter(photo => !deletedPhotoUrls.has(photo))
    .sort((a, b) => {
      // Get timestamps, defaulting to current time for new uploads
      const timeA = photoTimestampsRef.current.get(a) || Date.now();
      const timeB = photoTimestampsRef.current.get(b) || Date.now();
      // Sort in descending order (newest first)
      return timeB - timeA;
    });

  return (
    <div className="bg-dark-200 rounded-2xl border border-dark-300">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-white">📸 My Photos</h3>
        <label
          htmlFor="photo-upload"
          className={cn(
            "flex items-center space-x-2 px-4 py-2 rounded-xl",
            "bg-gold-400 hover:bg-gold-500 text-dark-50 cursor-pointer",
            "transform hover:scale-105 active:scale-95 transition-all duration-200",
            isLoading && "opacity-50 cursor-not-allowed"
          )}
        >
          <ImagePlus className="h-5 w-5" />
          <span>Add Photo</span>
        </label>
        <input
          ref={fileInputRef}
          id="photo-upload"
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
          multiple={false}
          disabled={isLoading}
        />
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-500/10 text-red-500 text-sm rounded-lg border border-red-500/20">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-px bg-dark-300">
        <AnimatePresence>
          {allPhotos.map((photo, index) => (
            <motion.div
              key={photo}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2, delay: index * 0.05 }}
              className={cn(
                "relative aspect-square overflow-hidden group bg-dark-200",
                "focus:outline-none focus:ring-2 focus:ring-gold-400",
                "transform transition-all duration-300",
                "hover:z-10 hover:scale-[1.02]"
              )}
            >
              <div 
                onClick={() => handlePhotoClick(photo)}
                className="w-full h-full cursor-pointer"
              >
                <img
                  src={photo}
                  alt={`Gallery photo ${index + 1}`}
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
                <div className={cn(
                  "absolute inset-0 bg-gradient-to-t from-dark-900/90 via-dark-900/30 to-transparent",
                  "opacity-0 group-hover:opacity-100",
                  "transition-opacity"
                )} />
                <div className="absolute inset-x-0 bottom-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity">
                  <div className="flex items-center justify-between text-white">
                    <div className="flex items-center space-x-2">
                      <button className="hover:text-gold-400 transition-colors">
                        ❤️ {Math.floor(Math.random() * 50) + 1}
                      </button>
                      <button className="hover:text-gold-400 transition-colors">
                        💬 {Math.floor(Math.random() * 10) + 1}
                      </button>
                    </div>
                    <span className="text-sm">{new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>
              <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                <button
                  onClick={() => handleDelete(photo)}
                  className={cn(
                    "p-2 bg-red-500 rounded-lg text-white",
                    "hover:bg-red-600 transition-colors",
                    "transform hover:scale-110 active:scale-95"
                  )}
                  aria-label="Delete photo"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        <label
          htmlFor="photo-upload"
          className={cn(
            "aspect-square",
            "border border-dashed border-dark-400",
            "flex items-center justify-center cursor-pointer",
            "hover:border-gold-400 hover:bg-dark-300/50 transition-all duration-300",
            "group"
          )}
        >
          <Camera className={cn(
            "h-8 w-8 text-dark-800",
            "group-hover:text-gold-400 transition-colors",
            "transform group-hover:scale-110",
            "transition-all duration-200"
          )} />
        </label>
      </div>
      {/* Photo Viewer Modal */}
      <Dialog open={!!selectedPhoto} onOpenChange={() => setSelectedPhoto(null)}>
        <DialogContent className="max-w-4xl p-0 bg-dark-200 border-dark-300">
          <DialogTitle className="sr-only">View Photo</DialogTitle>
          <div className="relative">
            <div className="relative aspect-video">
              <img
                src={selectedPhoto || ''}
                alt="Selected photo"
                className="w-full h-full object-contain"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark-900/80 via-dark-900/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
            </div>
            <button
              onClick={() => setSelectedPhoto(null)}
              className="absolute top-4 right-4 p-2 bg-dark-900/80 hover:bg-dark-900 rounded-full transition-colors"
              aria-label="Close photo viewer"
            >
              <X className="h-5 w-5 text-white" />
            </button>
          </div>
          <div className="p-4 border-t border-dark-300">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                {/* Add like/comment/share buttons here if needed */}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PhotoGallery;