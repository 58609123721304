import React from 'react';
import ReviewList from '../reviews/ReviewList';
import ReviewStatsDisplay from '../reviews/ReviewStats';
import type { Review, ReviewStats } from '../../types/review';

interface ReviewsSectionProps {
  memberId: string;
  memberName: string;
  sports: string[];
  rating: number;
  reviewCount: number;
}

const ReviewsSection: React.FC<ReviewsSectionProps> = ({
  memberId,
  memberName,
  sports,
  rating,
  reviewCount
}) => {
  const [reviews, setReviews] = React.useState<Review[]>([]);
  const [stats, setStats] = React.useState<ReviewStats>({
    averageRating: rating,
    totalReviews: reviewCount,
    ratingDistribution: {
      1: Math.floor(Math.random() * 10),
      2: Math.floor(Math.random() * 15),
      3: Math.floor(Math.random() * 20),
      4: Math.floor(Math.random() * 30),
      5: Math.floor(Math.random() * 40)
    }
  });

  const handleLikeReview = async (reviewId: string) => {
    setReviews(prev => 
      prev.map(review => 
        review.id === reviewId
          ? {
              ...review,
              likes: review.isLiked ? review.likes - 1 : review.likes + 1,
              isLiked: !review.isLiked
            }
          : review
      )
    );
  };

  const handleReportReview = async (reviewId: string) => {
    // In a real app, this would be an API call
    console.log('Reporting review:', reviewId);
  };

  return (
    <div className="space-y-6">
      <ReviewStatsDisplay stats={stats} />
      
      <ReviewList
        reviews={reviews}
        onLike={handleLikeReview}
        onReport={handleReportReview}
      />
    </div>
  );
};

export default ReviewsSection;