import React from 'react';
import type { Conversation } from '../../../types/chat';
import ConversationItem from './ConversationItem';
import { cn } from '@/lib/utils';

interface ConversationListProps {
  conversations: Conversation[];
  activeChat: string | null;
  onChatSelect: (chatId: string) => void;
}

const ConversationList: React.FC<ConversationListProps> = ({
  conversations = [], // Provide default empty array
  activeChat,
  onChatSelect
}) => {
  if (!Array.isArray(conversations)) {
    console.error('Conversations prop must be an array');
    return (
      <div className="flex items-center justify-center h-full text-dark-800">
        Error loading conversations
      </div>
    );
  }

  if (conversations.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-dark-800">
        No conversations found
      </div>
    );
  }

  return (
    <div className={cn(
      "divide-y",
      "dark:divide-dark-300",  // Dark mode divider
      "light:divide-gray-100", // Light mode divider
      "divide-opacity-50"      // Reduce opacity for both modes
    )}>
      {conversations.map((conversation) => (
        <ConversationItem
          key={conversation.id}
          conversation={conversation}
          isActive={activeChat === conversation.id}
          onClick={() => onChatSelect(conversation.id)}
        />
      ))}
    </div>
  );
};

export default ConversationList;