import { supabase } from '../supabase';

// Types
type ConnectionStatus = 'pending' | 'accepted' | 'declined';

interface Connection {
  id: string;
  initiator_id: string;
  receiver_id: string;
  status: ConnectionStatus;
  created_at: string;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

// Get user's connections
async function getConnections() {
  try {
    console.log('Fetching user connections...');
    // Get current user with retry
    const { data: { user }, error: userError } = await fetchWithRetry(() => 
      supabase.auth.getUser()
    );

    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    console.log('Authenticated user:', user.id);

    // Get user's database ID with retry
    const { data: userData, error: userDataError } = await fetchWithRetry(() =>
      supabase
        .from('users')
        .select('id, auth_id')
        .eq('auth_id', user.id)
        .single()
    );

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    console.log('Fetching connections for user:', userData.id);

    // Get connections with retry
    const { data, error } = await fetchWithRetry(() =>
      supabase
        .from('connections')
        .select(`
          id,
          initiator:initiator_id(id, auth_id, full_name, avatar_url, city),
          receiver:receiver_id(id, auth_id, full_name, avatar_url, city),
          status,
          created_at
        `)
        .or(`initiator_id.eq.${userData.id},receiver_id.eq.${userData.id}`)
        .order('created_at', { ascending: false })
    );

    if (error) throw error;

    console.log('Connections fetched successfully:', {
      count: data?.length || 0,
      statuses: data?.map(c => c.status)
    });

    return data;
  } catch (error) {
    console.error('Error fetching connections:', error);
    console.log('Connection fetch error details:', {
      error: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined
    });
    // Return empty array for network errors
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log('Network error, returning empty connections list');
      return [];
    }
    throw error;
  }
}

// Send connection request
export async function sendConnectionRequest(receiverId: string) {
  try {
    console.log('Sending connection request to:', receiverId);
    
    if (!receiverId) {
      console.error('No receiver ID provided');
      throw new Error('Invalid request: No receiver ID provided');
    }

    // Get current user with retry
    const { data: { user }, error: userError } = await fetchWithRetry(() => 
      supabase.auth.getUser()
    );

    if (userError) {
      console.error('Auth error:', userError);
      throw userError;
    }
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('Not authenticated');
    }

    console.log('Authenticated user:', user.id);

    // Get user's database ID with retry
    const { data: userData, error: userDataError } = await fetchWithRetry(() =>
      supabase
        .from('users')
        .select('id, auth_id')
        .eq('auth_id', user.id)
        .single()
    );

    if (userDataError) {
      console.error('User data error:', userDataError);
      throw userDataError;
    }
    if (!userData) {
      console.error('User profile not found');
      throw new Error('User profile not found');
    }

    // Get receiver's database ID
    console.log('Getting receiver database ID for:', receiverId);
    const { data: receiverData, error: receiverError } = await fetchWithRetry(() =>
      supabase
        .from('users')
        .select('id, auth_id')
        .eq('auth_id', receiverId)
        .single()
    );

    if (receiverError) {
      console.error('Receiver data error:', receiverError);
      throw receiverError;
    }
    if (!receiverData) {
      console.error('Receiver not found');
      throw new Error('Receiver not found');
    }

    console.log('Checking existing connection between database IDs:', {
      initiatorId: userData.id,
      receiverId: receiverData.id
    });

    // Check if connection exists with retry
    const { data: existingConnection } = await fetchWithRetry(() =>
      supabase
        .from('connections')
        .select('id, status')
        .or(`and(initiator_id.eq.${userData.id},receiver_id.eq.${receiverData.id}),and(initiator_id.eq.${receiverData.id},receiver_id.eq.${userData.id})`)
        .maybeSingle()
    );

    if (existingConnection) {
      console.log('Existing connection found:', existingConnection);
      if (existingConnection.status === 'pending') {
        console.log('Connection request already pending');
        throw new Error('Connection request already sent');
      }
      if (existingConnection.status === 'accepted') {
        console.log('Users already connected');
        throw new Error('Already connected');
      }
      if (existingConnection.status === 'declined') {
        // If previously declined, allow new request
        console.log('Previous declined connection found, allowing new request');
        await supabase
          .from('connections')
          .delete()
          .eq('id', existingConnection.id);
      }
    }

    console.log('Creating connection between database IDs:', {
      initiatorId: userData.id,
      receiverId: receiverData.id
    });

    // Create connection request with retry
    const { data, error } = await fetchWithRetry(() =>
      supabase
        .from('connections')
        .insert({
          initiator_id: userData.id,
          receiver_id: receiverData.id,
          status: 'pending'
        })
        .select(`
          id,
          status,
          created_at,
          initiator:initiator_id(id, auth_id, full_name, avatar_url),
          receiver:receiver_id(id, auth_id, full_name, avatar_url)
        `)
        .single()
    );

    if (error) {
      console.error('Failed to create connection:', error);
      throw error;
    }

    if (!data) {
      console.error('No data returned from connection creation');
      throw new Error('Failed to create connection request');
    }

    console.log('Connection request created successfully:', data);

    return data;
  } catch (error) {
    console.error('Error sending connection request:', error);
    console.log('Connection request error details:', {
      error: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined,
      receiverId
    });
    // Handle network errors gracefully
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      throw new Error('Unable to send connection request. Please check your connection and try again.');
    }
    throw error;
  }
}

// Accept connection request
export async function acceptConnectionRequest(connectionId: string) {
  try {
    console.log('Starting connection acceptance:', { connectionId });

    // Get current user
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    console.log('Authenticated user:', user.id);

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    console.log('User database ID:', userData.id);

    // Get connection details first
    const { data: connection, error: connectionError } = await supabase
      .from('connections')
      .select(`
        id,
        initiator_id,
        receiver_id,
        status
      `)
      .eq('id', connectionId)
      .single();

    if (connectionError) throw connectionError;
    if (!connection) throw new Error('Connection not found');

    console.log('Found connection:', connection);

    // Verify user is the receiver
    if (connection.receiver_id !== userData.id) {
      throw new Error('Not authorized to accept this connection');
    }

    if (connection.status !== 'pending') {
      throw new Error('Connection is not in pending state');
    }

    // Update connection status
    const { data, error } = await supabase
      .from('connections')
      .update({ status: 'accepted' })
      .eq('id', connectionId)
      .select(`
        id,
        status,
        created_at,
        initiator:initiator_id(id, auth_id, full_name, avatar_url, city),
        receiver:receiver_id(id, auth_id, full_name, avatar_url, city)
      `)
      .single();

    if (error) {
      console.error('Error updating connection status:', error);
      throw error;
    }
    if (!data) {
      console.error('No data returned from connection update');
      throw new Error('Failed to update connection');
    }

    console.log('Connection accepted successfully:', data);
    return true;
  } catch (error) {
    console.error('Error accepting connection request:', error);
    // Handle network errors gracefully
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      throw new Error('Unable to accept connection request. Please check your connection and try again.');
    }
    throw error;
  }
}

// Decline connection request
export async function declineConnectionRequest(connectionId: string) {
  try {
    // Delete the connection instead of just updating status
    const { error } = await supabase
      .from('connections')
      .delete()
      .eq('id', connectionId);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error declining connection request:', error);
    // Handle network errors gracefully
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      throw new Error('Unable to decline connection request. Please check your connection and try again.');
    }
    throw error;
  }
}

// Subscribe to connection updates
function subscribeToConnections(callback: (payload: any) => void) {
  const subscription = supabase
    .channel('connections_channel')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'connections'
      },
      callback
    )
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
}