import { VenueApiResult } from '../../../types/venue';
import { SingaporeVenue } from '../../../types/singapore-venue';
import { FACILITY_AMENITIES } from './facility-constants';
import { formatActivityWithEmoji } from './activity.formatter';
import { VENUE_TYPE_IMAGES } from './venue-type-images';

export function formatVenueData(venue: SingaporeVenue): VenueApiResult {
  const formattedVenue = {
    id: generateUniqueVenueId(venue),
    name: venue.name,
    type: determineVenueType(venue.activities || []),
    address: venue.formatted_address,
    coordinates: {
      lat: venue.geometry.location.lat,
      lng: venue.geometry.location.lng
    },
    activities: (venue.activities || []).map(formatActivityWithEmoji),
    amenities: extractAmenities(venue),
    rating: venue.rating || 0,
    // Convert photo_urls to the required format
    photos: venue.photo_urls ? venue.photo_urls.map(url => ({
      height: 400,
      width: 400,
      url,
      photo_reference: url
    })) : [],
    reviews: venue.reviews?.map(review => ({
      author_name: review.author_name,
      rating: review.rating,
      text: review.text,
      time: review.time,
      profile_photo_url: review.profile_photo_url
    })),
    user_ratings_total: venue.user_ratings_total,
    opening_hours: venue.opening_hours ? {
      open_now: venue.opening_hours.open_now,
      periods: venue.opening_hours.periods,
      weekday_text: venue.opening_hours.weekday_text
    } : undefined,
    details: {
      website: venue.website,
      phone: venue.formatted_phone_number,
      openingHours: venue.opening_hours?.weekday_text?.join('\n')
    },
    source: 'singapore',
    lastUpdated: new Date().toISOString(),
    description: venue.description || venue.editorial_summary?.overview,
    logo: venue.logo
  };

  // Add fallback image if no photos are available
  if (!formattedVenue.photos?.length) {
    const venueType = formattedVenue.type;
    const fallbackImage = VENUE_TYPE_IMAGES[venueType as keyof typeof VENUE_TYPE_IMAGES] || VENUE_TYPE_IMAGES.default;
    formattedVenue.photos = [{
      height: 400,
      width: 400,
      url: fallbackImage,
      photo_reference: fallbackImage
    }];
  }

  return formattedVenue;
}

// Helper functions remain the same...
function generateUniqueVenueId(venue: SingaporeVenue): string {
  if (venue.place_id) {
    return `sg-${venue.place_id}`;
  }

  const coordinates = `${venue.geometry.location.lat}-${venue.geometry.location.lng}`
    .replace(/\./g, '')
    .slice(0, 10);

  const nameSlug = venue.name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '')
    .slice(0, 30);

  return `sg-${nameSlug}-${coordinates}`;
}

function determineVenueType(activities: string[]): string {
  if (activities.includes('tennis')) return 'Tennis';
  if (activities.includes('golf')) return 'Golf';
  if (activities.includes('swimming') || activities.includes('aquatics')) return 'Swimming';
  if (activities.includes('gym')) return 'Gym';
  if (activities.includes('fitness')) return 'Fitness';
  if (activities.length > 1) return 'Multi-Sport';
  return 'Fitness';
}

function extractAmenities(venue: SingaporeVenue): string[] {
  const amenities = new Set<string>();

  if (venue.activities?.includes('gym') || venue.activities?.includes('fitness')) {
    amenities.add(FACILITY_AMENITIES.GYM);
    amenities.add(FACILITY_AMENITIES.FITNESS_CENTER);
  }

  if (venue.activities?.includes('spa')) {
    amenities.add(FACILITY_AMENITIES.SPA);
    amenities.add(FACILITY_AMENITIES.SAUNA);
  }

  if (venue.activities?.includes('retail') || venue.activities?.includes('shop')) {
    amenities.add(FACILITY_AMENITIES.PRO_SHOP);
    amenities.add(FACILITY_AMENITIES.EQUIPMENT_RENTAL);
  }

  if (venue.activities?.includes('restaurant') || venue.activities?.includes('cafe')) {
    amenities.add(FACILITY_AMENITIES.RESTAURANT);
    amenities.add(FACILITY_AMENITIES.CAFE);
  }

  if (venue.activities?.includes('parking')) {
    amenities.add(FACILITY_AMENITIES.PARKING);
  }

  if (venue.activities?.some(activity => 
    ['tennis', 'squash', 'badminton', 'basketball', 'golf'].includes(activity)
  )) {
    amenities.add(FACILITY_AMENITIES.TRAINING);
    amenities.add(FACILITY_AMENITIES.LESSONS);
    amenities.add(FACILITY_AMENITIES.COACHING);
  }

  return Array.from(amenities);
}