import React from 'react';
import { Star } from 'lucide-react';
import { cn } from '@/lib/utils';

interface RateActivityButtonProps {
  onClick: () => void;
  className?: string;
}

const RateActivityButton: React.FC<RateActivityButtonProps> = ({ onClick, className }) => (
  <button
    onClick={onClick}
    className={cn(
      "flex items-center justify-center gap-2 h-10",
      "bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors",
      "text-sm font-medium whitespace-nowrap",
      "px-2 sm:px-4",
      className
    )}
  >
    <Star className="h-4 w-4" />
    <span className="hidden sm:inline">Rate Activity</span>
    <span className="sm:hidden">Rate</span>
  </button>
);

export default RateActivityButton;