import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '../ui/avatar';
import { useAuth } from '../../contexts/AuthContext';
import { avatarCache } from '@/lib/utils/avatarCache'; 
import { cn } from '@/lib/utils';
import { useProfile } from '@/hooks/useProfile';

const ProfileButton: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { profile, fetchProfile } = useProfile();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user?.id) {
      const loadAvatar = async () => {
        try {
          setIsLoading(true);
          setError(null);

          // Try to get from cache first
          const cached = avatarCache.getAvatar(user.id);
          if (cached && cached !== avatarCache.getDefaultAvatar()) {
            setAvatarUrl(cached);
            return;
          }

          // Fetch profile with retries
          await fetchProfile();
        } catch (err) {
          console.error('Error loading avatar:', err);
          // Use default avatar on error
          setAvatarUrl(avatarCache.getDefaultAvatar());
        } finally {
          setIsLoading(false);
        }
      }

      loadAvatar();
    }

    return () => {
      isMounted.current = false;
    };
  }, [user?.id]);

  // Update avatar when profile changes
  useEffect(() => {
    if (profile?.avatar_url && isMounted.current && !isLoading) {
      setAvatarUrl(profile.avatar_url);
      if (user?.id) {
        avatarCache.setAvatar(user.id, profile.avatar_url);
      }
    }
  }, [profile?.avatar_url, user?.id, isLoading]);

  return (
    <Avatar
      src={avatarUrl}
      onClick={() => navigate('/dashboard/profile')}
      className={cn(
        "mr-2 transition-all duration-300 transform",
        isLoading && "opacity-50"
      )}
    />
  );
};

export default ProfileButton;