import React, { useState } from 'react';
import { Search, Filter, RefreshCw } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { Switch } from '@/components/ui/switch';
import { motion } from 'framer-motion';

interface QuickPlayFiltersProps {
  searchTerm: string;
  selectedSport: string;
  selectedGender: string;
  selectedRating: string;
  showConnectionsOnly: boolean;
  onSearchChange: (value: string) => void;
  onSportChange: (value: string) => void;
  onGenderChange: (value: string) => void;
  onRatingChange: (value: string) => void;
  onConnectionsOnlyChange: (value: boolean) => void;
  sports: string[];
  showFilters: boolean;
  onToggleFilters: () => void;
  onSearchAgain: () => void;
  className?: string;
}

const QuickPlayFilters: React.FC<QuickPlayFiltersProps> = ({
  searchTerm,
  selectedSport,
  selectedGender,
  selectedRating,
  showConnectionsOnly,
  onSearchChange,
  onSportChange,
  onGenderChange,
  onRatingChange,
  onConnectionsOnlyChange,
  sports,
  showFilters,
  onToggleFilters,
  onSearchAgain,
  className
}) => {
  const { isDarkMode } = useTheme();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    setIsRefreshing(true);
    // Force new search
    onSearchAgain();
    // Add visual feedback
    setTimeout(() => setIsRefreshing(false), 1000);
  };

  return (
    <div className={cn("space-y-4", className)}>
      <div className="flex flex-col space-y-4">
        {/* Search Bar with Integrated Controls */}
        <div className="flex items-center gap-2">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <input
              type="text"
              placeholder="Find players, sports, locations, companies..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className={cn(
                "w-full pl-10 pr-4 py-3 bg-transparent",
                "text-white placeholder-dark-800",
                "focus:outline-none focus:ring-2 focus:ring-gold-400",
                isDarkMode 
                  ? "bg-dark-300 border-dark-400" 
                  : "bg-white border-gray-200",
                "rounded-xl border"
              )}
            />
          </div>
          
          <div className="flex-shrink-0 flex items-center gap-2">
            <motion.button 
              onClick={handleRefresh}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={{ rotate: isRefreshing ? 360 : 0 }}
              transition={{ duration: 1, ease: "linear" }}
              className={cn(
                "p-2 rounded-lg transition-colors flex-shrink-0",
                isDarkMode
                  ? "bg-dark-400 hover:bg-dark-500 text-white"
                  : "bg-white hover:bg-gray-50 text-gray-900"
              )}
              disabled={isRefreshing}
            >
              <RefreshCw className="h-5 w-5" />
            </motion.button>
            <button 
              onClick={onToggleFilters}
              className={cn(
                "flex items-center space-x-2 px-3 py-2 rounded-lg transition-colors flex-shrink-0",
                isDarkMode
                  ? "bg-dark-300 hover:bg-dark-400 text-white"
                  : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-50"
              )}
            >
              <Filter className="h-5 w-5" />
              <span className="hidden sm:inline">{showFilters ? 'Hide' : 'Filters'}</span>
            </button>
          </div>
        </div>

        {/* Additional Filters */}
        {showFilters && (
          <div className="space-y-4">
            {/* Filters Row */}
            <div className="grid grid-cols-3 gap-4">
              <select
                value={selectedSport}
                onChange={(e) => onSportChange(e.target.value)}
                className={cn(
                  "h-[38px] px-4 rounded-xl text-base transition-colors duration-200 border",
                  isDarkMode
                    ? "bg-dark-300 border-dark-400 text-white"
                    : "bg-white border-gray-200 text-gray-900"
                )}
              >
                <option value="all">Sports</option>
                {sports.filter(sport => sport !== 'all').map((sport) => (
                  <option key={sport} value={sport}>{sport}</option>
                ))}
              </select>

              <select
                value={selectedRating}
                onChange={(e) => onRatingChange(e.target.value)}
                className={cn(
                  "h-[38px] px-4 rounded-xl text-base transition-colors duration-200 border",
                  isDarkMode
                    ? "bg-dark-300 border-dark-400 text-white"
                    : "bg-white border-gray-200 text-gray-900"
                )}
              >
                <option value="all">Ratings</option>
                <option value="4.8">4.8+ ★</option>
                <option value="4.5">4.5+ ★</option>
                <option value="4.0">4.0+ ★</option>
              </select>

              <select
                value={selectedGender}
                onChange={(e) => onGenderChange(e.target.value)}
                className={cn(
                  "h-[38px] px-4 rounded-xl text-base transition-colors duration-200 border",
                  isDarkMode
                    ? "bg-dark-300 border-dark-400 text-white"
                    : "bg-white border-gray-200 text-gray-900"
                )}
              >
                <option value="all">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="rather_not_say">Rather not say</option>
              </select>
            </div>

            {/* Connections Only Toggle */}
            <div className="flex items-center justify-between bg-dark-200 rounded-xl p-4 border border-dark-300">
              <span className={isDarkMode ? "text-white" : "text-gray-900"}>
                Show Connections Only
              </span>
              <Switch
                checked={showConnectionsOnly}
                onCheckedChange={onConnectionsOnlyChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickPlayFilters;