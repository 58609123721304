import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Star, Calendar, Search, Filter } from 'lucide-react';
import { trainers } from '../../data/trainers';
import { Input } from '../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';
import RequestMatchModal from '../matchmaking/RequestMatchModal';

const TrainerSearch: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [selectedRating, setSelectedRating] = useState('all');
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState<any>(null);

  const sports = ['all', ...new Set(trainers.flatMap(trainer => trainer.sports))];
  const cities = ['all', ...new Set(trainers.map(trainer => trainer.city))];

  const filteredTrainers = trainers.filter(trainer => {
    const matchesSearch = trainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         trainer.city.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSport = selectedSport === 'all' || trainer.sports.includes(selectedSport);
    const matchesCity = selectedCity === 'all' || trainer.city === selectedCity;
    const matchesRating = selectedRating === 'all' || trainer.rating >= parseFloat(selectedRating);
    
    return matchesSearch && matchesSport && matchesCity && matchesRating;
  });

  const handleProfileClick = (trainerId: string) => {
    navigate(`/dashboard/members/trainer-${trainerId}`);
  };

  const handleRequestMatch = (trainer: any) => {
    setSelectedTrainer(trainer);
    setShowMatchModal(true);
  };

  return (
    <div className="space-y-6">
      {/* Search and Filters */}
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex flex-col space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
            <Input
              type="text"
              placeholder="Search trainers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 bg-dark-300 border-dark-400 text-white"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <Select value={selectedSport} onValueChange={setSelectedSport}>
              <SelectTrigger>
                <SelectValue placeholder="All Sports" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Sports</SelectItem>
                {sports.filter(sport => sport !== 'all').map((sport) => (
                  <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={selectedCity} onValueChange={setSelectedCity}>
              <SelectTrigger>
                <SelectValue placeholder="All Cities" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Cities</SelectItem>
                {cities.filter(city => city !== 'all').map((city) => (
                  <SelectItem key={city} value={city}>{city}</SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={selectedRating} onValueChange={setSelectedRating}>
              <SelectTrigger>
                <SelectValue placeholder="All Ratings" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Ratings</SelectItem>
                <SelectItem value="4.8">4.8+ ★</SelectItem>
                <SelectItem value="4.5">4.5+ ★</SelectItem>
                <SelectItem value="4.0">4.0+ ★</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      {/* Results Count */}
      <div className="text-dark-800">
        Found {filteredTrainers.length} trainers
      </div>

      {/* Trainers List */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredTrainers.map((trainer) => (
          <div
            key={trainer.id}
            className="bg-dark-200 rounded-xl p-6 border border-dark-300 hover:border-gold-400 transition-all duration-300"
          >
            <div className="flex items-start space-x-4">
              <button
                onClick={() => handleProfileClick(trainer.id)}
                className="flex-shrink-0"
              >
                <img
                  src={trainer.image}
                  alt={trainer.name}
                  className="w-16 h-16 rounded-full object-cover border-2 border-gold-400 hover:border-gold-500 transition-colors"
                />
              </button>
              <div className="flex-1">
                <div className="flex items-start justify-between">
                  <div>
                    <button
                      onClick={() => handleProfileClick(trainer.id)}
                      className="text-white font-medium hover:text-gold-400 transition-colors"
                    >
                      {trainer.name}
                    </button>
                    <div className="flex items-center text-dark-800 mt-1">
                      <MapPin className="h-4 w-4 mr-1" />
                      {trainer.city}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Star className="h-4 w-4 text-gold-400 mr-1" />
                    <span className="text-white">{trainer.rating}</span>
                    <span className="text-dark-800 ml-1">({trainer.reviews})</span>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 mt-3">
                  {trainer.sports.map((sport, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-dark-300 rounded-full text-xs text-white"
                    >
                      {sport}
                    </span>
                  ))}
                </div>

                <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-300">
                  <span className="text-dark-800">{trainer.priceRange}</span>
                  <button
                    onClick={() => handleRequestMatch(trainer)}
                    className="flex items-center space-x-2 px-4 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors"
                  >
                    <Calendar className="h-4 w-4" />
                    <span>Request Match</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Match Request Modal */}
      {selectedTrainer && (
        <RequestMatchModal
          isOpen={showMatchModal}
          onClose={() => {
            setShowMatchModal(false);
            setSelectedTrainer(null);
          }}
          member={{
            id: selectedTrainer.id,
            name: selectedTrainer.name,
            city: selectedTrainer.city,
            sports: selectedTrainer.sports
          }}
        />
      )}
    </div>
  );
};

export default TrainerSearch;