import React, { useState, useEffect } from 'react';
import { MapPin, Compass, Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '../../../contexts/ThemeContext';
import { useNotificationStore } from '../../../store/notificationStore';
import { searchVenuesWithGeocode } from '../../../lib/venue';
import { useDebounce } from 'use-debounce';

interface VenueSelectorProps {
  city: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

const VenueSelector: React.FC<VenueSelectorProps> = ({
  city,
  value,
  onChange,
  placeholder = "Enter venue name or address",
  className
}) => {
  const { isDarkMode } = useTheme();
  const { addNotification } = useNotificationStore();
  const [isLocating, setIsLocating] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState<Array<{ name: string; address: string }>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [debouncedValue] = useDebounce(value, 300);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!debouncedValue.trim()) {
        setSuggestions([]);
        return;
      }

      setIsSearching(true);
      try {
        const results = await searchVenuesWithGeocode(debouncedValue, city);
        setSuggestions(
          results.map(result => ({
            name: result.name || result.address || '',
            address: 'address' in result ? result.address || '' : ''
          }))
        );
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      } finally {
        setIsSearching(false);
      }
    };

    fetchSuggestions();
  }, [debouncedValue, city]);

  const handleGetCurrentLocation = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!navigator.geolocation) {
      addNotification({
        id: `location_${Date.now()}`,
        type: 'new_message',
        title: 'Location Not Available',
        message: 'Geolocation is not supported by your browser',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });
      return;
    }

    setIsLocating(true);
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      // Reverse geocode the coordinates
      const response = await fetch(
        `https://photon.komoot.io/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      );
      const data = await response.json();
      
      if (data.features?.[0]?.properties) {
        const location = data.features[0].properties;
        const address = [
          location.street,
          location.housenumber,
          location.city || city
        ].filter(Boolean).join(', ');
        
        onChange(address);
        setIsOpen(false);
      }
    } catch (error) {
      console.error('Error getting location:', error);
      addNotification({
        id: `location_error_${Date.now()}`,
        type: 'new_message',
        title: 'Location Error',
        message: 'Failed to get your current location',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard'
      });
    } finally {
      setIsLocating(false);
    }
  };

  const handleSuggestionClick = (suggestion: { name: string; address: string }) => {
    onChange(suggestion.name || suggestion.address);
    setIsOpen(false);
  };

  return (
    <div className={cn("relative", className)}>
      <div className="relative">
        <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
        <input
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          className={cn(
  "w-full pl-10 pr-12 py-2 border rounded-xl",
  "placeholder-dark-800 dark:placeholder-dark-800",
  "focus:outline-none focus:ring-2 focus:ring-gold-400",
  isDarkMode 
    ? "bg-dark-300 border-dark-400 text-white" 
    : "bg-white border-gray-200 text-gray-900",
  className
)}
        />
        <button
          onClick={handleGetCurrentLocation}
          disabled={isLocating}
          className={cn(
            "absolute right-3 top-1/2 transform -translate-y-1/2",
            "p-1.5 rounded-lg transition-colors",
            isDarkMode 
              ? "hover:bg-dark-400 text-dark-800 hover:text-white"
              : "hover:bg-gray-100 text-gray-500 hover:text-gray-700",
            "disabled:opacity-50 disabled:cursor-not-allowed"
          )}
          title="Use current location"
        >
          {isLocating ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Compass className="h-4 w-4" />
          )}
        </button>
      </div>

      {/* Suggestions Dropdown */}
      {isOpen && suggestions.length > 0 && (
        <div className={cn(
  "absolute z-50 w-full mt-1 border rounded-xl shadow-lg",
  isDarkMode
    ? "bg-dark-200 border-dark-400"
    : "bg-white border-gray-200"
)}>
  <div className="py-1 max-h-[200px] overflow-y-auto">
    {suggestions.map((suggestion, index) => (
      <button
        key={`${suggestion.name}-${index}`}
        onClick={() => handleSuggestionClick(suggestion)}
        className={cn(
          "w-full flex items-start px-3 py-2 transition-colors text-left",
          isDarkMode
            ? "hover:bg-dark-300 text-white"
            : "hover:bg-gray-100 text-gray-900",
          value === (suggestion.name || suggestion.address) && 
            (isDarkMode ? "bg-dark-300" : "bg-gray-100")
        )}
      >
        <MapPin className={cn(
          "h-4 w-4 mt-0.5 mr-2 flex-shrink-0",
          isDarkMode ? "text-dark-800" : "text-gray-400"
        )} />
        <div>
          <div className={isDarkMode ? "text-white" : "text-gray-900"}>
            {suggestion.name || suggestion.address}
          </div>
          {suggestion.address && suggestion.name !== suggestion.address && (
            <div className={isDarkMode ? "text-dark-800" : "text-gray-500"}>
              {suggestion.address}
            </div>
          )}
        </div>
      </button>
    ))}
  </div>
</div>
      )}

      {isSearching && (
        <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
          <Loader2 className="h-4 w-4 text-dark-800 animate-spin" />
        </div>
      )}
    </div>
  );
};

export default VenueSelector;