// Cache configuration
export const CACHE_DURATION = 60 * 1000; // 1 minute cache

// Memory cache for activities
export const activitiesCache = new Map<string, {
  data: any[];
  timestamp: number;
}>();

// Cache management functions
function getCachedActivities(): any[] | null {
  const cached = activitiesCache.get('activities');
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < CACHE_DURATION) {
    return cached.data;
  }

  activitiesCache.delete('activities');
  return null;
}

function setCachedActivities(data: any[]) {
  activitiesCache.set('activities', {
    data,
    timestamp: Date.now()
  });

  // Broadcast cache invalidation to other tabs
  window.localStorage.setItem('activities-cache-invalidated', Date.now().toString());
}

export function clearActivitiesCache() {
  activitiesCache.clear();
  // Broadcast cache invalidation
  window.localStorage.setItem('activities-cache-invalidated', Date.now().toString());
}

// Helper function to check if cache is stale
function isCacheStale(): boolean {
  const cached = activitiesCache.get('activities');
  if (!cached) return true;

  const now = Date.now();
  const age = now - cached.timestamp;
  return age >= CACHE_DURATION;
}