import { supabase } from '../supabase';
import { OnboardingData } from '../../types/onboarding';

export async function submitMembershipApplication(data: OnboardingData) {
  try {
    // Validate required fields
    if (!data.fullName || !data.email || !data.company || !data.city) {
      throw new Error('Missing required fields: fullName, email, company, and city are mandatory.');
    }

    // Call the RPC function
    const { data: result, error } = await supabase.rpc('handle_membership_application', {
      application_data: {
        fullName: data.fullName,
        email: data.email,
        company: data.company,
        role: data.role, // Updated field name
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
        city: data.city,
        country: data.country, // Updated field name
        phone: data.phone,
        linkedinUrl: data.linkedinUrl,
      },
    });

    // Handle errors from Supabase
    if (error) {
      console.error('Membership application error:', error);
      throw new Error('Failed to submit membership application. Please try again.');
    }

    // Ensure a result was returned
    if (!result) {
      throw new Error('No response from the server. Please try again later.');
    }

    // Return the result to trigger a success modal
    return result;
  } catch (error) {
    // Log and rethrow errors for handling in the calling function
    console.error('Error submitting membership application:', error);
    throw error instanceof Error
      ? error
      : new Error('An unexpected error occurred during submission.');
  }
}
