import React, { useState } from 'react';
import { Star, ChevronRight, MapPin } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/dialog';
import { cn } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

interface RatingStep {
  title: string;
  description: string;
  category: string;
}

const RATING_STEPS: RatingStep[] = [
  {
    title: 'How was your overall experience?',
    description: 'Rate your overall experience with this activity',
    category: 'overall'
  },
  {
    title: 'How was their punctuality?',
    description: 'Did they arrive on time and respect your schedule?',
    category: 'punctuality'
  },
  {
    title: 'How was their sportsmanship?',
    description: 'Were they respectful and showed good sportsmanship?',
    category: 'sportsmanship'
  },
  {
    title: 'How well did your skill levels match?',
    description: 'Was the skill level appropriate for a good match?',
    category: 'skillLevelMatch'
  },
  {
    title: 'How was their communication?',
    description: 'Were they responsive and clear in communication?',
    category: 'communication'
  },
  {
    title: 'How was the venue?',
    description: 'Rate the quality and condition of the venue',
    category: 'venue'
  }
];

interface NewRatingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (ratings: Record<string, number>, feedback: string) => Promise<void>;
  playerName: string;
  venueName: string;
  isSubmitting?: boolean;
}

const NewRatingModal: React.FC<NewRatingModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  playerName,
  venueName,
  isSubmitting = false
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [ratings, setRatings] = useState<Record<string, number>>({});
  const [feedback, setFeedback] = useState('');
  const [hoveredRating, setHoveredRating] = useState<number | null>(null);

  const handleRatingSelect = (rating: number) => {
    setRatings(prev => ({
      ...prev,
      [RATING_STEPS[currentStep].category]: rating
    }));

    // Auto advance to next step after rating
    if (currentStep < RATING_STEPS.length - 1) {
      setTimeout(() => setCurrentStep(prev => prev + 1), 500);
    }
  };

  const handleSubmit = async () => {
    try {
      await onSubmit(ratings, feedback);
      onClose();
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const isComplete = Object.keys(ratings).length === RATING_STEPS.length;

  const getRatingLabel = (rating: number, category: string) => {
    const labels: Record<string, string[]> = {
      overall: ['Poor', 'Fair', 'Good', 'Great', 'Excellent'],
      punctuality: ['Very Late', 'Late', 'On Time', 'Early', 'Very Punctual'],
      sportsmanship: ['Poor', 'Fair', 'Good', 'Great', 'Excellent'],
      skillLevelMatch: ['Mismatched', 'Slightly Off', 'Fair Match', 'Good Match', 'Perfect Match'],
      communication: ['Poor', 'Fair', 'Good', 'Great', 'Excellent'],
      venue: ['Poor', 'Fair', 'Good', 'Great', 'Excellent']
    };
    return labels[category]?.[rating - 1] || '';
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <DialogHeader>
          <DialogTitle className="text-center">Rate Your Experience</DialogTitle>
        </DialogHeader>

        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
            className="py-6"
          >
            {/* Step Title */}
            <h3 className="text-lg font-medium text-center mb-2">
              {RATING_STEPS[currentStep].title}
            </h3>

            {/* Context (Player Name or Venue) */}
            <p className="text-dark-800 text-center mb-6">
              {RATING_STEPS[currentStep].category === 'venue' ? (
                <span className="flex items-center justify-center gap-2">
                  <MapPin className="h-4 w-4" />
                  {venueName}
                </span>
              ) : (
                `with ${playerName}`
              )}
            </p>

            {/* Star Rating */}
            <div className="flex justify-center space-x-2 mb-4">
              {[1, 2, 3, 4, 5].map((rating) => (
                <button
                  key={rating}
                  onMouseEnter={() => setHoveredRating(rating)}
                  onMouseLeave={() => setHoveredRating(null)}
                  onClick={() => handleRatingSelect(rating)}
                  className="p-1 transition-transform hover:scale-110"
                >
                  <Star 
                    className={cn(
                      "h-8 w-8 transition-colors",
                      rating <= (hoveredRating || ratings[RATING_STEPS[currentStep].category] || 0)
                        ? "text-gold-400 fill-gold-400"
                        : "text-dark-800"
                    )}
                  />
                </button>
              ))}
            </div>

            {/* Rating Label */}
            <div className="h-6 text-center mb-6">
              {(hoveredRating || ratings[RATING_STEPS[currentStep].category]) && (
                <motion.span
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-gold-400"
                >
                  {getRatingLabel(
                    hoveredRating || ratings[RATING_STEPS[currentStep].category] || 0,
                    RATING_STEPS[currentStep].category
                  )}
                </motion.span>
              )}
            </div>

            {/* Progress Indicator */}
            <div className="flex justify-center space-x-2 mb-6">
              {RATING_STEPS.map((_, index) => (
                <div
                  key={index}
                  className={cn(
                    "w-2 h-2 rounded-full transition-colors",
                    index === currentStep
                      ? "bg-gold-400"
                      : index < currentStep
                      ? "bg-dark-400"
                      : "bg-dark-300"
                  )}
                />
              ))}
            </div>

            {/* Navigation */}
            <div className="flex justify-between items-center">
              {currentStep > 0 && (
                <button
                  onClick={() => setCurrentStep(prev => prev - 1)}
                  className="text-dark-800 hover:text-white transition-colors"
                >
                  Back
                </button>
              )}
              <div className="flex-1" />
              {currentStep < RATING_STEPS.length - 1 ? (
                <button
                  onClick={() => setCurrentStep(prev => prev + 1)}
                  disabled={!ratings[RATING_STEPS[currentStep].category]}
                  className={cn(
                    "flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors",
                    ratings[RATING_STEPS[currentStep].category]
                      ? "bg-gold-400 hover:bg-gold-500 text-dark-50"
                      : "bg-dark-300 text-dark-800 cursor-not-allowed"
                  )}
                >
                  <span>Next</span>
                  <ChevronRight className="h-4 w-4" />
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  disabled={!isComplete || isSubmitting}
                  className={cn(
                    "px-4 py-2 rounded-lg transition-colors",
                    isComplete
                      ? "bg-gold-400 hover:bg-gold-500 text-dark-50"
                      : "bg-dark-300 text-dark-800 cursor-not-allowed"
                  )}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

export default NewRatingModal;