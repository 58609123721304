import { Trip } from '../types/trip';

export const pastTrips: Trip[] = [
  {
    id: '1',
    city: 'Monte Carlo',
    startDate: '2024-01-15',
    endDate: '2024-01-20',
    shareOnFeed: true,
    activities: [
      {
        id: 'mc-1',
        sport: '🎾 Tennis',
        opponent: {
          id: '51',
          name: 'Sarah Connor',
          image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=300&q=80'
        },
        date: '2024-01-16',
        time: '10:00',
        venue: {
          name: 'Monte Carlo Tennis Club',
          address: '12 Avenue des Spélugues',
          city: 'Monte Carlo'
        },
        status: 'played'
      },
      {
        id: 'mc-2',
        sport: '⛳ Golf',
        opponent: {
          id: '52',
          name: 'James Wilson',
          image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&w=300&q=80'
        },
        date: '2024-01-18',
        time: '14:00',
        venue: {
          name: 'Monte Carlo Golf Club',
          address: 'Route du Mont Agel',
          city: 'Monte Carlo'
        },
        status: 'played'
      }
    ],
    createdAt: '2024-01-10T10:00:00.000Z'
  },
  {
    id: '2',
    city: 'London',
    startDate: '2024-02-01',
    endDate: '2024-02-05',
    shareOnFeed: true,
    activities: [
      {
        id: 'lon-1',
        sport: '🎾 Tennis',
        opponent: {
          id: 'lon1',
          name: 'Alexander Wright',
          image: 'https://images.unsplash.com/photo-1463453091185-61582044d556?auto=format&fit=crop&w=300&q=80'
        },
        date: '2024-02-02',
        time: '15:00',
        venue: {
          name: 'Queens Club',
          address: 'Palliser Road',
          city: 'London'
        },
        status: 'played'
      }
    ],
    createdAt: '2024-01-25T10:00:00.000Z'
  },
  {
    id: '3',
    city: 'Dubai',
    startDate: '2024-02-10',
    endDate: '2024-02-15',
    shareOnFeed: true,
    activities: [
      {
        id: 'dub-1',
        sport: '⛳ Golf',
        opponent: {
          id: 'dub1',
          name: 'Hassan Al-Rashid',
          image: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?auto=format&fit=crop&w=300&q=80'
        },
        date: '2024-02-12',
        time: '09:00',
        venue: {
          name: 'Emirates Golf Club',
          address: 'Emirates Hills 2',
          city: 'Dubai'
        },
        status: 'played'
      },
      {
        id: 'dub-2',
        sport: '🎾 Tennis',
        opponent: {
          id: 'dub2',
          name: 'Sophia Al-Malik',
          image: 'https://images.unsplash.com/photo-1548142813-c348350df52b?auto=format&fit=crop&w=300&q=80'
        },
        date: '2024-02-13',
        time: '16:00',
        venue: {
          name: 'Dubai Tennis Stadium',
          address: 'Dubai Tennis Stadium',
          city: 'Dubai'
        },
        status: 'played'
      }
    ],
    createdAt: '2024-02-05T10:00:00.000Z'
  }
];

const futureTripCities = [
  'Singapore',
  'Paris',
  'Barcelona',
  'Rome',
  'Madrid',
  'Luxembourg',
  'Washington DC'
];