import React from 'react';
import { MoreVertical } from 'lucide-react';
import { cn } from '@/lib/utils';

interface MenuButtonProps {
  onClick: () => void;
  className?: string;
}

export const MenuButton: React.FC<MenuButtonProps> = ({ onClick, className }) => (
  <button 
    onClick={onClick}
    className={cn(
      "text-dark-800 hover:text-gold-400 p-2 transition-colors",
      className
    )}
  >
    <MoreVertical className="h-5 w-5" />
  </button>
);