import * as React from "react"
import { Command as CommandPrimitive } from "cmdk"
import { Search } from "lucide-react"
import { cn } from "@/lib/utils"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { useTheme } from "@/contexts/ThemeContext"

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  
  return (
    <CommandPrimitive
      ref={ref}
      className={cn(
        "flex h-full w-full flex-col overflow-hidden rounded-md",
        isDarkMode ? "bg-dark-200 text-white" : "bg-white text-gray-900",
        className
      )}
      {...props}
    />
  );
})
Command.displayName = CommandPrimitive.displayName

interface CommandDialogProps extends React.ComponentProps<typeof Dialog> {}

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
  const { isDarkMode } = useTheme();
  
  return (
    <Dialog {...props}>
      <DialogContent className="overflow-hidden p-0 shadow-lg">
        <Command className={cn(
          "[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5",
          isDarkMode 
            ? "[&_[cmdk-group-heading]]:text-dark-800" 
            : "[&_[cmdk-group-heading]]:text-gray-500"
        )}>
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  )
}

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={cn(
      "flex items-center border-b px-3",
      isDarkMode ? "border-dark-300" : "border-gray-200"
    )} cmdk-input-wrapper="">
      <Search className={cn(
        "mr-2 h-4 w-4 shrink-0 opacity-50",
        isDarkMode ? "text-dark-800" : "text-gray-400"
      )} />
      <CommandPrimitive.Input
        ref={ref}
        className={cn(
          "flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50",
          isDarkMode 
            ? "placeholder:text-dark-800" 
            : "placeholder:text-gray-500",
          className
        )}
        {...props}
      />
    </div>
  )
})

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn("max-h-[300px] overflow-y-auto overflow-x-hidden", className)}
    {...props}
  />
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  
  return (
    <CommandPrimitive.Empty
      ref={ref}
      className={cn(
        "py-6 text-center text-sm",
        isDarkMode ? "text-dark-800" : "text-gray-500",
        className
      )}
      {...props}
    />
  );
})

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  
  return (
    <CommandPrimitive.Group
      ref={ref}
      className={cn(
        "overflow-hidden p-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium",
        isDarkMode 
          ? "text-white [&_[cmdk-group-heading]]:text-dark-800"
          : "text-gray-900 [&_[cmdk-group-heading]]:text-gray-500",
        className
      )}
      {...props}
    />
  );
})

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  
  return (
    <CommandPrimitive.Separator
      ref={ref}
      className={cn(
        "-mx-1 h-px",
        isDarkMode ? "bg-dark-300" : "bg-gray-200",
        className
      )}
      {...props}
    />
  );
})
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => {
  const { isDarkMode } = useTheme();
  
  return (
    <CommandPrimitive.Item
      ref={ref}
      className={cn(
        "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        isDarkMode
          ? "aria-selected:bg-dark-300 aria-selected:text-white"
          : "aria-selected:bg-gray-100 aria-selected:text-gray-900",
        className
      )}
      {...props}
    />
  );
})

CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  const { isDarkMode } = useTheme();
  
  return (
    <span
      className={cn(
        "ml-auto text-xs tracking-widest",
        isDarkMode ? "text-dark-800" : "text-gray-500",
        className
      )}
      {...props}
    />
  );
}
CommandShortcut.displayName = "CommandShortcut"

export {
  Command,
  
  
  CommandList,
  
  CommandGroup,
  CommandItem,
  
  
}