import React from 'react';
import { Trophy, Users, Globe, Shield } from 'lucide-react';
import { Button } from '../../ui/button';

interface RequestAccessWelcomeProps {
  onNext: () => void;
}

export const RequestAccessWelcome: React.FC<RequestAccessWelcomeProps> = ({ onNext }) => {
  return (
    <div className="bg-dark-200 rounded-xl p-6 border border-dark-300">
      <h1 className="text-2xl font-bold text-white text-center mb-6">
        Ready to Play the World?
      </h1>
      
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="bg-dark-300 rounded-xl p-4 text-center">
          <div className="bg-dark-400 w-10 h-10 rounded-full flex items-center justify-center mx-auto mb-3">
            <Users className="h-5 w-5 text-gold-400" />
          </div>
          <h3 className="text-base font-medium text-white mb-1">Elite Network</h3>
          <p className="text-dark-800 text-sm">Connect with top professionals</p>
        </div>

        <div className="bg-dark-300 rounded-xl p-4 text-center">
          <div className="bg-dark-400 w-10 h-10 rounded-full flex items-center justify-center mx-auto mb-3">
            <Trophy className="h-5 w-5 text-gold-400" />
          </div>
          <h3 className="text-base font-medium text-white mb-1">Quick Match</h3>
          <p className="text-dark-800 text-sm">Find your perfect match</p>
        </div>

        <div className="bg-dark-300 rounded-xl p-4 text-center">
          <div className="bg-dark-400 w-10 h-10 rounded-full flex items-center justify-center mx-auto mb-3">
            <Globe className="h-5 w-5 text-gold-400" />
          </div>
          <h3 className="text-base font-medium text-white mb-1">Global Access</h3>
          <p className="text-dark-800 text-sm">Premium venues worldwide</p>
        </div>

        <div className="bg-dark-300 rounded-xl p-4 text-center">
          <div className="bg-dark-400 w-10 h-10 rounded-full flex items-center justify-center mx-auto mb-3">
            <Shield className="h-5 w-5 text-gold-400" />
          </div>
          <h3 className="text-base font-medium text-white mb-1">Exclusive Club</h3>
          <p className="text-dark-800 text-sm">Verified members only</p>
        </div>
      </div>

      <Button 
        onClick={onNext}
        className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50 py-3 text-base font-medium"
      >
        &gt;&gt; Start
      </Button>
    </div>
  );
};