import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { cities } from '../../data/cities';
import CityCardList from './CityCardList';
import CityTabs from './CityTabs';
import CityMembersList from './CityMembersList';
import CityVenuesList from './CityVenuesList';
import { VenueApiList } from './venues-api';
import CityMembersModal from './CityMembersModal';
import BlurOverlay from '../ui/BlurOverlay';

interface LocationState {
  selectedCity?: string;
  showFacilityDetails?: boolean;
  facilityId?: string;
  activeTab?: 'clubs' | 'players' | 'trainers';
}

const FacilityManagement: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  
  const [selectedCity, setSelectedCity] = useState<string | null>(state?.selectedCity || null);
  const [activeTab, setActiveTab] = useState<'clubs' | 'players' | 'trainers'>(state?.activeTab || 'clubs');
  const [showMembersModal, setShowMembersModal] = useState(false);

  const handleCityClick = useCallback((city: string) => {
    setSelectedCity(city);
  }, []);

  const handleBack = () => {
    setSelectedCity(null);
    setActiveTab('clubs');
  };

  const handleTabChange = (tab: 'venues-api' | 'players' | 'trainers') => {
    setActiveTab(tab);
  };

  return (
    <div className="space-y-6">
      <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="text-2xl font-bold text-white">Cities</h1>
            <p className="text-dark-800 mt-1">Find premium clubs and facilities worldwide</p>
          </div>
        </div>
      </div>

      {selectedCity ? (
        <>
          <button
            onClick={handleBack}
            className="flex items-center space-x-2 px-4 py-2 bg-dark-200 rounded-xl text-white hover:bg-dark-300 transition-colors border border-dark-300"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back to Cities</span>
          </button>

          <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
            <div className="relative h-64">
              <img
                src={cities.find(c => c.name === selectedCity)?.image}
                alt={`${selectedCity} cityscape`}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark-100/90 via-dark-900/10 to-transparent" />
              <div className="absolute bottom-6 left-6">
                <h2 className="text-2xl font-bold text-white !text-white">
                  {selectedCity}
                </h2>
              </div>
            </div>

            <div className="p-6">
              <CityTabs activeTab={activeTab} onTabChange={handleTabChange} />
              
              <div className="mt-6">
                {activeTab === 'clubs' ? (
                  <VenueApiList city={selectedCity} />
                ) : activeTab === 'players' ? (
                  <CityMembersList city={selectedCity} />
                ) : (
                  <div className="relative min-h-[600px]">
                    {/* Scrollable content with blur */}
                    <div className="absolute inset-0 overflow-y-auto">
                      <div className="blur-sm pointer-events-none">
                        <CityVenuesList city={selectedCity} />
                      </div>
                    </div>

                    {/* Centered overlay */}
                    <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                      <div className="pointer-events-auto">
                        <BlurOverlay 
                          componentId="training"
                          title="Elite Training Network"
                          description="Connect with elite trainers worldwide. Available exclusively for Elite members."
                          mode="contained"
                          className="rounded-xl"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col space-y-6">
          <CityCardList
            cities={cities}
            onCityClick={handleCityClick}
          />
        </div>
      )}

      {showMembersModal && selectedCity && (
        <CityMembersModal
          city={selectedCity}
          isOpen={showMembersModal}
          onClose={() => setShowMembersModal(false)}
        />
      )}
    </div>
  );
};

export default FacilityManagement;