import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { useEffect, useState } from 'react';
import { Search, Filter, Loader2, FrownIcon } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { PLAYERS_CACHE_DURATION, playersCache } from '../lib/cache/players';
import { Player } from '../types/matchmaking';
import PlayerSkeleton from '../components/matchmaking/PlayerSkeleton';
import PlayerCardList from '../components/matchmaking/PlayerCardList';
import WelcomeMessage from '../components/matchmaking/WelcomeMessage';
import { Button } from '../components/ui/button';
import { cn } from '@/lib/utils';

const MatchMaking = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [sports, setSports] = useState<Set<string>>(new Set());
  const [cities, setCities] = useState<Set<string>>(new Set());
  const [cacheKey] = useState(`players-${Date.now()}`);

  const fetchWithRetry = async (operation: () => Promise<any>, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await operation();
      } catch (error) {
        if (i === retries - 1 || !(error instanceof Error) || !error.message.includes('Failed to fetch')) {
          throw error;
        }
        // Wait before retrying (exponential backoff)
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
      }
    }
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Check cache first
        const cached = playersCache.get('players');
        if (cached && Date.now() - cached.timestamp < PLAYERS_CACHE_DURATION) {
          console.log('Using cached players');
          setPlayers(cached.data);
          // Also restore sports and cities from cache
          if (cached.data.length > 0) {
            const cachedSports = new Set(cached.data.flatMap(p => p.sports));
            const cachedCities = new Set(cached.data.map(p => p.location));
            setSports(cachedSports);
            setCities(cachedCities);
          }
          setIsLoading(false);
          return;
        }

        // Get current user's ID with retry
        const { data: { user }, error: userError } = await fetchWithRetry(() => 
          supabase.auth.getUser()
        );

        if (userError) throw userError;
        if (!user) throw new Error('Not authenticated');

        // Fetch users with their profiles and sports
        const { data, error } = await fetchWithRetry(() =>
          supabase
            .from('users')
            .select(`
              *,
              user_profiles!inner (
                bio,
                preferences
              ),
              user_sports (
                sports (
                  name,
                  emoji
                )
              )
            `)
            .neq('auth_id', user.id)
        );

        if (error) throw error;

        // Transform data to match Player type
        const transformedPlayers = data.map(user => {
          const preferences = user.user_profiles.preferences || {};
          const userSports = user.user_sports?.map(us => 
            `${us.sports.emoji} ${us.sports.name}`
          ) || [];
          
          // Update sports and cities sets
          setSports(prev => new Set([...prev, ...userSports]));
          setCities(prev => new Set([...prev, user.city]));

          return {
            id: user.auth_id,
            name: user.full_name,
            age: preferences.age || 0,
            location: user.city,
            sports: userSports,
            rating: preferences.rating || 4.5,
            matchPercentage: Math.floor(Math.random() * 15 + 85),
            availability: ['morning', 'afternoon', 'evening'],
            image: user.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
            company: {
              name: preferences.company,
              position: preferences.jobTitle,
              industry: preferences.industry
            },
            distance: 0,
            recentActivity: 'Recently active'
          };
        });

        setPlayers(transformedPlayers);

        // Cache the results
        playersCache.set('players', {
          data: transformedPlayers,
          timestamp: Date.now()
        });

      } catch (error) {
        console.error('Error fetching players:', error);
        if (error instanceof Error && error.message === 'Failed to fetch') {
          setError('Unable to connect to server. Please check your connection.');
        } else {
          setError(error instanceof Error ? error.message : 'Failed to load players');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlayers();
  }, [cacheKey]); // Only re-run if cache key changes

  // Filter players based on search and filters
  const filteredPlayers = players.filter(player => {
    const matchesSearch = !searchTerm || 
      player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      player.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      player.sports.some(sport => sport.toLowerCase().includes(searchTerm.toLowerCase())) ||
      player.company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      player.company.position.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesSport = selectedSport === 'all' || player.sports.includes(selectedSport);
    const matchesCity = selectedCity === 'all' || player.location === selectedCity;

    return matchesSearch && matchesSport && matchesCity;
  });

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">Find & Play</h1>
              <p className="text-dark-800 mt-1">Find your perfect sports partner</p>
            </div>
          </div>
        </div>

        {/* Search and Filters */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <div className="flex flex-col space-y-4">
            {/* Search Bar */}
            <div className="flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                <input
                  type="text"
                  placeholder="Search players, sports, locations, companies..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
                />
              </div>
              <button 
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center space-x-2 px-3 py-2 bg-dark-300 rounded-lg hover:bg-dark-400 transition-colors"
              >
                <Filter className="h-5 w-5 text-dark-800" />
                <span className="text-white text-sm">{showFilters ? 'Hide Filters' : 'Show Filters'}</span>
              </button>
            </div>

            {/* Filters */}
            {showFilters && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <select
                  value={selectedSport}
                  onChange={(e) => setSelectedSport(e.target.value)}
                  className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                >
                  <option value="all">All Sports</option>
                  {[...sports].sort().map((sport) => (
                    <option key={sport} value={sport}>{sport}</option>
                  ))}
                </select>

                <select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                >
                  <option value="all">All Cities</option>
                  {[...cities].sort().map((city) => (
                    <option key={city} value={city}>{city}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Loading State */}
        {isLoading && (
          <PlayerSkeleton count={5} />
        )}

        {/* Error State */}
        {error && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
            <p className="text-center mb-2">
              {error === 'Failed to fetch' 
                ? 'Unable to connect to server. Please check your connection.' 
                : error}
            </p>
            <button 
              onClick={() => window.location.reload()}
              className="text-sm underline mt-2 hover:text-red-400 mx-auto block"
            >
              Try Again
            </button>
          </div>
        )}

        {/* Results Count */}
        {!isLoading && !error && (
          <div className="text-dark-800">
            Found {filteredPlayers.length} players
          </div>
        )}

        {/* Players List */}
        {!isLoading && !error && (
          <div className="space-y-4">
            {filteredPlayers.length > 0 ? (
              <PlayerCardList players={filteredPlayers} onMatchSelect={() => {}} />
            ) : (
              <div className="bg-dark-200 rounded-2xl p-6 sm:p-8 border border-dark-300 text-center">
                <div className="flex justify-center mb-4">
                  <FrownIcon className="h-12 w-12 text-dark-800" />
                </div>
                <h3 className="text-lg font-medium text-white mb-2">No Players Found</h3>
                <p className="text-dark-800 mb-6 max-w-md mx-auto">
                  Try adjusting your filters or search criteria to find more players.
                </p>
                <Button
                  onClick={() => {
                    setSearchTerm('');
                    setSelectedSport('all');
                    setSelectedCity('all');
                  }}
                  className="inline-flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
                >
                  Reset Filters
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default MatchMaking;