import React from 'react';

const MembershipCard: React.FC = () => {
  return (
    <div className="relative w-full h-full [transform-style:preserve-3d]">
      {/* Card Container */}
      <div className="absolute inset-0 rounded-xl overflow-hidden">
        {/* Base card image */}
        <img
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/professional_gold_card_optimized_horizontal-NgBaIbyTOCU7zJCe6fPBPM1RE78YY5.png"
          alt="Playclub.ai Professional Gold Card"
          className="w-full h-full object-contain"
        />
        
        {/* Holographic overlay */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-tr from-white/10 to-transparent opacity-50" />
          <div className="absolute inset-0 bg-gradient-to-t from-transparent via-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
        </div>
      </div>
    </div>
  );
};

export default MembershipCard;