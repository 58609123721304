import { useState, useCallback, useEffect } from 'react';
import { VenueApiResult } from '../../../../types/venue';
import { VenueService } from '../../../../lib/services/venues/venue.service';

export function useVenueSearch(city: string) {
  const [venues, setVenues] = useState<VenueApiResult[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showOpenOnly, setShowOpenOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch venues when component mounts or city changes
  useEffect(() => {
    const fetchVenues = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await VenueService.getVenues(city);
        setVenues(data);
      } catch (err) {
        console.error('Error fetching venues:', err);
        setError('Failed to load venues');
        setVenues([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVenues();
  }, [city]);

  // Filter venues based on search criteria
  const filteredVenues = venues.filter(venue => {
    // Search term filter
    const matchesSearch = !searchTerm || 
      venue.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      venue.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      venue.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      venue.activities.some(activity => 
        activity.toLowerCase().includes(searchTerm.toLowerCase())
      );

    // Tags filter
    const matchesTags = selectedTags.length === 0 || 
      selectedTags.some(tag => 
        venue.activities.includes(tag) || 
        venue.amenities.includes(tag)
      );

    // Open now filter
    const matchesOpenOnly = !showOpenOnly || 
      venue.opening_hours?.open_now === true;

    return matchesSearch && matchesTags && matchesOpenOnly;
  });

  return {
    searchTerm,
    setSearchTerm,
    selectedTags,
    setSelectedTags,
    showOpenOnly,
    setShowOpenOnly,
    filteredVenues,
    isLoading,
    error
  };
}