import React from 'react';
import { Activity } from '../../../../types/activity';
import { DateSelector, TimeSelector, VenueSelector } from './index';
import { Button } from '../../../ui/button';

interface ManageActivityFormProps {
  activity: Activity;
  onSubmit: (activity: Activity) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
}

const ManageActivityForm: React.FC<ManageActivityFormProps> = ({
  activity,
  onSubmit,
  onCancel,
  isSubmitting = false
}) => {
  // Ensure time is properly formatted when initializing form data
  const formatInitialTime = (time: string) => {
    if (!time) return '';
    console.log('Formatting initial time:', { rawTime: time });
    // If time includes seconds, remove them
    if (time.includes(':')) {
      const [hours, minutes] = time.split(':');
      const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
      console.log('Formatted time:', { formattedTime });
      return formattedTime;
    }
    return time;
  };

  console.log('Activity time:', { 
    originalTime: activity.time,
    formattedTime: formatInitialTime(activity.time)
  });

  const [formData, setFormData] = React.useState({
    date: activity.date || '',
    time: formatInitialTime(activity.time) || '',
    venue: activity.venue
  });

  console.log('Form data state:', formData);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData);
    onSubmit({
      ...activity,
      ...formData
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Header with opponent info */}
      <div className="flex items-center space-x-4 mb-6">
        <img
          src={activity.opponent.image}
          alt={activity.opponent.name}
          className="w-12 h-12 rounded-full object-cover"
        />
        <h3 className="text-lg font-medium text-white">
          {activity.sport} with {activity.opponent.name}
        </h3>
      </div>

      {/* Form Fields */}
      <div className="space-y-4">
        {/* Date and Time Row */}
        <div className="grid grid-cols-2 gap-4">
          <DateSelector
            selectedDate={formData.date}
            onDateChange={(date) => setFormData(prev => ({ ...prev, date: date }))}
          />
          <TimeSelector
            selectedTime={formData.time}
            onTimeChange={(time) => setFormData(prev => ({ ...prev, time: time }))}
          />
        </div>

        {/* Venue */}
        <VenueSelector
          venue={formData.venue}
          onVenueChange={(venue) => setFormData(prev => ({ ...prev, venue }))}
          city={activity.venue.city}
        />
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-3 pt-4">
        <Button
          type="button"
          onClick={onCancel}
          variant="outline"
          className="border-dark-400 text-white hover:bg-dark-400"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="bg-gold-400 hover:bg-gold-500 text-dark-50"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Update'}
        </Button>
      </div>
    </form>
  );
};

export default ManageActivityForm;