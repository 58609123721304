// Cache configuration
const POSTS_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const STALE_WHILE_REVALIDATE = 30 * 60 * 1000; // 30 minutes
const BATCH_SIZE = 10; // Number of posts to load per batch
const MAX_CACHE_SIZE = 50; // Maximum number of posts to cache
const MAX_STORAGE_SIZE = 2 * 1024 * 1024; // 2MB storage limit

// Memory cache for posts
interface PostsCacheEntry {
  data: any[];
  timestamp: number;
  isStale: boolean;
  userId: string;
  lastId?: string; // For pagination
}

const postsCache = new Map<string, PostsCacheEntry>();

// Helper function to clean avatar URLs from posts
function cleanPostData(posts: any[]) {
  return posts.map(post => ({
    ...post,
    author: {
      ...post.author,
      // Remove base64 avatar URLs
      image: post.author.image?.startsWith('data:image') 
        ? 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
        : post.author.image
    }
  }));
}

// Cache management functions
export function getCachedPosts(userId: string): any[] | null {
  const cached = postsCache.get(`posts-${userId}`);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  // Check if cache belongs to requested user
  if (cached.userId !== userId) {
    postsCache.delete(`posts-${userId}`);
    return null;
  }

  if (age < POSTS_CACHE_DURATION) {
    return cached.data;
  }

  // Allow stale data while revalidating
  if (age < STALE_WHILE_REVALIDATE) {
    cached.isStale = true;
    return cached.data;
  }

  postsCache.delete(`posts-${userId}`);
  return null;
}

// Helper function to get cached posts with pagination
function getCachedPostsPage(userId: string, lastId?: string): any[] | null {
  const cached = postsCache.get(`posts-${userId}`);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < POSTS_CACHE_DURATION) {
    if (lastId) {
      const lastIndex = cached.data.findIndex(post => post.id === lastId);
      if (lastIndex !== -1) {
        return cached.data.slice(lastIndex + 1, lastIndex + 1 + BATCH_SIZE);
      }
    }
    return cached.data.slice(0, BATCH_SIZE);
  }

  if (STALE_WHILE_REVALIDATE && age < STALE_WHILE_REVALIDATE) {
    cached.isStale = true;
    return cached.data;
  }

  postsCache.delete(`posts-${userId}`);
  return null;
}

export function setCachedPosts(userId: string, data: any[]) {
  // Clean data before caching
  const cleanedData = cleanPostData(data);

  // Limit number of cached posts
  const limitedData = cleanedData.slice(0, MAX_CACHE_SIZE);

  postsCache.set(`posts-${userId}`, {
    data: limitedData,
    timestamp: Date.now(),
    isStale: false,
    userId
  });

  try {
    const now = Date.now();
    Object.keys(localStorage)
      .filter(key => key.startsWith('posts-'))
      .forEach(key => {
        try {
          const cached = JSON.parse(localStorage.getItem(key) || '');
          if (now - cached.timestamp > STALE_WHILE_REVALIDATE) {
            localStorage.removeItem(key);
          }
        } catch {
          localStorage.removeItem(key);
        }
      });
  } catch (error) {
    console.warn('Error clearing old caches:', error);
  }
}

function clearPostsCache() {
  postsCache.clear();
  // Clear localStorage cache
  Object.keys(localStorage)
    .filter(key => key.startsWith('posts-'))
    .forEach(key => localStorage.removeItem(key));
}

// Load cache from localStorage on startup
export function initializePostsCache() {
  try {
    // Clear old caches
    const now = Date.now();
    Object.keys(localStorage)
      .filter(key => key.startsWith('posts-'))
      .forEach(key => {
        try {
          const cached = JSON.parse(localStorage.getItem(key) || '');
          if (!cached || now - cached.timestamp > STALE_WHILE_REVALIDATE) {
            localStorage.removeItem(key);
          }
        } catch {
          // Remove invalid cache entries
          localStorage.removeItem(key);
        }
      });

    Object.keys(localStorage)
      .filter(key => key.startsWith('posts-'))
      .forEach(key => {
        try {
          const cached = JSON.parse(localStorage.getItem(key) || '');
          if (cached && cached.timestamp > Date.now() - POSTS_CACHE_DURATION) {
            // Clean data before restoring to cache
            cached.data = cleanPostData(cached.data);
            postsCache.set(key, cached);
          } else {
            localStorage.removeItem(key);
          }
        } catch {
          // Remove invalid cache entries
          localStorage.removeItem(key);
        }
      });
  } catch (error) {
    console.warn('Failed to initialize posts cache:', error);
    // Clear all post caches on error
    Object.keys(localStorage)
      .filter(key => key.startsWith('posts-'))
      .forEach(key => localStorage.removeItem(key));
  }
}