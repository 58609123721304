import React from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import ChatLayout from '../components/chat/layout/ChatLayout';
import { useChat } from '../hooks/useChat';

const Messages = () => {
  const location = useLocation();
  const { 
    messages,
    isLoading,
    error,
    messageInput,
    setMessageInput,
    isTyping,
    handleSendMessage,
    handleMarkAsRead
  } = useChat();

  // Get active chat from location state
  const activeChat = location.state?.activeChat;
  const initialMessage = location.state?.initialMessage;
  const showMobileList = location.state?.showMobileList;

  // Set initial message if provided
  React.useEffect(() => {
    if (initialMessage) {
      setMessageInput(initialMessage);
    }
  }, [initialMessage, setMessageInput]);

  return (
    <DashboardLayout>
      <ChatLayout
        messages={messages}
        isLoading={isLoading}
        error={error}
        messageInput={messageInput}
        setMessageInput={setMessageInput}
        isTyping={isTyping}
        onSendMessage={handleSendMessage}
        onMarkAsRead={handleMarkAsRead}
        initialChat={activeChat}
        showMobileList={showMobileList}
      />
    </DashboardLayout>
  );
};

export default Messages;