import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createActivity } from '../lib/supabase/activities';
import { Activity } from '../types/activity';
import { useNotificationStore } from '../store/notificationStore';

export function useActivityCreation() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  const handleCreateActivity = async (activity: Omit<Activity, 'id'>) => {
    setIsSubmitting(true);
    setIsSuccess(false);
    try {
      await createActivity(activity);

      // Add notification for success
      addNotification({
        id: `activity_${Date.now()}`,
        type: 'match_request',
        title: 'Activity Request Sent',
        message: `Your request to play ${activity.sport} with ${activity.opponent.name} has been sent.`,
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/activities'
      });

      setIsSuccess(true);
    } catch (error) {
      console.error('Failed to create activity:', error);
      
      // Add error notification
      addNotification({
        id: `error_${Date.now()}`,
        type: 'new_message',
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to create activity. Please try again.',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/activities'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    isSuccess,
    handleCreateActivity
  };
}