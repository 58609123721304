import { useState, useEffect } from 'react';

export function useCounterAnimation(
  endValue: number,
  duration: number = 2000,
  startOnView: boolean = false
) {
  const [count, setCount] = useState(0);
  const [shouldStart, setShouldStart] = useState(!startOnView);

  useEffect(() => {
    if (!shouldStart) return;

    let startTime: number | null = null;
    let animationFrame: number;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      
      setCount(Math.floor(progress * endValue));

      if (progress < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    animationFrame = requestAnimationFrame(animate);

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [endValue, duration, shouldStart]);

  return {
    count,
    startAnimation: () => setShouldStart(true)
  };
}