import { createClient } from '@supabase/supabase-js';

const SESSION_CONFIG = {
  autoRefreshToken: true,
  persistSession: true,
  detectSessionInUrl: true,
  flowType: 'implicit',
  // Keep session alive for 7 days
  localStorage: {
    expiryDays: 7
  },
  // Refresh token 5 minutes before expiry
  refreshTokenThreshold: 300
};

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: SESSION_CONFIG
});