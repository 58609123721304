import React from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { timePeriods } from '../constants';
import { cn } from '@/lib/utils';

interface TimeSelectionStepProps {
  selectedTimes: string[];
  onTimesChange: (times: string[]) => void;
}

// Map time periods to actual times
const timeMap: Record<string, string> = {
  'morning': '09:00',
  'noon': '12:00',
  'afternoon': '14:00',
  'evening': '17:00',
  'night': '20:00'
};

const TimeSelectionStep: React.FC<TimeSelectionStepProps> = ({
  selectedTimes,
  onTimesChange
}) => {
  const { isDarkMode } = useTheme();

  const handleTimeSelect = (value: string) => {
    if (value === 'any') {
      // If 'any' is selected, toggle between all times and no times
      if (selectedTimes.length === Object.keys(timeMap).length) {
        onTimesChange([]);
        return;
      }
      // Select all times
      onTimesChange(Object.values(timeMap));
      return;
    }

    // Toggle the selected time
    const timeValue = timeMap[value];
    onTimesChange(prev => {
      if (prev.includes(timeValue)) {
        return prev.filter(t => t !== timeValue);
      }
      return [...prev, timeValue];
    });
  };

  return (
    <div className="space-y-4">
      <h3 className={cn(
        "text-xl font-semibold",
        isDarkMode ? "text-white" : "text-gray-900"
      )}>
        What times work best?
      </h3>
      <p className={cn(
        isDarkMode ? "text-dark-800" : "text-gray-500"
      )}>
        Select your preferred time slots
      </p>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
        {timePeriods.map((period) => {
          const isSelected = period.value === 'any' 
            ? selectedTimes.length === Object.keys(timeMap).length
            : selectedTimes.includes(timeMap[period.value]);
          const isDisabled = false; // Remove disabled state since we're allowing individual selections
          
          return (
            <button
              key={period.value}
              onClick={() => handleTimeSelect(period.value)}
              disabled={isDisabled}
              className={cn(
                "flex flex-col items-center p-4 rounded-xl transition-all duration-300",
                "transform hover:scale-105 active:scale-95",
                "border",
                isSelected
                  ? isDarkMode
                    ? "bg-gold-400 text-dark-50 border-gold-500"
                    : "bg-gold-400 text-dark-50 border-gold-500"
                  : isDisabled
                    ? isDarkMode
                      ? "bg-dark-400 text-dark-800 cursor-not-allowed border-dark-500"
                      : "bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200"
                    : isDarkMode
                      ? "bg-dark-300 text-white hover:bg-dark-400 border-dark-400"
                      : "bg-gray-100 text-gray-900 hover:bg-gray-200 border-gray-200",
                "shadow-sm hover:shadow-md"
              )}
            >
              <span className={cn(
                "font-medium mb-1",
                isSelected ? "text-dark-50" : isDisabled ? "text-dark-800" : ""
              )}>
                {period.label}
              </span>
              <span className={cn(
                "text-sm opacity-80",
                isSelected ? "text-dark-50" : isDisabled ? "text-dark-800" : ""
              )}>
                {period.time}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TimeSelectionStep;