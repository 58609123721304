// Cache configuration
export const CACHE_CONFIG = {
  duration: 60 * 1000, // 1 minute
  staleWhileRevalidate: true,
  retryAttempts: 3,
  retryDelay: 1000,
  networkTimeout: 5000
};

// Generic cache class
export class Cache<T> {
  private cache: Map<string, { data: T; timestamp: number }>;
  private name: string;

  constructor(name: string) {
    this.cache = new Map();
    this.name = name;
  }

  get(key: string): T | null {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const now = Date.now();
    const age = now - cached.timestamp;

    if (age < CACHE_CONFIG.duration) {
      console.log(`Using cached ${this.name} data for ${key}`);
      return cached.data;
    }

    if (CACHE_CONFIG.staleWhileRevalidate && age < CACHE_CONFIG.duration * 2) {
      console.log(`Using stale ${this.name} data for ${key} while revalidating`);
      return cached.data;
    }

    this.cache.delete(key);
    return null;
  }

  set(key: string, data: T) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  delete(key: string) {
    this.cache.delete(key);
  }

  clear() {
    this.cache.clear();
  }
}

// Network utilities
async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  options: {
    retries?: number;
    delay?: number;
    timeout?: number;
    onError?: (error: Error) => void;
  } = {}
): Promise<T> {
  const {
    retries = CACHE_CONFIG.retryAttempts,
    delay = CACHE_CONFIG.retryDelay,
    timeout = CACHE_CONFIG.networkTimeout,
    onError
  } = options;

  let lastError: Error;

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      const result = await Promise.race([
        operation(),
        new Promise<never>((_, reject) => {
          controller.signal.addEventListener('abort', () => {
            reject(new Error('Request timeout'));
          });
        })
      ]);

      clearTimeout(timeoutId);
      return result;
    } catch (error) {
      lastError = error instanceof Error ? error : new Error('Unknown error');
      
      const isNetworkError = 
        lastError.message.includes('Failed to fetch') ||
        lastError.message.includes('NetworkError') ||
        lastError.message.includes('timeout');

      if (isNetworkError && attempt < retries - 1) {
        console.log(`Retry attempt ${attempt + 1} of ${retries}`);
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, attempt)));
        continue;
      }

      if (onError) {
        onError(lastError);
      }
      
      throw lastError;
    }
  }

  throw lastError!;
}