import { Conversation } from '../types/chat';

// Additional conversations (6-20)
export const additionalConversations: Conversation[] = [
  {
    id: '6',
    name: 'Alexander Smith',
    image: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&w=300&q=80',
    city: 'Zurich',
    lastMessage: "I'll reserve the tennis court for Saturday morning.",
    time: '5:45',
    unread: 0,
    online: true
  },
  {
    id: '7',
    name: 'Isabella Weber',
    image: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?auto=format&fit=crop&w=300&q=80',
    city: 'Geneva',
    lastMessage: 'The ski conditions are perfect this weekend!',
    time: '4:30',
    unread: 2,
    online: false
  },
  {
    id: '8',
    name: 'James Thompson',
    image: 'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?auto=format&fit=crop&w=300&q=80',
    city: 'Frankfurt',
    lastMessage: 'Looking forward to our squash match tomorrow.',
    time: '3:15',
    unread: 0,
    online: true
  },
  {
    id: '9',
    name: 'Sophia Laurent',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&w=300&q=80',
    city: 'Paris',
    lastMessage: 'The Roland Garros courts are booked for us.',
    time: '2:50',
    unread: 1,
    online: false
  },
  {
    id: '10',
    name: 'Marcus Bennett',
    image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&w=300&q=80',
    city: 'London',
    lastMessage: 'Great game at the Lords Cricket Ground!',
    time: '1:30',
    unread: 0,
    online: true
  }
];

// Export message threads
export { additionalMessages } from './mockMessages';