import React from 'react';

interface AcceptButtonProps {
  onClick: () => void;
}

const AcceptButton: React.FC<AcceptButtonProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition-colors"
  >
    Accept
  </button>
);

export default AcceptButton;