import React from 'react';
import { useNavigate } from 'react-router-dom';
import { X, MessageCircle } from 'lucide-react';
import { members } from '../../data/members';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import ConnectionButton from './ConnectionButton';

interface MemberConnectionsProps {
  memberId: string;
  onClose: () => void;
}

const MemberConnections: React.FC<MemberConnectionsProps> = ({ memberId, onClose }) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const member = members.find(m => m.id === memberId);

  const memberConnections = members
    .filter(m => m.id !== memberId)
    .slice(0, member?.connections || 0)
    .map(m => ({
      ...m,
      connectionDate: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString(),
      mutualConnections: Math.floor(Math.random() * 10) + 1
    }));

  const handleProfileClick = (connectionId: string) => {
    onClose();
    navigate(`/dashboard/members/${connectionId}`);
  };

  const handleMessageClick = (connectionId: string, connectionName: string) => {
    navigate('/dashboard/messages', { 
      state: { 
        activeChat: connectionId,
        initialMessage: `Hi ${connectionName}!`,
        showMobileList: false
      }
    });
    onClose();
  };

  if (!member) return null;

  return (
    <div className="fixed inset-0 z-50 bg-dark-50/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className={cn(
            "relative w-full max-w-3xl rounded-2xl border",
            "max-h-[90vh] overflow-hidden flex flex-col",
            isDarkMode ? "bg-dark-200 border-dark-300" : "bg-white border-gray-200"
          )}>
            {/* Header */}
            <div className={cn(
              "p-4 sm:p-6 border-b flex-shrink-0",
              isDarkMode ? "border-dark-300" : "border-gray-200"
            )}>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className={cn(
                    "text-lg sm:text-xl font-bold pr-8",
                    isDarkMode ? "text-white" : "text-gray-900"
                  )}>
                    {member.name}'s Connections
                  </h2>
                  <p className={isDarkMode ? "text-dark-800" : "text-gray-500"}>
                    {memberConnections.length} connections
                  </p>
                </div>
                <button
                  onClick={onClose}
                  className={cn(
                    "p-2 rounded-lg transition-colors",
                    isDarkMode 
                      ? "text-dark-800 hover:text-white hover:bg-dark-400" 
                      : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                  )}
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Connections List */}
            <div className="flex-1 overflow-y-auto p-4 sm:p-6">
              <div className="space-y-4">
                {memberConnections.map((connection) => (
                  <div
                    key={connection.id}
                    className={cn(
                      "rounded-xl p-4 transition-colors",
                      isDarkMode 
                        ? "bg-dark-300 hover:bg-dark-400" 
                        : "bg-gray-50 hover:bg-gray-100"
                    )}
                  >
                    <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
                      {/* Profile Image */}
                      <button
                        onClick={() => handleProfileClick(connection.id)}
                        className="flex-shrink-0"
                      >
                        <img
                          src={connection.image}
                          alt={connection.name}
                          className={cn(
                            "w-16 h-16 rounded-full object-cover border-2 transition-colors",
                            isDarkMode 
                              ? "border-dark-400 hover:border-gold-400" 
                              : "border-gray-200 hover:border-gold-600"
                          )}
                        />
                      </button>

                      {/* Info and Actions */}
                      <div className="flex-1 min-w-0 flex flex-col sm:flex-row items-center sm:items-start justify-between gap-3 w-full">
                        <div className="text-center sm:text-left">
                          <button
                            onClick={() => handleProfileClick(connection.id)}
                            className={cn(
                              "font-medium hover:text-gold-400 transition-colors text-left truncate",
                              isDarkMode ? "text-white" : "text-gray-900"
                            )}
                          >
                            {connection.name}
                          </button>
                          <div className={cn(
                            "text-sm",
                            isDarkMode ? "text-dark-800" : "text-gray-500"
                          )}>
                            {connection.jobTitle} at {connection.company}
                          </div>
                          {connection.mutualConnections && connection.mutualConnections > 0 && (
                            <div className={cn(
                              "text-sm mt-1",
                              isDarkMode ? "text-dark-800" : "text-gray-500"
                            )}>
                              {connection.mutualConnections} mutual connection{connection.mutualConnections !== 1 ? 's' : ''}
                            </div>
                          )}
                        </div>

                        {/* Actions */}
                        <div className="flex items-center gap-2 w-full sm:w-auto">
                          <ConnectionButton
                            isConnected={connection.isConnected || false}
                            memberId={connection.id}
                            memberName={connection.name}
                            memberImage={connection.image}
                            onConnect={async () => {}}
                            onDisconnect={async () => {}}
                            className="w-full sm:w-auto min-w-[100px]"
                          />
                          <button 
                            onClick={() => handleMessageClick(connection.id, connection.name)}
                            className={cn(
                              "p-2 rounded-lg transition-colors flex-shrink-0",
                              isDarkMode 
                                ? "bg-dark-400 hover:bg-dark-500 text-white" 
                                : "bg-gray-100 hover:bg-gray-200 text-gray-700"
                            )}
                          >
                            <MessageCircle className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberConnections;
