import { useState, useCallback, useEffect } from 'react';
import { toggleLike, checkPostLike } from '../../lib/supabase/social';

interface UsePostInteractionsProps {
  postId: string;
  initialLikes: number;
  initialShares: number;
}

export function usePostInteractions({ postId, initialLikes, initialShares }: UsePostInteractionsProps) {
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(initialLikes);
  const [sharesCount, setSharesCount] = useState(initialShares);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Check initial like status
  useEffect(() => {
    let mounted = true;

    const checkLikeStatus = async () => {
      try {
        const hasLiked = await checkPostLike(postId);
        if (mounted) {
          setIsLiked(hasLiked);
        }
      } catch (error) {
        console.error('Error checking like status:', error);
        if (mounted) {
          setError(error instanceof Error ? error.message : 'Failed to check like status');
        }
      }
    };

    checkLikeStatus();

    return () => {
      mounted = false;
    };
  }, [postId]);

  const handleLike = useCallback(async () => {
    if (isLoading) return;

    // Optimistically update UI
    setIsLiked(prev => !prev);
    setLikesCount(prev => prev + (isLiked ? -1 : 1));

    setIsLoading(true);
    setError(null);

    try {
      const newIsLiked = await toggleLike(postId);
      // Only update if the request was successful
      if (newIsLiked !== isLiked) {
        setIsLiked(newIsLiked);
        setLikesCount(prev => newIsLiked ? prev + 1 : prev - 1);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      // Only show error if it's not a connection issue
      if (!(error instanceof Error) || !error.message.includes('Failed to fetch')) {
        setError(error instanceof Error ? error.message : 'Failed to toggle like');
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLiked, likesCount, postId, isLoading]);

  const handleShare = useCallback(() => {
    setSharesCount(prev => prev + 1);
  }, []);

  return {
    isLiked,
    likesCount,
    sharesCount,
    isLoading,
    error,
    handleLike,
    handleShare
  };
}