import React, { useState, useMemo } from 'react';
import { MapPin } from 'lucide-react';
import { cn } from '@/lib/utils';
import { facilities } from '../../../../data/facilities';
import { Venue } from '../../../../types/activity';

interface VenueSelectorProps {
  venue: Venue;
  onVenueChange: (venue: Venue) => void;
  city: string;
  className?: string;
}

const VenueSelector: React.FC<VenueSelectorProps> = ({
  venue,
  onVenueChange,
  city,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(venue.name);

  // Get venues for the selected city
  const cityVenues = useMemo(() => 
    facilities
      .filter(f => f.location.toLowerCase() === city.toLowerCase())
      .sort((a, b) => a.name.localeCompare(b.name)),
    [city]
  );

  // Filter venues based on input
  const filteredVenues = useMemo(() => {
    const search = inputValue.toLowerCase();
    return cityVenues.filter(venue => 
      venue.name.toLowerCase().includes(search) ||
      venue.address.toLowerCase().includes(search)
    );
  }, [cityVenues, inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setIsOpen(true);
    
    // Update venue with input value
    if (value.trim()) {
      onVenueChange({
        name: value,
        address: value,
        city: city
      });
    }
  };

  const handleVenueSelect = (selectedVenue: typeof cityVenues[0]) => {
    setInputValue(selectedVenue.name);
    onVenueChange({
      name: selectedVenue.name,
      address: selectedVenue.address,
      city: selectedVenue.location
    });
    setIsOpen(false);
  };

  return (
    <div className={cn("space-y-2", className)}>
      <label className="block text-dark-800">Venue</label>
      <div className="relative">
        <div className="relative">
          <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setTimeout(() => setIsOpen(false), 200)}
            placeholder="Enter or select venue"
            className={cn(
              "w-full pl-10 pr-4 py-2 bg-dark-300 border border-dark-400 rounded-xl",
              "text-white placeholder-dark-800",
              "focus:outline-none focus:ring-2 focus:ring-gold-400"
            )}
          />
        </div>

        {/* Dropdown for venue suggestions */}
        {isOpen && filteredVenues.length > 0 && (
          <div className="absolute z-50 w-full mt-1 bg-dark-200 border border-dark-400 rounded-xl shadow-lg max-h-[200px] overflow-y-auto">
            <div className="p-1">
              {filteredVenues.map((venue) => (
                <button
                  key={venue.id}
                  onClick={() => handleVenueSelect(venue)}
                  className="w-full text-left px-3 py-2 hover:bg-dark-300 rounded-lg transition-colors"
                >
                  <div className="text-white">{venue.name}</div>
                  <div className="text-xs text-dark-800">{venue.address}</div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Show selected venue address if it's a known venue */}
      {venue.address && venue.address !== venue.name && cityVenues.some(v => v.name === venue.name) && (
        <div className="text-sm text-dark-800 pl-10">
          {venue.address}
        </div>
      )}
    </div>
  );
};

export default VenueSelector;