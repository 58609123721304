import React from 'react';
import { MenuButton } from './MenuButton';
import { MenuList } from './MenuList';

interface ChatMenuProps {
  show: boolean;
  onToggle: () => void;
  onBlock: () => void;
  onReport: () => void;
}

export const ChatMenu: React.FC<ChatMenuProps> = ({
  show,
  onToggle,
  onBlock,
  onReport
}) => {
  return (
    <div className="relative">
      <MenuButton onClick={onToggle} />
      {show && (
        <MenuList
          show={show}
          onBlock={onBlock}
          onReport={onReport}
        />
      )}
    </div>
  );
};