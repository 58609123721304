import { supabase } from '../supabase';

class AvatarCache {
  private static instance: AvatarCache;
  private cache: Map<string, string>;
  private readonly defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';
  private storageKey = 'playclub_avatar_cache';
  private readonly expiryTime = 24 * 60 * 60 * 1000; // 24 hours for regular users
  private readonly currentUserExpiryTime = 7 * 24 * 60 * 60 * 1000; // 7 days for current user
  private readonly maxCacheSize = 100; // Maximum number of avatars to cache
  private readonly maxStorageSize = 2 * 1024 * 1024; // 2MB storage limit

  private constructor() {
    this.cache = new Map();
    this.loadFromStorage();
    this.cleanExpiredEntries();
  }

  static getInstance(): AvatarCache {
    if (!AvatarCache.instance) {
      AvatarCache.instance = new AvatarCache();
    }
    return AvatarCache.instance;
  }

  getDefaultAvatar(): string {
    return this.defaultAvatar;
  }

  private cleanExpiredEntries() {
    const now = Date.now();
    try {
      // Get current user ID
      const { data: { user } } = supabase.auth.getUser();
      const currentUserId = user?.id;

      const cached = localStorage.getItem(this.storageKey);
      if (cached) {
        const parsed = JSON.parse(cached);
        let hasChanges = false;
        
        Object.entries(parsed).forEach(([key, value]) => {
          const entry = value as { url: string; timestamp: number };
          const isCurrentUser = key === currentUserId;
          const expiryTime = isCurrentUser ? this.currentUserExpiryTime : this.expiryTime;
          
          if (now - entry.timestamp > expiryTime) {
            this.cache.delete(key);
            hasChanges = true;
          }
        });
        
        if (hasChanges) {
          this.saveToStorage();
        }
      }
    } catch (error) {
      console.warn('Failed to clean expired entries:', error);
    }
  }

  private loadFromStorage() {
    try {
      const cached = localStorage.getItem(this.storageKey);
      if (cached && cached.length < this.maxStorageSize) {
        const parsed = JSON.parse(cached);
        Object.entries(parsed).forEach(([key, value]) => {
          const entry = value as { url: string; timestamp: number };
          this.cache.set(key, entry.url);
        });
      } else if (cached) {
        // If cache is too large, clear it
        localStorage.removeItem(this.storageKey);
        this.cache.clear();
      }
    } catch (error) {
      console.warn('Failed to load avatar cache:', error);
    }
  }

  private saveToStorage() {
    try {
      // Enforce cache size limit
      while (this.cache.size > this.maxCacheSize) {
        const oldestKey = this.cache.keys().next().value;
        this.cache.delete(oldestKey);
      }

      const data = Object.fromEntries(
        Array.from(this.cache.entries()).map(([key, url]) => [
          key,
          { url, timestamp: Date.now() }
        ])
      );
      
      const serialized = JSON.stringify(data);
      if (serialized.length < this.maxStorageSize) {
        localStorage.setItem(this.storageKey, serialized);
      } else {
        // If data is too large, only store current user's avatar
        const currentUser = supabase.auth.getUser();
        if (currentUser) {
          const currentUserData = {
            [currentUser.id]: data[currentUser.id]
          };
          localStorage.setItem(this.storageKey, JSON.stringify(currentUserData));
        }
        // Clear cache except for current user
        this.cleanCache();
      }
    } catch (error) {
      console.warn('Failed to save avatar cache:', error);
      // If quota exceeded, clear old entries
      if (error instanceof Error && error.name === 'QuotaExceededError') {
        this.cleanCache();
      }
    }
  }

  private isValidUrl(url: string): boolean {
    if (!url || url === 'null' || url === 'undefined') return false;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  setAvatar(userId: string, url: string) {
    if (!this.isValidUrl(url)) {
      console.warn('Invalid avatar URL:', url);
      return;
    }
    this.cache.set(userId, url);
    try {
      this.saveToStorage();
    } catch (error) {
      console.warn('Failed to save avatar to cache:', error);
      // Continue without caching if storage fails
    }
  }

  getAvatar(userId: string): string {
    const cached = this.cache.get(userId);
    if (cached && this.isValidUrl(cached)) {
      return cached;
    }
    
    // Try to load from storage
    const stored = localStorage.getItem(`avatar_${userId}`);
    if (stored && this.isValidUrl(stored)) {
      this.setAvatar(userId, stored);
      return stored;
    }
    
    // Return default if no valid avatar found
    if (cached) {
      this.cache.delete(userId); // Clear invalid cached value
    }
    return this.defaultAvatar;
  }

  clearInvalidCache() {
    for (const [key, value] of this.cache.entries()) {
      if (!this.isValidUrl(value)) {
        this.cache.delete(key);
      }
    }
  }

  clearCache() {
    this.cache.clear();
    localStorage.removeItem(this.storageKey);
  }

  async updateAvatarUrl(userId: string, url: string) {
    try {
      if (!this.isValidUrl(url)) {
        throw new Error('Invalid avatar URL');
      }

      const { error } = await supabase.auth.updateUser({
        data: { avatar_url: url }
      });

      if (error) throw error;
      
      this.setAvatar(userId, url);
      return true;
    } catch (error) {
      console.error('Failed to update avatar:', error);
      return false;
    }
  }
}

export const avatarCache = AvatarCache.getInstance();