import React from 'react';

interface UserAvatarProps {
  image: string;
  name: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ image, name }) => (
  <img
    src={image}
    alt={name}
    className="w-14 h-14 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-colors"
  />
);