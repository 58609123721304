import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../ui/button';
import { cn } from '@/lib/utils';
import { CheckCircle2, Trophy, Users, Calendar } from 'lucide-react';

interface CompletionStepProps {
  onComplete: () => void;
}

const CompletionStep: React.FC<CompletionStepProps> = ({ onComplete }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="h-full flex flex-col bg-dark-200 rounded-xl p-4 sm:p-6 lg:p-8 border border-dark-300"
    >
      {/* Success Animation */}
      <motion.div 
        className="flex-shrink-0 mb-6 sm:mb-8"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ 
          type: "spring",
          stiffness: 260,
          damping: 20 
        }}
      >
        <div className="bg-green-400/20 p-4 rounded-full w-16 h-16 mx-auto flex items-center justify-center">
          <CheckCircle2 className="h-8 w-8 text-green-400" />
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center justify-between max-w-md mx-auto w-full">
        <div className="text-center mb-8">
          <motion.h2 
            className="text-2xl sm:text-3xl font-bold text-white mb-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            You're in. Let's play!
          </motion.h2>
          <motion.p 
            className="text-dark-800 text-sm sm:text-base"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Your profile is now complete. Get ready to connect, play, and network with elite professionals.
          </motion.p>
        </div>

        {/* Features Grid */}
        <motion.div 
          className="grid grid-cols-3 gap-3 w-full mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <div className="bg-dark-300 rounded-xl p-3 text-center">
            <Trophy className="h-6 w-6 text-gold-400 mb-2 mx-auto" />
            <h3 className="text-sm font-medium text-white mb-1">Find Players & Matches</h3>
          
          </div>
          
          <div className="bg-dark-300 rounded-xl p-3 text-center">
            <Users className="h-6 w-6 text-gold-400 mb-2 mx-auto" />
            <h3 className="text-sm font-medium text-white mb-1">Network</h3>

          </div>
          
          <div className="bg-dark-300 rounded-xl p-3 text-center">
            <Calendar className="h-6 w-6 text-gold-400 mb-2 mx-auto" />
            <h3 className="text-sm font-medium text-white mb-1">Book Sessions in Exclusive Clubs</h3>
          
          </div>
        </motion.div>

        {/* Enter Button */}
        <motion.div
          className="w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <Button
            onClick={() => {
              window.location.replace('https://playclub.ai');
            }}
            className={cn(
              "w-full sm:w-auto px-6 py-3 sm:px-8 sm:py-4",
              "text-base sm:text-lg font-medium",
              "bg-gradient-to-r from-gold-400 to-gold-500",
              "hover:from-gold-500 hover:to-gold-600",
              "text-dark-50 rounded-xl",
              "transform transition-all duration-300",
              "hover:scale-105 hover:shadow-lg",
              "focus:outline-none focus:ring-2 focus:ring-gold-400 focus:ring-offset-2 focus:ring-offset-dark-200"
            )}
          >
            Enter Playclub
          </Button>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default CompletionStep;