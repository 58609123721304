import React from 'react';
import { CheckCircle2 } from 'lucide-react';
import { Button } from '../../ui/button';

interface SuccessMessageProps {
  onClose: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ onClose }) => {
  return (
    <div className="text-center py-6">
      <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
        <CheckCircle2 className="h-8 w-8 text-dark-50" />
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">Update Sent!</h3>
      <p className="text-dark-800 mb-6">
        Your activity update request has been sent. You'll be notified once they respond.
      </p>
      <Button
        onClick={onClose}
        className="bg-gold-400 hover:bg-gold-500 text-dark-50 w-full"
      >
        Done
      </Button>
    </div>
  );
};

export default SuccessMessage;