import React from 'react';
import { Star } from 'lucide-react';
import { Member } from '../../../types/member';

interface ProfileStatsProps {
  member: Member;
  onShowCities: () => void;
  onShowConnections: () => void;
  onShowReviews: () => void;
}

const ProfileStats: React.FC<ProfileStatsProps> = ({
  member,
  onShowCities,
  onShowConnections,
  onShowReviews
}) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div className="bg-dark-300 rounded-xl p-4 text-center">
        <div className="text-2xl font-bold text-gold-400">{member.matchesPlayed}</div>
        <div className="text-dark-800 text-sm mt-1">Matches Played</div>
      </div>
      
      <button
        onClick={onShowCities}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="text-2xl font-bold text-gold-400">{member.citiesPlayed.length}</div>
        <div className="text-dark-800 text-sm mt-1">Cities Played</div>
      </button>
      
      <button
        onClick={onShowConnections}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="text-2xl font-bold text-gold-400">{member.connections}</div>
        <div className="text-dark-800 text-sm mt-1">Connections</div>
      </button>
      
      <button
        onClick={onShowReviews}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="flex items-center justify-center text-2xl font-bold text-gold-400">
          {member.rating}
          <Star className="h-5 w-5 ml-1" />
        </div>
        <div className="text-dark-800 text-sm mt-1">
          {member.isTrainer ? `${member.trainerInfo?.reviews || 0} Reviews` : 'Rating'}
        </div>
      </button>
    </div>
  );
};

export default ProfileStats;