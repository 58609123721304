import { useState, useCallback } from 'react';
import { useConnectionStore } from '../store/connectionStore';
import { useNotificationStore } from '../store/notificationStore';
import { supabase } from '../lib/supabase';
import { 
  sendConnectionRequest,
  acceptConnectionRequest,
  declineConnectionRequest
} from '../lib/supabase/connections';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export function useConnection() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addConnection, removeConnection, updateConnectionStatus } = useConnectionStore();
  const { addNotification } = useNotificationStore();

  const connect = useCallback(async (memberId: string, memberName: string, memberImage: string) => {
    setIsLoading(true);
    setError(null);
    console.log('Initiating connection request:', { 
      memberId, 
      memberName,
      memberImage 
    });

    try {
      // Get current user with retry
      const { data: { user }, error: userError } = await fetchWithRetry(() => 
        supabase.auth.getUser()
      );

      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      // Get initiator's database ID
      const { data: initiatorData, error: initiatorError } = await fetchWithRetry(() =>
        supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single()
      );

      if (initiatorError) throw initiatorError;
      if (!initiatorData) throw new Error('User profile not found');

      // Get receiver's database ID
      const { data: receiverData, error: receiverError } = await fetchWithRetry(() =>
        supabase
        .from('users')
        .select('id')
        .eq('auth_id', memberId)
        .single()
      );

      if (receiverError) throw receiverError;
      if (!receiverData) throw new Error('Member not found');

      // Check if connection exists
      const { data: existingConnection } = await fetchWithRetry(() =>
        supabase
        .from('connections')
        .select('id, status')
        .or(`and(initiator_id.eq.${initiatorData.id},receiver_id.eq.${receiverData.id}),and(initiator_id.eq.${receiverData.id},receiver_id.eq.${initiatorData.id})`)
        .maybeSingle()
      );

      if (existingConnection) {
        if (existingConnection.status === 'pending') {
          throw new Error('Connection request already sent');
        }
        if (existingConnection.status === 'accepted') {
          throw new Error('Already connected');
        }
      }

      // Create connection request
      const { data: connection, error: connectionError } = await fetchWithRetry(() =>
        supabase
        .from('connections')
        .insert({
          initiator_id: initiatorData.id,
          receiver_id: receiverData.id,
          status: 'pending'
        })
        .select(`
          id,
          status,
          created_at,
          initiator:initiator_id(id, auth_id, full_name, avatar_url),
          receiver:receiver_id(id, auth_id, full_name, avatar_url)
        `)
        .single()
      );

      if (connectionError) throw connectionError;
      if (!connection) throw new Error('Failed to create connection request');

      addConnection(connection);
      
      addNotification({
        id: `connection_${Date.now()}`,
        type: 'connection_request',
        title: 'Connection Request Sent',
        message: `Your connection request has been sent to ${memberName}`,
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/network',
        sender: {
          id: memberId,
          name: memberName,
          image: memberImage
        }
      });

      return true;
    } catch (error) {
      console.error('Failed to connect:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to send connection request';
      setError(errorMessage);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [addConnection, addNotification]);

  const acceptRequest = useCallback(async (connectionId: string) => {
    setIsLoading(true);
    setError(null);
    console.log('Accepting connection request:', { connectionId });
    try {
      const success = await acceptConnectionRequest(connectionId);
      if (!success) {
        throw new Error('Failed to accept connection request');
      }

      console.log('Connection request accepted successfully');
      updateConnectionStatus(connectionId, 'accepted');

      // Add notification
      addNotification({
        id: `connection_accepted_${Date.now()}`,
        type: 'connection_accepted',
        title: 'Connection Accepted',
        message: 'You are now connected!',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/network'
      });

      // Add notification
      addNotification({
        id: `connection_accepted_${Date.now()}`,
        type: 'connection_accepted',
        title: 'Connection Accepted',
        message: 'You are now connected!',
        timestamp: new Date().toISOString(),
        read: false,
        actionUrl: '/dashboard/network'
      });

      return true;
    } catch (err) {
      console.error('Failed to accept connection:', err);
      console.log('Failed to accept connection:', {
        error: err instanceof Error ? err.message : 'Unknown error',
        connectionId
      });
      setError(err instanceof Error ? err.message : 'Failed to accept connection request');
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [updateConnectionStatus]);

  const declineRequest = useCallback(async (connectionId: string) => {
    setIsLoading(true);
    setError(null);
    console.log('Declining connection request:', { connectionId });
    try {
      await declineConnectionRequest(connectionId);
      console.log('Connection request declined successfully');
      removeConnection(connectionId);
      return true;
    } catch (err) {
      console.error('Failed to decline connection:', err);
      console.log('Failed to decline connection:', {
        error: err instanceof Error ? err.message : 'Unknown error',
        connectionId
      });
      setError(err instanceof Error ? err.message : 'Failed to decline connection request');
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [removeConnection]);

  return {
    isLoading,
    error,
    connect,
    acceptRequest,
    declineRequest
  };
}