// src/components/hero/HeroContent.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { LogIn } from 'lucide-react';

interface HeroContentProps {
  onSignIn: () => void;
  onRequestAccess: () => void;
}

export const HeroContent: React.FC<HeroContentProps> = ({
  onSignIn,
  onRequestAccess
}) => {
  return (
    <motion.section 
      className={cn(
        "max-w-4xl mx-auto text-center",
        "px-4 sm:px-6 lg:px-8",
        "mt-12 sm:mt-24 lg:mt-32", // Reduced top margin for smaller screens
        "mb-24 sm:mb-32" // Added bottom margin
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1 
        className={cn(
          "font-sans font-bold mb-8",
          "text-5xl sm:text-6xl md:text-7xl lg:text-8xl",
          "bg-clip-text text-transparent",
          "bg-gradient-to-r from-white via-amber-200 to-amber-400",
          "tracking-tight leading-tight"
        )}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          delay: 0.2,
          duration: 0.8,
          ease: [0.4, 0, 0.2, 1]
        }}
      >
        Play the World.
      </motion.h1>
      
      <motion.p 
        className={cn(
          "font-sans font-light mb-16",
          "text-xl sm:text-2xl md:text-3xl",
          "text-gray-300",
          "max-w-2xl mx-auto mb-8 sm:mb-16", // Adjusted bottom margin
          "leading-relaxed"
        )}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          delay: 0.4,
          duration: 0.8,
          ease: [0.4, 0, 0.2, 1]
        }}
      >
        The global network for private club members—connect through Sports and Wellness when traveling.
      </motion.p>
      
      <motion.div 
        className="mb-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          delay: 0.6,
          duration: 0.8,
          ease: [0.4, 0, 0.2, 1]
        }}
      >
        <h2 className={cn(
          "font-sans font-light mb-8",
          "text-2xl sm:text-3xl",
          "text-amber-400",
          "tracking-wide",
          "mt-4 sm:mt-8" // Added top margin
        )}>
          Private Invite Only
        </h2>
        
        <div className="flex flex-col items-center space-y-6">
          <Button
            onClick={onSignIn}
            className={cn(
              "relative overflow-hidden group",
              "bg-gradient-to-r from-amber-400 via-yellow-400 to-amber-500",
              "text-dark-50 font-light",
              "h-16 px-12",
              "rounded-full",
              "text-xl tracking-wide", 
              "transform transition-all duration-500 hover:scale-105",
              "shadow-[0_0_15px_rgba(251,191,36,0.3)] hover:shadow-[0_0_35px_rgba(251,191,36,0.5)]",
              "border border-amber-400/20",
              "flex items-center justify-center gap-3"
            )}
          >
            <span className="relative z-10 font-medium">Sign In</span>
            <LogIn className="relative z-10 w-6 h-6" />
            <div 
              className={cn(
                "absolute inset-0",
                "bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-500",
                "opacity-0 group-hover:opacity-100",
                "transition-all duration-500",
                "blur-[0.5px]"
              )} 
            />
            {/* Gold shimmer effect */}
            <div 
              className={cn(
                "absolute inset-0 -translate-x-full",
                "bg-gradient-to-r from-transparent via-white/20 to-transparent", 
                "group-hover:translate-x-full",
                "transition-transform duration-1000 ease-in-out",
                "z-20"
              )}
            />
          </Button>
          
          <div className="text-gray-400 font-sans">
            Not a member yet?{' '}
            <button
              onClick={onRequestAccess}
              className={cn(
                "text-amber-400 hover:text-amber-300",
                "transition-colors duration-200",
                "font-medium"
              )}
            >
              Apply for Membership
            </button>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

