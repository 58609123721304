import { City } from '../../../types/city';
import { GeocodingService } from './geocoding.service';
import { PhotonService } from '../photon';

export async function searchCities(query: string): Promise<City[]> {
  if (!query?.trim()) return [];
  
  try {
    // Try Photon first for better location suggestions
    let results = await PhotonService.searchCities(query);
    
    // Fallback to Geocoding service if no results
    if (!results.length) {
      results = await GeocodingService.searchCities(query);
    }

    return results;
  } catch (error) {
    console.error('Error searching cities:', error);
    return [];
  }
}