import React, { useState, useEffect } from 'react';
import { UserCircle } from 'lucide-react';
import { cn } from '@/lib/utils';
import { isValidUrl } from '@/lib/utils/url';

interface AvatarProps {
  src?: string;
  alt?: string;
  size?: number;
  onClick?: () => void;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  alt = 'Profile',
  size = 35,
  onClick,
  className
}) => {
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [isValidSrc, setIsValidSrc] = useState(false);

  // Reset error state if src changes
  useEffect(() => {
    setImageError(false);
    setImageLoading(true);
    setIsValidSrc(Boolean(src && src !== 'null' && src !== 'undefined' && isValidUrl(src)));
  }, [src]);

  const Component = onClick ? 'button' : 'div';

  const shouldShowImage = isValidSrc && !imageError;
  return (
    <Component
      {...(onClick ? { onClick, 'aria-label': 'View profile' } : {})}
      className={cn(
        "relative rounded-full overflow-hidden",
        "border-2 transition-all duration-200",
        onClick && "hover:border-gold-400 hover:scale-105 cursor-pointer",
        "dark:border-dark-400 dark:hover:border-gold-400",
        "light:border-light-300 light:hover:border-gold-600",
        className
      )}
      style={{ width: size, height: size }}
    >
      {shouldShowImage ? (
        <img
          src={src}
          alt={alt}
          className={cn(
            "w-full h-full object-cover transition-opacity duration-200",
            imageLoading ? "opacity-0" : "opacity-100"
          )}
          onError={() => setImageError(true)}
          onLoad={() => setImageLoading(false)}
          loading="eager"
        />
      ) : (
        <div className={cn(
          "w-full h-full flex items-center justify-center",
          "dark:bg-dark-300 dark:text-dark-800",
          "light:bg-light-200 light:text-light-600"
        )}>
          <UserCircle className="w-5/6 h-5/6" />
        </div>
      )}
      {imageLoading && isValidSrc && !imageError && (
        <div className="absolute inset-0 flex items-center justify-center bg-dark-300">
          <div className="w-1/2 h-1/2 rounded-full border-2 border-gold-400 border-t-transparent animate-spin" />
        </div>
      )}
    </Component>
  );
};

export default Avatar;