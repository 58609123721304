import React from 'react';
import { MapPin } from 'lucide-react';
import NavigationOptions from './NavigationOptions';
import type { Facility } from '../../types/facility';

interface VenueCardProps {
  facility: Facility;
}

const VenueCard: React.FC<VenueCardProps> = ({ facility }) => {
  return (
    <div className="bg-dark-200 rounded-xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300">
      <div className="relative h-48">
        <img
          src={facility.coverImage}
          alt={facility.name}
          className="w-full h-full object-cover"
        />
      </div>
      
      <div className="p-6">
        <h3 className="text-xl font-semibold text-white mb-2">{facility.name}</h3>
        <div className="flex items-center text-dark-800 mb-4">
          <MapPin className="h-4 w-4 mr-2" />
          <span>{facility.address}, {facility.location}</span>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {facility.amenities.map((amenity, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white"
            >
              {amenity}
            </span>
          ))}
        </div>

        <NavigationOptions
          venueName={facility.name}
          coordinates={facility.coordinates}
        />
      </div>
    </div>
  );
};

export default VenueCard;