import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { Member } from '../../types/member';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';
import ConnectionStats from './stats/ConnectionStats';
import RatingStats from './stats/RatingStats';
import { useAuth } from '../../contexts/AuthContext';

interface ProfileStatsProps {
  member: Member;
  onShowCities: () => void;
  onShowConnections: () => void;
  onShowReviews: () => void;
}

const ProfileStats: React.FC<ProfileStatsProps> = ({
  member,
  onShowCities,
  onShowConnections,
  onShowReviews
}) => {
  const { user } = useAuth();
  const [connectionCount, setConnectionCount] = useState(member.connections);
  const [isLoadingConnections, setIsLoadingConnections] = useState(false);
  const [matchesPlayed, setMatchesPlayed] = useState(member.matchesPlayed);
  const [isLoadingMatches, setIsLoadingMatches] = useState(false);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  useEffect(() => {
    const fetchConnectionCount = async () => {
      try {
        // Get the correct auth_id based on whether this is the current user's profile
        const targetAuthId = member.id === 'current-user' ? user?.id : member.id;
        
        if (!targetAuthId) {
          console.warn('No valid auth ID available');
          return;
        }

        setIsLoadingConnections(true);
        
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', targetAuthId)
          .single();

        if (userError) throw userError;

        // Get connection count
        const { count, error: countError } = await supabase
          .from('connections')
          .select('*', { count: 'exact', head: true })
          .or(`initiator_id.eq.${userData.id},receiver_id.eq.${userData.id}`)
          .eq('status', 'accepted');

        if (countError) throw countError;

        if (count !== null) {
          setConnectionCount(count);
        }
      } catch (error) {
        console.error('Error fetching connection count:', error);
        // Keep existing count on error
      } finally {
        setIsLoadingConnections(false);
      }
    };

    fetchConnectionCount();
  }, [member.id]);

  useEffect(() => {
    const fetchMatchesPlayed = async () => {
      try {
        // Get the correct auth_id based on whether this is the current user's profile
        const targetAuthId = member.id === 'current-user' ? user?.id : member.id;
        
        if (!targetAuthId) {
          console.warn('No valid auth ID available');
          return;
        }

        setIsLoadingMatches(true);
        let retries = MAX_RETRIES;

        while (retries > 0) {
          try {
            // Get user's database ID
            const { data: userData, error: userError } = await supabase
              .from('users')
              .select('id')
              .eq('auth_id', targetAuthId)
              .single();

            if (userError) throw userError;

            // Get all past activities where user was either player 1 or player 2
            const today = new Date().toISOString().split('T')[0];
            const { count, error: countError } = await supabase
              .from('activities')
              .select('*', { count: 'exact', head: true })
              .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
              .lt('date', today)
              .eq('status', 'played');

            if (countError) throw countError;

            if (count !== null) {
              console.log('Past activities count:', count);
              setMatchesPlayed(count);
            }
            break; // Success, exit retry loop
          } catch (err) {
            if (err instanceof Error && err.message.includes('Failed to fetch')) {
              retries--;
              if (retries > 0) {
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                continue;
              }
            }
            throw err;
          }
        }
      } catch (error) {
        console.error('Error fetching matches played:', error);
        // Keep existing count on error
        setMatchesPlayed(prev => prev);
      } finally {
        setIsLoadingMatches(false);
      }
    };

    fetchMatchesPlayed();
  }, [member.id, member.matchesPlayed, user?.id]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div className={cn(
        "rounded-xl p-4 text-center",
        isLoadingMatches ? "animate-pulse" : "",
        "bg-light-200",
        "dark:bg-dark-300"
      )}>
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {matchesPlayed}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Matches Played
        </div>
      </div>
      
      <button
        onClick={onShowCities}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {member.citiesPlayed.length}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Cities Played
        </div>
      </button>
      
      <button
        onClick={onShowConnections}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          isLoadingConnections ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {connectionCount}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Connections
        </div>
      </button>
      
      <button 
        onClick={onShowReviews}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "flex items-center justify-center text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {member.rating}
          <Star className="h-5 w-5 ml-1" />
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          {member.isTrainer ? `${member.trainerInfo?.reviews || 0} Reviews` : 'Rating'}
        </div>
      </button>
    </div>
  );
};

export default ProfileStats;