import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import { useTheme } from '../../../contexts/ThemeContext';
import { Player } from '../../../types/matchmaking';
import PlayerCard from '../PlayerCard';
import PlayerCardList from '../PlayerCardList';
import NoPlayersFound from './NoPlayersFound';
import SearchingState from './SearchingState';

interface PlayerResultsProps {
  players: Player[];
  onMatchSelect: (player: Player) => void;
  onSearchAgain: () => void;
  isSearching?: boolean;
}

const PlayerResults: React.FC<PlayerResultsProps> = ({
  players,
  onMatchSelect,
  onSearchAgain,
  isSearching = false
}) => {
  const { isDarkMode } = useTheme();

  if (isSearching) {
    return <SearchingState onSearchComplete={() => {}} />;
  }

  if (players.length === 0) {
    return <NoPlayersFound onSearchAgain={onSearchAgain} />;
  }

  return (
    <AnimatePresence>
      <div className="space-y-4">
        {players.map((player, index) => (
          <motion.div
            key={player.id}
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ 
              duration: 0.3,
              delay: index * 0.05,
              layout: { duration: 0.3 }
            }}
          >
            <PlayerCard
              player={player}
              sports={player.sports}
              dates={[new Date().toISOString().split('T')[0]]}
              timeSlots={['morning', 'afternoon', 'evening']}
              onMatchSelect={() => onMatchSelect(player)}
              isDarkMode={isDarkMode}
            />
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  );
};

export default PlayerResults;