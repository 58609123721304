import React from 'react';
import { Star } from 'lucide-react';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface PlayerAvatarProps {
  image: string;
  name: string;
  rating: number;
  onClick: () => void;
}

const PlayerAvatar: React.FC<PlayerAvatarProps> = ({ image, name, rating, onClick }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="flex flex-col items-center sm:items-start gap-2">
      <button onClick={onClick} className="relative">
        <img
          src={image}
          alt={name}
          className={cn("w-20 h-20 rounded-full object-cover border-2 transition-colors",
            isDarkMode 
              ? "border-dark-400 hover:border-gold-400" 
              : "border-gray-200 hover:border-gold-600"
          )}
        />
      </button>
      <div className={cn(
        "flex items-center px-3 py-1.5 rounded-full transition-colors",
        isDarkMode 
          ? "bg-dark-300 hover:bg-dark-400" 
          : "bg-gray-100 hover:bg-gray-200"
      )}>
        <Star className="h-4 w-4 text-gold-400 mr-1" />
        <span className={isDarkMode ? "text-white" : "text-gray-900"}>
          {rating}
        </span>
      </div>
    </div>
  );
};

export default PlayerAvatar;