import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import WelcomeActions from './WelcomeActions';
import NewTripModal from '../profile/trips/NewTripModal';
import QuickMatchModal from '../matchmaking/QuickMatchModal';

const WelcomeMessage: React.FC = () => {
  const navigate = useNavigate();
  const [showTripModal, setShowTripModal] = useState(false);
  const [showQuickMatchModal, setShowQuickMatchModal] = useState(false);

  const handleQuickPlayClick = () => {
    navigate('/dashboard/quick-play', {
      replace: true,
      state: { searchKey: Date.now() }
    });
  };

  // Return null to hide the component
  return null;
};

export default WelcomeMessage;