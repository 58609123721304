import React from 'react';
import { AlertCircle, Building, Linkedin } from 'lucide-react';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { countries } from 'countries-list';
import CitySearchInput from '../../trips/CitySearchInput';
import { FormData } from '../../../lib/types';

interface RequestAccessFieldsProps {
  formData: FormData;
  setFormData: (data: FormData) => void;
  handleFieldBlur: (field: string) => void;
  getFieldError: (field: string) => null | string;
  isSubmitting: boolean;
  submitError?: string;
}

export const RequestAccessFields: React.FC<RequestAccessFieldsProps> = ({
  formData,
  setFormData,
  handleFieldBlur,
  getFieldError,
  isSubmitting,
  submitError
}) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <Input
            value={formData.fullName}
            onChange={(e) => setFormData({...formData, fullName: e.target.value})}
            onBlur={() => handleFieldBlur('fullName')}
            className="bg-dark-300 border-dark-400 text-white h-10 text-sm font-medium"
            placeholder="Full Name *"
          />
          {getFieldError('fullName') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('fullName')}
            </div>
          )}
        </div>

        <div className="col-span-2">
          <Input
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({...formData, email: e.target.value})}
            onBlur={() => handleFieldBlur('email')}
            className="bg-dark-300 border-dark-400 text-white h-10 text-sm font-medium"
            placeholder="Email *"
          />
          {getFieldError('email') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('email')}
            </div>
          )}
        </div>

        <div className="relative">
          <Building className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
          <Input
            value={formData.company}
            onChange={(e) => setFormData({...formData, company: e.target.value})}
            onBlur={() => handleFieldBlur('company')}
            className="pl-10 bg-dark-300 border-dark-400 text-white h-10 text-sm font-medium"
            placeholder="Company *"
          />
          {getFieldError('company') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('company')}
            </div>
          )}
        </div>

        <div>
          <Input
            value={formData.role}
            onChange={(e) => setFormData({...formData, role: e.target.value})}
            onBlur={() => handleFieldBlur('role')}
            className="bg-dark-300 border-dark-400 text-white h-10 text-sm font-medium"
            placeholder="Role *"
          />
          {getFieldError('role') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('role')}
            </div>
          )}
        </div>

        <div>
          <Select
            value={formData.country}
            onValueChange={(value) => {
              setFormData({...formData, country: value, city: ''});
              handleFieldBlur('country');
            }}
          >
            <SelectTrigger className="bg-dark-300 border-dark-400 text-white h-10">
              <SelectValue placeholder="Country *" />
            </SelectTrigger>
            <SelectContent className="max-h-[200px] overflow-y-auto bg-dark-300 border-dark-400 text-sm font-medium">
              {Object.entries(countries)
                .map(([code, country]) => ({
                  code,
                  name: country.name
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ code, name }) => (
                  <SelectItem 
                    key={code} 
                    value={name}
                    className="text-white hover:bg-dark-400 focus:bg-dark-400"
                  >
                    {name}
                  </SelectItem>
                ))
              }
            </SelectContent>
          </Select>
          {getFieldError('country') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('country')}
            </div>
          )}
        </div>

        <div>
          <CitySearchInput
            value={formData.city}
            onChange={(city) => {
              setFormData({...formData, city});
              handleFieldBlur('city');
            }}
            error={getFieldError('city')}
          />
        </div>

        <div>
          <Input
            type="date"
            value={formData.dateOfBirth}
            onChange={(e) => setFormData({...formData, dateOfBirth: e.target.value})}
            onBlur={() => handleFieldBlur('dateOfBirth')}
            className="bg-dark-300 border-dark-400 text-white h-10 text-sm font-medium"
            max={new Date().toISOString().split('T')[0]}
            placeholder="Date of Birth *"
            aria-label="Date of Birth (must be 18 or older)"
            title="Date of Birth (must be 18 or older)"
          />
          <p className="text-dark-800 text-xs mt-1">Must be 18+ years old to apply</p>
          {getFieldError('dateOfBirth') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('dateOfBirth')}
            </div>
          )}
        </div>

        <div>
          <Select
            value={formData.gender}
            onValueChange={(value) => {
              setFormData({...formData, gender: value});
              handleFieldBlur('gender');
            }}
          >
            <SelectTrigger className="bg-dark-300 border-dark-400 text-white h-10">
              <SelectValue placeholder="Gender" />
            </SelectTrigger>
            <SelectContent className="text-sm font-medium">
              <SelectItem value="male">Male</SelectItem>
              <SelectItem value="female">Female</SelectItem>
              <SelectItem value="rather_not_say">Rather not say</SelectItem>
            </SelectContent>
          </Select>
          {getFieldError('gender') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('gender')}
            </div>
          )}
        </div>

        <div className="col-span-2">
          <div className="relative">
            <Linkedin className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={formData.linkedinUrl || 'https://www.linkedin.com/in/'}
              onChange={(e) => setFormData({...formData, linkedinUrl: e.target.value})}
              onBlur={() => handleFieldBlur('linkedinUrl')}
              className="pl-10 bg-dark-300 border-dark-400 text-white h-10 text-sm font-medium"
              placeholder="LinkedIn Profile URL"
            />
          </div>
          {getFieldError('linkedinUrl') && (
            <div className="text-red-400 text-xs flex items-center mt-1">
              <AlertCircle className="h-3 w-3 mr-1" />
              {getFieldError('linkedinUrl')}
            </div>
          )}
        </div>
      </div>

      {submitError && (
        <div className="text-red-400 text-sm text-center flex items-center justify-center">
          <AlertCircle className="h-4 w-4 mr-2" />
          {submitError}
        </div>
      )}

      
      <Button
        type="submit"
        disabled={isSubmitting}
        className="relative w-full overflow-hidden group bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:via-yellow-600 hover:to-yellow-700 text-black font-light py-6 px-16 rounded-full transition-all duration-300 text-lg transform hover:scale-105 shadow-lg hover:shadow-xl border border-yellow-300 backdrop-blur-sm"
      >
        <span className="relative z-10">{isSubmitting ? 'Submitting...' : 'Apply for Membership'}</span>
        <div className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-yellow-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-200" />
      </Button>
    </>
  );
};