import React, { useState } from 'react';
import { 
  Bell, Lock, Palette, User
} from 'lucide-react';
import NotificationSettings from './sections/NotificationSettings';
import SecuritySettings from './sections/SecuritySettings';
import PreferenceSettings from './sections/PreferenceSettings';
import MyAccountSettings from './sections/MyAccountSettings';
import SettingsSidebar from './SettingsSidebar';

export type SettingsTab = 'account' | 'notifications' | 'security' | 'preferences';

export const settingsTabs = [
  { id: 'account', label: 'My Account', icon: User },
  { id: 'notifications', label: 'Notifications', icon: Bell },
  { id: 'security', label: 'Security', icon: Lock },
  { id: 'preferences', label: 'Preferences', icon: Palette }
] as const;

const SettingsPanel: React.FC = () => {
  const [activeTab, setActiveTab] = useState<SettingsTab>('account');

  const renderContent = () => {
    switch (activeTab) {
      case 'account':
        return <MyAccountSettings />;
      case 'notifications':
        return <NotificationSettings />;
      case 'security':
        return <SecuritySettings />;
      case 'preferences':
        return <PreferenceSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
      {/* Sidebar */}
      <div className="lg:col-span-1">
        <SettingsSidebar activeTab={activeTab} onTabChange={setActiveTab} />
      </div>

      {/* Content */}
      <div className="lg:col-span-3">
        {renderContent()}
      </div>
    </div>
  );
};

export default SettingsPanel;