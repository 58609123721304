import React, { memo } from 'react';
import { Linkedin, Facebook, Instagram } from 'lucide-react';
import { cn } from '@/lib/utils';

export interface SocialLinksProps {
  linkedinUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  className?: string;
}

const SocialLinks = memo(function SocialLinks({
  linkedinUrl,
  facebookUrl,
  instagramUrl,
  className
}) {
  const handleLinkClick = (url?: string) => {
    if (url) {
      // Ensure URL has protocol
      const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };


  return (
    <div className={cn("flex items-center justify-center space-x-3", className)}>
      <button
        onClick={() => handleLinkClick(linkedinUrl)}
        className={cn(
          "p-1.5 rounded-lg transition-colors",
          linkedinUrl 
            ? "text-dark-800 hover:text-[#0077b5] hover:bg-dark-300" 
            : "text-dark-800/50 cursor-not-allowed"
        )}
        disabled={!linkedinUrl}
        title={linkedinUrl ? "LinkedIn Profile" : "No LinkedIn URL provided"}
        aria-label={linkedinUrl ? "Visit LinkedIn Profile" : "LinkedIn not provided"}
      >
        <Linkedin className="h-5 w-5" />
      </button>

      <button
        onClick={() => handleLinkClick(facebookUrl)}
        className={cn(
          "p-1.5 rounded-lg transition-colors",
          facebookUrl 
            ? "text-dark-800 hover:text-[#1877f2] hover:bg-dark-300" 
            : "text-dark-800/50 cursor-not-allowed"
        )}
        disabled={!facebookUrl}
        title={facebookUrl ? "Facebook Profile" : "No Facebook URL provided"}
        aria-label={facebookUrl ? "Visit Facebook Profile" : "Facebook not provided"}
      >
        <Facebook className="h-5 w-5" />
      </button>

      <button
        onClick={() => handleLinkClick(instagramUrl)}
        className={cn(
          "p-1.5 rounded-lg transition-colors",
          instagramUrl 
            ? "text-dark-800 hover:text-[#e4405f] hover:bg-dark-300" 
            : "text-dark-800/50 cursor-not-allowed"
        )}
        disabled={!instagramUrl}
        title={instagramUrl ? "Instagram Profile" : "No Instagram URL provided"}
        aria-label={instagramUrl ? "Visit Instagram Profile" : "Instagram not provided"}
      >
        <Instagram className="h-5 w-5" />
      </button>
    </div>
  );
});

export default SocialLinks;