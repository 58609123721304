import { Venue, VenueSuggestion } from '../types/venue';
import { venues } from '../data/venues';
import { searchLocation, MapLocation } from './mapping';
import { CITY_CENTERS } from './mapping/constants';

function getVenuesByLocation(location: string): Venue[] {
  return venues.filter(venue => 
    venue.location.toLowerCase() === location.toLowerCase()
  );
}

function getVenuesBySport(sport: string): Venue[] {
  return venues.filter(venue => 
    venue.category.toLowerCase() === sport.toLowerCase()
  );
}

function getVenuesByLocationAndSport(location: string, sport: string): Venue[] {
  return venues.filter(venue => 
    venue.location.toLowerCase() === location.toLowerCase() &&
    venue.category.toLowerCase() === sport.toLowerCase()
  );
}

function formatVenueSuggestion(venue: Venue): VenueSuggestion {
  return {
    id: venue.id,
    name: venue.name,
    location: venue.address,
    type: venue.type,
    category: venue.category,
    coordinates: venue.coordinates
  };
}

function searchVenues(query: string, city?: string): VenueSuggestion[] {
  const normalizedQuery = query.toLowerCase();
  
  return venues
    .filter(venue => {
      if (city && venue.location.toLowerCase() !== city.toLowerCase()) {
        return false;
      }
      
      return venue.name.toLowerCase().includes(normalizedQuery) ||
             venue.address.toLowerCase().includes(normalizedQuery);
    })
    .map(formatVenueSuggestion);
}

export async function searchVenuesWithGeocode(
  query: string,
  city: string
): Promise<(VenueSuggestion | MapLocation)[]> {
  if (!query.trim() || !city || !CITY_CENTERS[city]) {
    return [];
  }

  try {
    // Get venue suggestions first
    const venueSuggestions = searchVenues(query, city);
    
    // Get location suggestions from Photon, filtered by city
    const locationResults = await searchLocation(query, city);
    
    // Combine results, removing duplicates
    const combinedResults = [
      ...venueSuggestions,
      ...locationResults.filter(loc => 
        !venueSuggestions.some(venue => 
          venue.name.toLowerCase() === (loc.name || '').toLowerCase()
        )
      )
    ];

    return combinedResults;
  } catch (error) {
    console.error('Error searching venues:', error);
    return searchVenues(query, city); // Fallback to venue-only search
  }
}