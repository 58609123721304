import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface GalleryControlsProps {
  onPrevious: () => void;
  onNext: () => void;
  showControls: boolean;
}

export const GalleryControls: React.FC<GalleryControlsProps> = ({
  onPrevious,
  onNext,
  showControls
}) => {
  if (!showControls) return null;

  return (
    <>
      <button
        onClick={onPrevious}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-dark-200/80 hover:bg-dark-300/80 rounded-full transition-colors"
        aria-label="Previous photo"
      >
        <ChevronLeft className="h-6 w-6 text-white" />
      </button>
      <button
        onClick={onNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-dark-200/80 hover:bg-dark-300/80 rounded-full transition-colors"
        aria-label="Next photo"
      >
        <ChevronRight className="h-6 w-6 text-white" />
      </button>
    </>
  );
};