import React, { useState } from 'react';
import { Crown } from 'lucide-react';
import EliteApplicationSuccess from './EliteApplicationSuccess';

const BlurredProgressOverlay: React.FC = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  if (showSuccess) {
    return <EliteApplicationSuccess onClose={() => setShowSuccess(false)} />;
  }

  return (
    <div className="backdrop-blur-sm bg-dark-200/30 rounded-2xl p-8 max-w-md mx-auto text-center">
      <div className="bg-gold-400/20 p-4 rounded-full w-16 h-16 mx-auto mb-6 flex items-center justify-center">
        <Crown className="h-8 w-8 text-gold-400" />
      </div>
      <h3 className="text-2xl font-semibold text-white mb-3">Elite Progress Tracking</h3>
      <p className="text-dark-800 mb-6">Track your improvement with advanced analytics and AI-powered insights. Available exclusively for Elite members.</p>
      <button 
        onClick={() => setShowSuccess(true)}
        className="px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
      >
        Apply to Elite
      </button>
    </div>
  );
};

export default BlurredProgressOverlay;