import { VenueServiceError } from './types';

export class VenueError extends Error implements VenueServiceError {
  constructor(
    message: string,
    public readonly code: string,
    public readonly status?: number,
    public readonly details?: unknown
  ) {
    super(message);
    this.name = 'VenueError';
  }

  static fromError(error: unknown): VenueError {
    if (error instanceof VenueError) return error;

    if (error instanceof Error) {
      return new VenueError(error.message, 'UNKNOWN_ERROR');
    }

    return new VenueError('An unknown error occurred', 'UNKNOWN_ERROR');
  }
}