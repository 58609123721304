
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users2 } from 'lucide-react';
import { members } from '../../data/members';
import PlayerCard from '../matchmaking/PlayerCard';
import RequestMatchModal from '../matchmaking/RequestMatchModal';
import { Player } from '../../types/matchmaking';

interface CityMembersListProps {
  city: string;
}

const CityMembersList: React.FC<CityMembersListProps> = ({ city }) => {
  const navigate = useNavigate();
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  // Convert members to Player type for consistency
  const cityPlayers: Player[] = members
    .filter(member => member.city === city)
    .map(member => ({
      id: member.id,
      name: member.name,
      age: member.age,
      location: member.city,
      distance: 0, // Same city, so distance is 0
      sports: member.sports,
      rating: member.rating,
      matchPercentage: Math.floor(Math.random() * 15 + 85), // 85-100%
      availability: member.availability,
      image: member.image,
      recentActivity: `Active ${Math.floor(Math.random() * 3) + 1}h ago`,
      company: {
        name: member.company,
        position: member.jobTitle,
        industry: member.industry
      },
      isConnected: member.isConnected,
      mutualConnections: Math.floor(Math.random() * 10) + 1
    }));

  if (cityPlayers.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl">
        <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Players Found</h3>
        <p className="text-dark-800">
          No players are currently available in {city}
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {cityPlayers.map((player) => (
        <PlayerCard
          key={player.id}
          player={player}
          sports={player.sports}
          dates={[new Date().toISOString().split('T')[0]]}
          timeSlots={['morning', 'afternoon', 'evening']}
          onMatchSelect={() => {}}
        />
      ))}
    </div>
  );
};

export default CityMembersList;
