import React from 'react';
import { FrownIcon, RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { useTheme } from '../../../contexts/ThemeContext';

interface NoPlayersFoundProps {
  onSearchAgain: () => void;
}

const NoPlayersFound: React.FC<NoPlayersFoundProps> = ({ onSearchAgain }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className={cn(
        "text-center py-12 rounded-xl",
        isDarkMode ? "bg-dark-300" : "bg-gray-50"
      )}
    >
      <FrownIcon className={cn(
        "h-12 w-12 mx-auto mb-4",
        isDarkMode ? "text-dark-800" : "text-gray-400"
      )} />
      
      <h3 className={cn(
        "text-xl font-semibold mb-2",
        isDarkMode ? "text-white" : "text-gray-900"
      )}>
        No Players Found
      </h3>
      
      <p className={cn(
        "mb-6",
        isDarkMode ? "text-dark-800" : "text-gray-500"
      )}>
        Try adjusting your filters to find more players
      </p>
      
      <button
        onClick={onSearchAgain}
        className={cn(
          "inline-flex items-center space-x-2 p-2 rounded-lg transition-colors",
          isDarkMode
            ? "bg-dark-400 hover:bg-dark-500 text-white-50"
            : "bg-gray-50 hover:bg-gray-100 text-dark-50"
        )}
      >
        <RefreshCw className="h-5 w-5" />
      </button>
    </motion.div>
  );
};

export default NoPlayersFound;