import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { VenuePhoto } from '@/types/venue';
import { GalleryControls } from './GalleryControls';
import { GalleryThumbnails } from './GalleryThumbnails';

interface VenueGalleryProps {
  photos: VenuePhoto[];
  isOpen: boolean;
  onClose: () => void;
  venueName: string;
}

export const VenueGallery: React.FC<VenueGalleryProps> = ({
  photos,
  isOpen,
  onClose,
  venueName
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl p-0 bg-dark-200 border-dark-300">
        <DialogTitle className="sr-only">
          {venueName} Photo Gallery
        </DialogTitle>
        
        <div className="relative">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 z-50 p-2 bg-dark-200/80 hover:bg-dark-300/80 rounded-full transition-colors"
            aria-label="Close gallery"
          >
            <X className="h-5 w-5 text-white" />
          </button>

          {/* Main image */}
          <div className="relative aspect-video">
            <img
              src={photos[currentIndex].photo_reference || photos[currentIndex].url}
              alt={`${venueName} photo ${currentIndex + 1}`}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Navigation controls */}
          <GalleryControls
            onPrevious={handlePrevious}
            onNext={handleNext}
            showControls={photos.length > 1}
          />

          {/* Thumbnails */}
          <GalleryThumbnails
            photos={photos}
            currentIndex={currentIndex}
            onSelect={setCurrentIndex}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};