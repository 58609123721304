import { useState, useCallback } from 'react';
import { getUserLocation } from '@/lib/utils/location';
import { searchCities } from '@/lib/services/geocoding';

export function useAddressSearch() {
  const [address, setAddress] = useState('');
  const [referencePoint, setReferencePoint] = useState<{ lat: number; lng: number } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAddressChange = useCallback(async (newAddress: string) => {
    setAddress(newAddress);
    setIsLoading(true);
    setError(null);

    if (!newAddress.trim()) {
      try {
        const location = await getUserLocation();
        setReferencePoint({
          lat: location.latitude,
          lng: location.longitude
        });
      } catch (err) {
        console.error('Error getting user location:', err);
        setError('Could not get current location');
      }
      setIsLoading(false);
      return;
    }

    try {
      const results = await searchCities(newAddress);
      if (results.length > 0 && results[0].coordinates) {
        setReferencePoint(results[0].coordinates);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      console.error('Error searching location:', err);
      setError('Error finding location');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const resetLocation = useCallback(async () => {
    setAddress('');
    setError(null);
    try {
      const location = await getUserLocation();
      setReferencePoint({
        lat: location.latitude,
        lng: location.longitude
      });
    } catch (err) {
      console.error('Error resetting location:', err);
      setError('Could not get current location');
    }
  }, []);

  return {
    address,
    referencePoint,
    isLoading,
    error,
    setAddress: handleAddressChange,
    setReferencePoint,
    resetLocation
  };
}