import { useState, useEffect, RefObject } from 'react';

interface ScrollIndicators {
  showLeftArrow: boolean;
  showRightArrow: boolean;
}

export function useScrollIndicators(scrollRef: RefObject<HTMLElement>) {
  const [indicators, setIndicators] = useState<ScrollIndicators>({
    showLeftArrow: false,
    showRightArrow: false
  });

  const checkScroll = () => {
    const el = scrollRef.current;
    if (!el) return;

    const hasLeftScroll = el.scrollLeft > 0;
    const hasRightScroll = el.scrollLeft < (el.scrollWidth - el.clientWidth - 1);

    setIndicators({
      showLeftArrow: hasLeftScroll,
      showRightArrow: hasRightScroll
    });
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    // Initial check
    checkScroll();

    // Add event listeners
    const observer = new ResizeObserver(checkScroll);
    observer.observe(el);
    el.addEventListener('scroll', checkScroll);

    return () => {
      observer.disconnect();
      el.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return { indicators, checkScroll };
}