import { VenueApiResult } from '../../../types/venue';
import { SingaporeVenue } from '../../../types/singapore-venue';
import { formatVenueData } from './venue.formatter';
import { VenueCache } from './cache';
import { VenueError } from './error';

const SINGAPORE_VENUES_URL = 'https://raw.githubusercontent.com/uri100/json_pc/main/singapore_venues.json';
const CACHE_KEY = 'singapore_venues';

export class SingaporeVenueService {
  static async getVenues(): Promise<VenueApiResult[]> {
    try {
      // Check cache first
      const cachedData = VenueCache.get(CACHE_KEY);
      if (cachedData) return cachedData;

      const response = await fetch(SINGAPORE_VENUES_URL);
      
      if (!response.ok) {
        throw new VenueError(
          'Failed to fetch Singapore venues',
          'FETCH_ERROR',
          response.status
        );
      }

      const data = await response.json();
      
      if (!data || typeof data !== 'object') {
        throw new VenueError(
          'Invalid venue data format',
          'INVALID_DATA'
        );
      }

      const venues = Object.values(data)
        .filter((venue): venue is SingaporeVenue => (
          this.isValidVenue(venue)
        ))
        .map(formatVenueData);

      // Cache the results
      VenueCache.set(CACHE_KEY, venues);

      return venues;

    } catch (error) {
      const venueError = VenueError.fromError(error);
      console.error('Error fetching Singapore venues:', venueError);
      
      // Return cached data if available
      return VenueCache.get(CACHE_KEY) || [];
    }
  }

  private static isValidVenue(venue: unknown): venue is SingaporeVenue {
    return Boolean(
      venue &&
      typeof venue === 'object' &&
      'name' in venue &&
      'geometry' in venue &&
      (venue as any).geometry?.location?.lat &&
      (venue as any).geometry?.location?.lng
    );
  }
}